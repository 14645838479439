import { Box, Skeleton, Typography, useTheme } from "@mui/material";
import { FC } from "react";
import { HELOCFormProps } from "typedef/HELOCFormProps";

const INTEREST_RATE_MULTIPLIER = 10000;
const PERCENTAGE = 100;
const AMOUNT_OF_DECIMALS = 2;

const InterestRate: FC<HELOCFormProps> = ({ newValuesSlider }) => {
  const theme = useTheme();
  const monthlyPaymentInitialDraw = newValuesSlider?.monthlyPaymentInitialDraw;

  return (
    <Box
      display="grid"
      gridTemplateColumns="1fr 1fr"
      alignItems="center"
      sx={{ backgroundColor: theme.palette.background.paper, p: 2 }}
    >
      <Typography
        variant="h6"
        fontFamily="Pro Display Regular"
        color={theme.palette.text.primary}
        sx={{ fontSize: { xs: "1rem", md: "1.3rem" } }}
      >
        Interest Rate:
      </Typography>
      <Box
        sx={{
          flexDirection: {
            xs: "column",
            md: "row",
            alignItems: { xs: "flex-start", md: "center" },
          },
        }}
        gap={1}
        display="flex"
        alignItems="center"
      >
        {newValuesSlider ? (
          <>
            <Typography
              variant="h5"
              display="flex"
              alignItems="center"
              color={theme.palette.text.primary}
              fontFamily={"Pro Display Bold"}
              sx={{
                width: "fit-content",
                fontSize: { xs: "1rem", md: "1.3rem" },
                display: "flex",
                alignItems: "center",
              }}
            >
              {(
                Math.floor(
                  (newValuesSlider?.interestRate ?? 0) *
                    INTEREST_RATE_MULTIPLIER,
                ) / PERCENTAGE
              ).toFixed(AMOUNT_OF_DECIMALS)}
              %
            </Typography>
            <Box
              alignItems="center"
              sx={{ fontSize: { xs: "0.7rem", md: "1rem" } }}
            >
              <Typography
                fontWeight={400}
                fontFamily={"Pro Display Regular"}
                color={theme.palette.text.disabled}
                sx={{ fontSize: { xs: "0.7rem", md: "1rem" } }}
              >
                ($
                {Number(monthlyPaymentInitialDraw?.toFixed(0))?.toLocaleString(
                  "en-US",
                )}{" "}
                Est. Monthly Payment)
              </Typography>
            </Box>
          </>
        ) : (
          <Skeleton width="75%" height={"1.5rem"} />
        )}
      </Box>
    </Box>
  );
};

export default InterestRate;
