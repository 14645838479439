import React, { useEffect, useRef, useState } from "react";
import { motion, useInView } from "framer-motion";
import digitalImage from "assets/Images/digital-loan.png";
import {
  Box,
  Container,
  List,
  ListItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import { handleOcuppancy } from "screens/BorrowerRegisterForm/useRegisterForm";
import API from "utils/API";
import numberToKString from "utils/numberToKString";

const MotionListItem = motion(ListItem);

const listVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
    },
  },
};

export default function CHomeEquity() {
  const theme = useTheme();
  const { privateLabel } = usePrivateLabel();
  const privateLabelId = privateLabel?.id;

  const [rateMortgageAvailable, setRateMortgageAvailable] = useState({
    minLoanAmount: 0,
    maxLoanAmount: 0,
  });
  const occupencyAvailable = privateLabel?.occupancyTypesEnabled.map(
    (occupency) => handleOcuppancy(occupency),
  );
  const loadedMinMaxPricingEngine = useRef(false);

  const ref = useRef(null);
  const inView = useInView(ref, { once: true, amount: 0.5 });

  const getMinMaxPricingEngine = async (encodePricingEngineIds: string) => {
    API.get(`/get-heloc-pricing-engine?ids=${encodePricingEngineIds}`).then(
      (result) => {
        if ("error" in result) {
          return console.error(result.error);
        } else {
          setRateMortgageAvailable(
            result.data as {
              minLoanAmount: number;
              maxLoanAmount: number;
            },
          );
        }
      },
    );
  };

  useEffect(() => {
    const fetchPricingEngines = async () => {
      if (occupencyAvailable && !loadedMinMaxPricingEngine.current) {
        const pricingEngineIds = occupencyAvailable.map(
          (occupency) => `${privateLabelId}#${occupency}`,
        );
        const encodePricingEngineIds = encodeURIComponent(
          pricingEngineIds.join(),
        );
        await getMinMaxPricingEngine(encodePricingEngineIds);
        loadedMinMaxPricingEngine.current = true;
      }
    };
    fetchPricingEngines();
  }, [occupencyAvailable, privateLabelId]);

  const homeEquityBenefitsNote = privateLabel?.borrowerPortalConfiguration
    ?.customHomePageContent?.homeEquityBenefitsNote ? (
    privateLabel.borrowerPortalConfiguration.customHomePageContent
      .homeEquityBenefitsNote
  ) : (
    <>
      ** Investment properties fund in as few as 3 business days. Primary
      residences and Second homes differ.
    </>
  );
  const montserratFontFamily = "'Montserrat', sans-serif";

  return (
    <Container disableGutters={useMediaQuery(theme.breakpoints.only("xs"))}>
      <Box bgcolor={theme.palette.background.paper} p={{ md: 8, xs: 2 }}>
        <Typography
          variant="h3"
          mt={{ xs: 2, md: 0 }}
          fontSize={{ xs: "1.8rem", md: "2.9rem" }}
          role="heading"
          fontWeight={800}
          fontFamily={montserratFontFamily}
          aria-level={1}
          style={{
            whiteSpace: "pre-line",
          }}
        >
          {privateLabel?.isNFTYDoor ? (
            <>
              <span style={{ color: theme.palette.primary.main }}>NFTY</span>
              Door
            </>
          ) : (
            <span style={{ color: theme.palette.primary.main }}>
              {privateLabel?.lenderName}
            </span>
          )}
          <br /> Home Equity Benefits:
        </Typography>
        <Box
          className="benefits__body"
          p={0}
          mt={1}
          width="100%"
          maxWidth="lg"
          display="flex"
          alignItems={{ xs: undefined, sm: "start" }}
          flexDirection={{ xs: "column-reverse", sm: "row" }}
          justifyContent={{ xs: undefined, sm: "space-between" }}
        >
          <Box margin="10px 0 0 0">
            <Typography
              variant="h3"
              color={theme.palette.text.primary}
              letterSpacing={-0.8}
              fontSize={{ xs: "1rem", md: "1.2rem" }}
              role="heading"
              aria-level={2}
              fontWeight={500}
              fontFamily={montserratFontFamily}
              maxWidth={"80%"}
            >
              We provide on-demand access to your home equity so you can finance
              your life. Keep your low-interest rate mortgage and tap $
              {numberToKString(rateMortgageAvailable.minLoanAmount)} to $
              {numberToKString(rateMortgageAvailable.maxLoanAmount)} of new
              money.
            </Typography>
            <motion.div
              ref={ref}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={listVariants}
            >
              <List>
                <MotionListItem
                  variants={itemVariants}
                  style={{
                    fontFamily: "'Montserrat', sans-serif",
                    flexDirection: "column",
                    margin: "5px 0",
                  }}
                  disableGutters
                  dense
                  alignItems="flex-start"
                >
                  <Typography
                    variant="h5"
                    color={theme.palette.primary.main}
                    letterSpacing={-0.8}
                    fontSize={{ xs: "1rem", md: "1.2rem" }}
                    role="heading"
                    aria-level={2}
                    fontWeight={700}
                    fontFamily={montserratFontFamily}
                  >
                    Apply online
                  </Typography>
                  <Typography fontFamily={montserratFontFamily}>
                    1-minute application with no paperwork
                  </Typography>
                </MotionListItem>
                <MotionListItem
                  variants={itemVariants}
                  style={{
                    fontFamily: "'Montserrat', sans-serif",
                    flexDirection: "column",
                    margin: "5px 0",
                  }}
                  disableGutters
                  dense
                  alignItems="flex-start"
                >
                  <Typography
                    variant="h5"
                    color={theme.palette.primary.main}
                    letterSpacing={-0.8}
                    fontSize={{ xs: "1rem", md: "1.2rem" }}
                    role="heading"
                    aria-level={2}
                    fontWeight={700}
                    fontFamily={montserratFontFamily}
                  >
                    Get Pre-Qualified
                  </Typography>
                  <Typography fontFamily={montserratFontFamily}>
                    Instant Pre-Qualification won't impact your credit score
                  </Typography>
                </MotionListItem>
                <MotionListItem
                  variants={itemVariants}
                  style={{
                    fontFamily: "'Montserrat', sans-serif",
                    flexDirection: "column",
                    margin: "5px 0",
                  }}
                  disableGutters
                  dense
                  alignItems="flex-start"
                >
                  <Typography
                    variant="h5"
                    color={theme.palette.primary.main}
                    letterSpacing={-0.8}
                    fontSize={{ xs: "1rem", md: "1.2rem" }}
                    role="heading"
                    aria-level={2}
                    fontWeight={700}
                    fontFamily={montserratFontFamily}
                  >
                    Get Approved
                  </Typography>
                  <Typography fontFamily={montserratFontFamily}>
                    Closing in as little as 3 days*
                  </Typography>
                </MotionListItem>
                <MotionListItem
                  variants={itemVariants}
                  disableGutters
                  dense
                  alignItems="flex-start"
                  sx={{ mt: 3 }}
                >
                  <Typography
                    variant="h5"
                    letterSpacing={-0.8}
                    fontSize={{ xs: "0.75rem", md: "0.9rem" }}
                    role="heading"
                    aria-level={2}
                    fontWeight={500}
                    fontFamily={montserratFontFamily}
                    width={{ md: 400, xs: undefined }}
                  >
                    {homeEquityBenefitsNote}
                  </Typography>
                </MotionListItem>
              </List>
            </motion.div>
          </Box>
          {privateLabel?.isNFTYDoor && (
            <Box
              component="img"
              alt="Hand holding cellphone showing screenshot of nftydoor page."
              src={digitalImage}
              width={{ xs: "100%", sm: "38%", md: "36%" }}
              sx={{
                transform: { xs: "scale(0.9)", md: "scale(1)" },
              }}
            />
          )}
        </Box>
      </Box>
    </Container>
  );
}
