import CButton from "components/CButton";
import CDialog from "components/CDialog";
import { useMemo } from "react";
import EditBankAccount from "typedef/EditBankAccountProcess";
import Loan from "typedef/Loan";

export type EditBankAccountDialogProps = {
  open: boolean;
  onAccept: () => void;
  onDismiss: () => void;
  editBankProcess: EditBankAccount | undefined;
  loan: Loan;
};

const EditBankAccountDialog: React.FC<EditBankAccountDialogProps> = ({
  open,
  onAccept,
  onDismiss,
  editBankProcess,
  loan,
}) => {
  const editBankDialogTitle = useMemo(() => {
    return editBankProcess?.pendingVerification
      ? `Your new bank account is pending verification. This will replace the currently connected account
    *** ${loan?.borrowerSteps?.fundingVerification?.mask}. Do you want to continue?`
      : `You are about to connect another bank account which will replace the
currently connected account ***
${editBankProcess?.mask ?? loan?.borrowerSteps?.fundingVerification?.mask}.
Are you sure?`;
  }, [editBankProcess, loan]);

  return (
    <CDialog open={open} maxWidth="xs" title={editBankDialogTitle}>
      <CButton
        fullWidth
        variant="contained"
        onClick={onAccept}
        sx={{ textTransform: "inherit", fontSize: 20, marginBottom: 2 }}
      >
        Proceed
      </CButton>
      <CButton
        sx={{ textTransform: "inherit", fontSize: 20 }}
        fullWidth
        variant="outlined"
        onClick={onDismiss}
      >
        Discard
      </CButton>
    </CDialog>
  );
};

export default EditBankAccountDialog;
