import { CTrackerTimelineProps } from ".";
import CancelIcon from "@mui/icons-material/Cancel";
import SettingsIcon from "@mui/icons-material/Settings";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { SvgIcon, useTheme } from "@mui/material";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";

import { useEffect, useState } from "react";

const CTimelineDot: React.FC<
  Pick<CTrackerTimelineProps, "status" | "processingStatus">
> = ({ status, processingStatus }) => {
  const theme = useTheme();

  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setRotation((prevRotation) => (prevRotation - 1) % 360);
    }, 10);

    return () => clearInterval(interval);
  }, []);

  if (status === "analyzing")
    if (processingStatus) {
      return (
        <SvgIcon fontSize="large" sx={{ transform: `rotate(${rotation}deg)` }}>
          <ChangeCircleIcon
            sx={{ mt: 0.5 }}
            htmlColor={theme.palette.warning.main}
          />
        </SvgIcon>
      );
    } else {
      return (
        <SettingsIcon
          sx={{ mt: 0.5 }}
          fontSize="large"
          htmlColor={theme.palette.warning.main}
        />
      );
    }

  if (status === "success")
    return (
      <CheckCircleIcon
        sx={{ mt: 0.5 }}
        fontSize="large"
        htmlColor={theme.palette.success.main}
      />
    );
  if (status === "failed")
    return (
      <CancelIcon
        fontSize="large"
        htmlColor={theme.palette.error.main}
        sx={{ mt: 0.5 }}
      />
    );
  return (
    <CheckCircleIcon
      fontSize="large"
      sx={{ mt: 0.5 }}
      htmlColor={theme.palette.text.disabled}
    />
  );
};

export default CTimelineDot;
