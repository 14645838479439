import { FC } from "react";
import CDialog from "components/CDialog";
import CloseIcon from "@mui/icons-material/Close";
import dollarFormatter from "utils/FormatterDollar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";

type ThankYouDialogProps = {
  nextPayment?: { amount?: number };
  typePayment?: string;
  amountPayment?: number;
  openModalThanks: boolean;
  handleCloseModalThanks: () => void;
};

const ThankYouDialog: FC<ThankYouDialogProps> = ({
  nextPayment,
  typePayment,
  amountPayment,
  openModalThanks,
  handleCloseModalThanks,
}) => {
  const theme = useTheme();
  return (
    <CDialog
      fullWidth
      maxWidth="xs"
      scroll={"paper"}
      open={openModalThanks}
      onClose={handleCloseModalThanks}
      disableEscapeKeyDown
      hideBackdrop
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Box
        gap={1}
        width={"100%"}
        display={"flex"}
        alignItems={"start"}
        justifyContent={"start"}
        flexDirection={"column"}
      >
        <Box
          mb={4}
          width={"100%"}
          display={"flex"}
          alignItems={"center"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <Typography
            fontFamily={"Pro Display Bold"}
            variant="h6"
            textAlign={"left"}
          >
            Thank you for your payment of{" "}
            {typePayment === "next"
              ? nextPayment?.amount &&
                dollarFormatter.format(nextPayment?.amount)
              : amountPayment?.toString()?.replace(" ", "")}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleCloseModalThanks}
            sx={{
              padding: 0,
              color: theme.palette.text.secondary,
            }}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </Box>
        <Box textAlign={"left"}>
          <Typography fontFamily={"Pro Display Regular"} variant="h6">
            It will be debited on the date selected. Please note that payments
            requested up to 5pm ET will be processed on the same day. <br />
            <br /> To change this payment please email{" "}
            <a
              style={{
                cursor: "pointer",
                color: theme.palette.primary.main,
                textDecoration: "none",
              }}
              href={`mailto:support@nftydoor.com`}
            >
              support@nftydoor.com
            </a>{" "}
            or call{" "}
            <a
              style={{
                cursor: "pointer",
                color: theme.palette.primary.main,
                textDecoration: "none",
              }}
              href={`tel:+18449755150`}
            >
              844-975-5150
            </a>
          </Typography>
        </Box>
      </Box>
    </CDialog>
  );
};

export default ThankYouDialog;
