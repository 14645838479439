import CTracker from "components/CTracker";
import { TrackerContextProvider } from "components/CTracker/useTrackerContext";

interface AdminBorrowerTrackerProps {
  isBorrower?: boolean;
}

const AdminBorrowerTracker = ({ isBorrower }: AdminBorrowerTrackerProps) => {
  return (
    <TrackerContextProvider>
      <CTracker isBorrower={isBorrower ?? true} />
    </TrackerContextProvider>
  );
};

export default AdminBorrowerTracker;
