import { Theme } from "@mui/material";
import { SystemCssProperties } from "@mui/system";
import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";

const CButton: React.FC<LoadingButtonProps> = ({ sx, children, ...props }) => {
  return (
    <LoadingButton
      sx={[
        {
          display: "flex",
          fontSize: "1rem",
          alignItems: "center",
          borderRadius: "10px",
          justifyContent: "center",
          padding: "0.7rem 1.5rem",
          textTransform: "inherit",
          fontFamily: "Montserrat",
        },
        {
          "& .MuiButton-root": {
            ...(props["aria-label"] && { "aria-label": props["aria-label"] }),
            ...(props.name && { name: props.name }),
          },
        },
        sx ? (sx as SystemCssProperties<Theme>) : null,
      ]}
      TouchRippleProps={{
        "aria-hidden": "true",
        // eslint-disable-next-line
        ["data-acsb-hidden" as any]: "true",
        // eslint-disable-next-line
        ["data-acsb-force-hidden" as any]: "true",
      }}
      {...props}
    >
      {children}
    </LoadingButton>
  );
};

export default CButton;
