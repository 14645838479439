/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment";
import dayjs, { Dayjs } from "dayjs";
import CButton from "components/CButton";
import CDialog from "components/CDialog";
import parseMoney from "utils/parseMoney";
import { FC, Fragment, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import CNumberField from "components/CNumberField";
import dollarFormatter from "utils/FormatterDollar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Controller, FieldValues, UseFormReturn } from "react-hook-form";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

type MakeaPaymentDialogProps = {
  error?: string;
  loading: boolean;
  nextPayment?: {
    nextPaymentDate?: string;
    amount?: number;
    isPayable?: boolean;
  };
  payNow: () => void;
  openModal: boolean;
  onSubmit: () => void;
  loadingSubmit: boolean;
  handleCloseModal: () => void;
  form: UseFormReturn<FieldValues, any>;
};

const MakeaPaymentDialog: FC<MakeaPaymentDialogProps> = ({
  form,
  error,
  payNow,
  loading,
  onSubmit,
  openModal,
  nextPayment,
  loadingSubmit,
  handleCloseModal,
}) => {
  const theme = useTheme();
  const { errors } = form.formState;
  const [value, setValue] = useState<Dayjs | null>(dayjs());
  const [errorDate, setErrorDate] = useState<string | null>();

  useEffect(() => {
    if (value) form.setValue("paymentDate", dayjs(value).format("MM-DD-YYYY"));
  }, [value, form]);

  const disableSubmit = !(
    form.watch("amountToPay") && form.watch("paymentDate")
  );

  return (
    <CDialog
      fullWidth
      maxWidth="xs"
      scroll={"paper"}
      open={openModal}
      onClose={handleCloseModal}
      disableEscapeKeyDown
    >
      <form style={{ width: "100%" }} onSubmit={onSubmit}>
        <Box
          gap={1}
          width={"100%"}
          display={"flex"}
          alignItems={"start"}
          justifyContent={"start"}
          flexDirection={"column"}
        >
          <Box
            mb={4}
            width={"100%"}
            display={"flex"}
            alignItems={"center"}
            flexDirection={"row"}
            justifyContent={"space-between"}
          >
            <Typography fontFamily={"Pro Display Bold"} variant="h5">
              Make a Payment
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleCloseModal}
              sx={{
                padding: 0,
                color: theme.palette.text.secondary,
              }}
            >
              <CloseIcon fontSize="large" />
            </IconButton>
          </Box>
          {nextPayment && (
            <Box
              pb={3}
              mb={3}
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              borderBottom={"1px solid #31313127"}
            >
              <Box
                display={"flex"}
                alignItems={"start"}
                flexDirection={"column"}
              >
                {nextPayment.isPayable ? (
                  <Fragment>
                    <Typography
                      fontFamily={"Pro Display Regular"}
                      variant="subtitle1"
                    >
                      Your next payment is
                    </Typography>
                    <Typography
                      fontFamily={"Pro Display Semibold"}
                      variant="subtitle1"
                    >
                      {moment(nextPayment?.nextPaymentDate)
                        ?.add(1, "day")
                        ?.format("MM/DD/YYYY")}{" "}
                      <span style={{ fontFamily: "Pro Display Regular" }}>
                        for
                      </span>{" "}
                      {nextPayment?.amount &&
                        dollarFormatter.format(nextPayment?.amount)}
                    </Typography>
                  </Fragment>
                ) : (
                  <Typography
                    fontFamily={"Pro Display Regular"}
                    textAlign={"left"}
                    maxWidth={250}
                  >
                    Your next payment will be available after the monthly
                    statement is generated
                  </Typography>
                )}
              </Box>
              <Box display={"flex"} alignItems={"end"}>
                <CButton
                  loading={loading}
                  onClick={payNow}
                  disabled={!nextPayment?.isPayable}
                  variant="outlined"
                  sx={{ py: 1, whiteSpace: "nowrap" }}
                >
                  Pay Now
                </CButton>
              </Box>
            </Box>
          )}
          <Box mb={1}>
            <Typography fontFamily={"Pro Display Bold"} variant="h6">
              Principal Payment
            </Typography>
          </Box>

          <Controller
            control={form.control}
            name="amountToPay"
            render={({ field }) => (
              <CNumberField
                fullWidth
                label="How much do you want to pay?"
                placeholder="$10.000"
                isNumericString
                thousandSeparator
                allowNegative={false}
                allowEmptyFormatting={false}
                prefix="$ "
                {...field}
                error={errors?.subsequentAmount?.message as string}
                value={parseMoney(field.value)}
              />
            )}
          />

          <Controller
            control={form.control}
            name="paymentDate"
            render={(field) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Payment Date"
                  value={value}
                  onChange={(newValue) => setValue(newValue)}
                  minDate={dayjs()}
                  maxDate={dayjs().add(7, "day")}
                  sx={{
                    color: theme.palette.text.primary,
                    display: "flex",
                    alignItems: "start",
                  }}
                  {...field}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                    },
                  }}
                  onError={(err) => setErrorDate(err)}
                />
              </LocalizationProvider>
            )}
          />
          {(error || errorDate) && (
            <Typography color={theme.palette.error.main} mt={2}>
              {errorDate
                ? `The selected date is not correct. Please select a date between ${moment().format(
                    "MM/DD/YYYY",
                  )} and ${moment().add(7, "days").format("MM/DD/YYYY")}.`
                : error}
            </Typography>
          )}

          <CButton
            fullWidth
            type="submit"
            sx={{ mt: 3 }}
            loading={loadingSubmit}
            variant="contained"
            onClick={onSubmit}
            disabled={disableSubmit}
          >
            Submit
          </CButton>
        </Box>
      </form>
    </CDialog>
  );
};

export default MakeaPaymentDialog;
