import {
  Dialog,
  Divider,
  Typography,
  DialogContent,
  DialogContentText,
  useTheme,
} from "@mui/material";
import { FC } from "react";
import CDialogTitle from "components/CDialogTitle";
import CDialogTransition from "components/CDialogTransition";

type DialogProps = {
  open: boolean;
  handleClose: () => void;
};

const CCommunicationsDialog: FC<DialogProps> = ({ open, handleClose }) => {
  const theme = useTheme();
  return (
    <Dialog
      open={open}
      maxWidth="lg"
      scroll={"paper"}
      fullWidth={true}
      onClose={handleClose}
      TransitionComponent={CDialogTransition}
      aria-labelledby="customized-dialog-title"
      onClick={(e) => {
        e.stopPropagation();
      }}
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: 10,
        },
      }}
    >
      <DialogContent>
        <CDialogTitle onClose={handleClose}>
          NFTYDoor’s Electronic Communications Policy
        </CDialogTitle>
        <Divider sx={{ mb: 4 }} />
        <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
          <Typography color={theme.palette.text.primary} textAlign={"justify"}>
            <Typography
              variant="h4"
              display={"flex"}
              color={theme.palette.text.primary}
              fontWeight={"bold"}
              justifyContent={"center"}
            >
              Electronic Communications Policy
            </Typography>
            <br />
            <Typography
              variant="subtitle2"
              color={theme.palette.text.secondary}
            >
              Last revised: August 19th, 2022
            </Typography>
            <br />
            <Typography variant="h5" color={theme.palette.text.primary}>
              Please read this information carefully
            </Typography>
            <br />
            You are agreeing to transact business with us electronically. This
            means you will not receive paper copies of important notices,
            agreements and disclosures. By checking a box or clicking on a
            button, you may be providing your consent as if you had given your
            signature on a piece of paper. In addition you consent to the use of
            your phone number for SMS communications, such as identify
            verification, and communications from NFTYDoor LLC for the purposes
            of verifying your loan application.
            <br /> <br />
            Message and data rates may apply depending on the wireless plan that
            you have with your carrier.
            <br />
            <br />
            <Typography variant="h5" color={theme.palette.text.primary}>
              Definitions
            </Typography>
            <br /> • “We,” “us,” “our” and “NFTYDoor” means NFTYDoor LLC and its
            affiliates.
            <br /> • “Communication” means any information that we are required
            by law to provide to you in writing in connection with your
            application or any information we may otherwise provide to you
            relating to any loan that you obtain from us. This includes, but is
            not limited to, all communications, agreements, policies, documents,
            notices and disclosures. This includes SMS messages as well. <br />
            <br />
            <Typography variant="h5" color={theme.palette.text.primary}>
              Electronic delivery of communications
            </Typography>
            <br /> By consenting to this Electronic Communications Policy, any
            Communications we provide to you may be provided in electronic form.
            We may also use electronic signatures and obtain them from you as
            part of our transactions with you. All communications that we
            provide to you electronically will be provided either directly to
            your email address on file in your online account, on our website,
            or on your online account.
            <br />
            <br /> Your consent covers all Communications relating to the loan
            origination and closing process with NFTYDoor LLC loans. Your
            consent remains in effect until you give us notice that you are
            withdrawing it.
            <br />
            <br />
            <Typography variant="h5" color={theme.palette.text.primary}>
              Hardware and software requirements
            </Typography>
            <br /> Before you consent, you should consider whether you have the
            following required hardware and software capabilities to access,
            view and retain <br />
            <br />
            Communications:
            <br /> • access to a computer, operating system and
            telecommunications connections to the Internet capable of receiving,
            accessing, displaying and either printing or storing Communications
            electronically. Your Internet browser must meet the following
            minimum requirements: the current and previous major releases of
            Chrome, Firefox, Edge, or Safari on a rolling basis. Also, the
            specific Internet Browser must support at least 256-bit encryption;
            <br /> • a valid email address (your email address on file with
            NFTYDoor). <br />
            <br />
            We will notify you of any changes to these requirements that create
            a risk that you may not be able to receive Communications
            electronically.
            <br />
            <br /> By giving your consent you are confirming that you have
            access to the necessary equipment and are able to receive, open, and
            print or download a copy of any Communications for your records.
            Your cell phone provider or another service provider might impose
            data or usage charges for sending and receiving text messages.
            <br />
            <br />
            <Typography variant="h5" color={theme.palette.text.primary}>
              SMS Communications
            </Typography>
            <br />
            Message & Data rates may apply. By texting us, you consent to
            receive text messages from NFTYDoor at the mobile number you used to
            text and you are opting-in to receive future messages or a phone
            call in the number you provided. Message & Data rates may apply.
            Reply STOP anytime to opt-out.
            <br />
            <br />
            <Typography variant="h5" color={theme.palette.text.primary}>
              Withdrawing your consent
            </Typography>
            <br />
            You may withdraw your consent to receive Communications
            electronically by contacting us at support@nftydoor.com. Your
            withdrawal of consent will become effective after we have had a
            reasonable opportunity to act upon it. <br />
            <br />
            If you fail to provide consent, or if you withdraw your consent to
            receive Communications electronically, NFTYDoor reserves the right
            to either deny your application, restrict or deactivate your online
            account, close your account, or charge additional fees for paper
            copies. <br />
            <br />
            <Typography variant="h5" color={theme.palette.text.primary}>
              Requesting paper copies of electronic Communications
            </Typography>
            <br />
            If, after you consent to receive Communications electronically, you
            would like a paper copy of a Communication we previously sent you,
            you may request a copy within 180 days of the date we provided the
            Communication to you by contacting us as described above. In order
            for us to send you paper copies, you must have a current street
            address on file as your “Current Address” in your NFTYDoor online
            account. NFTYDoor may charge you a record request fee for paper
            copies of each Communication.
            <br />
            <br />{" "}
            <Typography variant="h5" color={theme.palette.text.primary}>
              Updating your contact information
            </Typography>
            <br /> You must promptly notify us of any change to your email
            address or smart phone number so that NFTYDoor can communicate with
            you electronically. You may do so by calling us at 1-844-975-5150.
            <br />
            <br /> You understand and agree that if NFTYDoor sends you an
            electronic Communication but you do not receive it because your
            email address or smart phone number on file is incorrect, out of
            date, blocked by your service provider, or you are otherwise unable
            to receive electronic Communications, NFTYDoor will be deemed to
            have provided the Communication to you. <br />
            <br />
            <Typography variant="h5" color={theme.palette.text.primary}>
              Legal effect
            </Typography>
            <br /> Your Electronic Communications Consent means that any
            Disclosures shall have the same meaning as if provided in paper
            form. By pressing the button “Check My Rate”, “I accept,” “Submit”,
            “Confirm”, “Verify”, “I agree” or similar buttons indicating your
            authorization, you are providing your electronic signature with the
            same effect as if your signature had been provided on paper. <br />
            <br />
          </Typography>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default CCommunicationsDialog;
