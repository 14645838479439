import { useState } from "react";
import {
  FieldValues,
  Path,
  PathValue,
  useForm,
  UseFormReturn,
} from "react-hook-form";

import DemographicsDataChecks from "typedef/DemographicsDataChecks";

import { DemographicsVerification } from "typedef/BorrowerSteps";

export const defaultValuesDemographicsForm: DemographicsDataChecks = {
  ethnicity: {
    hispanicOrLatino: false,
    mexican: false,
    puertoRican: false,
    cuban: false,
    otherHispanicOrLatino: false,
    customEthnicity: "",
    notHispanicOrLatino: false,
    prefNoSay: false,
  },
  race: {
    americanIndianOrAlaskaNative: false,
    customAmericanIndianOrAlaskan: "",
    asian: false,
    asianIndian: false,
    chinese: false,
    filipino: false,
    japanese: false,
    korean: false,
    vietnamese: false,
    otherAsian: false,
    customOtherAsian: "",
    blackOrAfricanAmerican: false,
    nativeHawaiian: false,
    guamanianOrChamorro: false,
    samoan: false,
    otherPacificIslander: false,
    customOtherPacificIslander: "",
    nativeHawaiianOrOtherPacificIslander: false,
    white: false,
    prefNoSay: false,
  },
  sex: {
    male: false,
    female: false,
    prefNoSay: false,
  },
  citizenship: "",
};

const useDemographicsData = () => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [demographicData, setDemographicData] = useState<
    DemographicsVerification | { status: string }
  >({ status: "pending" });

  const form = useForm<DemographicsDataChecks>({
    defaultValues: defaultValuesDemographicsForm,
  });

  return {
    demographicData,
    setDemographicData,
    errorMessage,
    setErrorMessage,
    ...form,
  };
};

export default useDemographicsData;

function checkAnswer(data: DemographicsDataChecks) {
  return (
    checkSex(data) &&
    checkEthnicity(data) &&
    checkRace(data) &&
    checkCitizenship(data)
  );
}

function checkSex(data: DemographicsDataChecks) {
  return data.sex.female || data.sex.male || data.sex.prefNoSay;
}

function checkEthnicity(data: DemographicsDataChecks) {
  return (
    data.ethnicity.prefNoSay ||
    data.ethnicity.notHispanicOrLatino ||
    data.ethnicity.hispanicOrLatino ||
    data.ethnicity.cuban ||
    data.ethnicity.mexican ||
    data.ethnicity.otherHispanicOrLatino ||
    data.ethnicity.puertoRican
  );
}

function checkCitizenship(data: DemographicsDataChecks) {
  return data.citizenship !== "";
}

function checkRace(data: DemographicsDataChecks) {
  return (
    data.race.prefNoSay ||
    data.race.white ||
    data.race.blackOrAfricanAmerican ||
    data.race.americanIndianOrAlaskaNative ||
    data.race.nativeHawaiianOrOtherPacificIslander ||
    data.race.nativeHawaiian ||
    data.race.guamanianOrChamorro ||
    data.race.samoan ||
    data.race.otherPacificIslander ||
    data.race.asian ||
    data.race.asianIndian ||
    data.race.chinese ||
    data.race.filipino ||
    data.race.japanese ||
    data.race.korean ||
    data.race.vietnamese ||
    data.race.otherAsian
  );
}

export const getDemographicsData = <T extends FieldValues>({
  demographicsData,
  form,
}: {
  demographicsData: DemographicsDataChecks;
  form: UseFormReturn<T>;
}): DemographicsDataChecks | { error: boolean; message: string } => {
  if (checkAnswer(demographicsData)) {
    if (!demographicsData.ethnicity.otherHispanicOrLatino) {
      form.setValue(
        "ethnicity.customEthnicity" as Path<T>,
        "" as PathValue<T, Path<T>>,
      );
    }
    if (!demographicsData.race.americanIndianOrAlaskaNative) {
      form.setValue(
        "race.customAmericanIndianOrAlaskan" as Path<T>,
        "" as PathValue<T, Path<T>>,
      );
    }
    if (!demographicsData.race.otherAsian) {
      form.setValue(
        "race.customOtherAsian" as Path<T>,
        "" as PathValue<T, Path<T>>,
      );
    }
    if (!demographicsData.race.otherPacificIslander) {
      form.setValue(
        "race.customOtherPacificIslander" as Path<T>,
        "" as PathValue<T, Path<T>>,
      );
    }
    const demographicsRes = {
      ethnicity: {
        hispanicOrLatino: demographicsData.ethnicity.hispanicOrLatino,
        mexican: demographicsData.ethnicity.mexican,
        puertoRican: demographicsData.ethnicity.puertoRican,
        cuban: demographicsData.ethnicity.cuban,
        otherHispanicOrLatino: demographicsData.ethnicity.otherHispanicOrLatino,
        customEthnicity: demographicsData.ethnicity.customEthnicity,
        notHispanicOrLatino: demographicsData.ethnicity.notHispanicOrLatino,
        prefNoSay: demographicsData.ethnicity.prefNoSay,
      },
      race: {
        americanIndianOrAlaskaNative:
          demographicsData.race.americanIndianOrAlaskaNative,
        customAmericanIndianOrAlaskan:
          demographicsData.race.customAmericanIndianOrAlaskan,
        asian: demographicsData.race.asian,
        asianIndian: demographicsData.race.asianIndian,
        chinese: demographicsData.race.chinese,
        filipino: demographicsData.race.filipino,
        japanese: demographicsData.race.japanese,
        korean: demographicsData.race.korean,
        vietnamese: demographicsData.race.vietnamese,
        otherAsian: demographicsData.race.otherAsian,
        customOtherAsian: demographicsData.race.customOtherAsian,
        blackOrAfricanAmerican: demographicsData.race.blackOrAfricanAmerican,
        nativeHawaiian: demographicsData.race.nativeHawaiian,
        guamanianOrChamorro: demographicsData.race.guamanianOrChamorro,
        samoan: demographicsData.race.samoan,
        otherPacificIslander: demographicsData.race.otherPacificIslander,
        customOtherPacificIslander:
          demographicsData.race.customOtherPacificIslander,
        nativeHawaiianOrOtherPacificIslander:
          demographicsData.race.nativeHawaiianOrOtherPacificIslander,
        white: demographicsData.race.white,
        prefNoSay: demographicsData.race.prefNoSay,
      },
      sex: {
        male: demographicsData.sex.male,
        female: demographicsData.sex.female,
        prefNoSay: demographicsData.sex.prefNoSay,
      },
      citizenship: demographicsData.citizenship,
    };
    return demographicsRes;
  } else {
    return {
      error: true,
      message: "Please answer all questions",
    };
  }
};
