import { Fragment } from "react";
import {
  Box,
  useTheme,
  MenuItem,
  Container,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CDialog from "components/CDialog";
import CButton from "components/CButton";
import useContactus from "./useContactus";
import CTextField from "components/CTextField";
import CNumberField from "components/CNumberField";
import { Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";

export default function Contactus() {
  const theme = useTheme();
  const form = useContactus();
  const control = form.control;
  const { errors } = form.formState;
  const navigate = useNavigate();
  const { privateLabel } = usePrivateLabel();
  return (
    <Fragment>
      <Container
        maxWidth="md"
        disableGutters={useMediaQuery(theme.breakpoints.only("xs"))}
      >
        <Box>
          <Typography
            mt={4}
            mb={5}
            variant="h2"
            lineHeight={1}
            color={theme.palette.text.primary}
            letterSpacing={-0.8}
            pl={{ xs: 2, md: 0 }}
            fontSize={{ xs: "3rem", md: "5rem" }}
          >
            Contact us
          </Typography>
        </Box>
        <Box py={2} bgcolor={theme.palette.background.paper} borderRadius={8}>
          <Box px={{ xs: 2, md: 6 }} pt={6}>
            <Typography
              variant="h6"
              color={theme.palette.text.primary}
              textAlign={"left"}
            >
              Thank you for your interest in{" "}
              <span style={{ color: theme.palette.primary.main }}>
                {privateLabel ? privateLabel.lenderName : "NFTY"}
              </span>
              {privateLabel ? null : "Door!"} For an immediate response please
              use the chat at the bottom of this page. All other inquiries will
              be responded to within 24 hours.
            </Typography>
          </Box>
          <form onSubmit={form.onSubmit}>
            <Box px={{ xs: 2, md: 6 }} py={{ xs: 3, md: 6 }}>
              <CTextField
                newRequired="(Required)"
                select
                fullWidth
                label="Tell us who you are"
                value={form.watch("tellUs")}
                sx={{ mb: 3 }}
                error={errors.tellUs?.message}
                onChange={({ target }) => form.setValue("tellUs", target.value)}
              >
                <MenuItem value="Borrower">Borrower</MenuItem>
                <MenuItem value="Loan Officer">Loan Officer</MenuItem>
                <MenuItem value="Press">Press</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </CTextField>
              <Box
                width={"100%"}
                display={"flex"}
                alignItems={"flex-start"}
                flexDirection={{ xs: "column", md: "row" }}
              >
                <Box width={{ xs: "100%", md: "50%" }} mr={{ xs: 0, md: 1 }}>
                  <Controller
                    control={control}
                    name="firstName"
                    render={({ field }) => (
                      <CTextField
                        label="First Name"
                        error={errors.firstName?.message}
                        placeholder="John"
                        fullWidth
                        sx={{ mb: 3 }}
                        {...field}
                        newRequired="(Required)"
                      />
                    )}
                  />
                </Box>

                <Box width={{ xs: "100%", md: "50%" }} ml={{ xs: 0, md: 1 }}>
                  <Controller
                    control={control}
                    name="lastName"
                    render={({ field }) => (
                      <CTextField
                        label="Last Name"
                        error={errors.lastName?.message}
                        placeholder="Doe"
                        fullWidth
                        sx={{ mb: 3 }}
                        {...field}
                        newRequired="(Required)"
                      />
                    )}
                  />
                </Box>
              </Box>

              <Controller
                control={control}
                name="email"
                render={({ field }) => (
                  <CTextField
                    label="Email Address   "
                    error={form.formState.errors.email?.message}
                    placeholder="Type in here"
                    fullWidth
                    sx={{ mb: 3 }}
                    {...field}
                    newRequired="(Required)"
                  />
                )}
              />

              <Controller
                control={control}
                name="phoneNumber"
                render={({ field }) => (
                  <CNumberField
                    newRequired="(Required)"
                    fullWidth
                    autoComplete="off"
                    label="Phone Number"
                    placeholder="+1(123)-123-1234"
                    format={
                      process.env.REACT_APP_ENV === "prod"
                        ? "+1###############"
                        : "+###############"
                    }
                    sx={{ mb: 3 }}
                    allowEmptyFormatting
                    {...field}
                    error={errors.phoneNumber?.message}
                  />
                )}
              />
              <Controller
                control={control}
                name="message"
                render={({ field }) => (
                  <CTextField
                    label="Message"
                    error={errors.message?.message}
                    placeholder="Type you message here..."
                    fullWidth
                    multiline
                    rows={5}
                    sx={{ mb: 3 }}
                    {...field}
                    newRequired="(Required)"
                  />
                )}
              />
              <CButton
                fullWidth
                type="submit"
                variant="contained"
                loading={form.loading}
              >
                Submit
              </CButton>
            </Box>
          </form>
        </Box>
      </Container>
      <CDialog
        icon="check"
        description={
          "The data was sent successfully. We will be contacting you as soon as possible."
        }
        open={form.showDialogConfirm}
      >
        <CButton
          fullWidth
          variant="contained"
          onClick={() => {
            form.setShowDialogConfirm(false);
            return navigate("/", { replace: true });
          }}
        >
          Accept
        </CButton>
      </CDialog>
    </Fragment>
  );
}
