import {
  Box,
  List,
  ListItem,
  useTheme,
  Container,
  Typography,
  useMediaQuery,
} from "@mui/material";
import digitalImage from "assets/Images/digital-loan.png";

export default function CWhatIs() {
  const theme = useTheme();

  return (
    <Container disableGutters={useMediaQuery(theme.breakpoints.only("xs"))}>
      <Box bgcolor={theme.palette.background.paper} p={{ md: 8, xs: 2 }}>
        <Typography
          mt={1}
          mb={2}
          variant="h3"
          color={theme.palette.text.primary}
          letterSpacing={-0.8}
          fontFamily="Pro Display Semibold"
          fontSize={{ xs: "2rem", md: "3rem" }}
        >
          What is{" "}
          <span style={{ color: theme.palette.primary.main }}>NFTY</span>Door?
        </Typography>
        <Typography
          variant="h5"
          color={theme.palette.text.primary}
          textAlign="justify"
          fontSize={"1.2rem"}
          letterSpacing={-0.5}
        >
          NFTYDoor is a direct lender of home equity lines of credit (HELOCs).
          We partner with lenders to provide a “HELOC in a box” solution so that
          you can offer this valuable and timely product to your customers.
        </Typography>
        <Box
          p={0}
          mt={4}
          width="100%"
          maxWidth="lg"
          display="flex"
          alignItems={{ xs: undefined, sm: "center" }}
          flexDirection={{ xs: "column-reverse", sm: "row" }}
          justifyContent={{ xs: undefined, sm: "space-between" }}
        >
          <Box mt={{ xs: 2, md: 0 }} mr={{ xs: 0, md: 4 }}>
            <Typography
              variant="h3"
              mt={{ xs: 2, md: 0 }}
              fontSize={{ xs: "1.6rem", md: "2.8rem" }}
            >
              Why partner with
              <span style={{ color: theme.palette.primary.main }}> NFTY</span>
              Door?
            </Typography>
            <Typography
              my={2}
              variant="h5"
              color={theme.palette.text.primary}
              textAlign="justify"
              fontSize={"1.2rem"}
              letterSpacing={-0.5}
            >
              NFTYDoor offers the fastest and most convenient HELOC in the US
              and its 100% branded to you so you can have the following:
            </Typography>
            <List>
              <ListItem disableGutters dense alignItems="flex-start">
                <Box
                  mr={1}
                  mt={0.4}
                  display="inline"
                  color={theme.palette.primary.main}
                >
                  •
                </Box>
                <Typography textAlign={"justify"}>
                  <span style={{ color: theme.palette.primary.main }}>
                    Retention:
                  </span>{" "}
                  Offer your customers 1 minute application to tap their equity,
                  and we fund in as little as 3 days.
                </Typography>
              </ListItem>
              <ListItem disableGutters dense alignItems="flex-start">
                <Box
                  mr={1}
                  mt={0.4}
                  display="inline"
                  color={theme.palette.primary.main}
                >
                  •
                </Box>
                <Typography textAlign={"justify"}>
                  <span style={{ color: theme.palette.primary.main }}>
                    Revenue:
                  </span>{" "}
                  You will receive a commission on all closed loans. This is a
                  great way to leverage your existing customer base with a
                  modern product that suits their needs.
                </Typography>
              </ListItem>
              <ListItem disableGutters dense alignItems="flex-start">
                <Box
                  mr={1}
                  mt={0.4}
                  display="inline"
                  color={theme.palette.primary.main}
                >
                  •
                </Box>
                <Typography textAlign={"justify"}>
                  <span style={{ color: theme.palette.primary.main }}>
                    Recapture:
                  </span>{" "}
                  We provide a servicing portal branded to you. This keeps you
                  top of mind for when they are ready to refinance or new a
                  purchase loan in the future.
                </Typography>
              </ListItem>
              <ListItem disableGutters dense alignItems="flex-start">
                <Box
                  mr={1}
                  mt={0.4}
                  display="inline"
                  color={theme.palette.primary.main}
                >
                  •
                </Box>
                <Typography textAlign={"justify"}>
                  <span style={{ color: theme.palette.primary.main }}>
                    Rapid:
                  </span>{" "}
                  NFTYDoor is not only the fastest HELOC for customers, but our
                  “HELOC in a box” solution for lenders can be up and running in
                  days.
                </Typography>
              </ListItem>
            </List>
          </Box>
          <Box
            alt="Hand holding cellphone showing screenshot of nftydoor page."
            component="img"
            src={digitalImage}
            width={{ xs: "100%", sm: "38%", md: "36%" }}
          />
        </Box>
      </Box>
    </Container>
  );
}
