import * as React from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

type CChartDoughnutProps = {
  drawAmount: number;
};

const CChartDoughnut: React.FC<CChartDoughnutProps> = ({ drawAmount }) => {
  return (
    <Box sx={{ position: "relative" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: () => "#49B162",
        }}
        size={200}
        thickness={10}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        disableShrink
        sx={{
          color: () => "#FF9571",
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
        }}
        size={200}
        thickness={10}
        value={drawAmount}
      />
    </Box>
  );
};

export default CChartDoughnut;
