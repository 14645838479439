import API from "utils/API";

const runHardCreditLambda = async (loanId: string, borrowerType: string) => {
  await API.post({
    url: "/invoke",
    data: {
      FunctionName: "validateHardCredit",
      InvocationType: "RequestResponse",
      Payload: {
        loanId: loanId,
        borrowerType: borrowerType,
      },
    },
  });
};

export default runHardCreditLambda;
