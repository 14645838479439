import { FC } from "react";
import { LinearProgress, LinearProgressProps } from "@mui/material";

/**
 * @author Giuliano Antonanzas
 */
const CLoading: FC<LinearProgressProps> = (props) => {
  return (
    <div
      style={{
        width: "100%",
        height: "86vh",
        margin: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {" "}
      <LinearProgress color="primary" style={{ width: "60%" }} {...props} />
    </div>
  );
};

export default CLoading;
