import { Fragment } from "react";
import CButton from "components/CButton";
import { Box, Container, Typography, useTheme } from "@mui/material";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import { Link } from "react-router-dom";

const QuestionsBox: React.FC = () => {
  const theme = useTheme();
  const { privateLabel } = usePrivateLabel();
  return (
    <Fragment>
      <Container maxWidth="md" disableGutters>
        <Box
          mt={2}
          display={"flex"}
          borderRadius={10}
          bgcolor={theme.palette.background.paper}
          px={{ xs: 2, md: 6 }}
          py={{ xs: 4, md: 5 }}
          alignItems={"center"}
          justifyContent={"space-between"}
          flexDirection={{ xs: "column", md: "row" }}
        >
          <Typography
            variant="h5"
            sx={{
              fontFamily: "Pro Text Semibold",
              fontSize: { xs: "1.4rem", md: "2rem" },
              marginBottom: { xs: "1rem", md: "0rem" },
            }}
          >
            Questions? Ask our Support Team
          </Typography>
          <Link
            to={privateLabel?.contactUsLink || "/contactus"}
            style={{ textDecoration: "none" }}
            target="_blank"
          >
            <CButton
              type="submit"
              variant="contained"
              sx={{
                textTransform: "inherit",
              }}
            >
              Contact us
            </CButton>
          </Link>
        </Box>
      </Container>
    </Fragment>
  );
};

export default QuestionsBox;
