import { Fragment } from "react";
import { Box, Typography, useTheme } from "@mui/material";

export type CDetailLineProps = {
  firstColumn: string;
  secondColumn: string;
};

const CDetailLine: React.FC<CDetailLineProps> = ({
  firstColumn,
  secondColumn,
}) => {
  const theme = useTheme();

  return (
    <Fragment>
      <Box mb={2} display={"flex"}>
        <Typography
          whiteSpace={"nowrap"}
          alignItems={"start"}
          variant="subtitle1"
        >
          {firstColumn}
        </Typography>
        <Box
          mb={0.8}
          marginX={2}
          width={"100%"}
          borderBottom={`1px dashed ${theme.palette.text.secondary}`}
        />
        <Typography
          whiteSpace={"nowrap"}
          variant="subtitle1"
          alignItems={"end"}
        >
          {secondColumn}
        </Typography>
      </Box>
    </Fragment>
  );
};

export default CDetailLine;
