export const statesNames = [
  { state_id: "FHA", stateName: "Federal Housing Administration" },
  { state_id: "FNMA", stateName: "Federal National Mortgage Association" },
  { state_id: "USDA", stateName: "U.S. Department of Agriculture" },
  { state_id: "AL", stateName: "Alabama" },
  { state_id: "AK", stateName: "Alaska" },
  { state_id: "AS", stateName: "American Samoa" },
  { state_id: "AZ", stateName: "Arizona" },
  { state_id: "AR", stateName: "Arkansas" },
  { state_id: "CA", stateName: "California" },
  { state_id: "CO", stateName: "Colorado" },
  { state_id: "CT", stateName: "Connecticut" },
  { state_id: "DE", stateName: "Delaware" },
  { state_id: "DC", stateName: "District of Columbia" },
  { state_id: "FM", stateName: "Federated States of Micronesia" },
  { state_id: "FL", stateName: "Florida" },
  { state_id: "GA", stateName: "Georgia" },
  { state_id: "GU", stateName: "Guam" },
  { state_id: "HI", stateName: "Hawaii" },
  { state_id: "ID", stateName: "Idaho" },
  { state_id: "IL", stateName: "Illinois" },
  { state_id: "IN", stateName: "Indiana" },
  { state_id: "IA", stateName: "Iowa" },
  { state_id: "KS", stateName: "Kansas" },
  { state_id: "KY", stateName: "Kentucky" },
  { state_id: "LA", stateName: "Louisiana" },
  { state_id: "ME", stateName: "Maine" },
  { state_id: "MH", stateName: "Marshall Islands" },
  { state_id: "MD", stateName: "Maryland" },
  { state_id: "MA", stateName: "Massachusetts" },
  { state_id: "MI", stateName: "Michigan" },
  { state_id: "MN", stateName: "Minnesota" },
  { state_id: "MS", stateName: "Mississippi" },
  { state_id: "MO", stateName: "Missouri" },
  { state_id: "MT", stateName: "Montana" },
  { state_id: "NE", stateName: "Nebraska" },
  { state_id: "NV", stateName: "Nevada" },
  { state_id: "NH", stateName: "New Hampshire" },
  { state_id: "NJ", stateName: "New Jersey" },
  { state_id: "NM", stateName: "New Mexico" },
  { state_id: "NY", stateName: "New York" },
  { state_id: "NC", stateName: "North Carolina" },
  { state_id: "ND", stateName: "North Dakota" },
  { state_id: "MP", stateName: "Northern Mariana Islands" },
  { state_id: "OH", stateName: "Ohio" },
  { state_id: "OK", stateName: "Oklahoma" },
  { state_id: "OR", stateName: "Oregon" },
  { state_id: "PW", stateName: "Palau" },
  { state_id: "PA", stateName: "Pennsylvania" },
  { state_id: "PR", stateName: "Puerto Rico" },
  { state_id: "RI", stateName: "Rhode Island" },
  { state_id: "SC", stateName: "South Carolina" },
  { state_id: "SD", stateName: "South Dakota" },
  { state_id: "TN", stateName: "Tennessee" },
  { state_id: "TX", stateName: "Texas" },
  { state_id: "UT", stateName: "Utah" },
  { state_id: "VT", stateName: "Vermont" },
  { state_id: "VI", stateName: "Virgin Islands" },
  { state_id: "VA", stateName: "Virginia" },
  { state_id: "WA", stateName: "Washington" },
  { state_id: "WV", stateName: "West Virginia" },
  { state_id: "WI", stateName: "Wisconsin" },
  { state_id: "WY", stateName: "Wyoming" },
];
