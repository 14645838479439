import { Typography } from "@mui/material";
import TruvBridge from "@truv/react";
import CTrackerTimelineItem from "components/CTimelineItem";
import useTracker from "components/CTracker/useTrackerContext";
import React, { useMemo, useState } from "react";
import useInsurance from "./useInsurance";
import { TrackerStatusColor } from "typedef/TrackerStatusColor";
import { mergeMissingDocsByStep } from "utils/missingDocuments";
import UploadEachDocument from "components/CTracker/StepsDialogs/UploadEachDocument";

const InsuranceVerification: React.FC<{
  trackerStatusColor: TrackerStatusColor;
}> = ({ trackerStatusColor }) => {
  const insurance = useInsurance();
  const { loan } = useTracker();
  const borrowerSteps = loan?.borrowerSteps;
  const [open, setOpen] = useState<boolean>(false);
  const [showVerifyButton, setShowVerifyButton] = useState(true);
  const [loading, setLoading] = useState(false);

  const prevStep =
    (borrowerSteps?.creditVerification.status === "success" ||
      (borrowerSteps?.creditVerification.status === "analyzing" &&
        (borrowerSteps?.creditVerification.needDocReview !== undefined ||
          borrowerSteps?.creditVerification.deletedDocRequest))) &&
    borrowerSteps?.homeValuationVerification.status === "success";
  const status = prevStep
    ? borrowerSteps?.insuranceVerification?.status ?? "analyzing"
    : "pending";

  const message = borrowerSteps?.insuranceVerification?.message ?? "";

  const stepName = "insuranceVerification";

  const docsToUpload = useMemo(() => {
    if (!loan || !loan?.borrowerMissingDocs) return [];

    return mergeMissingDocsByStep(loan, stepName);
  }, [loan]);

  const needDocUpload = borrowerSteps?.insuranceVerification?.needDocReview;

  const hasAppraisalType =
    loan?.borrowerSteps?.appraisalVerification?.appraisalType &&
    loan?.borrowerSteps?.appraisalVerification?.appraisalType?.length > 0 &&
    loan?.borrowerSteps?.appraisalVerification?.status !== "pending";

  return (
    <CTrackerTimelineItem
      status={status}
      title="Insurance Verification"
      loading={loading}
      tooltip="As a lender, we require homeowners insurance that has sufficient dwelling coverage of all loans and is valid for at least 45 days past closing. Flood insurance will be requested if you’re in a Flood Zone."
      {...(showVerifyButton &&
        status === "analyzing" &&
        prevStep &&
        (!borrowerSteps.appraisalVerification || hasAppraisalType) &&
        !borrowerSteps.insuranceVerification.validationDate && {
          action: {
            message: "Connect Your Account",
            onClick: async () => {
              setLoading(true);
              await insurance.getBridgeToken();
              setOpen(true);
            },
          },
        })}
    >
      <TruvBridge
        isOpened={open}
        onClose={() => {
          setOpen(false);
          setLoading(false);
          setShowVerifyButton(true);
        }}
        bridgeToken={insurance.bridgeToken as string}
        onSuccess={async (public_token: string) => {
          setLoading(false);
          setOpen(false);
          await insurance.sendPublicToken(public_token);
        }}
        onEvent={() => setShowVerifyButton(false)}
      />

      <Typography variant="h6" color={[trackerStatusColor[status]]}>
        {status === "analyzing" &&
          (needDocUpload === true || needDocUpload === undefined) &&
          message}
        {status === "analyzing" && needDocUpload === false && (
          <UploadEachDocument docsToUpload={docsToUpload} loan={loan} />
        )}
        {status === "failed" && message}
        {status === "success" && message}
      </Typography>
    </CTrackerTimelineItem>
  );
};

export default InsuranceVerification;
