import API from "utils/API";
import { useState } from "react";
import CButton from "components/CButton";
import { useForm } from "react-hook-form";
import CTextField from "components/CTextField";
import CPasswordField from "components/CPasswordField";
import { Box, FormHelperText, Typography, useTheme } from "@mui/material";
import { passwordMsgHelper, regexPassword } from "CONST";
import { useSearchParams } from "react-router-dom";
import NewPasswordSuccess from "./NewPasswordSuccess";

type SubmitCodeForm = {
  code: string;
  newPassword: string;
  newPasswordRepeat: string;
};

const SubmitCode = () => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const [loading, setLoading] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState<string>();
  const { register, handleSubmit, formState, setError, getValues } =
    useForm<SubmitCodeForm>();
  const [done, setDone] = useState(false);

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    setErrorSubmit(undefined);
    const values = getValues();
    if (values.newPassword !== values.newPasswordRepeat) {
      setError("newPasswordRepeat", { message: "passwords do not match" });
      setLoading(false);
      return;
    }
    const result = await API.post({
      url: "/confirm-forgot-password",
      data: {
        ...data,
        email: email?.trim(),
      },
    });

    if ("error" in result) {
      setErrorSubmit(result.error);
    } else {
      setDone(true);
    }
    setLoading(false);
  });
  if (done) {
    return <NewPasswordSuccess email={email as string} />;
  }

  return (
    <Box py={2} bgcolor={theme.palette.background.paper} borderRadius={8}>
      <form onSubmit={onSubmit}>
        <Box px={{ xs: 2, md: 6 }} py={{ xs: 3, md: 6 }}>
          <CTextField
            fullWidth
            {...register("code", {
              required: { value: true, message: "This field is required" },
              minLength: {
                value: 6,
                message: "The code must be at least 6 characters long",
              },
              maxLength: {
                value: 6,
                message: "The code can have a maximum of 6 characters",
              },
              pattern: {
                value: /^(\d{6})$/i,
                message: "The code must contain 6 numbers",
              },
            })}
            error={formState?.errors?.code?.message}
            label="Password Recovery Code"
            placeholder="Enter your code"
          />
          <CPasswordField
            fullWidth
            sx={{ mt: 3 }}
            {...register("newPassword", {
              required: {
                value: true,
                message: "Password is required.",
              },
              minLength: {
                value: 9,
                message: "The password must be at least 9 characters long.",
              },
              maxLength: {
                value: 100,
                message: "The password must be less than 100 characters.",
              },
              pattern: {
                value: regexPassword,
                message: passwordMsgHelper,
              },
            })}
            error={formState?.errors?.newPassword?.message}
            label="New Password"
            placeholder="Type new password"
          />
          <Typography
            mt={1}
            ml={1.6}
            color={theme.palette.text.secondary}
            variant="subtitle2"
          >
            Minimum length is 9 characters with at least 1 number, 1 special
            character, 1 uppercase letter and 1 lowercase letter.
          </Typography>
          <CPasswordField
            fullWidth
            sx={{ mt: 3 }}
            {...register("newPasswordRepeat", {
              required: {
                value: true,
                message: "Password is required.",
              },
              minLength: {
                value: 9,
                message: "The password must be at least 9 characters long.",
              },
              maxLength: {
                value: 100,
                message: "The password must be less than 100 characters.",
              },
              pattern: {
                value: regexPassword,
                message: passwordMsgHelper,
              },
            })}
            error={formState?.errors?.newPasswordRepeat?.message}
            label="New Password Confirmation"
            placeholder="Retype new password"
          />
          <Typography
            mt={1}
            ml={1.6}
            color={theme.palette.text.secondary}
            variant="subtitle2"
          >
            Minimum length is 9 characters with at least 1 number, 1 special
            character, 1 uppercase letter and 1 lowercase letter.
          </Typography>
          {errorSubmit && (
            <FormHelperText error={Boolean(errorSubmit)}>
              <Typography textAlign={"center"}>{errorSubmit}</Typography>
            </FormHelperText>
          )}
          <Box mt={4}>
            <CButton
              sx={{ marginTop: "2rem" }}
              fullWidth
              loading={loading}
              disabled={loading}
              type="submit"
              variant="contained"
            >
              Submit
            </CButton>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default SubmitCode;
