import Loan from "typedef/Loan";
import { DocumentItem, MissingDocuments } from "typedef/MissingDocuments";

const formatStepMissingDocs = (
  docs: DocumentItem[],
  stepName: keyof MissingDocuments,
  isBorrower: boolean,
) => {
  return docs.map((doc) => ({
    ...doc,
    step: stepName,
    isBorrower,
  }));
};

export const mergeMissingDocsByStep = (
  loan: Loan,
  step: keyof MissingDocuments,
) => {
  const b1Docs = formatStepMissingDocs(
    loan?.borrowerMissingDocs?.[step] ?? [],
    step,
    true,
  );
  const b2Docs = formatStepMissingDocs(
    loan?.coborrowerMissingDocs?.[step] ?? [],
    step,
    false,
  );
  return [...b1Docs, ...b2Docs];
};
