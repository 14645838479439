import moment from "moment";

const hollidays = [
  moment("2021-12-31"),
  moment("2022-01-17"),
  moment("2022-02-21"),
  moment("2022-05-30"),
  moment("2022-06-20"),
  moment("2022-07-04"),
  moment("2022-09-05"),
  moment("2022-10-10"),
  moment("2022-11-11"),
  moment("2022-11-24"),
  moment("2022-12-26"),
  moment("2023-01-02"),
  moment("2023-01-16"),
  moment("2023-02-20"),
  moment("2023-05-29"),
  moment("2023-06-19"),
  moment("2023-07-04"),
  moment("2023-09-04"),
  moment("2023-10-09"),
  moment("2023-11-10"),
  moment("2023-11-23"),
  moment("2023-12-25"),
  moment("2024-01-01"),
  moment("2024-01-15"),
  moment("2024-02-19"),
  moment("2024-05-27"),
  moment("2024-06-19"),
  moment("2024-07-04"),
  moment("2024-09-02"),
  moment("2024-10-14"),
  moment("2024-11-11"),
  moment("2024-11-28"),
  moment("2024-12-25"),
  moment("2025-01-01"),
  moment("2025-01-20"),
  moment("2025-02-17"),
  moment("2025-05-26"),
  moment("2025-06-19"),
  moment("2025-07-04"),
  moment("2025-09-01"),
  moment("2025-10-13"),
  moment("2025-11-11"),
  moment("2025-11-27"),
  moment("2025-12-25"),
  moment("2026-01-01"),
  moment("2026-01-19"),
  moment("2026-02-16"),
  moment("2026-05-25"),
  moment("2026-06-19"),
  moment("2026-07-03"),
  moment("2026-09-07"),
  moment("2026-10-12"),
  moment("2026-11-11"),
  moment("2026-11-26"),
  moment("2026-12-25"),
  moment("2027-01-01"),
  moment("2027-01-18"),
  moment("2027-02-15"),
  moment("2027-05-31"),
  moment("2027-06-18"),
  moment("2027-07-05"),
  moment("2027-09-06"),
  moment("2027-10-11"),
  moment("2027-11-11"),
  moment("2027-11-25"),
  moment("2027-12-25"),
  moment("2027-12-31"),
  moment("2028-01-17"),
  moment("2028-02-21"),
  moment("2028-05-29"),
  moment("2028-06-19"),
  moment("2028-07-04"),
  moment("2028-09-04"),
  moment("2028-10-09"),
  moment("2028-11-10"),
  moment("2028-11-23"),
  moment("2028-12-25"),
  moment("2029-01-01"),
  moment("2029-01-15"),
  moment("2029-02-19"),
  moment("2029-05-28"),
  moment("2029-06-19"),
  moment("2029-07-04"),
  moment("2029-09-03"),
  moment("2029-10-08"),
  moment("2029-11-12"),
  moment("2029-11-22"),
  moment("2029-12-25"),
  moment("2030-01-01"),
  moment("2030-01-21"),
  moment("2030-02-18"),
  moment("2030-05-27"),
  moment("2030-06-19"),
  moment("2030-07-04"),
  moment("2030-09-02"),
  moment("2030-10-14"),
  moment("2030-11-11"),
  moment("2030-11-28"),
  moment("2030-12-25"),
];

export default hollidays;
