import API from "utils/API";
import { usePlaidLink } from "react-plaid-link";
import { useCallback, useEffect, useState } from "react";
import useTracker from "components/CTracker/useTrackerContext";

const usePlaid = () => {
  const { loan } = useTracker();
  const [linkToken, setLinkToken] = useState<string | null>(null);
  const [loadingPlaid, setLoadingPlaid] = useState<boolean>(false);

  /** usePlaidLink execute the modal of plaid and you get the plaidStatus */
  const { open, ready, exit } = usePlaidLink({
    token: linkToken,
    onEvent: async (eventName, eventMetadata) => {
      await API.post({
        url: `/save-to-loan/plaid-event`,
        data: {
          loanId: loan?.id,
          event: {
            eventName,
            eventMetadata,
          },
        },
      });
    },
    onSuccess: () => null,
  });

  const loadEmployment = useCallback(async () => {
    setLoadingPlaid(true);
    const response = await API.get<{ link_token: string }>(
      `/get-plaid/income/link-token?loanId=${loan?.id}`,
    );
    if ("error" in response) {
      console.error(response.error);
    } else {
      setLinkToken(response.data.link_token);
    }
  }, [loan?.id]);

  useEffect(() => {
    if (linkToken) {
      open();
      setLoadingPlaid(false);
    }
  }, [ready, exit, open, linkToken]);

  return {
    loadEmployment,
    loadingPlaid,
  };
};

export default usePlaid;
