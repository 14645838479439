import { Fragment, useContext } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { HomeMonitorContext } from "../useHomeMonitorContext";
import { deniedTypes } from "../deniedTypes";

const ArchivedReason: React.FC = () => {
  const { loan } = useContext(HomeMonitorContext);
  const theme = useTheme();
  const isArchived = loan?.borrowerFlags?.archiveType;

  const getDeniedReasonsString = (deniedReasons: []) => {
    if (!deniedReasons || deniedReasons.length === 0) {
      return isArchived;
    }

    return (
      deniedReasons
        .map((reason) => {
          const deniedType = deniedTypes.find((type) => type.value === reason);
          return deniedType?.label || reason;
        })
        .join(", ") + "."
    );
  };

  let deniedReasonsString;
  if (loan?.borrowerFlags?.deniedReasons) {
    deniedReasonsString = getDeniedReasonsString(
      loan?.borrowerFlags?.deniedReasons,
    );
  }

  return (
    <Fragment>
      <Box
        borderRadius={10}
        bgcolor={theme.palette.background.paper}
        px={{ xs: 2, md: 6 }}
        py={{ xs: 4, md: 5 }}
        mt={{ xs: 1, md: 1 }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={{ xs: "start", md: "start" }}
          flexDirection={{ xs: "column", md: "column" }}
        >
          <Box>
            <Typography variant="h5" sx={{ fontFamily: "Pro Text Semibold" }}>
              This Loan is Archived
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="subtitle1"
              color={theme.palette.text.secondary}
              sx={{ opacity: 0.9 }}
            >
              The loan was archived due to the follwing reason:{" "}
              <span style={{ color: theme.palette.primary.main }}>
                {deniedReasonsString}
              </span>
            </Typography>
            <Typography
              variant="subtitle1"
              color={theme.palette.text.secondary}
              sx={{ opacity: 0.9 }}
            >
              You can see below all documents related to this file
            </Typography>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default ArchivedReason;
