import CTracker from "components/CTracker";
import { TrackerContextProvider } from "components/CTracker/useTrackerContext";
import useUserCustomerContext from "context/UserCustomer/useUserCustomerContext";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const CoborrowerTracker: React.FC = () => {
  const { loan, selectedLoanId, handleSelectLoan } = useUserCustomerContext();

  const [searchParams] = useSearchParams();
  const loanId = searchParams.get("loanId");

  useEffect(() => {
    if (loanId && !selectedLoanId) {
      handleSelectLoan(loanId);
    }
  }, [loanId, selectedLoanId, handleSelectLoan, loan]);

  return (
    <TrackerContextProvider>
      <CTracker isBorrower={false} />
    </TrackerContextProvider>
  );
};

export default CoborrowerTracker;
