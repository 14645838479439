import PricingEngine from "typedef/PricingEngine";

function getMaxCLTVHELOC(pricingEngine: PricingEngine, creditScore: number) {
  const indexCS = pricingEngine?.rangesCreditScore?.findIndex(
    (item) => creditScore >= item.min && creditScore <= item.max,
  );

  if (indexCS === -1) {
    return 0;
  }

  const margin =
    pricingEngine?.margin?.[indexCS]?.filter((item) => item !== null) ?? [];

  if (margin?.length === 0) {
    return 0;
  }

  const marginRateWithHighestCLTV = [...margin].pop();

  if (!marginRateWithHighestCLTV) {
    return 0;
  }

  const indexCLTV = pricingEngine?.margin?.[indexCS].lastIndexOf(
    marginRateWithHighestCLTV,
  );

  if (indexCLTV === -1 || indexCLTV === undefined) {
    return 0;
  }

  const maxCLTV = pricingEngine?.rangesCltv[indexCLTV];

  return maxCLTV.max;
}

export default getMaxCLTVHELOC;
