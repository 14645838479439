import { TrackerContextType } from "components/CTracker/useTrackerContext";
import { useEffect, useMemo, useState } from "react";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import { mergeMissingDocsByStep } from "utils/missingDocuments";
import Loan from "typedef/Loan";
import { MissingDocuments } from "typedef/MissingDocuments";
import { useGetDtiByCreditScore } from "utils/useGetDtiByCreditScore";

export interface DocsToUpload {
  step: keyof MissingDocuments;
  isBorrower: boolean;
  docName: string;
  submitted: boolean;
}
export const useIncomeVerification = (
  loan: Loan | undefined,
  type: TrackerContextType["type"],
  isModalOpen: boolean,
) => {
  const [showDebtToIncomeModal, setShowDebtToIncomeModal] = useState(false);
  const [openTruv, setOpenTruv] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const { privateLabel } = usePrivateLabel();

  const allowPlaidIncome =
    privateLabel?.borrowerPortalConfiguration?.allowPlaidIncome;

  const isBorrower = type === "borrower";
  const isSelfEmployed = isBorrower
    ? loan?.borrowerEmploymentType === "Self Employed"
    : loan?.coborrowerEmploymentType === "Self Employed";
  const step = isBorrower ? loan?.borrowerSteps : loan?.coborrowerSteps;
  const message = step?.incomeVerification?.message ?? "";

  const promptManualTruv = step?.incomeVerification.promptManualTruv;

  const needDocsUploadB1 =
    loan?.borrowerSteps?.incomeVerification?.needDocReview;
  const needDocsUploadB2 =
    loan?.coborrowerSteps?.incomeVerification?.needDocReview;

  const taxReturnsRequested = isBorrower
    ? loan?.borrowerMissingDocs?.incomeVerification?.some(
        (missingDoc) => missingDoc?.docName?.includes("Tax return"),
      )
    : loan?.coborrowerMissingDocs?.incomeVerification?.some(
        (missingDoc) => missingDoc?.docName?.includes("Tax return"),
      );

  const taxReturnsUploaded = isBorrower
    ? loan?.borrowerMissingDocs?.incomeVerification?.some(
        (missingDoc) =>
          missingDoc?.docName?.includes("Tax return 1") && missingDoc.submitted,
      ) &&
      loan?.borrowerMissingDocs?.incomeVerification?.some(
        (missingDoc) =>
          missingDoc?.docName?.includes("Tax return 2") && missingDoc.submitted,
      )
    : loan?.coborrowerMissingDocs?.incomeVerification?.some(
        (missingDoc) =>
          missingDoc?.docName?.includes("Tax return 1") && missingDoc.submitted,
      ) &&
      loan?.coborrowerMissingDocs?.incomeVerification?.some(
        (missingDoc) =>
          missingDoc?.docName?.includes("Tax return 2") && missingDoc.submitted,
      );

  const stepName = "incomeVerification";

  const docsToUpload: DocsToUpload[] = useMemo(() => {
    if (!loan || !loan?.borrowerMissingDocs) return [];

    return mergeMissingDocsByStep(loan, stepName);
  }, [loan]);

  const prevStep = isBorrower
    ? step?.creditVerification.status === "success" ||
      (step?.creditVerification.status === "analyzing" &&
        (step?.creditVerification.needDocReview !== undefined ||
          step?.creditVerification.deletedDocRequest))
    : step?.creditVerification.status === "success" ||
      (step?.creditVerification.status === "analyzing" &&
        (step?.creditVerification.needDocReview !== undefined ||
          step?.creditVerification.deletedDocRequest ||
          loan?.borrowerSteps?.creditVerification.needDocReview !== undefined ||
          loan?.borrowerSteps?.creditVerification.deletedDocRequest));
  const status = prevStep
    ? step?.incomeVerification?.status ?? "pending"
    : "pending";

  const isB1Docs =
    loan?.borrowerSteps?.incomeVerification?.truvIncome?.paystub1 ||
    loan?.borrowerSteps?.incomeVerification?.truvIncome?.paystub2 ||
    loan?.borrowerSteps?.incomeVerification?.w2AdditionalIncome;
  const isB2Docs =
    loan?.coborrowerSteps?.incomeVerification?.truvIncome?.paystub1 ||
    loan?.coborrowerSteps?.incomeVerification?.truvIncome?.paystub2 ||
    loan?.coborrowerSteps?.incomeVerification?.w2AdditionalIncome;

  const annualIncomeInital = loan?.initialOffer?.annualIncome;
  const annualDebts = loan?.borrowerSteps?.creditVerification?.monthlyDebtLoad;
  const debtToIncomeRatioInitial = loan?.initialOffer?.debtToIncomeRatio;
  const debtToIncomeRatiofinal = loan?.finalOffer?.debtToIncomeRatio as number;
  const annualIncome = loan?.borrowerSteps?.incomeVerification?.annualIncome;
  const dtiItems = useGetDtiByCreditScore();

  const maxDebtToIncomeRatio = dtiItems.dti;
  const debtToIncomeRatio = debtToIncomeRatiofinal ?? debtToIncomeRatioInitial;
  const incomeUpdate =
    annualIncome === undefined ? annualIncomeInital ?? 0 : annualIncome ?? 0;
  const loanBalanceCredit = (annualDebts ? annualDebts * 12 : 0).toLocaleString(
    "en-US",
  );
  const incomeMessage =
    maxDebtToIncomeRatio !== undefined &&
    (debtToIncomeRatio ?? 0) <= maxDebtToIncomeRatio
      ? " acceptable!"
      : " too high. Our CX team will review options and contact you.";

  const boPlaidWarningFlag = loan?.borrowerFlags?.showedPlaidWarning;
  const coPlaidWarningFlag = loan?.coborrowerFlags?.showedPlaidWarning;
  const processingStatus = step?.incomeVerification?.processingStatus;
  const needsReview = step?.incomeVerification?.needsReview;

  const needDocsUpload =
    needDocsUploadB1 === false || needDocsUploadB2 === false;

  const isShowModal = Boolean(
    !isModalOpen &&
      status === "analyzing" &&
      needsReview === undefined &&
      prevStep &&
      !taxReturnsUploaded &&
      ((isBorrower && !boPlaidWarningFlag) ||
        (!isBorrower && !coPlaidWarningFlag)) &&
      (step?.incomeVerification.twnWasRun === true || isSelfEmployed),
  );

  const isDataReady =
    loan?.borrowerSteps?.incomeVerification?.truvIncome ||
    loan?.borrowerSteps?.incomeVerification?.plaidCRA;

  const showDebtsTable =
    loan?.borrowerSteps?.incomeVerification?.annualIncome !== undefined ||
    loan?.initialOffer?.useProceeds === "Debt Consolidation" ||
    taxReturnsUploaded;
  const requestedAdminMessage = "¡ A new paydown has been proposed !";

  useEffect(() => {
    if (isDataReady) {
      setShowDebtToIncomeModal(false);
    } else {
      setShowDebtToIncomeModal(isShowModal);
    }
    setShowDebtToIncomeModal(isShowModal);
  }, [isShowModal, isDataReady]);
  return {
    processingStatus,
    loading,
    status,
    needsReview,
    prevStep,
    isBorrower,
    boPlaidWarningFlag,
    coPlaidWarningFlag,
    isSelfEmployed,
    taxReturnsRequested,
    setLoading,
    step,
    allowPlaidIncome,
    setOpenTruv,
    promptManualTruv,
    isB1Docs,
    isB2Docs,
    openTruv,
    needDocsUploadB1,
    needDocsUploadB2,
    taxReturnsUploaded,
    docsToUpload,
    loanBalanceCredit,
    incomeUpdate,
    debtToIncomeRatio,
    incomeMessage,
    message,
    showDebtToIncomeModal,
    needDocsUpload,
    showDebtsTable,
    requestedAdminMessage,
  };
};
