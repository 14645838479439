import API from "utils/API";
import { useState } from "react";
import CButton from "components/CButton";
import { useForm } from "react-hook-form";
import CTextField from "components/CTextField";
import { Box, FormHelperText, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

const SendCode = () => {
  const [loading, setLoading] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState<string>();
  const { register, handleSubmit, formState } = useForm<{
    email: string;
  }>();
  const navigate = useNavigate();

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    setErrorSubmit(undefined);
    const result = await API.post({
      url: "/send-forgot-password-code",
      data,
    });

    if ("error" in result) {
      setErrorSubmit(result.error);
    } else {
      navigate(
        `/confirm-new-password?email=${encodeURIComponent(data.email)}`,
        { replace: true },
      );
    }
    setLoading(false);
  });
  const theme = useTheme();
  return (
    <Box py={2} bgcolor={theme.palette.background.paper} borderRadius={8}>
      <form onSubmit={onSubmit}>
        <Box px={{ xs: 2, md: 6 }} py={{ xs: 3, md: 6 }}>
          <CTextField
            {...register("email", {
              required: {
                value: true,
                message: "Email is required.",
              },
              minLength: {
                value: 2,
                message: "The Email must be at least 2 characters long.",
              },
              maxLength: {
                value: 100,
                message: "The Email cannot be longer than 100 characters.",
              },
              pattern: {
                value:
                  /^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]{2,40})+(@([a-zA-Z0-9-]{2,20}))+(?:\.[a-zA-Z0]{2,3})*$/i,
                message: "The email format is incorrect.",
              },
            })}
            error={formState?.errors?.email?.message}
            fullWidth
            label="E-mail"
            placeholder="example@example.com"
          />

          {errorSubmit && (
            <FormHelperText error={Boolean(errorSubmit)}>
              <Typography textAlign={"center"}>{errorSubmit}</Typography>
            </FormHelperText>
          )}
          <Box mt={4}>
            <CButton
              fullWidth
              type="submit"
              loading={loading}
              disabled={loading}
              variant="contained"
            >
              Send code
            </CButton>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default SendCode;
