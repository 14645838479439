import API from "utils/API";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import Address from "typedef/Address";
import { useForm } from "react-hook-form";
import { RegisterResponseType } from "typedef/RegisterForm";
import { useSearchParams } from "react-router-dom";
import parseMoney from "utils/parseMoney";
import { InviteContextType } from "context/Invite/useInviteContext";
import {
  defaultValuesDemographicsForm,
  getDemographicsData,
} from "components/CTracker/Steps/DemographicsVerification/useDemographicData";
import DemographicsDataChecks from "typedef/DemographicsDataChecks";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { passwordMsgHelper, regexPassword } from "CONST";
import qs from "qs";
import { IsCellphone } from "screens/BorrowerInviteRegisterForm/useRegisterForm";
import useUser from "context/UserCustomer/useUserCustomerContext";

export type RegisterFormCoborrowerInputs = {
  email: string;
  agree: boolean;
  certify: boolean;
  consent: boolean;
  password: string;
  lastName: string;
  firstName: string;
  phoneNumber: string;
  employmentType: string;
  maritalStatus: string;
  primaryBorrower: string;
  propertyAddress: string;
  address?: Address | null;
  socialSecurityNumber: string;
  dateOfBirth: string | undefined;
  annualIncome: string;
  employerNameCoborrower?: string;
  startDateCoborrower?: string;
  manualAddress?: Address;
  salaryIncomeCoborrower?: string;
  selfEmploymentIncomeCoborrower?: string;
  socialIncomeCoborrower?: string;
  otherIncomeCoborrower?: string;
  totalAnnualIncomeCoborrower?: string;
} & DemographicsDataChecks;

export type RegisterFormCoborrowerDialogs =
  | "terms"
  | "privacy"
  | "verify"
  | "error"
  | "communications"
  | "EMAIL_IN_USE_ON_PL";

type CoborrowerIncomes = {
  salaryIncomeCoborrower: number;
  selfEmploymentIncomeCoborrower: number;
  socialIncomeCoborrower: number;
  otherIncomeCoborrower: number;
};
const MAX_INCOME = 9999999.99;
const MIN_INCOME = 10000;
const MAX_PHONE_LENGTH = 12;
function useYupValidationSchema(invite?: InviteContextType) {
  return useMemo(() => {
    return yup.object().shape({
      firstName: yup
        .string()
        .required("First Name is required.")
        .min(2, "The First Name must be at least 2 characters long.")
        .max(120, "The First Name cannot be longer than 120 characters.")
        .matches(
          /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<,>;:[\]]{2,}$/,
          "The First Name format is incorrect.",
        ),
      lastName: yup
        .string()
        .required("Last Name is required.")
        .min(2, "The Last Name must be at least 2 characters long.")
        .max(120, "The Last Name cannot be longer than 120 characters.")
        .matches(
          /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<,>;:[\]]{2,}$/,
          "The First Name format is incorrect.",
        ),
      phoneNumber: yup
        .string()
        .trim()
        .required("Phone number is required.")
        .test(
          "minLenght",
          "The phone number must be 11 characters long.",
          (value) => {
            if (!value) return true;
            if (process.env.REACT_APP_ENV === "prod") {
              return value.replaceAll(/ /g, "").length === MAX_PHONE_LENGTH;
            }
            return true;
          },
        )
        .notOneOf(
          [yup.ref("phoneBorrower")],
          "Phone number must be different from the borrower's phone number.",
        ),
      otherwise: yup.string().optional(),
      maritalStatus: yup.string().required("Marital status is required."),
      socialSecurityNumber: yup
        .string()
        .required("This field is required")
        .test("is-valid-ssn", "This field is required", function (newValue) {
          const { disabledField } = this.parent;
          return (
            (newValue && newValue.replace(/ /g, "").length >= 11) ||
            !!disabledField
          );
        }),
      dateOfBirth: yup.string().required("Date of Birth is required."),
      salaryIncomeCoborrower: yup
        .string()
        .test(
          "is-greater-than-1000",
          "Salary income must be greater than $1000.",
          (value) => {
            if (!value) return true;
            const numericValue = parseFloat(
              value.replace(/[$,\s]/g, "").replace(/^0+(?=\d)/, ""),
            );
            return numericValue >= 1000;
          },
        )
        .test(
          "is-less-than-9999999",
          "Salary income must be less than $9,999,999.99.",
          (value) => {
            if (!value) return true;
            const numericValue = parseFloat(
              value.replace(/[$,\s]/g, "").replace(/^0+(?=\d)/, ""),
            );
            return numericValue <= 9999999.99;
          },
        ),
      selfEmploymentIncomeCoborrower: yup
        .string()
        .test(
          "is-greater-than-1000",
          "Self-employment income must be greater than $1000.",
          (value) => {
            if (!value) return true;
            const numericValue = parseFloat(
              value.replace(/[$,\s]/g, "").replace(/^0+(?=\d)/, ""),
            );
            return numericValue >= 1000;
          },
        )
        .test(
          "is-less-than-9999999",
          "Self-employment income must be less than $9,999,999.99.",
          (value) => {
            if (!value) return true;
            const numericValue = parseFloat(
              value.replace(/[$,\s]/g, "").replace(/^0+(?=\d)/, ""),
            );
            return numericValue <= 9999999.99;
          },
        ),
      socialIncomeCoborrower: yup
        .string()
        .test(
          "is-greater-than-1000",
          "Social income must be greater than $1000.",
          (value) => {
            if (!value) return true;
            const numericValue = parseFloat(
              value.replace(/[$,\s]/g, "").replace(/^0+(?=\d)/, ""),
            );
            return numericValue >= 1000;
          },
        )
        .test(
          "is-less-than-9999999",
          "Social income must be less than $9,999,999.99.",
          (value) => {
            if (!value) return true;
            const numericValue = parseFloat(
              value.replace(/[$,\s]/g, "").replace(/^0+(?=\d)/, ""),
            );
            return numericValue <= 9999999.99;
          },
        ),

      otherIncomeCoborrower: yup
        .string()
        .test(
          "is-greater-than-1000",
          "Other income must be greater than $1000.",
          (value) => {
            if (!value) return true;
            const numericValue = parseFloat(
              value.replace(/[$,\s]/g, "").replace(/^0+(?=\d)/, ""),
            );
            return numericValue >= 1000;
          },
        )
        .test(
          "is-less-than-9999999",
          "Other income must be less than $9,999,999.99.",
          (value) => {
            if (!value) return true;
            const numericValue = parseFloat(
              value.replace(/[$,\s]/g, "").replace(/^0+(?=\d)/, ""),
            );
            return numericValue <= 9999999.99;
          },
        ),
      employerNameCoborrower: yup.string().when("salaryIncomeCoborrower", {
        is: (value: string) => value !== undefined && value !== "",
        then: yup.string().required("Employer name is required."),
        otherwise: yup.string().optional(),
      }),
      startDateCoborrower: yup.string().when("salaryIncomeCoborrower", {
        is: (value: string) => value !== undefined && value !== "",
        then: yup
          .string()
          .required("Start date is required.")
          .test("min date", "Date must be after 1908-02-05", (value) => {
            return new Date(value as string) >= new Date("1908-02-05");
          })
          .test(
            "max date",
            `Date must be before ${moment().format("YYYY-MM-DD")}`,
            (value) => {
              return new Date(value as string) <= new Date();
            },
          )
          .test(
            "after-date-of-birth",
            "The employment start date cannot be earlier than the date of birth.",
            function (startDate) {
              const { dateOfBirth } = this.parent;
              if (!startDate || !dateOfBirth) return true;
              return new Date(startDate) > new Date(dateOfBirth);
            },
          ),
        otherwise: yup.string().optional(),
      }),
      totalAnnualIncomeCoborrower: yup
        .string()
        .test(
          "total-equals-zero",
          " Income information is required to apply and create a valid offer afterwards",
          function () {
            const {
              salaryIncomeCoborrower,
              selfEmploymentIncomeCoborrower,
              socialIncomeCoborrower,
              otherIncomeCoborrower,
            } = this.parent;
            const totalIncome = [
              parseMoney(salaryIncomeCoborrower),
              parseMoney(selfEmploymentIncomeCoborrower),
              parseMoney(socialIncomeCoborrower),
              parseMoney(otherIncomeCoborrower),
            ].reduce((sum, value) => sum + (value || 0), 0);

            return totalIncome !== 0;
          },
        )
        .test(
          "max",
          "The value cannot be greater than $9,999,999.99.",
          function () {
            const {
              salaryIncomeCoborrower,
              selfEmploymentIncomeCoborrower,
              socialIncomeCoborrower,
              otherIncomeCoborrower,
            } = this.parent;
            const totalIncome = [
              parseMoney(salaryIncomeCoborrower),
              parseMoney(selfEmploymentIncomeCoborrower),
              parseMoney(socialIncomeCoborrower),
              parseMoney(otherIncomeCoborrower),
            ].reduce((sum, value) => sum + (value || 0), 0);

            return totalIncome <= MAX_INCOME;
          },
        )
        .test("min", "The value cannot be less than $10,000.", function () {
          const {
            salaryIncomeCoborrower,
            selfEmploymentIncomeCoborrower,
            socialIncomeCoborrower,
            otherIncomeCoborrower,
          } = this.parent;
          const totalIncome = [
            parseMoney(salaryIncomeCoborrower),
            parseMoney(selfEmploymentIncomeCoborrower),
            parseMoney(socialIncomeCoborrower),
            parseMoney(otherIncomeCoborrower),
          ].reduce((sum, value) => sum + (value || 0), 0);

          return totalIncome >= MIN_INCOME;
        })
        .test(
          "min",
          "Total Annual Income must be greater than $0.",
          function () {
            const {
              salaryIncomeCoborrower,
              selfEmploymentIncomeCoborrower,
              socialIncomeCoborrower,
              otherIncomeCoborrower,
            } = this.parent;
            const totalIncome = [
              parseMoney(salaryIncomeCoborrower),
              parseMoney(selfEmploymentIncomeCoborrower),
              parseMoney(socialIncomeCoborrower),
              parseMoney(otherIncomeCoborrower),
            ].reduce((sum, value) => sum + (value || 0), 0);

            return totalIncome > 0;
          },
        ),
      password: yup
        .string()
        .required("Password is required.")
        .max(100, "The password must be less than 100 characters.")
        .min(9, "The password must be at least 9 characters long.")
        .matches(regexPassword, passwordMsgHelper),
    });
  }, []);
}

function calculateTotalAnnualIncome(incomes: CoborrowerIncomes): number {
  return Object.values(incomes).reduce(
    (total, income) => total + (income ?? 0),
    0,
  );
}

const useRegisterFormCoborrower = (invite?: InviteContextType) => {
  const [searchParams] = useSearchParams();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [openedDialog, setOpenedDialog] =
    useState<RegisterFormCoborrowerDialogs>();

  const [emailAlreadyUseOnPL, setEmailAlreadyUseOnPL] = useState<string>("");
  const [addressData, setAddressData] = useState<Address>();
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [totalAnnualIncome, setTotalAnnualIncome] = useState(0);
  const schema = useYupValidationSchema(invite);
  const { user } = useUser();

  const form = useForm<RegisterFormCoborrowerInputs>({
    mode: "onChange",
    defaultValues: {
      firstName: invite?.firstName ?? "",
      lastName: invite?.lastName ?? "",
      phoneNumber: invite?.phoneNumber ?? "",
      maritalStatus: invite?.maritalStatus ?? "",
      socialSecurityNumber: invite?.socialSecurityNumber ?? "",
      dateOfBirth: invite?.dateOfBirth ?? "",
      employmentType: invite?.employmentType ?? "",
      employerNameCoborrower: invite?.employerNameCoborrower,
      startDateCoborrower: invite?.startDateCoborrower,
      totalAnnualIncomeCoborrower: invite?.annualIncome ?? "",
      ...defaultValuesDemographicsForm,
    },
    resolver: yupResolver(schema),
  });

  const [showIncomeInputsCoborrower, setShowIncomeInputsCoborrower] = useState({
    salaryIncomeCoborrower: false,
    selfEmploymentIncomeCoborrower: false,
    socialIncomeCoborrower: false,
    otherIncomeCoborrower: false,
  });
  const [coborrowerIncomes, setCoborrowerIncomes] = useState({
    salaryIncomeCoborrower: 0,
    selfEmploymentIncomeCoborrower: 0,
    socialIncomeCoborrower: 0,
    otherIncomeCoborrower: 0,
  });

  const incomeFieldsCoborrower = [
    "salaryIncomeCoborrower",
    "selfEmploymentIncomeCoborrower",
    "socialIncomeCoborrower",
    "otherIncomeCoborrower",
  ] as const;

  const incomeFields = [
    "salaryIncome",
    "selfEmploymentIncome",
    "socialIncome",
    "otherIncome",
  ] as const;

  const setCheckboxDefaultValues = () => {
    const updatedState = { ...showIncomeInputsCoborrower };
    incomeFieldsCoborrower.forEach((incomeField) => {
      if (
        invite?.annualIncomeSources &&
        invite?.annualIncomeSources[incomeField] &&
        invite?.annualIncomeSources[incomeField] !== "0"
      ) {
        const auxIncomes = coborrowerIncomes;
        auxIncomes[incomeField] = parseFloat(
          invite?.annualIncomeSources[incomeField] ?? "0",
        );
        setCoborrowerIncomes(auxIncomes);
        updatedState[incomeField] = true;
      }
    });
    incomeFields.forEach((incomeField) => {
      if (
        invite &&
        invite?.body?.incomeSourcesCoborrower &&
        invite?.body?.incomeSourcesCoborrower[incomeField]
      ) {
        const auxIncomes = coborrowerIncomes;
        auxIncomes[`${incomeField}Coborrower`] =
          invite?.body?.incomeSourcesCoborrower[incomeField] ?? 0;
        setCoborrowerIncomes(auxIncomes);
        updatedState[`${incomeField}Coborrower`] = true;
      }
    });
    setShowIncomeInputsCoborrower(updatedState);
  };

  useEffect(() => {
    const totalAnnualIncome = calculateTotalAnnualIncome(coborrowerIncomes);
    setTotalAnnualIncome(totalAnnualIncome);
    form.setValue("totalAnnualIncomeCoborrower", totalAnnualIncome.toString());
  }, [coborrowerIncomes, form, totalAnnualIncome, invite]);

  useEffect(() => {
    setCheckboxDefaultValues();
    if (invite) {
      const address = invite?.customAddress ?? invite.customCoborrowerAddress;
      form.reset({
        firstName: invite?.firstName,
        lastName: invite?.lastName,
        socialSecurityNumber:
          invite?.socialSecurityNumber ?? invite?.body?.ssnCoborrower ?? "",
        phoneNumber: invite?.phoneNumber ?? user?.phoneNumber,
        maritalStatus: invite?.maritalStatus,
        dateOfBirth: invite?.dateOfBirth ?? invite?.body?.dateOfBirthCoborrower,
        employmentType: invite?.employmentType,
        annualIncome: invite?.annualIncome,
        address: invite?.address as Address,
        employerNameCoborrower: invite?.employerNameCoborrower,
        startDateCoborrower: invite?.startDateCoborrower,
        manualAddress: {
          city: address?.city,
          secondary: address?.secondary,
          state: address?.state,
          street_line: address
            ? `${address?.street_number} ${address?.street_name} ${address?.street_suffix}`
            : undefined,
          zipcode: address?.zipcode,
        },
        totalAnnualIncomeCoborrower:
          invite?.annualIncome ?? invite?.body?.totalAnnualIncomeCoborrower,
        ...defaultValuesDemographicsForm,
      });
      form.setValue(
        "salaryIncomeCoborrower",
        invite?.annualIncomeSources?.salaryIncomeCoborrower
          ? invite?.annualIncomeSources?.salaryIncomeCoborrower
          : invite?.body?.incomeSourcesCoborrower?.salaryIncome?.toString() ===
              ""
            ? undefined
            : invite?.body?.incomeSourcesCoborrower?.salaryIncome?.toString(),
      );
      form.setValue(
        "selfEmploymentIncomeCoborrower",
        invite?.annualIncomeSources?.selfEmploymentIncomeCoborrower
          ? invite?.annualIncomeSources?.selfEmploymentIncomeCoborrower
          : invite?.body?.incomeSourcesCoborrower?.selfEmploymentIncome?.toString() ===
              ""
            ? undefined
            : invite?.body?.incomeSourcesCoborrower?.selfEmploymentIncome?.toString(),
      );
      form.setValue(
        "socialIncomeCoborrower",
        invite?.annualIncomeSources?.socialIncomeCoborrower
          ? invite?.annualIncomeSources?.socialIncomeCoborrower
          : invite?.body?.incomeSourcesCoborrower?.socialIncome?.toString() ===
              ""
            ? undefined
            : invite?.body?.incomeSourcesCoborrower?.socialIncome?.toString(),
      );
      form.setValue(
        "otherIncomeCoborrower",
        invite?.annualIncomeSources?.otherIncomeCoborrower
          ? invite?.annualIncomeSources?.otherIncomeCoborrower
          : invite?.body?.incomeSourcesCoborrower?.otherIncome?.toString() ===
              ""
            ? undefined
            : invite?.body?.incomeSourcesCoborrower?.otherIncome?.toString(),
      );
      form.setValue(
        "totalAnnualIncomeCoborrower",
        invite?.annualIncome ?? invite?.body?.totalAnnualIncomeCoborrower,
      );
      form.setValue(
        "socialSecurityNumber",
        invite?.socialSecurityNumber ?? invite?.body?.ssnCoborrower ?? "",
      );
      setAddressData(invite?.address);
      const totalAux =
        invite?.annualIncome ?? invite?.body?.totalAnnualIncomeCoborrower;
      setTotalAnnualIncome(Number(totalAux) ?? 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invite]);

  const onSubmit = form.handleSubmit(async (data) => {
    if (data.selfEmploymentIncomeCoborrower === "") {
      return form.setError("selfEmploymentIncomeCoborrower", {
        message: "This field is required",
      });
    }
    if (data.socialIncomeCoborrower === "") {
      return form.setError("socialIncomeCoborrower", {
        message: "This field is required",
      });
    }
    if (data.otherIncomeCoborrower === "") {
      return form.setError("otherIncomeCoborrower", {
        message: "This field is required",
      });
    }
    if (data.salaryIncomeCoborrower === "") {
      return form.setError("salaryIncomeCoborrower", {
        message: "This field is required",
      });
    }
    setIsAccordionOpen(false);
    if (!invite?.isMobile) {
      const phoneCoborrower = data?.phoneNumber;
      const phoneNumbers = {
        phoneCoborrower: phoneCoborrower,
      };
      const queryString = qs.stringify(phoneNumbers);

      try {
        const response = (await API.get(
          `/getServiceUnsecured/validate-phone-number?${queryString}`,
        )) as IsCellphone;

        const isCellPhone = response.data;

        if (!isCellPhone?.isValid) {
          form.setError("phoneNumber", {
            type: "manual",
            message: `Please provide a valid cell phone number so that you are notified of the next steps of your application.`,
          });
          return;
        }
      } catch (error) {
        setErrorMessage(`Error validating phone numbers: ${error}`);
      }
    }
    if (!addressData || !addressData.street_line) {
      return form.setError("propertyAddress", {
        message: "This field is required",
      });
    }
    const { street_line, ...address } = addressData as Address;
    const street = street_line;

    const validatedDemographicsData =
      getDemographicsData<RegisterFormCoborrowerInputs>({
        demographicsData: data,
        form,
      });
    if ("error" in validatedDemographicsData) {
      setIsAccordionOpen(true);
      return setErrorMessage(validatedDemographicsData.message);
    } else {
      const coborrowerIncomes = [
        data?.salaryIncomeCoborrower,
        data?.selfEmploymentIncomeCoborrower,
        data?.socialIncomeCoborrower,
        data?.otherIncomeCoborrower,
      ].map((value: string | undefined) => {
        if (value) {
          return parseMoney(value.toString());
        }
        return 0;
      });

      const annualIncome = [...coborrowerIncomes].reduce(
        (acc, curr) => acc + curr,
        0,
      );

      const employmentType = data?.salaryIncomeCoborrower
        ? "Full Time Employed/ W2"
        : "Self Employed";

      const response = await API.post<RegisterResponseType>({
        url: `/register-user/coborrower`,
        data: {
          address: {
            street,
            ...address,
          },
          hasAcceptTerms: true,
          agreeNotifications: true,
          email: invite?.email?.trim(),
          password: data.password,
          lastName: data.lastName?.trim(),
          firstName: data.firstName?.trim(),
          employmentType: employmentType,
          maritalStatus: data.maritalStatus,
          phoneNumber: data.phoneNumber.replace(/ /g, ""),
          socialSecurityNumber: data.socialSecurityNumber.replace(/-/g, ""),
          dateOfBirth: moment(data.dateOfBirth).format("yyyy/MM/DD"),
          annualIncome: annualIncome,
          code: searchParams.get("code"),
          employerNameCoborrower: data.employerNameCoborrower,
          startDateCoborrower: data.startDateCoborrower,
          manualAddress: {
            ...data.manualAddress,
            street: data.manualAddress?.street_line,
            state: data.manualAddress?.state?.toLocaleUpperCase(),
          },
          demographics: validatedDemographicsData,
          salaryIncomeCoborrower: data.salaryIncomeCoborrower ?? 0,
          selfEmploymentIncomeCoborrower:
            data.selfEmploymentIncomeCoborrower ?? 0,
          socialIncomeCoborrower: data.socialIncomeCoborrower ?? 0,
          otherIncomeCoborrower: data.otherIncomeCoborrower ?? 0,
          isB2Mobile: true,
          boConsentedHardpull: data?.consent,
        },
      });
      if ("error" in response) {
        setErrorMessage(response.error);
        setOpenedDialog("error");
      } else if (response?.data?.userAccountNoNeedVerification?.message) {
        setEmailAlreadyUseOnPL(
          response.data.userAccountNoNeedVerification.message,
        );
        setOpenedDialog("EMAIL_IN_USE_ON_PL");
      } else {
        setOpenedDialog("verify");
      }
    }
  });

  const handleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  return {
    onSubmit,
    openedDialog,
    errorMessage,
    setOpenedDialog,
    emailAlreadyUseOnPL,
    setErrorMessage,
    addressData,
    setAddressData,
    handleAccordion,
    isAccordionOpen,
    showIncomeInputsCoborrower,
    setShowIncomeInputsCoborrower,
    coborrowerIncomes,
    setCoborrowerIncomes,
    totalAnnualIncome,
    setTotalAnnualIncome,
    incomeFieldsCoborrower,
    ...form,
  };
};

export default useRegisterFormCoborrower;
