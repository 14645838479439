import { Theme, useTheme } from "@mui/material";
import { Link, LinkProps } from "react-router-dom";
import { SystemCssProperties } from "@mui/system";
import Typography from "@mui/material/Typography";

export interface CLinkProps extends LinkProps {
  to: string;
  children: React.ReactNode;
  sx?: SystemCssProperties<Theme>;
  handleClick?: () => void;
}

const CLink: React.FC<CLinkProps> = ({
  children,
  to,
  sx,
  handleClick,
  ...props
}) => {
  const theme = useTheme();
  return (
    <Link
      onClick={handleClick}
      to={to}
      style={{ textDecoration: "none" }}
      {...props}
    >
      <Typography
        sx={[
          {
            color: theme.palette.text.primary,
            fontSize: "1.2rem",
            textDecoration: "none",
            padding: { md: "1rem", xs: 0 },
            fontFamily: "Pro Display Regular",
          },
          sx ? (sx as SystemCssProperties<Theme>) : null,
        ]}
      >
        {children}
      </Typography>
    </Link>
  );
};

export default CLink;
