import moment, { Moment } from "moment";
import isHolliday from "./isHolliday";

function addWorkingDays(date: Date | Moment, daysToAdd: number) {
  let workingDaysAdded = 0;
  let daysAdded = 0;
  while (workingDaysAdded < daysToAdd) {
    if (!isHolliday(moment(date).add(daysAdded + 1, "days"))) {
      workingDaysAdded++;
    }
    daysAdded++;
  }

  return moment(date).add(daysAdded, "days");
}

export default addWorkingDays;
