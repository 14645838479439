const color = {
  red: "#ef6161",
  gray: "#808080",
  white: "#ffffff",
  black: "#313131",
  green: "#48B162",
  yellow: "#E6C026",
  purple: "#6767aa",
  primary: "#6767aa",
  lightGray: "#c9c9c9",
  background: "#f9f7ef",
};

export default color;
