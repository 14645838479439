import { Fragment, useContext } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { HomeMonitorContext } from "../useHomeMonitorContext";

const AddressBox: React.FC = () => {
  const { loan, property } = useContext(HomeMonitorContext);
  const theme = useTheme();

  return (
    <Fragment>
      <Box
        borderRadius={10}
        bgcolor={theme.palette.background.paper}
        px={{ xs: 2, md: 6 }}
        py={{ xs: 4, md: 5 }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={{ xs: "start", md: "center" }}
          flexDirection={{ xs: "column", md: "row" }}
        >
          <Box>
            {property && (
              <Fragment>
                <Typography
                  variant="h5"
                  sx={{ fontFamily: "Pro Text Semibold" }}
                >
                  {property?.address?.delivery_line_1}.
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ fontFamily: "Pro Text Light" }}
                >
                  {property?.address?.components?.city_name},{" "}
                  {property?.address?.components?.state_abbreviation}.{" "}
                  {property?.address?.components?.zipcode}
                </Typography>
              </Fragment>
            )}
          </Box>
          <Box textAlign={"right"}>
            <Typography
              variant="subtitle1"
              color={theme.palette.text.secondary}
              sx={{ opacity: 0.9 }}
            >
              Loan #:{" "}
              <span style={{ color: theme.palette.primary.main }}>
                {loan?.id}
              </span>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default AddressBox;
