import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/system";

interface TitleComponentProps {
  title: string;
}
export const TitleComponent = ({ title }: TitleComponentProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        paddingBottom: "20px",
        marginLeft: "20px",
        marginRight: "20px",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          color: theme.palette.text.primary,
          fontWeight: 700,
          paddingTop: "20px",
          textAlign: "left",
          fontSize: "2.5rem",
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};
