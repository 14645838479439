import { useCallback, useState } from "react";
import EditBankAccount from "typedef/EditBankAccountProcess";
import API from "utils/API";

const useEditBankProcess = (loanId: string) => {
  const [editBankProcess, setEditBankProcess] = useState<EditBankAccount>();

  const getEditBankProcess = useCallback(async () => {
    if (!loanId) {
      return;
    }

    await API.get<EditBankAccount>(`/get-edit-bank-account/${loanId}`).then(
      (res) => {
        if ("error" in res) {
          setEditBankProcess(undefined);
          console.error(res.error);
        } else {
          setEditBankProcess(res.data);
        }
      },
    );
  }, [loanId]);

  return {
    editBankProcess,
    getEditBankProcess,
  };
};

export default useEditBankProcess;
