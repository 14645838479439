import { Fragment } from "react";
import { Box, useTheme, Container, useMediaQuery } from "@mui/material";
import CustomizedTables from "components/LicensesTable";

export default function Licenses() {
  const theme = useTheme();

  return (
    <Fragment>
      <Container
        maxWidth="lg"
        disableGutters={useMediaQuery(theme.breakpoints.only("xs"))}
      >
        <Box bgcolor={theme.palette.background.paper} p={{ md: 8, xs: 2 }}>
          <CustomizedTables />
        </Box>
      </Container>
    </Fragment>
  );
}
