import moment, { Moment } from "moment";
import hollidays from "./hollidays";

function isHolliday(date?: Date | Moment) {
  if (!date) return true;

  const day = moment(date).day();
  if (day === 0) return true;

  return (
    hollidays.some((holliday) => moment(date).isSame(holliday, "day")) ||
    moment(date).get("isoWeekday") === 7
  );
}

export default isHolliday;
