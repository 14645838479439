import {
  LStoken,
  LStokenExp,
  LSAdminToken,
  LSAdminTokenExp,
  LSLoanOfficerToken,
  LSLoanOfficerTokenExp,
} from "CONST";
import getEnvVariable from "env";

export type Data = {
  code: number;
  message: string;
  userAccountNoNeedVerification?: {
    message: string;
  };
};

export type APIResponse<T> = {
  status: number;
  data: T;
  userAccountNoNeedVerification?: {
    message: string;
  };
};

export type APIError = {
  status: number;
  errorId?: string;
  error: string;
};

export type RequestConfig = {
  url: string;
  token?: string | null;
  data?: Record<string, unknown>;
  method?: "POST" | "PUT";
};

class API {
  public static async get<T = unknown>(
    config: RequestConfig | string,
  ): Promise<APIResponse<T> | APIError> {
    try {
      const { url, token } = this.parseConfig(config);
      const isAdmin = url.startsWith("/admin-impersonate/");
      const isLoanOfficer = localStorage.getItem(LSLoanOfficerToken);
      const fixedUrl = isAdmin ? url.replace("/admin-impersonate/", "/") : url;
      const apiUrl = !fixedUrl.startsWith("/get-unsec/private-label")
        ? getEnvVariable("API_URL")
        : getEnvVariable("API_URL").replace("app", "admin");

      let replaceString = "admin";

      if (isLoanOfficer) {
        replaceString = "lo";
      }
      const response = await fetch(
        `${isAdmin ? apiUrl.replace("app", replaceString) : apiUrl}${fixedUrl}`,
        {
          method: "GET",
          headers: {
            ...(token ? { Authorization: `Bearer ${token}` } : {}),
          },
        },
      );

      if (response.status > 300) {
        const error = await response.json();
        if (response.status === 401) {
          localStorage.removeItem(LStoken);
          console.log("Token expired");
          window.location.pathname = "/login";
        } else {
          return {
            status: response.status,
            errorId: error.error,
            error: error.message,
          };
        }
      }
      return {
        status: response.status,
        data: (await response.json()) as T,
      };
    } catch (error) {
      console.error(error);
      this.checkTokenExpired();
      return {
        status: 400,
        error: String(error),
      };
    }
  }

  public static async post<T = unknown>(
    config: RequestConfig | string,
  ): Promise<APIResponse<T> | APIError> {
    try {
      const { url, token, data, method } = this.parseConfig(config);
      const isAdmin = url.startsWith("/admin-impersonate/");
      const isLoanOfficer = localStorage.getItem(LSLoanOfficerToken);
      const fixedUrl = isAdmin ? url.replace("/admin-impersonate/", "/") : url;
      const apiUrl = fixedUrl.startsWith(`/archive/loan`)
        ? getEnvVariable("API_URL").replace("app", "admin")
        : !fixedUrl.startsWith("/bluesage-validate-license")
          ? getEnvVariable("API_URL")
          : getEnvVariable("API_URL").replace("app", "lo");

      let replaceString = "admin";
      if (isLoanOfficer) {
        replaceString = "lo";
      }
      const response = await fetch(
        `${isAdmin ? apiUrl.replace("app", replaceString) : apiUrl}${fixedUrl}`,
        {
          method: method ?? "POST",
          headers: {
            ...(token ? { Authorization: `Bearer ${token}` } : {}),
          },
          body: JSON.stringify(data),
        },
      );
      try {
        if (response.status > 300) {
          const error = await response.json();
          return {
            status: response.status,
            errorId: error.error,
            error: error.message,
          };
        }
        return {
          status: response.status,
          data: (await response.json()) as T,
        };
      } catch (error) {
        if (response.status < 300 && response.status >= 200) {
          return {
            status: response.status,
            data: undefined as unknown as T,
          };
        } else {
          return {
            status: response.status,
            error: String(error),
          };
        }
      }
    } catch (error) {
      console.error(error);
      this.checkTokenExpired();
      return {
        status: 400,
        error: String(error),
      };
    }
  }

  public static parseConfig(config: RequestConfig | string): RequestConfig {
    if (typeof config === "string") {
      return {
        url: config,
        token:
          localStorage.getItem(LStoken) ??
          localStorage.getItem(LSAdminToken) ??
          localStorage.getItem(LSLoanOfficerToken),
      };
    } else {
      return {
        url: config.url,
        method: config?.method,
        token:
          config.token ??
          localStorage.getItem(LStoken) ??
          localStorage.getItem(LSAdminToken) ??
          localStorage.getItem(LSLoanOfficerToken),
        data: config.data,
      };
    }
  }

  static checkTokenExpired() {
    const expToken = localStorage.getItem(LStokenExp);
    const expAdminToken = localStorage.getItem(LSAdminTokenExp);
    const expLoanOfficerToken = localStorage.getItem(LSLoanOfficerTokenExp);
    if (expToken) {
      const dateNow = new Date().getTime();
      const authDate = Number(expToken ?? 0);

      if (dateNow > authDate) {
        localStorage.removeItem(LStokenExp);
        window.location.reload();
      }
    } else if (expAdminToken) {
      const dateNow = new Date().getTime();
      const authDate = Number(expAdminToken ?? 0);

      if (dateNow > authDate) {
        localStorage.removeItem(LSAdminTokenExp);
        window.location.reload();
      }
    } else if (expLoanOfficerToken) {
      const dateNow = new Date().getTime();
      const authDate = Number(expLoanOfficerToken ?? 0);

      if (dateNow > authDate) {
        localStorage.removeItem(LSLoanOfficerTokenExp);
        window.location.reload();
      }
    }
  }
}

export default API;
