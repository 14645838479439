import Loan from "typedef/Loan";

const getFundedDateByLoan = (loan?: Loan) => {
  const disbursementTransaction =
    loan?.borrowerSteps?.fundingVerification.disbursementTransaction;
  return (
    loan?.borrowerSteps?.fundingVerification?.fundedAt ??
    disbursementTransaction?.effective_at ??
    disbursementTransaction?.created_at ??
    loan?.borrowerSteps?.fundingVerification?.fundsSentAt ??
    loan?.borrowerSteps?.fundingVerification?.createdAt
  );
};

export default getFundedDateByLoan;
