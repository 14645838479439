import {
  Box,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

import moment from "moment";
import dollarFormatter from "utils/FormatterDollar";

type Address = {
  line1: string;
  locality: string;
  region: string;
  postal_code: string;
};

type paginateItem = {
  formatedDate: string;
  formatedAmount: string;
  state: string;
  metadata: object;
  paymentType: string;
  status: string;
  payTo: string;
  check: string;
  memo: string;
  address: Address | string;
};

type TableProps = {
  payments: paginateItem[];
  pageLimit: number;
  handleChange: (event: React.ChangeEvent<unknown>, value: number) => void;
  currentPage: number;
};

const commonCellStyles = {
  p: 0,
  py: 1,
  maxWidth: "70px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

function formatTypes(type: string) {
  let formattedType;

  switch (type) {
    case "ach":
      formattedType = "ACH";
      break;
    case "wire":
      formattedType = "Wire";
      break;
    case "rtp":
      formattedType = "RTP";
      break;
    case "check":
      formattedType = "Check";
      break;
    default:
      formattedType = type;
  }
  return formattedType;
}

function stateFormat(state: string) {
  const words = state?.replace(/[_]/g, " ")?.split(" ");
  return words
    ?.map((word) => {
      return word[0]?.toUpperCase() + word?.substring(1);
    })
    ?.join(" ");
}

const formatAddress = (address: Address | string): string => {
  if (typeof address === "string") return address;
  return `${address.line1} ${address.locality}, ${address.region} ${address.postal_code}`;
};

const PaymentsHistoryTable: React.FC<TableProps> = ({
  payments,
  pageLimit,
  currentPage,
  handleChange,
}) => {
  const theme = useTheme();

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ "&:first-child th": { border: 0 } }}>
              <TableCell align="left" sx={{ padding: 0 }}>
                <Typography
                  variant="subtitle1"
                  color={theme.palette.text.secondary}
                  whiteSpace={"nowrap"}
                >
                  Date
                </Typography>
              </TableCell>
              <TableCell align="left" sx={{ px: 0, py: 1 }}>
                <Typography
                  variant="subtitle1"
                  color={theme.palette.text.secondary}
                  whiteSpace={"nowrap"}
                >
                  Amount
                </Typography>
              </TableCell>
              <TableCell align="left" sx={{ px: 0, py: 1 }}>
                <Typography
                  variant="subtitle1"
                  color={theme.palette.text.secondary}
                  whiteSpace={"nowrap"}
                >
                  Payto
                </Typography>
              </TableCell>
              <TableCell align="left" sx={{ px: 0, py: 1 }}>
                <Typography
                  variant="subtitle1"
                  color={theme.palette.text.secondary}
                  whiteSpace={"nowrap"}
                >
                  Address/Last Four
                </Typography>
              </TableCell>
              <TableCell align="left" sx={{ px: 0, py: 1 }}>
                <Typography
                  variant="subtitle1"
                  color={theme.palette.text.secondary}
                  whiteSpace={"nowrap"}
                >
                  Method
                </Typography>
              </TableCell>
              <TableCell align="left" sx={{ px: 0, py: 1 }}>
                <Typography
                  variant="subtitle1"
                  color={theme.palette.text.secondary}
                  whiteSpace={"nowrap"}
                >
                  Status
                </Typography>
              </TableCell>
              <TableCell align="left" sx={{ px: 0, py: 1 }}>
                <Typography
                  variant="subtitle1"
                  color={theme.palette.text.secondary}
                  whiteSpace={"nowrap"}
                >
                  Memo
                </Typography>
              </TableCell>
              <TableCell align="center" sx={{ px: 0, py: 1 }}>
                <Typography
                  variant="subtitle1"
                  color={theme.palette.text.secondary}
                  whiteSpace={"nowrap"}
                >
                  Check#
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          {payments.map((payment, index) => (
            <TableBody>
              <TableRow key={`payment-${index}`}>
                <TableCell align="left" sx={commonCellStyles}>
                  {payment.formatedDate
                    ? moment(payment.formatedDate).format("YYYY-MM-DD")
                    : "-"}
                </TableCell>
                <TableCell align="left" sx={commonCellStyles}>
                  {dollarFormatter.format(Number(payment.formatedAmount))}
                </TableCell>
                <Tooltip title={payment.payTo}>
                  <TableCell
                    align="left"
                    sx={{ ...commonCellStyles, pr: 2, minWidth: "90px" }}
                  >
                    {payment.payTo}
                  </TableCell>
                </Tooltip>
                <Tooltip title={formatAddress(payment.address)}>
                  <TableCell align="left" sx={commonCellStyles}>
                    {formatAddress(payment.address)}
                  </TableCell>
                </Tooltip>
                <TableCell align="left" sx={commonCellStyles}>
                  {payment?.paymentType
                    ? formatTypes(payment?.paymentType)
                    : "-"}
                </TableCell>
                <Tooltip title={stateFormat(payment?.status)}>
                  <TableCell align="left" sx={{ ...commonCellStyles, pr: 2 }}>
                    {stateFormat(payment?.status)}
                  </TableCell>
                </Tooltip>
                <Tooltip title={payment.memo}>
                  <TableCell align="left" sx={commonCellStyles}>
                    {payment.memo ?? "-"}
                  </TableCell>
                </Tooltip>
                <Tooltip title={payment.check ?? "-"}>
                  <TableCell align="center" sx={commonCellStyles}>
                    {payment.check ?? "-"}
                  </TableCell>
                </Tooltip>
              </TableRow>
            </TableBody>
          ))}
        </Table>
      </TableContainer>
      <Box display={"flex"} alignSelf={"flex-end"} pt={2}>
        <Pagination
          count={pageLimit}
          color="primary"
          onChange={handleChange}
          page={currentPage}
        />
      </Box>
    </>
  );
};
export default PaymentsHistoryTable;
