import Loan from "typedef/Loan";
import { useMemo } from "react";
import { mergeMissingDocsByStep } from "./missingDocuments";
import { MissingDocuments } from "typedef/MissingDocuments";

const useMissingDocs = (loan?: Loan) => {
  const docsToUpload = useMemo(() => {
    const mergedArray = [];
    if (!loan || !loan?.borrowerMissingDocs) return [];
    const allSteps = [
      ...Object.keys(loan?.borrowerMissingDocs),
      ...Object.keys(loan?.coborrowerMissingDocs ?? {}),
    ];

    const setAllSteps = new Set(allSteps);
    //@ts-ignore
    const arrayOfSteps = [...setAllSteps];

    for (const key of arrayOfSteps) {
      const docsByStep = mergeMissingDocsByStep(
        loan,
        key as keyof MissingDocuments,
      );
      mergedArray.push(...docsByStep);
    }
    return mergedArray;
  }, [loan]);
  return docsToUpload;
};

export default useMissingDocs;
