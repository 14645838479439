import { Box, Typography } from "@mui/material";
import { useDebtToIncomeModal } from "./useDebtToIncomeModal";
import { DebtToIncomePlaid } from "./DebtToIncomePlaid";
import { DebtToIncomeTruv } from "./DebtToIncomeTruv";
import Loan from "typedef/Loan";
import { DocsToUpload } from "../useIncomeVerification";
import CDialog from "components/CDialog";
import CButton from "components/CButton";
import color from "context/MaterialUITheme/color";

interface DebtToIncomeProps {
  open: boolean;
  docsToUpload: DocsToUpload[];
  handlePlaid: () => void;
  handleTruv: () => void;
  handleOpenTruv: (manualUpload?: boolean) => void;
  needDocsUpload: boolean;
  isSelfEmployed: boolean;
  loading: boolean;
  step?: Loan["borrowerSteps"] | Loan["coborrowerSteps"];
  loan?: Loan;
  taxReturnsRequested?: boolean;
}

export const DebtToIncomeModal = ({
  open,
  docsToUpload,
  handlePlaid,
  handleTruv,
  needDocsUpload,
  isSelfEmployed,
  loading,
  step,
  loan,
  taxReturnsRequested,
  handleOpenTruv,
}: DebtToIncomeProps) => {
  const { styles } = useDebtToIncomeModal();
  const { styles: detbToIncomeStyles } = useDebtToIncomeModal();
  const processing =
    step?.incomeVerification?.errorMessagePlaid ===
      "Processing… Please give us a moment" ||
    step?.incomeVerification?.errorMessageTruv ===
      "Processing… Please give us a moment";

  const isShowUploadDocsTruv = step?.incomeVerification?.truvWasRun;
  return (
    <CDialog open={open}>
      <Box sx={styles.containerStyle}>
        <Typography
          component={"h4"}
          variant="h4"
          color={"black"}
          fontWeight={700}
        >
          Debt to Income (&quot;DTI&quot;)
        </Typography>
        <Box sx={styles.contentContainerStyle}>
          <Typography sx={{ ...styles.text, mb: 2 }}>
            Please select from the following options to verify your DTI:
          </Typography>
          <DebtToIncomePlaid
            loading={loading || processing}
            handleButton={handlePlaid}
            step={step}
          />
          <DebtToIncomeTruv
            loading={loading || processing}
            loan={loan}
            docsToUpload={docsToUpload}
            handleButton={handleTruv}
            needDocsUpload={needDocsUpload}
            isSelfEmployed={isSelfEmployed}
            taxReturnsRequested={taxReturnsRequested}
            step={step}
          />

          {isShowUploadDocsTruv && !isSelfEmployed && (
            <>
              <Typography
                component={"h5"}
                variant="h5"
                color={color.black}
                fontWeight={700}
                sx={{ mt: 4 }}
              >
                Manually upload your Income docs
              </Typography>
              <Typography sx={{ ...detbToIncomeStyles.text, mt: 2 }}>
                If you weren't able to connect through our other methods, try
                manually uploading your most recent W2 and 2 most recent
                paystubs.
              </Typography>
              <CButton
                loading={loading}
                variant="contained"
                sx={styles.buttonStyles}
                onClick={() => handleOpenTruv(true)}
                name={"debtToIncomeTruv-upload-your-docs"}
                aria-label={"debtToIncomeTruv-upload-your-docs"}
              >
                Upload your docs here!
              </CButton>
            </>
          )}
        </Box>
      </Box>
    </CDialog>
  );
};
