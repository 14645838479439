import { useCallback, useEffect, useState } from "react";
import API from "utils/API";

export interface UseVerificationDialogConfig {
  defaultVerificationType?: "sms" | "email";
  onConfirmed(): void;
  email?: string;
  phoneNumber?: string;
}

const useVerificationDialog = ({
  defaultVerificationType,
  onConfirmed,
  email,
  phoneNumber,
}: UseVerificationDialogConfig) => {
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [resendingCode, setResendingCode] = useState(false);
  const [verificationType, setVerificationType] = useState<"sms" | "email">(
    defaultVerificationType ?? "sms",
  );

  const onChangeCode = useCallback((newCode: string) => {
    setCode(newCode);
  }, []);

  const resendCode = useCallback(async () => {
    if (email && verificationType && phoneNumber) {
      setResendingCode(true);
      const response = await API.post({
        url: "/resend-code",
        data: {
          username: email?.trim(),
          type: verificationType,
          phoneNumber: phoneNumber?.split(" ")[0],
        },
      });
      if ("error" in response) {
        setError(response.error);
      }
      setResendingCode(false);
    }
    // eslint-disable-next-line
  }, [verificationType]);

  useEffect(() => {
    resendCode();
  }, [verificationType, resendCode]);

  const togglerVerificationType = useCallback(() => {
    setVerificationType((prev) => (prev === "sms" ? "email" : "sms"));
  }, []);

  useEffect(() => {
    (async () => {
      if (code.length === 6) {
        setError("");
        setLoading(true);
        setCode("");
        const response = await API.post({
          url: "/confirm-user",
          data: {
            code,
            type: verificationType,
            username: email?.trim(),
            phoneNumber: phoneNumber?.split(" ")[0],
          },
        });
        setLoading(false);
        if ("error" in response) {
          setError(response.error);
        } else {
          onConfirmed();
        }
      }
    })();
  }, [code, email, onConfirmed, verificationType, phoneNumber]);

  return {
    code,
    error,
    loading,
    resendCode,
    onChangeCode,
    resendingCode,
    verificationType,
    togglerVerificationType,
  };
};

export default useVerificationDialog;
