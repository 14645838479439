import React from "react";
import { Box, Typography, useTheme, Skeleton } from "@mui/material";
import CButton from "components/CButton";
import CDialog from "components/CDialog";
import dollarFormatter from "utils/FormatterDollar";

interface ShowDetailsModalProps {
  openModal: boolean;
  onClose?: (event?: React.SyntheticEvent, reason?: string) => void;
  totalFee: number | undefined;
  details: (string | number)[][];
  total: number;
  loading: boolean;
  originationFee: number | null;
  state?: string;
}

const ShowDetailsModal: React.FC<ShowDetailsModalProps> = ({
  openModal,
  onClose,
  totalFee,
  details,
  total,
  loading,
  originationFee,
  state,
}) => {
  const theme = useTheme();
  const PERCENTAJE = 100;
  const AMOUNT_OF_DECIMALS = 2;
  const totalFeePercentaje = totalFee
    ? (totalFee * PERCENTAJE).toFixed(AMOUNT_OF_DECIMALS)
    : "2.99";

  return (
    <CDialog
      maxWidth="lg"
      open={openModal}
      PaperProps={{
        sx: {
          width: { xs: "95vw", sm: "320px" },
          maxWidth: "1200px",
          margin: "0 auto",
        },
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.background.paper,
          textAlign: "center",
          width: "0 auto",
        }}
      >
        <Typography
          variant="h4"
          fontFamily="Pro Display Bold"
          width="100%"
          textAlign={"left"}
        >
          Estimated Fees
        </Typography>
        <Box
          sx={{
            justifyContent: "justify-between",
            display: "flex",
            gap: "60px",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box
            style={{
              justifyContent: "justify-between",
              display: "flex",
              flexDirection: "column",
              flex: "1 1 0",
            }}
          >
            <Box
              sx={{
                display: "flex",
                textAlign: "left",
                textWrap: "nowrap",
                margin: "20px 0 15px 0",
              }}
            >
              <Typography
                fontSize={18}
                color={theme.palette.text.primary}
                fontFamily="Pro Display Bold"
                sx={{ flex: "1 1 0", textAlign: "left", textWrap: "nowrap" }}
              >
                {`${state !== "MI" ? "Origination Fee" : "Processing Fee"} (${
                  totalFeePercentaje ?? 0
                }%)`}
                :
              </Typography>
              <Typography
                fontSize={14}
                color={theme.palette.text.primary}
                fontFamily="Pro Display Bold"
                sx={{ flex: "1 1 0", textAlign: "right" }}
              >
                {!loading ? (
                  dollarFormatter.format(Number(originationFee))
                ) : (
                  <Skeleton
                    width="75%"
                    height={"1.5rem"}
                    sx={{ flex: "1 1 0", textAlign: "right" }}
                  />
                )}
              </Typography>
            </Box>
            <Typography
              fontSize={18}
              color={theme.palette.text.primary}
              fontFamily="Pro Display Bold"
              sx={{ flex: "1 1 0", textAlign: "left", textWrap: "nowrap" }}
            >
              Fees:
            </Typography>
            {details.map(([title, value]) => (
              <Box
                key={title}
                display="flex"
                justifyContent="space-between"
                alignItems={"flex-start"}
                sx={{ flex: "1 1 0" }}
              >
                <Typography
                  fontSize={18}
                  color={theme.palette.text.primary}
                  sx={{ flex: "1 1 0", textAlign: "left", textWrap: "nowrap" }}
                >
                  {title}:
                </Typography>
                <Typography
                  fontSize={14}
                  color={theme.palette.text.primary}
                  fontFamily="Pro Display Regular"
                  ml={2}
                  sx={{ flex: "1 1 0", textAlign: "right" }}
                >
                  {!loading ? (
                    typeof value === "number" ? (
                      dollarFormatter.format(Number(value))
                    ) : (
                      String(value)
                    )
                  ) : (
                    <Skeleton
                      width="75%"
                      height={"1.5rem"}
                      sx={{ flex: "1 1 0", textAlign: "right" }}
                    />
                  )}
                </Typography>
              </Box>
            ))}
            <Box
              sx={{
                display: "flex",
                textAlign: "left",
                textWrap: "nowrap",
                marginTop: "20px",
              }}
            >
              <Typography
                fontSize={18}
                color={theme.palette.text.primary}
                fontFamily="Pro Display Bold"
                sx={{ flex: "1 1 0", textAlign: "left", textWrap: "nowrap" }}
              >
                Total:
              </Typography>
              <Typography
                fontSize={14}
                color={theme.palette.text.primary}
                fontFamily="Pro Display Bold"
                sx={{ flex: "1 1 0", textAlign: "right" }}
              >
                {!loading ? (
                  dollarFormatter.format(Number(total))
                ) : (
                  <Skeleton
                    width="75%"
                    height={"1.5rem"}
                    sx={{ flex: "1 1 0", textAlign: "right" }}
                  />
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
        <CButton
          type="reset"
          variant="outlined"
          fullWidth
          sx={{
            marginTop: 2,
            backgroundColor: theme.palette.primary.main,
            color: "white",
            "&:hover": {
              backgroundColor: theme.palette.primary.dark,
            },
          }}
          onClick={onClose}
        >
          Close Details
        </CButton>
      </Box>
    </CDialog>
  );
};

export default ShowDetailsModal;
