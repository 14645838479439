import { Box, Typography, useTheme } from "@mui/material";
import CDialog from "components/CDialog";
import CButton from "components/CButton";
import ClosingRefDialog from "assets/Images/ClosingRefDialog.png";
interface ClosingNowDialogProps {
  open: boolean;
  onClose: () => void;
}
export const ClosingNowDialog = ({ open, onClose }: ClosingNowDialogProps) => {
  const theme = useTheme();
  return (
    <CDialog open={open} maxWidth="xs">
      <Box display="flex" alignItems="center" flexDirection="column">
        <Typography
          variant="h6"
          sx={{ mb: 2, fontSize: "1.2rem" }}
          color={theme.palette.text.secondary}
        >
          You will be able to start within 5 minutes. Please get your Photo ID
          ready!{" "}
          <a
            href="https://support.proof.com/hc/en-us/articles/360057120014-Acceptable-Forms-of-ID-for-Online-Notarization"
            target="_blank"
            rel="noopener noreferrer"
          >
            (Which IDs are valid?)
          </a>{" "}
          <br />
          Once your documents are ready you will see the “Review and Sign”
          button in your portal.
        </Typography>
        <Box sx={{ my: 1 }}>
          <img
            width={342}
            height={158}
            src={ClosingRefDialog}
            alt="preview-button-sign-closing-disclosures"
          />
        </Box>
        <Typography
          variant="h6"
          sx={{ mb: 2, fontSize: "1.2rem" }}
          color={theme.palette.text.secondary}
        >
          While you wait, please ensure you’ve connected your bank account for
          your funding. FYI, you will also receive an email from Proof with your
          closing link.
        </Typography>
        <CButton
          loading={false}
          fullWidth
          variant="contained"
          sx={{ mt: 1 }}
          onClick={onClose}
          name={"close-now-ron"}
          aria-label={"close-now-ron"}
        >
          Confirm
        </CButton>
      </Box>
    </CDialog>
  );
};
export default ClosingNowDialog;
