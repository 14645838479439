import CLoader from "components/CLoader";
import { useContext } from "react";
import { HomeMonitorContext } from "./useHomeMonitorContext";

const HomeMonitorLoader: React.FC = () => {
  const { loan, property } = useContext(HomeMonitorContext);
  return <CLoader loading={loan && property ? false : true} />;
};

export default HomeMonitorLoader;
