import CHeader from "components/CHeader";
import CFooter from "components/CFooter";
import { Outlet } from "react-router-dom";
import { InviteCustomerContextProvider } from "context/Invite/useInviteContext";

const LayoutWithContexts = () => {
  return (
    <InviteCustomerContextProvider>
      <CHeader />
      <Outlet />
      <CFooter />
    </InviteCustomerContextProvider>
  );
};

export default LayoutWithContexts;
