import { Typography, useTheme, Box } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import { Close } from "@mui/icons-material";

export type Messages = {
  CLTVAndFICO: string;
  CLTV: string;
  StateRegulations: string;
  drawLessThan100: string;
  BPOIsOutstanding: string;
};

const MARGIN_WITH_STATE_REGULATIONS = 33;
const MARGIN_WITHOUT_STATE_REGULATIONS = 90;

const FinalOfferMessages = ({
  reasons,
  errorMessage,
}: {
  reasons: (keyof Messages)[];
  errorMessage: string | undefined;
}) => {
  const theme = useTheme();

  return (
    <>
      {reasons.map((reason) => (
        <Box
          key={reason}
          mt={4}
          mb={5}
          px={{ xs: 2, md: 0 }}
          display="flex"
          alignItems="center"
        >
          <WarningIcon
            style={{
              fontSize: 30,
              marginRight: 8,
              marginBottom:
                reason === "StateRegulations"
                  ? MARGIN_WITH_STATE_REGULATIONS
                  : MARGIN_WITHOUT_STATE_REGULATIONS,
            }}
            htmlColor={theme.palette.warning.main}
          />
          <Typography
            color={theme.palette.text.primary}
            fontSize={{ xs: ".8rem", md: "1.2rem" }}
            dangerouslySetInnerHTML={{ __html: messages[reason] }}
          />
        </Box>
      ))}

      {errorMessage && (
        <Box
          mt={4}
          mb={5}
          px={{ xs: 2, md: 0 }}
          display="flex"
          alignItems="center"
        >
          <Close
            style={{
              fontSize: 30,
              marginRight: 8,
              color: "red",
            }}
          />
          <Typography
            color={theme.palette.text.primary}
            fontSize={{ xs: ".8rem", md: "1.2rem" }}
          >
            {errorMessage}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default FinalOfferMessages;

const messages: Messages = {
  CLTVAndFICO:
    "<b>It looks like your rate may have increased because our pricing is based on CLTV (combined loan to value) ratios and your FICO score.</b> If either the CLTV goes up or the FICO goes down from the stated values of the application to your verified results, then this can cause your rate to go up. We are sorry for any inconvenience.",
  CLTV: "<b>Its looks like your available loan sizes may have decreased because of CLTV (combined loan to value) restrictions.</b> If there are differences between the stated values or debts of the application to your verified results, this can cause your loan size to go down. We are sorry for any inconvenience.",
  StateRegulations:
    "<b>Due to your state and local regulations, there may be a change on your loan amount as follows.</b>",
  drawLessThan100:
    "<b>Your selected a draw of less than 100%.</b> Please note that after closing, there may be delays in your ability draw more funds for up to a month as we transfer loan servicing to another company. If you would like to avoid these delays, please consider increasing your draw to 100%. This is your final chance before we go to closing.",
  BPOIsOutstanding:
    "<b>Special note regarding your property value.</b> The BPO property value report that we ordered is still outstanding. If the report comes back with a valuation that supports your application, then there will be no delays. However, if the BPO value is lower than expected, it might cause your offer to change and/or be withdraw.",
};
