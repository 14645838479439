import API from "utils/API";
import { useEffect, useState } from "react";
import useTracker from "components/CTracker/useTrackerContext";

const useAnnuncementCoborrower = () => {
  const { loan } = useTracker();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (
      loan?.borrowerFlags?.coborrowerInvited &&
      loan?.borrowerSteps?.incomeVerification.status === "success" &&
      loan.coborrowerSteps?.incomeVerification.status !== "success" &&
      loan?.borrowerFlags?.offeredAddingEmployment &&
      !loan?.borrowerFlags?.finalOfferAccepted &&
      !loan.finalOffer &&
      !loan.borrowerFlags?.annuncementCoborrowerPending
    ) {
      setOpenModal(true);
    }
  }, [
    loan?.borrowerFlags?.coborrowerInvited,
    loan?.borrowerSteps?.incomeVerification.status,
    loan?.coborrowerSteps?.incomeVerification.status,
    loan?.finalOffer,
    loan?.borrowerFlags?.finalOfferAccepted,
    loan?.borrowerFlags?.offeredAddingEmployment,
    loan?.borrowerFlags?.annuncementCoborrowerPending,
  ]);

  const handleAddAnnuncementCoborrowerPendign = async () => {
    setLoading(true);
    await API.post({
      url: `/save-to-loan/flags?entity=borrower&loanId=${loan?.id}`,
      data: {
        annuncementCoborrowerPending: true,
      },
    });
    setLoading(false);
    setOpenModal(false);
  };

  return {
    loading,
    openModal,
    handleAddAnnuncementCoborrowerPendign,
  };
};

export default useAnnuncementCoborrower;
