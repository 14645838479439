import API from "utils/API";
import { useState, useEffect } from "react";
import Loan from "typedef/Loan";
import useUser from "context/UserCustomer/useUserCustomerContext";
export type TermsOfTheLoanHELOCProps = {
  primeRate: number;
};
const useTermsOfTheLoanHELOC = (loan?: Loan) => {
  const { userAdmin } = useUser();
  const [helocPricingEngine, setHelocPricingEngine] = useState<
    TermsOfTheLoanHELOCProps | undefined
  >();
  const APIUrl = userAdmin?.id
    ? "/admin-impersonate/get/heloc-pricing-engine/heloc?"
    : `/get/heloc-pricing-engine?`;

  useEffect(() => {
    (async () => {
      if (loan) {
        const encodePricingEngine = encodeURIComponent(loan.pricingEngineId);

        const response = await API.get<TermsOfTheLoanHELOCProps>(
          `${APIUrl}date=${loan.pricingEngineCreatedAt}&id=${encodePricingEngine}`,
        );
        if ("error" in response) {
          console.error(response.error);
        } else {
          setHelocPricingEngine(response.data);
        }
      }
    })();
  }, [loan?.pricingEngineCreatedAt, loan?.pricingEngineId, loan, APIUrl]);
  return {
    helocPricingEngine,
  };
};
export default useTermsOfTheLoanHELOC;
