function numberToKString(n: number | string) {
  const thousand = 1e3;
  const million = 1e6;
  const billion = 1e9;

  const num = Number(n);
  if (num >= million && num < billion) {
    return num / million + "M";
  } else if (num >= thousand) {
    return num / thousand + "k";
  }

  return num.toString();
}
export default numberToKString;
