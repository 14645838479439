import API from "utils/API";
import { Doc } from "./CTable";
import { useEffect, useState } from "react";
import Loan from "typedef/Loan";
import { useParams } from "react-router-dom";

type Paginate = {
  page: number;
  pageItems: Doc[];
  pageLimit: number;
  loading: boolean;
};

export type DocumentsHook = {
  refetch: boolean;
  documents: Paginate;
  openDialog: boolean;
  setRefetch: React.Dispatch<React.SetStateAction<boolean>>;
  currentPage: number;
  handleChange: (event: React.ChangeEvent<unknown>, value: number) => void;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
};

const useDocuments = (isAdmin: boolean, loan?: Loan) => {
  const [refetch, setRefetch] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };
  const params = useParams();
  const isBorrowerType = params?.borrowerType === "borrower";

  const currentAdminLoanId = params?.loanId;

  const [documents, setDocuments] = useState<Paginate>({
    page: 1,
    pageItems: [],
    pageLimit: 1,
    loading: false,
  });

  useEffect(() => {
    if (loan) {
      setDocuments((prev) => ({
        ...prev,
        loading: true,
      }));
      const userId = isBorrowerType ? loan.borrowerId : loan.coborrowerId;
      API.post<Paginate>({
        url:
          isAdmin && currentAdminLoanId
            ? `/admin-impersonate/paginate/documents-home-monitor?loanId=${loan?.id}&userId=${userId}`
            : `/paginate/documents?loanId=${loan?.id}`,
        data: {
          page: currentPage,
          items: 5,
        },
      })
        .then((result) => {
          if (!("error" in result)) {
            setDocuments({
              ...result?.data,
              loading: false,
            });
          }
        })
        .catch(() => {
          setDocuments((prev) => ({
            ...prev,
            loading: false,
          }));
        });
    }
  }, [loan, currentPage, refetch, isBorrowerType, isAdmin, currentAdminLoanId]);

  return {
    refetch,
    documents,
    openDialog,
    setRefetch,
    currentPage,
    handleChange,
    setOpenDialog,
  };
};

export default useDocuments;
