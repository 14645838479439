import CButton from "components/CButton";
import { useNavigate } from "react-router-dom";

const CLoginButton: React.FC<{
  handleClick: () => void;
}> = ({ handleClick }) => {
  const navigate = useNavigate();

  return (
    <CButton
      onClick={() =>
        (() => {
          handleClick();
          navigate("/login", { replace: true });
        })()
      }
      sx={{
        maxWidth: "240px",
        marginTop: { md: 0, xs: 2 },
        marginLeft: { md: 2, xs: 0 },
        border: { xs: "solid 1px white", md: "none" },
      }}
      variant="contained"
    >
      {"Log in"}
    </CButton>
  );
};

export default CLoginButton;
