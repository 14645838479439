import API from "utils/API";
import Loan from "typedef/Loan";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useCallback, useEffect } from "react";
import runHardCreditLambda from "utils/runHardCreditLambda";
import Address from "typedef/Address";
import { FieldErrors } from "react-hook-form";
import { DemographicsVerification } from "typedef/BorrowerSteps";
import { LSLoanOfficerToken } from "CONST";
import useUser from "context/UserCustomer/useUserCustomerContext";

export type ValuesSliderProps = {
  minDraw?: number;
  maxDraw?: number;
  showSlider?: boolean;
};
export type ValuesHELOC = {
  APR?: number;
  initialDrawAmount?: number;
  interestRate?: number;
  maximumInitialDraw?: number;
  minimumInitialDraw?: number;
  monthlyInterestCharge?: number;
  monthlyPayment?: number;
  monthlyPaymentInitialDraw?: number;
  netFundingWiredToBank?: number;
  amount?: number;
};

const useDefineLoanType = (loan: Loan, isAdmin?: boolean) => {
  const navigate = useNavigate();
  const params = useParams();
  const currentAdminLoanId = params?.loanId;
  const [loading, setLoading] = useState(true);
  const [isAccepting, setIsAccepting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [primaryHomeAddress, setPrimaryHomeAddres] = useState<Address>();
  const [primaryHomeAddressError, setPrimaryHomeAddresError] =
    useState<FieldErrors>();
  const { handleSelectLoan } = useUser();
  const borrowerType = "borrower";

  const [newValuesSlider, setNewValuesSlider] = useState<
    ValuesHELOC | undefined
  >();
  const [initialValuesSliderHELOC, setInitialValuesSliderHELOC] = useState<
    ValuesSliderProps | undefined
  >();

  const [marks, setMarks] = useState<
    {
      value: number;
    }[]
  >([]);

  const loanComingFromPrequal = Boolean(loan?.submittedByLO);

  useEffect(() => {
    setInitialValuesSliderHELOC(undefined);
    setNewValuesSlider(undefined);
    setMarks([]);
    (async () => {
      setLoading(true);
      const response =
        isAdmin && currentAdminLoanId
          ? await API.get<ValuesSliderProps>(
              `/admin-impersonate/get/get-heloc-slider-values/value?loanId=${currentAdminLoanId}`,
            )
          : await API.get<ValuesSliderProps>(
              `/get/get-heloc-slider-values?loanId=${loan?.id}`,
            );

      if ("error" in response) {
        console.error(response.error);
      } else {
        setInitialValuesSliderHELOC(response.data);
      }
      setLoading(false);
    })();
  }, [loan, isAdmin, currentAdminLoanId]);

  useEffect(() => {
    if (initialValuesSliderHELOC?.maxDraw && loan?.id) {
      (async () => {
        setLoading(true);
        let replaceString = "admin";

        const isLoanOfficer = localStorage.getItem(LSLoanOfficerToken);
        if (isLoanOfficer) {
          replaceString = "lo";
        }
        const recalculatedLoanResponse =
          isAdmin && currentAdminLoanId
            ? await API.post<ValuesHELOC>({
                url: `/admin-impersonate/save-to-loan-${replaceString}/calculate-heloc-offer-initial?ignoreMaxDti=true`,
                data: {
                  initialDrawAmount: initialValuesSliderHELOC?.maxDraw,
                  loanId: currentAdminLoanId,
                },
              })
            : await API.post<ValuesHELOC>({
                url: `/calculate-heloc-offer/initial?ignoreMaxDti=true`,
                data: {
                  initialDrawAmount: initialValuesSliderHELOC?.maxDraw,
                  loanId: loan?.id,
                },
              });

        if ("error" in recalculatedLoanResponse) {
          console.error(recalculatedLoanResponse);
          setShowErrorMessage(true);
          setErrorMessage(recalculatedLoanResponse.error);
        } else {
          setShowErrorMessage(false);
          setErrorMessage("");
          setNewValuesSlider(recalculatedLoanResponse.data);
        }
        setLoading(false);
      })();
    }
  }, [initialValuesSliderHELOC, loan?.id, isAdmin, currentAdminLoanId]);

  useEffect(() => {
    if (loan) {
      const minOffer = initialValuesSliderHELOC?.minDraw ?? 0;
      const maxOffer = initialValuesSliderHELOC?.maxDraw ?? 0;
      const steps = 10.0;
      const jump = (maxOffer - minOffer) / steps;
      const padding = jump * 0.1;
      const paddedMinOffer = minOffer + padding;
      const paddedMaxOffer = maxOffer - padding * 2;
      const paddedJump = (paddedMaxOffer - paddedMinOffer) / steps;

      setMarks(
        Array.from({ length: steps + 1 }).map((_, index) => ({
          value: paddedMinOffer + paddedJump * index,
        })),
      );
    }
    // eslint-disable-next-line
  }, [initialValuesSliderHELOC]);

  const recalculateOffer = async (incomingNewAmount: number | number[]) => {
    if (Array.isArray(incomingNewAmount)) return undefined;
    setLoading(true);
    let initialDrawAmount = incomingNewAmount;
    if (incomingNewAmount > (initialValuesSliderHELOC?.maxDraw ?? 0))
      initialDrawAmount = initialValuesSliderHELOC?.maxDraw ?? 0;
    if (incomingNewAmount < (initialValuesSliderHELOC?.minDraw ?? 0))
      initialDrawAmount = initialValuesSliderHELOC?.minDraw ?? 0;
    const recalculatedLoanResponse = await API.post<ValuesHELOC>({
      url: `/calculate-heloc-offer/initial?ignoreMaxDti=true`,
      data: { initialDrawAmount, loanId: loan?.id },
    });
    if ("error" in recalculatedLoanResponse) {
      console.error(recalculatedLoanResponse);
      setShowErrorMessage(true);
      setErrorMessage(recalculatedLoanResponse.error);
    } else {
      setShowErrorMessage(false);
      setErrorMessage("");
      setNewValuesSlider(recalculatedLoanResponse?.data);
    }
    setLoading(false);
  };

  const savePrimaryHomeAddress = async (primaryHomeAddress: Address) => {
    await API.post({
      url: `/save-to-loan/save-primary-home-address`,
      data: { address: primaryHomeAddress, loanId: loan?.id },
    });
  };

  const saveOffer = useCallback(
    async (
      loanType: string,
      initialDrawAmount = 0,
      demographics?: DemographicsVerification,
      boConsentedHardpull = false,
    ) => {
      setIsAccepting(true);
      if (primaryHomeAddress) {
        await savePrimaryHomeAddress(primaryHomeAddress);
      }
      const response = await API.post({
        url: `/define-loan-type?ignoreMaxDti=true`,
        data: {
          loanId: loan?.id,
          loanType: loanType,
          ...(loanType === "HELOC" && {
            initialDrawAndProceed:
              initialDrawAmount ??
              newValuesSlider?.initialDrawAmount ??
              initialValuesSliderHELOC?.maxDraw,
          }),
          demographics: demographics,
        },
      });
      if ("error" in response) {
        console.error(response);
        setErrorMessage(response.error);
      } else {
        if (loanComingFromPrequal) {
          runHardCreditLambda(loan.id, borrowerType);
        }
        await API.post({
          url: `/save-to-loan/flags?entity=borrower&loanId=${loan?.id}`,
          data: {
            initialOfferChanged: true,
            boConsentedHardpull: boConsentedHardpull ?? undefined,
          },
        });
        // Refresh loan to get the new values and prevent redirect on tracker
        await handleSelectLoan(loan.id, false);

        navigate(`/borrower-tracker?loanId=${loan?.id}`, {
          replace: true,
        });
      }
      setIsAccepting(false);
    },
    // eslint-disable-next-line
    [
      navigate,
      initialValuesSliderHELOC,
      newValuesSlider,
      loan,
      primaryHomeAddress,
    ],
  );

  return {
    loan,
    marks,
    loading,
    saveOffer,
    isAccepting,
    errorMessage,
    newValuesSlider,
    showErrorMessage,
    initialValuesSliderHELOC,
    recalculateOffer,
    setPrimaryHomeAddres,
    primaryHomeAddress,
    setPrimaryHomeAddresError,
    primaryHomeAddressError,
  };
};

export default useDefineLoanType;
