import CDot from "components/CDot";
import useDrawHELOC from "./useDrawHELOC";
import { Fragment, useContext } from "react";
import RequestDrawDialog from "./RequestDrawDialog";
import CChartDoughnut from "components/CChartDoughnut";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { HomeMonitorContext } from "../useHomeMonitorContext";
import RequestConfirmedDialog from "./RequestConfirmedDialog";
import useUser from "context/UserCustomer/useUserCustomerContext";

const DrawHELOC = () => {
  const theme = useTheme();
  const { loan } = useContext(HomeMonitorContext);
  const { user } = useUser();
  const isBorrower = user && user.id === loan?.borrowerId;
  const drawHELOC = useDrawHELOC(loan);

  const drawAmount =
    ((drawHELOC?.drawInfo?.result?.totalDrawAmount ?? 0) /
      (drawHELOC?.drawInfo?.result?.totalLoanAmount ?? 1)) *
    100;
  const disabled = drawHELOC.isTexas
    ? (drawHELOC?.drawInfo?.result?.totalToRequest ?? 0) <= 0
    : (drawHELOC?.drawInfo?.result?.totalToRequest ?? 0) <= 125;

  if (loan?.loanType === "HELOC") {
    return (
      <Fragment>
        <Box
          my={2}
          gap={6}
          display={"flex"}
          borderRadius={10}
          bgcolor={theme.palette.background.paper}
          px={{ xs: 2, md: 6 }}
          py={{ xs: 4, md: 5 }}
          alignItems={"center"}
          sx={{
            flexWrap: { xs: "wrap", md: "nowrap" },
            justifyContent: { xs: "center", md: "space-between" },
          }}
        >
          {drawHELOC.drawInfo && <CChartDoughnut drawAmount={drawAmount} />}
          <Box display={"flex"} flexDirection={"column"} px={{ xs: 0, md: 8 }}>
            <Typography variant="h5" sx={{ fontFamily: "Pro Text Semibold" }}>
              <CDot color={"transparent"} />
              Total Credit Line: $
              {drawHELOC?.drawInfo?.result?.totalLoanAmount?.toLocaleString(
                "en-US",
              )}
            </Typography>
            <Typography variant="h5" sx={{ fontFamily: "Pro Text Semibold" }}>
              <CDot color={"#FF9571"} />
              Amount Drawn: $
              {drawHELOC?.drawInfo?.result?.totalDrawAmount?.toLocaleString(
                "en-US",
              )}
            </Typography>
            <Typography variant="h5" sx={{ fontFamily: "Pro Text Semibold" }}>
              <CDot color={"#49B162"} />
              Available Draw: $
              {drawHELOC?.drawInfo?.result?.totalToRequest?.toLocaleString(
                "en-US",
              )}
            </Typography>
            {isBorrower && (
              <Button
                onClick={drawHELOC.handleOpenModal}
                sx={{
                  px: 6,
                  mt: 2.4,
                  py: 1.4,
                  mx: "auto",
                  width: "fit-content",
                  borderRadius: "24px",
                  textTransform: "none",
                  fontFamily: "Pro Text Light",
                }}
                fullWidth={false}
                variant="contained"
                disabled={disabled}
              >
                Request Draw
              </Button>
            )}
          </Box>
        </Box>
        <RequestDrawDialog {...drawHELOC} />
        <RequestConfirmedDialog {...drawHELOC} />
      </Fragment>
    );
  } else return <></>;
};

export default DrawHELOC;
