import { Fragment } from "react";
import CButton from "components/CButton";
import CDialog from "components/CDialog";
import CoborrowerInvite from "../CoborrowerInvite";
import useJointAnnouncement from "./useJointAnnouncement";

export type NPEForm = {
  email: string;
};

const JointAccountAnnouncement = () => {
  const jointAnnouncement = useJointAnnouncement();

  return (
    <Fragment>
      <CDialog
        maxWidth="xs"
        icon="warning"
        title={`We've noticed the following names as owners on your bank account: ${jointAnnouncement?.ownerNames?.join(
          ", ",
        )}`}
        description="You will need to add this person as a co-borrower"
        open={jointAnnouncement.openAnnouncement}
      >
        <CButton
          fullWidth
          onClick={jointAnnouncement.handleAccept}
          variant="contained"
          sx={{ marginBottom: 3, marginTop: 1 }}
          name="jointAccountAnnouncement-add"
        >
          Add
        </CButton>
      </CDialog>

      <CoborrowerInvite
        open={jointAnnouncement.coborrowerInviteIsOpen}
        onClose={() => {
          jointAnnouncement.setCoborrowerInviteIsOpen(false);
          jointAnnouncement.setIsModalOpen(false);
        }}
        onDismiss={() => {
          jointAnnouncement.setCoborrowerInviteIsOpen(false);
          jointAnnouncement.setIsModalOpen(false);
        }}
      />
    </Fragment>
  );
};

export default JointAccountAnnouncement;
