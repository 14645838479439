import { ChangeEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { incomeOptions } from "./incomeOptions";
import BorrowerSteps from "typedef/BorrowerSteps";
import CoborrowerSteps from "typedef/CoborrowerSteps";
import API from "utils/API";
import useLoan from "components/CTracker/useLoan";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { isFilePdf } from "utils/validateFile";
import { Buffer } from "buffer";
import parseMoney from "utils/parseMoney";

export const getDigitsLenghtFromStringAmount = (value?: string) => {
  const digits = value?.replace(" ", "").replace(/[$,\s]/g, "");
  const integerPart = digits?.split(".")[0];
  return integerPart?.length;
};

const INCOME_MIN_DIGITS = 4;
const INCOME_MAX_DIGITS = 9;

const schema = yup.object().shape({
  incomeType: yup.string().required(),
  amount: yup
    .string()
    .required("Annual income amount is required")
    .test(
      "min-digits",
      "Income amount must have at least 4 digits",
      (value) => {
        const digitsLenght = getDigitsLenghtFromStringAmount(value);
        const isValid = digitsLenght && digitsLenght >= INCOME_MIN_DIGITS;
        return isValid as boolean;
      },
    )
    .test("max-digits", "Income amount must have at most 9 digits", (value) => {
      const digitsLenght = getDigitsLenghtFromStringAmount(value);
      const isValid = digitsLenght && digitsLenght <= INCOME_MAX_DIGITS;
      return isValid as boolean;
    }),
  document: yup.mixed().required("Document is required"),
});

type AdditionalIncomeModalProps = {
  incomeType?: string;
  amount?: string;
  document?: string | null;
  fileType?: string;
  fileExt?: string;
  documentName?: string;
};

export type IncomeSources = {
  [key: string]: number | undefined;
};

type UseIncomeDocUploadProps = {
  step?: BorrowerSteps | CoborrowerSteps;
  isAdditional: boolean;
  open: boolean;
  isBorrower: boolean;
  handleClose: () => void;
  incomeSourcesOptions: { label: string; value: string }[];
};

const useIncomeDocUpload = ({
  step,
  isAdditional = false,
  open = false,
  isBorrower,
  handleClose,
  incomeSourcesOptions,
}: UseIncomeDocUploadProps) => {
  const loan = useLoan();
  const [selectedIncomeType, setSelectedIncomeType] = useState("");
  const [optionAmount, setOptionAmount] = useState<number | undefined>(
    undefined,
  );
  const [availableIncomeOptions, setAvailableIncomeOptions] =
    useState(incomeSourcesOptions);
  const [moreThanOneIncomeSource, setMoreThanOneIncomeSource] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (open) {
      const incomeSources: IncomeSources =
        step?.incomeVerification?.incomeSources || {};
      const hasIncomeSources = Object.keys(incomeSources).length > 0;
      if (hasIncomeSources && selectedIncomeType === "" && !isAdditional) {
        const incomeSourceKeys = Object.keys(incomeSources);
        setMoreThanOneIncomeSource(incomeSourceKeys.length > 1);

        setAvailableIncomeOptions(incomeSourcesOptions);

        if (incomeSourcesOptions.length === 1) {
          setSelectedIncomeType(incomeSourcesOptions[0].value);
          setOptionAmount(
            incomeSources[incomeSourcesOptions[0].value] || undefined,
          );
        } else if (incomeSourcesOptions.length > 1) {
          const defaultIncomeType = incomeSourcesOptions[0].value;
          setSelectedIncomeType(defaultIncomeType);
          setOptionAmount(incomeSources[defaultIncomeType] || undefined);
        }
      } else if (isAdditional && selectedIncomeType === "") {
        setSelectedIncomeType(incomeOptions[0].value);
        setOptionAmount(undefined);
      }
    }
  }, [step, isAdditional, selectedIncomeType, open, incomeSourcesOptions]);

  const handleIncomeSourceType = (event: ChangeEvent<HTMLInputElement>) => {
    const newIncomeType = event.target.value;
    setSelectedIncomeType(newIncomeType);
    setOptionAmount(
      isAdditional
        ? undefined
        : (step?.incomeVerification?.incomeSources as IncomeSources)?.[
            newIncomeType
          ] ?? undefined,
    );
  };
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    clearErrors,
    setError,
    watch,
  } = useForm<AdditionalIncomeModalProps>({
    defaultValues: {
      incomeType: isAdditional ? incomeOptions[0].value : "",
      amount: isAdditional ? "0" : "",
      document: undefined as string | undefined,
    },
    resolver: yupResolver(schema),
  });

  const documentName = watch("documentName");

  async function convertToBase64(event: React.ChangeEvent<HTMLInputElement>) {
    const selectedFile = event.target.files as FileList | null | undefined;

    if (JSON.stringify(selectedFile)?.length > 0) {
      clearErrors("document");
      const fileToLoad = selectedFile?.[0];

      const isPdf = await isFilePdf(fileToLoad as File);

      const megabyteInBytes = 1048576;
      const maxSizeInMb = 100;
      const sizeInMB = fileToLoad && fileToLoad.size / megabyteInBytes;
      const fileType = fileToLoad?.type as string;
      const fileExt = fileToLoad?.name?.split(".").pop() as string;

      if (!isPdf) {
        event.target.value = "";
        setError("document", { message: "File type not supported" });
        setValue("document", "");
        setValue("fileType", "");
        setValue("fileExt", "");
        setValue("documentName", "Upload documents (required)");
        return;
      }

      if (sizeInMB && sizeInMB > maxSizeInMb) {
        event.target.value = "";
        setError("document", {
          message: `You can't upload files larger than ${maxSizeInMb} MB`,
        });
      } else {
        const fileReader = new FileReader();
        fileReader.onload = function (fileLoadedEvent) {
          setValue("document", fileLoadedEvent?.target?.result as string);
          setValue("fileType", fileType);
          setValue("fileExt", fileExt);
          setValue("documentName", fileToLoad?.name);
        };
        fileReader.readAsDataURL(fileToLoad as Blob);
      }
    }
  }

  useEffect(() => {
    setValue("incomeType", selectedIncomeType);
    if (optionAmount !== undefined) setValue("amount", optionAmount.toString());
  }, [selectedIncomeType, optionAmount, setValue]);

  const handleRefresh = () => {
    reset();
    setSelectedIncomeType("");
    setOptionAmount(undefined);
  };

  const onSubmit = handleSubmit(async (data) => {
    if (!data.document) {
      return setError("document", { message: "Document is required" });
    }
    setIsLoading(true);
    const incomeTypeFiltered = incomeOptions.find(
      (option) => option.value === data.incomeType,
    )?.label;

    const newDocumentName = `${incomeTypeFiltered?.replaceAll(" ", "_")}_${
      isAdditional ? "AdditionalIncome" : "VerifyIncome"
    }_${new Date().getTime()}`;

    const userId = isBorrower ? loan?.borrowerId : loan?.coborrowerId;

    const resultPresignedUrl = (await API.post({
      url: "/additional-income/get-presigned-url",
      data: {
        loanId: loan?.id,
        userId: userId,
        fileType: data?.fileType,
        extension: data?.fileExt,
        documentName: newDocumentName,
      },
    })) as { error?: string; data: { docKey?: string; presignedUrl: string } };

    if ("error" in resultPresignedUrl) {
      setIsLoading(false);
      setError("document", {
        message: "Error uploading document. Please, try again!",
      });
    } else {
      const base64Data = data?.document?.split(",")[1];
      const buffer = Buffer.from(base64Data, "base64");
      const presignedUrl = resultPresignedUrl?.data?.presignedUrl;
      const docKey = resultPresignedUrl?.data?.docKey;

      const response = await fetch(presignedUrl, {
        method: "PUT",
        headers: {
          "Content-Type": data.fileType as string,
        },
        body: buffer,
      });

      const failedStatus = 300;
      if (response.status >= failedStatus) {
        setIsLoading(false);
        setError("document", {
          message: "Error uploading document. Please, try again!",
        });
      }
      const annualAmount = isAdditional
        ? parseMoney(data?.amount ?? "0")
        : optionAmount;

      if (annualAmount) {
        await API.post({
          url: "/additional-income/add-additional-income",
          data: {
            loanId: loan?.id,
            amount: annualAmount,
            docKey: docKey,
            incomeType: incomeTypeFiltered,
            incomeSource: !isAdditional,
          },
        });
        setIsLoading(false);
        handleClose();
        handleRefresh();
      }
    }
  });

  return {
    control,
    handleSubmit,
    selectedIncomeType,
    handleIncomeSourceType,
    onSubmit,
    availableIncomeOptions,
    moreThanOneIncomeSource,
    optionAmount,
    handleRefresh,
    convertToBase64,
    documentName,
    isLoading,
  };
};

export default useIncomeDocUpload;
