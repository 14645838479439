import { FC } from "react";
import CDialog from "components/CDialog";
import Close from "@mui/icons-material/Close";
import { Box, IconButton, Typography, useTheme } from "@mui/material";

type RequestConfirmedDialogProps = {
  openModalConfirm: boolean;
  handleCloseModalConfirm: () => void;
};

const RequestConfirmedDialog: FC<RequestConfirmedDialogProps> = ({
  openModalConfirm,
  handleCloseModalConfirm,
}) => {
  const theme = useTheme();
  return (
    <CDialog fullWidth maxWidth="xs" scroll={"paper"} open={openModalConfirm}>
      <Box
        mb={2}
        display="flex"
        width={"100%"}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <Typography
          variant="h5"
          textAlign="left"
          fontFamily={"Pro Display Bold"}
        >
          Draw request confirmed!
        </Typography>
        <IconButton onClick={() => handleCloseModalConfirm()}>
          <Close fontSize="large" htmlColor={theme.palette.text.secondary} />
        </IconButton>
      </Box>
      <Box
        gap={1}
        display={"flex"}
        textAlign={"start"}
        flexDirection={"column"}
      >
        <Typography fontFamily={"Pro Display Regular"} variant="h6">
          Please be advised that deposits will be processed within two to three
          business days.
          <br />
          <br />
          To modify this request please email{" "}
          <a
            style={{
              cursor: "pointer",
              color: theme.palette.primary.main,
              textDecoration: "none",
            }}
            href={`mailto:support@nftydoor.com`}
          >
            support@nftydoor.com
          </a>{" "}
          or call us at{" "}
          <a
            style={{
              cursor: "pointer",
              color: theme.palette.primary.main,
              textDecoration: "none",
            }}
            href={`tel:+18449755150`}
          >
            844-975-5150
          </a>
          .
        </Typography>
      </Box>
    </CDialog>
  );
};

export default RequestConfirmedDialog;
