import { Typography, CircularProgress, Box } from "@mui/material";
import CButton from "components/CButton";
import CDialog from "components/CDialog";
import { FC, useState } from "react";
import ConfirmationDialog from "./confirmationDialog";
import API from "utils/API";

interface HelpDialogProps {
  isOpen: boolean;
  onClose: () => void;
  isBorrower: boolean;
  loanId?: string;
}

const HelpDialog: FC<HelpDialogProps> = ({
  isOpen,
  onClose,
  isBorrower,
  loanId,
}) => {
  const [loading, setLoading] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  const setHelpFlag = async () => {
    await API.post({
      url: `/save-to-loan/save-help-flag?entity=${
        isBorrower ? "borrower" : "coborrower"
      }&loanId=${loanId}`,
      data: { needsHelp: true },
    });
  };

  const onConfirm = async () => {
    setLoading(true);
    await setHelpFlag();
    setLoading(false);
    setIsConfirmationDialogOpen(true);
  };

  const closeBothDialogs = () => {
    setIsConfirmationDialogOpen(false);
    onClose();
  };

  return (
    <>
      <CDialog
        open={isOpen}
        maxWidth="md"
        icon="warning"
        title="Are you sure you need help?"
      >
        <Typography
          fontWeight={300}
          fontSize={{ md: "1.3rem" }}
          marginBottom={3}
        >
          This will alert our CX team and they will reach out to you shortly.
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          gap="24px"
          margin="10px"
          paddingTop="20px"
        >
          <CButton
            disabled={loading}
            fullWidth
            variant="outlined"
            onClick={onClose}
            sx={{
              textTransform: "inherit",
              fontSize: 18,
              fontWeight: 500,
            }}
            name="helpDialog-noIMisclicked"
            aria-label="helpDialog-noIMisclicked"
          >
            {loading ? (
              <CircularProgress size={32} sx={{ color: "white" }} />
            ) : (
              "No, I misclicked"
            )}
          </CButton>

          <CButton
            disabled={loading}
            fullWidth
            variant="contained"
            onClick={onConfirm}
            sx={{ textTransform: "inherit", fontSize: 18, fontWeight: 500 }}
            name="helpDialog-yesIConfirm"
            aria-label="helpDialog-yesIConfirm"
          >
            {loading ? (
              <CircularProgress size={32} sx={{ color: "white" }} />
            ) : (
              "Yes, I confirm"
            )}
          </CButton>
        </Box>
      </CDialog>
      {isConfirmationDialogOpen && (
        <ConfirmationDialog
          open={isConfirmationDialogOpen}
          close={closeBothDialogs}
        />
      )}
    </>
  );
};

export default HelpDialog;
