import API from "utils/API";
import Loan from "typedef/Loan";
import { useNavigate } from "react-router-dom";
import { useState, useCallback, useEffect } from "react";
import useUser from "context/UserCustomer/useUserCustomerContext";

export type Marks = Loan["initialOffer"][];

const useHeloanOffer = () => {
  const navigate = useNavigate();
  const [loan, setLoan] = useState<Loan>();
  const [loading, setLoading] = useState(true);
  const [marks, setMarks] = useState<Marks>([]);
  const [isAccepting, setIsAccepting] = useState(false);
  const [markSelectIndex, setMarkSelectIndex] = useState(0);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const { selectedLoanId } = useUser();

  const saveOffer = useCallback(async () => {
    setIsAccepting(true);
    const response = await API.post({
      url: `/accept-offer/final`,
      data: { amount: marks[markSelectIndex].amount, loanId: loan?.id },
    });
    if ("error" in response) {
      console.error(response);
      setErrorMessage(response.error);
      setShowErrorMessage(true);
    } else {
      navigate(`/borrower-tracker?loanId=${selectedLoanId}`, { replace: true });
    }
    setIsAccepting(false);
  }, [navigate, marks, markSelectIndex, loan?.id, selectedLoanId]);

  useEffect(() => {
    if (loan?.id) {
      (async () => {
        setShowErrorMessage(false);
        setLoading(true);
        const loanResponse = await API.get<Loan>(
          `/get/my-loan?id=${selectedLoanId}`,
        );
        if ("error" in loanResponse) {
          setShowErrorMessage(true);
          setErrorMessage(loanResponse.error);
        } else {
          setLoan(loanResponse.data);
          const offersResponse = await API.get<Marks>(
            `/get-offer/final?loanId=${loan?.id}`,
          );
          if ("error" in offersResponse) {
            setShowErrorMessage(true);
            setErrorMessage(offersResponse.error);
          } else {
            setMarks(offersResponse.data);
          }
        }
        setLoading(false);
      })();
    }
  }, [loan?.id, selectedLoanId]);

  return {
    loan,
    loading,
    saveOffer,
    isAccepting,
    errorMessage,
    showErrorMessage,
    marks,
    markSelectIndex,
    setMarkSelectIndex,
  };
};

export default useHeloanOffer;
