import { forwardRef, useState } from "react";
import { IconButton, InputAdornment, useTheme } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CTextField, { CTextFieldProps } from "components/CTextField";

const CPasswordField: React.FC<Omit<CTextFieldProps, "ref">> = forwardRef(
  (props, ref) => {
    const handleMouseDownPassword = (
      event: React.MouseEvent<HTMLButtonElement>,
    ) => {
      event.preventDefault();
    };

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };
    const theme = useTheme();
    return (
      <CTextField
        ref={ref}
        type={showPassword ? "text" : "password"}
        InputProps={{
          sx: {
            color: theme.palette.text.primary,
            borderRadius: "9px",
            padding: "18px, 14px",
          },

          endAdornment: (
            <InputAdornment position="end" sx={{ pr: 1 }}>
              <IconButton
                edge="end"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                aria-label="toggle password visibility"
              >
                {!showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...props}
      />
    );
  },
);

export default CPasswordField;
