import API from "utils/API";
import { useEffect, useState } from "react";
import useTracker from "components/CTracker/useTrackerContext";

const useAnnuncementCreateNewOfferPendign = () => {
  const { loan } = useTracker();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (
      loan?.borrowerSteps?.incomeVerification.status === "success" &&
      loan?.borrowerFlags?.offeredAddingEmployment &&
      !loan?.borrowerFlags?.finalOfferAccepted &&
      !loan.finalOffer &&
      !loan.borrowerFlags?.annuncementFinalOfferPending &&
      loan?.borrowerSteps?.createNewOfferVerification?.status === "failed"
    ) {
      setOpenModal(true);
    }
  }, [
    loan?.borrowerFlags?.coborrowerInvited,
    loan?.borrowerSteps?.incomeVerification.status,
    loan?.finalOffer,
    loan?.borrowerSteps?.createNewOfferVerification?.status,
    loan?.borrowerFlags?.finalOfferAccepted,
    loan?.borrowerFlags?.offeredAddingEmployment,
    loan?.borrowerFlags?.annuncementCoborrowerPending,
    loan?.borrowerFlags?.annuncementFinalOfferPending,
  ]);

  const handleAddAnnuncementCreateNewOfferPending = async () => {
    setLoading(true);
    await API.post({
      url: `/save-to-loan/flags?entity=borrower&loanId=${loan?.id}`,
      data: {
        annuncementFinalOfferPending: true,
      },
    });
    setLoading(false);
    setOpenModal(false);
  };

  return {
    loading,
    openModal,
    handleAddAnnuncementCreateNewOfferPending,
  };
};

export default useAnnuncementCreateNewOfferPendign;
