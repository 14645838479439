import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Typography } from "@mui/material";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import { statesNames } from "typedef/stateMaping";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function CustomizedTables() {
  const { privateLabel } = usePrivateLabel();
  return (
    <TableContainer component={Paper} elevation={0}>
      <Box>
        <Typography
          mt={4}
          mb={5}
          ml={5}
          variant="h2"
          lineHeight={1}
          letterSpacing={-0.8}
          pl={{ xs: 2, md: 0 }}
          fontSize={{ xs: "3rem", md: "5rem" }}
        >
          Licenses
        </Typography>
      </Box>
      <Table
        sx={{
          minWidth: 700,
          borderCollapse: "collapse",
          border: "1px solid black",
        }}
        aria-label="customized table"
      >
        <TableHead>
          <TableRow>
            <StyledTableCell
              align="center"
              style={{ borderRight: "1px solid #ddd" }}
            >
              State
            </StyledTableCell>
            <StyledTableCell
              align="center"
              style={{ borderRight: "1px solid #ddd" }}
            >
              License Name
            </StyledTableCell>
            <StyledTableCell
              align="center"
              style={{ borderRight: "1px solid #ddd" }}
            >
              License Number
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {privateLabel?.statesLicensed.map((data) => (
            <StyledTableRow key={data.state_id}>
              <StyledTableCell
                align="center"
                component="th"
                scope="row"
                style={{ borderRight: "1px solid black" }}
              >
                {
                  statesNames.find((state) => state.state_id === data.state_id)
                    ?.stateName
                }
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ borderRight: "1px solid black" }}
              >
                {data.licenseName}
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ borderRight: "1px solid black" }}
              >
                {data.licenseNumber}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
