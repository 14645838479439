import { Fragment } from "react";
import CHeader from "components/CHeader";
import CFooter from "components/CFooter";
import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <Fragment>
      <CHeader />
      <Outlet />
      <CFooter />
    </Fragment>
  );
};

export default Layout;
