import { FC, useState } from "react";
import CButton from "components/CButton";
import CDialog from "components/CDialog";
import parseMoney from "utils/parseMoney";
import { Close } from "@mui/icons-material";
import { Controller } from "react-hook-form";
import CNumberField from "components/CNumberField";
import useEditLoanAmount from "../EditLoanAmount/useEditLoanAmount";
import {
  Box,
  DialogProps,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import ChangeLoanAmountAndAppraisalModal from "../EditLoanAmount/ChangeLoanAmountAndAppraisalModal";

const EditLoanAmount: FC<
  DialogProps & {
    onDismiss?(): void;
    endpoint: string;
    isFirstLien?: boolean;
    tnMaxOffer?: number; //temporary TN validation
    loanId?: string;
    state?: string;
    handleRefreshLoan?: () => void;
    handleRefreshOffer?: () => void;
    handleSubmit?: (amount: number, loanId: string) => void;
    setEditLoanAmountOpen: (value: React.SetStateAction<boolean>) => void;
  }
> = ({
  onDismiss,
  handleRefreshLoan,
  handleRefreshOffer,
  handleRefreshOffer: _handleRefreshOffer,
  handleSubmit,
  ...props
}) => {
  const [popAppraisalModal, setPopAppraisalModal] = useState(false);
  const [avmsData, setAvmsData] = useState<string[] | undefined>();
  const handleSetPopAppraisalModal = (value: boolean) => {
    setPopAppraisalModal(value);
  };
  const form = useEditLoanAmount(
    props.endpoint,
    props.onClose,
    props.setEditLoanAmountOpen,
    handleSetPopAppraisalModal,
    setAvmsData,
    props.isFirstLien,
    props.tnMaxOffer, //temporary TN validation
    props.state,
    props.loanId,
    handleRefreshLoan,
    handleRefreshOffer,
  );
  const control = form.control;
  const { errors } = form.formState;
  const theme = useTheme();
  const disableSubmit = !form.watch("newLoanAmount");
  const newLoanAmount = form.watch("newLoanAmount");

  return (
    <>
      <CDialog maxWidth="xs" {...props}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            form.onSubmit();
          }}
        >
          <Box display="flex" alignItems="center">
            <Typography
              variant="h5"
              fontSize={{ xs: 20, md: 30 }}
              textAlign="left"
              fontFamily={"Pro Display Semibold"}
            >
              Enter in your desired loan amount
            </Typography>
            <IconButton
              onClick={() => {
                if (onDismiss) onDismiss();
                else {
                  props.onClose?.({}, "backdropClick");
                  form.reset();
                  form.resetField("newLoanAmount");
                }
              }}
            >
              <Close fontSize="large" htmlColor={theme.palette.text.disabled} />
            </IconButton>
          </Box>
          <Controller
            name="newLoanAmount"
            control={control}
            render={({ field }) => (
              <CNumberField
                fullWidth
                label="Loan Amount"
                placeholder="$200,000"
                isNumericString
                thousandSeparator
                allowNegative={false}
                allowEmptyFormatting={false}
                prefix="$"
                sx={{ mt: 2, mb: 2 }}
                {...field}
                error={errors?.newLoanAmount?.message}
                value={parseMoney(field.value)}
              />
            )}
          />
          <CButton
            type="submit"
            disabled={disableSubmit || Boolean(errors.newLoanAmount)}
            loading={form.formState.isSubmitting}
            variant="contained"
            fullWidth
            sx={{ paddingY: "19px", marginTop: 2 }}
          >
            Change Loan Amount
          </CButton>
          <CButton
            type="reset"
            variant="outlined"
            fullWidth
            sx={{ marginTop: 2, paddingY: "19px" }}
            onClick={() => {
              form.reset();
              form.resetField("newLoanAmount");
              props.onClose?.({}, "backdropClick");
            }}
          >
            Cancel
          </CButton>
        </form>
      </CDialog>
      {popAppraisalModal && (
        <ChangeLoanAmountAndAppraisalModal
          onClose={() => setPopAppraisalModal(false)}
          newAmount={parseMoney(newLoanAmount)}
          reset={() => form.resetField("newLoanAmount")}
          closeEditModal={() => props.onClose?.({}, "backdropClick")}
          refresh={props.onSubmit}
          avmsData={avmsData}
        />
      )}
    </>
  );
};

export default EditLoanAmount;
