import { Box, Typography, useTheme } from "@mui/material";
import useHomeMonitor from "../useHomeMonitorContext";
import PaymentsHistoryTable from "./PaymentHistoryTable";

const PaymentHistoryBox: React.FC = () => {
  const theme = useTheme();
  const homeMonitorContext = useHomeMonitor();
  const payments = homeMonitorContext.paymentHook;
  return (
    <Box
      borderRadius={10}
      bgcolor={theme.palette.background.paper}
      px={{ xs: 2, md: 6 }}
      py={{ xs: 4, md: 5 }}
      mt={1}
    >
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={{ xs: "start", md: "start" }}
        flexDirection={{ xs: "column", md: "column" }}
      >
        <Box>
          <Typography variant="h5" sx={{ fontFamily: "Pro Text Semibold" }}>
            Disbursement History
          </Typography>
        </Box>
        {payments && payments.payments ? (
          <PaymentsHistoryTable
            payments={payments.payments.pageItems}
            pageLimit={payments.payments.pageLimit}
            currentPage={payments.currentPage}
            handleChange={payments.handleChange}
          />
        ) : (
          <Typography
            mt={2}
            variant="subtitle1"
            fontWeight={600}
            color={theme.palette.text.secondary}
            sx={{ fontFamily: "Pro Text Light", whiteSpace: "pre-line" }}
          >
            No payments to display.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default PaymentHistoryBox;
