import moment from "moment";
import usePayments from "./usePayments";
import CDetailLine from "../CDetailLine";
import CButton from "components/CButton";
import useMakePayment from "./useMakePayment";
import { Box, Typography, useTheme } from "@mui/material";
import useNextPaymentDate from "./nextPaymentDate";
import dollarFormatter from "utils/FormatterDollar";
import MakeaPaymentDialog from "./MakeaPaymentDialog";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import usePayoff from "./usePayoff";
import PayoffDialog from "./PayoffDialog";
import ThankYouDialog from "./ThankYouDialog";
import API from "utils/API";
import useUser from "context/UserCustomer/useUserCustomerContext";
import usePlaidEditBankAccount from "./usePlaidEditBankAccount";
import EditBankAccountDialog from "./EditBankAccountDialog";
import EditBankResultModalDialog from "./EditBankResultModalDialog";
import useEditBankProcess from "screens/HomeMonitor/ScheduledPayments/useEditBankProcess";
import useLoan from "../useLoan";
import getFundedDateByLoan from "utils/getFundedDateByLoan";
import useMonthlyPaymentsDone from "../useMonthlyPaymentsDone";

type NextPayment = {
  amount: number;
  isPayble: boolean;
};

const ScheduledPayments: React.FC<{ isAdmin: boolean }> = ({ isAdmin }) => {
  const theme = useTheme();
  const { overviewInfo } = useNextPaymentDate();
  const payoff = usePayoff({ isAdmin });
  const { loan, refetchLoan } = useLoan({ isAdmin });
  const { user } = useUser();
  const paymentHistory = usePayments(loan?.id);
  const isBorrower = user && user.id === loan?.borrowerId;
  const makePayment = useMakePayment({ loanId: loan?.id as string });
  const currentDuePaymentNumber = useMonthlyPaymentsDone(loan);
  const [seePaymentHistory, setSeePaymentHistory] = useState(false);
  const [nextPayment, setNextPayment] = useState<NextPayment>();
  const [editBankResultDialog, setEditBankResultDialog] = useState({
    open: false,
  });
  const { editBankProcess, getEditBankProcess } = useEditBankProcess(
    loan?.id ?? "",
  );
  const [editBankError, setEditBankError] = useState(false);

  const handleEditBankError = useCallback(() => {
    setEditBankError(true);
  }, []);

  const openEditBankResultDialog = async () => {
    await refetchLoan();
    await getEditBankProcess();
    setEditBankResultDialog((prev) => ({
      ...prev,
      open: true,
    }));
  };

  const closeEditBankResultDialog = async () => {
    setEditBankResultDialog((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const [editBankAccountDialog, setEditBankAccountDialog] = useState({
    open: false,
  });

  const openEditBankAccountDialog = async () => {
    setEditBankError(false);
    await getEditBankProcess();
    setEditBankAccountDialog((prev) => ({
      ...prev,
      open: true,
    }));
  };

  const closeEditBankAccountDialog = () => {
    setEditBankAccountDialog((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const plaid = usePlaidEditBankAccount(
    openEditBankResultDialog,
    handleEditBankError,
    loan?.id as string,
  );

  useEffect(() => {
    if (loan?.id) {
      API.get<NextPayment>(`/get/next-payment?loanId=${loan.id}`).then(
        (res) => {
          if ("error" in res) {
            console.error(res);
          } else {
            setNextPayment(res.data);
          }
        },
      );
    }
  }, [loan?.id]);

  const bankAccountNumber = useMemo(() => {
    const capitalizeFirstLetter = (word: string | undefined) => {
      if (!word) return;
      return word.charAt(0).toUpperCase() + word.slice(1);
    };

    if (editBankProcess && !editBankProcess?.pendingVerification) {
      return `${capitalizeFirstLetter(
        editBankProcess.accountSubtype,
      )} *** ${editBankProcess?.mask}`;
    }
    return `${capitalizeFirstLetter(
      loan?.borrowerSteps?.fundingVerification.accountSubtype,
    )} *** ${loan?.borrowerSteps?.fundingVerification?.mask}`;
  }, [editBankProcess, loan]);

  return (
    <Fragment>
      <Box
        mt={1}
        bgcolor={theme.palette.background.paper}
        px={{ xs: 2, md: 6 }}
        pt={{ xs: 4, md: 5 }}
        {...((loan?.loanType === "HELOAN" || paymentHistory?.payments) && {
          pb: { xs: 4, md: 5 },
        })}
        sx={{ borderTopLeftRadius: 33, borderTopRightRadius: 33 }}
      >
        <Typography variant="h5" fontFamily={"Pro Text Semibold"}>
          {loan?.loanType === "HELOC" ? "Estimated" : "NFTYDoor"} Monthly
          Payment of{" "}
          {loan?.loanType === "HELOAN" &&
            loan?.borrowerSteps?.fundingVerification?.amortizationSchedule
              ?.slice(currentDuePaymentNumber, currentDuePaymentNumber + 1)
              .map((item) => {
                return dollarFormatter.format(item.payment);
              })}
          {loan?.loanType === "HELOC" &&
            dollarFormatter.format(
              nextPayment?.amount ? nextPayment?.amount : 0,
            )}
        </Typography>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"baseline"}
          flexDirection={{ xs: "column", md: "row" }}
        >
          <Typography
            variant="subtitle1"
            color={theme.palette.text.secondary}
            sx={{ opacity: 0.9 }}
          >
            {loan?.loanType === "HELOC" ? (
              "Please see your monthly statement for your actual payment due"
            ) : (
              <>is due on {overviewInfo?.nextPaymentDate}</>
            )}
          </Typography>
          <Box
            display={"flex"}
            flexDirection={{ xs: "column", md: "row" }}
            justifyContent={"space-around"}
            alignItems={"baseline"}
            gap={0.5}
          >
            <Typography
              variant="subtitle1"
              color={theme.palette.text.secondary}
              sx={{ opacity: 0.9 }}
            >
              {bankAccountNumber}
            </Typography>
            <CButton
              sx={{
                minHeight: 0,
                minWidth: 0,
                padding: 0,
                width: 35,
              }}
              onClick={openEditBankAccountDialog}
            >
              Edit
            </CButton>
          </Box>
        </Box>{" "}
      </Box>

      <Box
        mt={
          loan?.loanType === "HELOAN" || paymentHistory?.payments
            ? 0.5
            : undefined
        }
        bgcolor={theme.palette.background.paper}
        px={{ xs: 2, md: 6 }}
        pb={{ xs: 4, md: 5 }}
        {...((loan?.loanType === "HELOAN" || paymentHistory?.payments) && {
          pt: { xs: 4, md: 5 },
        })}
        sx={{ borderBottomLeftRadius: 33, borderBottomRightRadius: 33 }}
      >
        {loan?.loanType === "HELOAN" && (
          <>
            <Typography
              mb={2}
              variant="h6"
              color={theme.palette.text.secondary}
              sx={{ opacity: 0.9 }}
            >
              Next Scheduled Payments
            </Typography>
            {/* map data in CDetailLine */}
            {loan?.borrowerSteps?.fundingVerification?.amortizationSchedule
              ?.slice(currentDuePaymentNumber, currentDuePaymentNumber + 3)
              .map((item) => {
                return (
                  <CDetailLine
                    key={item.paymentNumber}
                    firstColumn={moment(getFundedDateByLoan(loan))
                      .add(item.paymentNumber + 1, "month")
                      .startOf("month")
                      .format("MM/DD/YYYY")}
                    secondColumn={dollarFormatter.format(item.payment)}
                  />
                );
              })}
          </>
        )}
        <button
          style={{ all: "unset", textDecoration: "none", cursor: "pointer" }}
          onClick={() => setSeePaymentHistory(!seePaymentHistory)}
        >
          {paymentHistory?.payments && (
            <Box display={"flex"} alignItems={"center"} mb={2}>
              <Typography
                variant="h6"
                color={theme.palette.primary.main}
                sx={{ opacity: 0.9 }}
              >
                See payment history
              </Typography>
              {seePaymentHistory ? (
                <KeyboardArrowUp htmlColor="#6767aa" />
              ) : (
                <KeyboardArrowDown htmlColor="#6767aa" />
              )}
            </Box>
          )}
        </button>

        {seePaymentHistory &&
          paymentHistory?.payments?.pageItems.map((item) => {
            return (
              <CDetailLine
                firstColumn={moment(item?.formatedDate, "MM-DD-YYYY").format(
                  "MM/DD/YYYY",
                )}
                secondColumn={dollarFormatter.format(
                  Number(item.formatedAmount),
                )}
              />
            );
          })}
        {isBorrower && (
          <Box justifyContent={"space-between"} display={"flex"}>
            <CButton variant="outlined" onClick={payoff.handleOpenModal}>
              Request Loan Payoff
            </CButton>
            <PayoffDialog {...payoff} />
            <CButton variant="contained" onClick={makePayment.handleOpenModal}>
              Make a Payment
            </CButton>
          </Box>
        )}
      </Box>
      <MakeaPaymentDialog {...makePayment}></MakeaPaymentDialog>
      <ThankYouDialog {...makePayment}></ThankYouDialog>
      {loan && (
        <EditBankAccountDialog
          open={editBankAccountDialog.open}
          onAccept={() => {
            plaid.load();
            closeEditBankAccountDialog();
          }}
          onDismiss={closeEditBankAccountDialog}
          editBankProcess={editBankProcess}
          loan={loan}
        />
      )}
      {loan && (
        <EditBankResultModalDialog
          open={editBankResultDialog.open}
          onDismiss={closeEditBankResultDialog}
          editBankProcess={editBankProcess}
          loan={loan}
          error={editBankError}
        />
      )}
    </Fragment>
  );
};

export default ScheduledPayments;
