import { useState, useEffect } from "react";
import getMaxCLTVHELOC from "./getMaxCLTVHELOC";
import parseMoney from "utils/parseMoney";
import API from "utils/API";
import PricingEngine from "typedef/PricingEngine";
import { UseFormWatch } from "react-hook-form";
import { RegisterFormInputs } from "./useRegisterForm";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import { handleOcuppancy } from "screens/BorrowerRegisterForm/useRegisterForm";
import Loan from "typedef/Loan";

const useCalculateMaxLoan = (
  watch?: UseFormWatch<RegisterFormInputs>,
  loan?: Loan,
) => {
  const [maxLoanAmount, setMaxLoanAmount] = useState(0);
  const estimatedHomeValue = watch
    ? watch().estimatedHomeValue
    : loan?.borrowerSteps?.appraisalVerification?.homeValue ??
      loan?.borrowerSteps?.homeValuationVerification?.estimatedCostData ??
      loan?.property?.homeValue;
  const homeValue = parseMoney(String(estimatedHomeValue));
  const currentLoanBalance = watch
    ? watch().currentLoanBalance
    : loan?.borrowerSteps?.creditVerification?.loanBalance ??
      loan?.initialOffer?.currentLoanBalance;
  const loanBalance = parseMoney(String(currentLoanBalance));
  const creditScoreRange = watch
    ? watch().creditScoreRange
    : loan?.borrowerSteps?.creditVerification?.creditScore ??
      loan?.initialOffer?.creditScore;
  const creditScoreLabel = watch
    ? watch().creditScoreRange
    : loan?.borrowerSteps?.creditVerification?.creditScore ??
      loan?.initialOffer?.creditScore;
  const creditScore = String(creditScoreLabel)?.includes("-")
    ? String(creditScoreRange)?.split(" - ")[1]
    : watch
      ? watch().creditScoreRange
      : loan?.borrowerSteps?.creditVerification?.creditScore ??
        loan?.initialOffer?.creditScore;
  const currentOccupancy = watch ? watch().occupancyType : loan?.occupancy;
  const { privateLabel } = usePrivateLabel();

  const [pricingEngine, setPricingEngine] = useState<PricingEngine>();

  useEffect(() => {
    const privateLabelId = privateLabel?.id;
    const helocType = handleOcuppancy(currentOccupancy ?? "Primary Residence");
    const pricingEngineId = `${privateLabelId}#${helocType}`;
    const encodePricingEngineId = encodeURIComponent(pricingEngineId);

    API.get<PricingEngine>(
      `/get-heloc-pricing-engine?id=${encodePricingEngineId}`,
    ).then((result) => {
      if ("error" in result) {
        return;
      } else {
        setPricingEngine(result.data);
      }
    });
  }, [currentOccupancy, privateLabel]);

  useEffect(() => {
    if (
      homeValue !== undefined &&
      loanBalance !== undefined &&
      creditScore !== undefined &&
      pricingEngine !== undefined
    ) {
      const maxCLTV = getMaxCLTVHELOC(pricingEngine, Number(creditScore));
      const calculatedMaxLoanAmount = homeValue * maxCLTV - loanBalance;

      setMaxLoanAmount(calculatedMaxLoanAmount);
    }
  }, [
    estimatedHomeValue,
    currentLoanBalance,
    homeValue,
    loanBalance,
    creditScore,
    pricingEngine,
  ]);

  return { maxLoanAmount, pricingEngine };
};

export default useCalculateMaxLoan;
