import API from "utils/API";
import Loan from "typedef/Loan";
import { useCallback, useEffect, useState } from "react";
import useUser from "context/UserCustomer/useUserCustomerContext";

const useLoan = () => {
  const [loan, setLoan] = useState<Loan>();
  const { user, selectedLoanId } = useUser();

  const getAndSetLoan = useCallback(async () => {
    if (user && selectedLoanId) {
      const response = await API.get<Loan>(`/get/my-loan?id=${selectedLoanId}`);
      if ("error" in response) {
        console.error(response.error);
      } else {
        setLoan(response.data);
      }
    }
  }, [user, selectedLoanId]);

  useEffect(() => {
    getAndSetLoan();
  }, [getAndSetLoan]);

  return loan;
};

export default useLoan;
