import { nextPaymentDate } from "typedef/nextPaymentDate";
import API from "utils/API";
import { useEffect, useState, useContext } from "react";
import { HomeMonitorContext } from "../useHomeMonitorContext";

const useNextPaymentDate = () => {
  const { loan } = useContext(HomeMonitorContext);
  const [overviewInfo, setOverviewInfo] = useState<nextPaymentDate>();

  useEffect(() => {
    if (loan?.id) {
      API.get({
        url: `/overview/${loan.id}`,
      }).then((result) => {
        if ("data" in result) setOverviewInfo(result?.data as nextPaymentDate);
      });
    }
  }, [loan]);

  return {
    overviewInfo,
  };
};

export default useNextPaymentDate;
