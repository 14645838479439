export const getDocsMessage = (
  docName: string,
  name?: string,
  taxYear?: string,
  companyName?: string,
  lienBalance?: number,
) => {
  switch (docName) {
    case "Homestead Exemption":
      return "Please upload your Texas Homestead exemption documents.";

    case "Copy of ID":
      return `Please provide us a copy of ${name}'s Government-issued identification.`;

    case "Bankruptcy Docs":
      return "A bankruptcy was detected on your credit report. Please provide discharge documents for review.";

    case "Property Tax Bill":
      return "Please upload your property tax bill so that we can verify your home's APN.";

    case "Lien Payoff Docs":
      return `Please provide us with a payoff statement for the lien.`;

    case "Trust":
      return "Please upload your trust documents for review.";

    case "Recent Mortgage Statement":
      return "We were not able to determine your mortgage balance, please upload your most recent mortgage statement.";

    case "Mortgage Modification Documents":
      return "It appears there has been a mortgage modification in the past. Please provide us with the modification agreement.";

    case "Foreclosure Docs":
      return "It appears there has been a foreclosure in the past. Please provide your discharge paperwork or a letter of explanation for review.";

    case "Employment Verification":
      return "Our income automations did not receive an employment status. In order to verify your income, please request your employer to send an email to support@nftydoor.com. The letter should be from a company email address and include your start date, position, and employment status. An email from your superior or HR professional is acceptable. Alternatively, you can ask them to send a signed letter on a company letterhead and upload it here.";

    case "Fixed Income Documents":
      return `Please upload a copy of ${name}'s income. This can include your award letters, distribution schedules, alimony or child support income, or SSA-1099.`;

    case "Additional Tax Return":
      return `Please upload ${name}'s Personal Tax Return for the tax year ${taxYear}.`;

    case "Tax Return Extension":
      return "Please upload your tax return extension.";

    case "Insurance Policy":
      return "Please upload a copy of your Homeowners Policy / Declarations Page.";

    case "Insurance Declaration Page Update - Coverage":
      return `Your dwelling coverage is less than the minimum. Please contact your agency and increase your policy amount. Alternatively, please upload your replacement cost estimator.`;

    case "Insurance Declaration Page Update - Expiry":
      return "Your insurance looks like it is about to expire, please upload your renewal.";

    case "Flood Insurance Policy":
      return "Please upload a copy of your flood insurance policy.";

    case "Death Certificate":
      return "The other party on your title appears to be deceased. Please upload a copy of their death certificate so we can adjust your vesting.";

    case "Payoff Texas 50(a)6":
      return `Your title report shows there’s another 50(a)6 loan on the property. Please confirm this has been paid off by uploading a paid and closed letter. Alternatively, you can pay at closing by uploading a payoff statement.`;

    case "Proof of Tax Payment":
      return "Taxes show as delinquent. Please upload property tax bill or proof of payment. Alternatively, please let us know if you'd like this paid at closing and we'll add it to your loan.";

    case "Judgement Docs":
      return `Please provide us with a paid and closed letter.`;

    case "Notice of Completion":
      return "A lien has been identified on title, that appears to be related to home improvement. Please provide a copy of the completion of that project.";

    case "Payoff Statement":
      return `Please provide us with a payoff statement for the lien at ${companyName} with an approximate balance of $${lienBalance}.`;

    default:
      return "Please, upload the following document:";
  }
};
