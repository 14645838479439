import API from "utils/API";
import { useForm } from "react-hook-form";
import { DialogProps } from "@mui/material";
import useTracker from "components/CTracker/useTrackerContext";
import { useEffect } from "react";

const useCoborrowerInviteForm = (
  onClose?: Pick<DialogProps, "onClose">["onClose"],
  customFlags?: Record<string, string | number | boolean>,
) => {
  const { loan, setIsModalOpen } = useTracker();

  const form = useForm<{
    firstName: string;
    lastName: string;
    email: string;
  }>();
  const addedNPE = loan?.borrowerFlags?.offeredAddingNPEAsCoborrower
    ? true
    : false;
  const b2Exists = loan?.coborrowerId ? true : false;

  useEffect(() => {
    if (!addedNPE && !b2Exists && loan?.property?.dateAddressMatched) {
      setIsModalOpen(true);
    } else if (b2Exists) {
      setIsModalOpen(false);
    } else {
      setIsModalOpen(false);
    }
  }, [addedNPE, setIsModalOpen, b2Exists, loan?.property?.dateAddressMatched]);

  const onSubmit = form.handleSubmit(async (data) => {
    const response = await API.post({
      url: `/invite-coborrower`,
      data: {
        coborrowerName: data.firstName?.trim(),
        lastName: data.lastName?.trim(),
        coborrowerEmail: data.email?.trim(),
        loanId: loan?.id,
      },
    });
    if ("error" in response) {
      if (response.error?.includes("Email")) {
        form.setError("email", {
          message: response.error,
        });
      } else {
        alert(response.error);
      }
    } else {
      await API.post({
        url: `/save-to-loan/flags?entity=borrower&loanId=${loan?.id}`,
        data: {
          coborrowerInvited: true,
          ...customFlags,
        },
      });
      setIsModalOpen(false);
      onClose?.({}, "backdropClick");
    }
  });

  return {
    ...form,
    onSubmit,
  };
};

export default useCoborrowerInviteForm;
