import CHow from "./CHow";
import CWhatIs from "./CWhatIs";
import CBanner from "./CBanner";
import { Fragment } from "react";
import CButton from "components/CButton";
import {
  Box,
  useTheme,
  Container,
  Typography,
  useMediaQuery,
} from "@mui/material";

export default function Lenders() {
  const theme = useTheme();

  return (
    <Fragment>
      <CBanner />
      <CWhatIs />
      <CHow />
      <Container disableGutters={useMediaQuery(theme.breakpoints.only("xs"))}>
        <Box
          display={"flex"}
          p={{ md: 8, xs: 2 }}
          bgcolor={theme.palette.background.paper}
          justifyContent={"space-between"}
          flexDirection={{ xs: "column", md: "row" }}
          sx={{
            borderBottomLeftRadius: 33,
            borderBottomRightRadius: 33,
          }}
        >
          <Box>
            <Typography
              variant="h4"
              color={theme.palette.text.primary}
              fontSize={{ xs: "2rem", md: "3rem" }}
            >
              When can we start?
            </Typography>
            <Typography
              variant="h6"
              color={theme.palette.text.primary}
              fontSize={"1rem"}
            >
              Contact us today, and we can get you up and running ASAP!
            </Typography>
          </Box>
          <Box my={{ xs: 2, md: 0 }}>
            <CButton
              href="mailto:info@nftydoor.com?Subject=info@nftydoor.com"
              variant="contained"
            >
              Become a Partner
            </CButton>
          </Box>
        </Box>
      </Container>
    </Fragment>
  );
}
