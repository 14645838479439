import { Fragment, useMemo, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import PersonaDialogForm from "./PersonaDialogForm";
import CTrackerTimelineItem from "components/CTimelineItem";
import useTracker from "components/CTracker/useTrackerContext";
import { TrackerStatusColor } from "typedef/TrackerStatusColor";
import { NeededDoc } from "../AddMissingDocsDialog";
import { mergeMissingDocsByStep } from "utils/missingDocuments";
import UploadEachDocument from "components/CTracker/StepsDialogs/UploadEachDocument";

const IdVerification: React.FC<{ trackerStatusColor: TrackerStatusColor }> = ({
  trackerStatusColor,
}) => {
  const theme = useTheme();
  const { loan, type } = useTracker();
  const isBorrower = type === "borrower";
  const step = isBorrower ? loan?.borrowerSteps : loan?.coborrowerSteps;
  const idVerification = step?.idVerification;
  const prevStep = loan?.initialOffer;
  const status = prevStep ? idVerification?.status ?? "pending" : "pending";
  const needDocsUploadB1 = loan?.borrowerSteps?.idVerification?.needDocReview;
  const needDocsUploadB2 = loan?.coborrowerSteps?.idVerification?.needDocReview;
  const processingStatus = step?.idVerification?.processingStatus;

  const stepName = "idVerification";

  const docsToUpload: NeededDoc[] = useMemo(() => {
    if (!loan || !loan?.borrowerMissingDocs) return [];

    return mergeMissingDocsByStep(loan, stepName) as NeededDoc[];
  }, [loan]);

  const [personaDialogIsOpen, setPersonaDialogIsOpen] = useState(false);

  return (
    <Fragment>
      <CTrackerTimelineItem
        processingStatus={processingStatus}
        status={status === "pending" && isBorrower ? "analyzing" : status}
        title="ID Verification"
        tooltip="We partner with industry-leading 3rd Parties to verify your identity and comply with Federal Law."
        {...(idVerification?.status === "pending" &&
          loan &&
          isBorrower && {
            action: {
              message: "Add SSN and DOB",
              onClick: () => setPersonaDialogIsOpen(true),
            },
          })}
      >
        {isBorrower && !idVerification?.data && (
          <>
            <Typography
              variant="h6"
              sx={{ color: theme.palette.text.disabled }}
            >
              {status === "failed" && "We are unable to verify your ID."}
            </Typography>
            <Typography variant="h6" color={[trackerStatusColor[status]]}>
              {status === "failed" && "Please contact support@nftydoor.com"}
            </Typography>
          </>
        )}
        {!isBorrower && status === "failed" && (
          <Typography variant="h6" sx={{ color: theme.palette.text.disabled }}>
            We are unable to verify your ID.
          </Typography>
        )}

        <Typography variant="h6" color={[trackerStatusColor[status]]}>
          {status === "success" && idVerification?.message}
          {(status === "analyzing" &&
            (needDocsUploadB1 === true || needDocsUploadB1 === undefined) &&
            (needDocsUploadB2 === true || needDocsUploadB2 === undefined) &&
            idVerification?.message) ??
            "Analyzing"}
          {status === "analyzing" &&
            (needDocsUploadB1 === false || needDocsUploadB2 === false) && (
              <UploadEachDocument docsToUpload={docsToUpload} loan={loan} />
            )}
          {!isBorrower &&
            status === "failed" &&
            "Please contact support@nftydoor.com"}
        </Typography>

        {isBorrower &&
          status === "failed" &&
          idVerification?.data &&
          idVerification?.message && (
            <Box flexDirection={"row"} display={"flex"}>
              <Typography
                variant="h6"
                color={theme.palette.text.primary}
                mr={1}
              >
                {idVerification?.message}
              </Typography>
              <Typography
                variant="h6"
                sx={{ color: theme.palette.text.disabled }}
              >
                {idVerification?.data?.secondaryMessage}{" "}
                <a
                  style={{
                    cursor: "pointer",
                    color: theme.palette.text.disabled,
                  }}
                  href={`mailto:${idVerification?.data?.link}`}
                >
                  {idVerification?.data?.link}
                </a>
              </Typography>
            </Box>
          )}
      </CTrackerTimelineItem>
      <PersonaDialogForm
        open={personaDialogIsOpen}
        onClose={() => setPersonaDialogIsOpen(false)}
      />
    </Fragment>
  );
};

export default IdVerification;
