/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs, { Dayjs } from "dayjs";
import CButton from "components/CButton";
import CDialog from "components/CDialog";
import CTextField from "components/CTextField";
import { FC, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Controller, FieldValues, UseFormReturn } from "react-hook-form";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

type PayoffDialogProps = {
  error?: string;
  loading: boolean;
  openModal: boolean;
  onSubmit: () => void;
  handleCloseModal: () => void;
  form: UseFormReturn<FieldValues, any>;
};

const PayoffDialog: FC<PayoffDialogProps> = ({
  form,
  error,
  loading,
  onSubmit,
  openModal,
  handleCloseModal,
}) => {
  const theme = useTheme();
  const { errors } = form.formState;
  const [value, setValue] = useState<Dayjs | null>(dayjs());

  useEffect(() => {
    if (value)
      form.setValue("goodUntilDate", dayjs(value).format("MM-DD-YYYY"));
  }, [value, form]);

  return (
    <CDialog
      fullWidth
      maxWidth="xs"
      scroll={"paper"}
      open={openModal}
      disableEscapeKeyDown
      onClose={handleCloseModal}
      onClick={(e) => e.stopPropagation()}
    >
      <form style={{ width: "100%" }} onSubmit={onSubmit}>
        <Box
          gap={1}
          width={"100%"}
          display={"flex"}
          alignItems={"start"}
          justifyContent={"start"}
          flexDirection={"column"}
        >
          <Box
            mb={2}
            width={"100%"}
            display={"flex"}
            alignItems={"center"}
            flexDirection={"row"}
            justifyContent={"space-between"}
          >
            <Typography fontFamily={"Pro Display Bold"} variant="h5">
              Request Loan Payoff
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleCloseModal}
              sx={{
                padding: 0,
                color: theme.palette.text.secondary,
              }}
            >
              <CloseIcon fontSize="large" />
            </IconButton>
          </Box>

          <Controller
            control={form.control}
            name="firstName"
            render={({ field }) => (
              <CTextField
                label="First Name"
                placeholder="First Name"
                fullWidth
                sx={{ mb: 2 }}
                {...field}
                error={errors?.firstName?.message as string}
              />
            )}
          />
          <Controller
            control={form.control}
            name="lastName"
            render={({ field }) => (
              <CTextField
                label="Last Name"
                placeholder="Last Name"
                fullWidth
                sx={{ mb: 2 }}
                {...field}
                error={errors?.lastName?.message as string}
              />
            )}
          />
          <Controller
            control={form.control}
            name="companyName"
            render={({ field }) => (
              <CTextField
                label="Company Name"
                placeholder="Company Name"
                fullWidth
                sx={{ mb: 2 }}
                {...field}
                error={errors?.companyName?.message as string}
              />
            )}
          />
          <Controller
            control={form.control}
            name="email"
            render={({ field }) => (
              <CTextField
                label="Email Address"
                placeholder="Enter Email"
                fullWidth
                sx={{ mb: 2 }}
                {...field}
                error={errors?.email?.message as string}
              />
            )}
          />
          <Controller
            control={form.control}
            name="goodUntilDate"
            render={(field) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Good Until Date"
                  value={value}
                  onChange={(newValue) => setValue(newValue)}
                  minDate={dayjs()}
                  maxDate={dayjs().add(45, "day")}
                  sx={{
                    color: theme.palette.text.primary,
                    display: "flex",
                    alignItems: "start",
                  }}
                  {...field}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                    },
                  }}
                />
              </LocalizationProvider>
            )}
          />

          {error && (
            <Typography color={theme.palette.error.main} mt={2}>
              {error}
            </Typography>
          )}

          <CButton
            fullWidth
            type="submit"
            sx={{ mt: 3 }}
            loading={loading}
            variant="contained"
            onClick={onSubmit}
          >
            Send
          </CButton>
        </Box>
      </form>
    </CDialog>
  );
};

export default PayoffDialog;
