import {
  Dialog,
  Typography,
  DialogContent,
  DialogContentText,
  useTheme,
} from "@mui/material";
import { FC } from "react";
import CButton from "components/CButton";
import CDialogTitle from "components/CDialogTitle";
import CDialogTransition from "components/CDialogTransition";
import Loan from "typedef/Loan";
import useTermsOfTheLoanHELOC from "./useTermsOfTheLoanHELOC";

type DialogProps = {
  loan?: Loan;
  open: boolean;
  handleClose: () => void;
};

const TermsOfTheLoan: FC<DialogProps> = ({ open, handleClose, loan }) => {
  const theme = useTheme();
  const property = loan?.property?.address;
  const { helocPricingEngine } = useTermsOfTheLoanHELOC(loan);

  return (
    <Dialog
      open={open}
      maxWidth="md"
      scroll={"paper"}
      fullWidth={true}
      onClose={handleClose}
      TransitionComponent={CDialogTransition}
      aria-labelledby="customized-dialog-title"
      onClick={(e) => {
        e.stopPropagation();
      }}
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: 10,
        },
      }}
    >
      <DialogContent sx={{ padding: 4 }}>
        <CDialogTitle onClose={handleClose}></CDialogTitle>
        <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
          <Typography
            color={theme.palette.text.primary}
            textAlign={"justify"}
            variant="subtitle1"
          >
            <Typography fontFamily={"Pro Display Bold"}>
              The information provided is based on a $
              {Math.floor(loan?.initialOffer?.amount ?? 0).toLocaleString(
                "en-US",
              )}{" "}
              home equity loan.{" "}
              <span style={{ fontFamily: "Pro Display Regular" }}>
                The property is located in {property?.components?.city_name},{" "}
                {property?.metadata?.county_name} county in the State of{" "}
                {property?.components?.state_abbreviation}. The property is an
                existing single family home and will be used as a primary
                residence. The assumed credit score is{" "}
                {loan?.initialOffer.creditScore}.
              </span>
            </Typography>
            <Typography mt={1} fontFamily={"Pro Display Bold"}>
              Interest Rate/APR:{" "}
              <span style={{ fontFamily: "Pro Display Regular" }}>
                Up to 80% LTV the initial rate is{" "}
                {((helocPricingEngine?.primeRate ?? 0) * 100 + 3.75).toFixed(2)}
                % and the rate is based on Prime + 3.75%, subject to increase.
                Rate is based on the Prime Rate as published in the Wall Street
                Journal. ANNUAL PERCENTAGE RATE can change monthly.
              </span>
            </Typography>
            <Typography mt={1} fontFamily={"Pro Display Bold"}>
              Term:{" "}
              {property?.components?.state_abbreviation === "TN" ? (
                <span style={{ fontFamily: "Pro Display Regular" }}>
                  Term: 5 year draw period followed by a 10 year amortized
                  repayment period.
                </span>
              ) : (
                <span style={{ fontFamily: "Pro Display Regular" }}>
                  Term: 5 year draw period followed by a 25 year amortized
                  repayment period.
                </span>
              )}
            </Typography>
            <Typography mt={1} fontFamily={"Pro Display Bold"}>
              Closing Fees:{" "}
              <span style={{ fontFamily: "Pro Display Regular" }}>
                $1,088.80
              </span>
            </Typography>
            <Typography mt={1} fontFamily={"Pro Display Bold"}>
              Annual Fee:{" "}
              <span style={{ fontFamily: "Pro Display Regular" }}>$199</span>
            </Typography>
            <Typography mt={1} fontFamily={"Pro Display Regular"}>
              Monthly Payment is an Estimate Only. Your Monthly Statement will
              reflect the final monthly amount due based on Average Daily
              Balance, Daily Periodic Rate, and number of days in the billing
              cycle
            </Typography>
          </Typography>
        </DialogContentText>
        <CButton
          sx={{ marginTop: 3 }}
          fullWidth
          variant="contained"
          onClick={handleClose}
        >
          Got it
        </CButton>
      </DialogContent>
    </Dialog>
  );
};

export default TermsOfTheLoan;
