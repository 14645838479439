import { useState } from "react";
import CButton from "components/CButton";
import CDialog from "components/CDialog";
import DocumentsRequest from "components/HELOCForm/DocumentsRequest";
import { Box } from "@mui/system";
import API from "utils/API";
import useLoan from "components/CTracker/useLoan";
import useMissingDocs from "utils/useMissingDocs";
import { NeededDoc } from "components/CTracker/Steps/AddMissingDocsDialog";

const UploadDocumentB2 = () => {
  const loan = useLoan();
  const docsToUpload: NeededDoc[] = useMissingDocs(loan);
  const filteredDocsToUpload = docsToUpload.filter(
    (doc) => doc.docName !== "Truv W2 and paystubs",
  );
  const setFlag = async () => {
    await API.post({
      url: `/save-to-loan/flags?entity=coborrower&loanId=${loan?.id}`,
      data: { showedDocUploadModal: true },
    });
  };

  const [modalOpen, setModalOpen] = useState(true);
  const handleLater = async () => {
    setFlag();
  };
  const handleClose = async () => {
    const isNotAllSubmitted = docsToUpload.some(
      (doc) => doc.submitted === false || doc?.rejected,
    );
    if (isNotAllSubmitted) {
      setModalOpen(false);
    } else {
      setFlag();
      setModalOpen(false);
    }
  };
  return (
    <CDialog open={modalOpen}>
      <DocumentsRequest docsToUpload={filteredDocsToUpload} isCoborrower />
      {docsToUpload.length > 0 && (
        <Box
          sx={{
            display: "flex",
            marginTop: 3,
            gap: 2,
            maxHeight: "100px",
            minWidth: "400px",
          }}
        >
          <CButton
            fullWidth
            onClick={handleLater}
            variant="outlined"
            sx={{ marginBottom: 3, marginTop: 1 }}
            name="uploadDocumentsB2-iWillDoItLater"
            aria-label="uploadDocumentsB2-iWillDoItLater"
          >
            I'll do it later
          </CButton>
          <CButton
            fullWidth
            onClick={handleClose}
            variant="contained"
            sx={{ marginBottom: 3, marginTop: 1 }}
            name="uploadDocumentsB2-close"
            aria-label="uploadDocumentsB2-close"
          >
            Close
          </CButton>
        </Box>
      )}
    </CDialog>
  );
};

export default UploadDocumentB2;
