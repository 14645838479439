const styles = {
  modal: {
    maxWidth: 600,
  },

  shortDescription: {
    fontSize: "1.2rem",
    fontWeight: 700,
    margin: "1rem 0",
  },
  description: {
    fontSize: "1.2rem",
  },
  containerButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1.2rem",
  },
  buttonStyles: {
    mt: 3,
    width: { md: "auto", xs: "100%" },
  },
};

export default styles;
