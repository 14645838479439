/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, Fragment } from "react";
import CButton from "components/CButton";
import CDialog from "components/CDialog";
import parseMoney from "utils/parseMoney";
import { Box, Typography, useTheme } from "@mui/material";
import DrawRequests from "typedef/DrawRequests";
import CNumberField from "components/CNumberField";
import { Controller, FieldValues, UseFormReturn } from "react-hook-form";
import dollarFormatter from "utils/FormatterDollar";

type RequestDrawDialogProps = {
  error?: string;
  loading: boolean;
  openModal: boolean;
  onSubmit: () => void;
  drawInfo?: DrawRequests;
  handleCloseModal: () => void;
  form: UseFormReturn<FieldValues, any>;
  isTexas: boolean;
};

const RequestDrawDialog: FC<RequestDrawDialogProps> = ({
  form,
  error,
  loading,
  drawInfo,
  onSubmit,
  openModal,
  handleCloseModal,
  isTexas,
}) => {
  const { errors } = form.formState;
  const drawFee = isTexas ? 0 : 125;
  const drawRequest = drawInfo?.result?.totalToRequest ?? 0;
  const available = drawRequest;
  const inputValue = form.watch("subsequentAmount");
  const inputValueWithFee = inputValue ? parseMoney(inputValue) - drawFee : 0;
  const theme = useTheme();
  return (
    <CDialog
      fullWidth
      maxWidth="xs"
      scroll={"paper"}
      open={openModal}
      onClose={handleCloseModal}
    >
      <form style={{ width: "100%" }} onSubmit={onSubmit}>
        <Box
          gap={1}
          display={"flex"}
          alignItems={"start"}
          justifyContent={"start"}
          flexDirection={"column"}
        >
          <Box
            mb={1}
            gap={1}
            display={"flex"}
            alignItems={"start"}
            justifyContent={"start"}
            flexDirection={"column"}
          >
            <Typography fontFamily={"Pro Display Bold"} variant="h5">
              Draw Request
            </Typography>
            <Typography variant="h6">
              Available Draw: ${available?.toLocaleString("en-US")}
            </Typography>
          </Box>

          <Controller
            control={form.control}
            name="subsequentAmount"
            render={({ field }) => (
              <CNumberField
                fullWidth
                label="Amount Requested"
                placeholder="Enter amount"
                isNumericString
                thousandSeparator
                allowNegative={false}
                allowEmptyFormatting={false}
                prefix="$ "
                {...field}
                error={errors?.subsequentAmount?.message as string}
                value={parseMoney(field.value)}
              />
            )}
          />
          {error && (
            <Typography color={theme.palette.error.main}>{error}</Typography>
          )}

          {!isTexas && (
            <Fragment>
              <Typography>Draw Fee: $125 </Typography>
              <Typography>
                Your total is:{" "}
                {inputValueWithFee < 0
                  ? `$0`
                  : dollarFormatter.format(inputValueWithFee)}{" "}
              </Typography>
            </Fragment>
          )}
          <CButton
            fullWidth
            type="submit"
            sx={{ mt: 3 }}
            loading={loading}
            variant="contained"
          >
            Accept
          </CButton>
          <CButton onClick={handleCloseModal} fullWidth variant="outlined">
            Cancel
          </CButton>
        </Box>
      </form>
    </CDialog>
  );
};

export default RequestDrawDialog;
