import "assets/Styles/baseStyle.scss";
import { StrictMode } from "react";
import App from "./App";
import { createRoot } from "react-dom/client";
import DevBanner from "components/DevBanner";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

root.render(
  <StrictMode>
    <DevBanner />
    <App />
  </StrictMode>,
);
