import { Box, Typography, useTheme } from "@mui/material";

export default function FortunaInformation() {
  const theme = useTheme();
  return (
    <Box
      display={"flex"}
      borderRadius={10}
      bgcolor={theme.palette.background.paper}
      mb={3}
      py={5}
      px={7}
      position="relative"
      justifyContent={"space-between"}
      alignItems={{ xs: "center", sm: "flex-start" }}
      flexDirection={{ xs: "column", sm: "row" }}
    >
      <Box py={{ xs: 0, sm: 2 }} mt={{ xs: 3, sm: 0 }} flex={1}>
        <Typography
          variant="h6"
          color={theme.palette.text.primary}
          textAlign={"left"}
        >
          <span style={{ fontFamily: "Pro Display Semibold" }}>Fortuna </span>
          is proud to be partnering with NFTYDoor to provide bridge financing
          for the purchase of your new home. Now, not only are you able to waive
          the home sale contingency on your next contract, but you’ll also be
          empowered to leverage your equity to put the down payment on your new
          home.
        </Typography>
      </Box>
    </Box>
  );
}
