import moment from "moment";
import { Fragment } from "react";
import useTracker from "../useTrackerContext";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
const LoanInformation: React.FC = () => {
  const { privateLabel } = usePrivateLabel();
  const { property, loan } = useTracker();
  const theme = useTheme();

  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={{ xs: "start", md: "center" }}
      flexDirection={{ xs: "column", md: "row" }}
    >
      <Box>
        {property && (
          <Fragment>
            <Typography
              variant="h5"
              sx={{ fontFamily: "Pro Text Semibold" }}
              color={theme.palette.text.primary}
            >
              {property?.address?.delivery_line_1}.
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ fontFamily: "Pro Text Light" }}
              color={theme.palette.text.primary}
            >
              {property?.address?.components?.city_name},{" "}
              {property?.address?.components?.state_abbreviation}.{" "}
              {property?.address?.components?.zipcode}
            </Typography>
          </Fragment>
        )}
      </Box>
      <Box textAlign={{ xs: "left", md: "right" }} mt={{ xs: 2, md: 0 }}>
        <Typography
          variant="subtitle1"
          color={theme.palette.text.secondary}
          sx={{ opacity: 0.9 }}
        >
          Last update:{" "}
          {loan?.initialOffer.createdAt
            ? moment(loan?.initialOffer.createdAt).format("lll")
            : "-"}
          <br />
          {loan?.loanOfficer && (
            <Fragment>
              {loan.broker?.lenderName ?? privateLabel?.lenderName} | NMLS ID #
              {loan.broker?.NMLScompany ?? privateLabel?.NMLScompany}
              <br />
              {loan.loanOfficer?.firstName} {loan.loanOfficer.lastName} | NMLS
              ID #{loan.loanOfficer?.NMLSNumber}
              <br />
            </Fragment>
          )}
          Application #:{" "}
          <span style={{ color: theme.palette.primary.main }} data-cy="loanId">
            {loan?.id}
          </span>
        </Typography>
      </Box>
    </Box>
  );
};

export default LoanInformation;
