import Typography from "@mui/material/Typography";
import LoggedInDocumentForm from "components/CLoggedInDocumentForm";
import CTrackerTimelineItem from "components/CTimelineItem";
import useTracker from "components/CTracker/useTrackerContext";
import { useRef, useState } from "react";
import { TrackerStatusColor } from "typedef/TrackerStatusColor";
import API from "utils/API";

const InitialDisclosuresVerification: React.FC<{
  trackerStatusColor: TrackerStatusColor;
}> = ({ trackerStatusColor }) => {
  const { loan, type } = useTracker();
  const formRef = useRef<HTMLFormElement>(null);
  const [tokenAccess, setTokenAccess] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const isBorrower = type === "borrower";
  const step = isBorrower ? loan?.borrowerSteps : loan?.coborrowerSteps;

  const noNeedForAppraisal =
    loan?.borrowerSteps?.homeValuationVerification.status === "success" &&
    !loan?.borrowerSteps?.appraisalVerification?.status;

  const hasBroker = loan?.brokerId;
  const prevStep = hasBroker
    ? step?.brokerDisclosuresVerification?.status === "success"
    : true;

  const linkToSign = step?.disclosuresVerification?.link;
  const status = prevStep
    ? step?.disclosuresVerification?.status ?? "pending"
    : "pending";
  const message = step?.disclosuresVerification?.message;
  const hasAppraisalType =
    loan?.borrowerSteps?.appraisalVerification?.appraisalType &&
    loan?.borrowerSteps?.appraisalVerification?.appraisalType?.length > 0 &&
    loan?.borrowerSteps?.appraisalVerification?.status !== "pending";

  const handleClickSignInitials = async () => {
    if (!linkToSign) return;
    setIsLoading(true);
    const result = await API.post<{ accessToken: string }>({
      url: "/invoke",
      data: {
        FunctionName: "getDocutechToken",
        InvocationType: "RequestResponse",
        Service: "nftydoor-core",
        Payload: {
          loanId: loan?.id,
          linkToSign,
        },
      },
    });

    if ("error" in result) {
      window.open(linkToSign, "_blank");
      setIsLoading(false);
    } else if ("data" in result) {
      const accessTokenDT = result?.data?.accessToken;
      setTokenAccess(accessTokenDT);
      setTimeout(() => {
        formRef?.current?.submit();
        setIsLoading(false);
      }, 1500);
    }
  };

  return (
    <CTrackerTimelineItem
      status={status}
      title="Lender Disclosures"
      tooltip="Your Lender Disclosures will contain your state-specific acknowledgments and a loan cost breakdown."
      loading={isLoading}
    >
      <Typography variant="h6" color={[trackerStatusColor[status]]}>
        {status === "analyzing" && linkToSign && message}
        {status === "analyzing" && !linkToSign && message}
        {status === "failed" &&
          (step?.disclosuresVerification?.messageToBorrower ?? message)}
        {status === "success" && message}
      </Typography>
      <LoggedInDocumentForm
        formRef={formRef}
        linkToSign={linkToSign}
        token={tokenAccess}
        handleClickSignInitials={handleClickSignInitials}
        isLoading={isLoading}
        showButton={Boolean(
          prevStep &&
            status !== "success" &&
            message &&
            linkToSign &&
            loan &&
            (noNeedForAppraisal || hasAppraisalType),
        )}
        text={"Sign your Lender Documents"}
      />
    </CTrackerTimelineItem>
  );
};

export default InitialDisclosuresVerification;
