import { FC, Fragment } from "react";
import { Marks } from "./useHeloanOffer";
import { Box, Typography, useTheme } from "@mui/material";
import dollarFormatter from "utils/FormatterDollar";
type SingleMarkProps = {
  marks: Marks;
};
const SingleMark: FC<SingleMarkProps> = ({ marks }) => {
  const [mark] = marks;
  const theme = useTheme();
  return (
    <Fragment>
      <Box
        display={"flex"}
        alignItems={{ md: "center" }}
        justifyContent={"space-between"}
        flexDirection={{ xs: "column", md: "row" }}
      >
        <Box flex={1} textAlign={"left"} mb={{ xs: 4, md: 0 }}>
          <Typography
            variant="h5"
            fontWeight={"bolder"}
            color={theme.palette.primary.main}
          >
            Finalize requested loan <br />
            amount to proceed to <br />
            your loan application
          </Typography>
        </Box>

        <Box flex={1} textAlign={{ xs: "left", md: "right" }}>
          <Typography variant="h3">
            ${Math.floor(mark.amount ?? 0).toLocaleString("en-US")}
          </Typography>
          <Typography variant="subtitle1">
            {((mark.interestRate ?? 0) * 100).toFixed(2)}% Interest Rate,{" "}
            {(Math.round((mark.APR ?? 0) * 10000) / 100).toFixed(2)}% APR, 25
            year term, and monthly payments of{" "}
            {dollarFormatter.format(mark.monthlyPayment ?? 0)}
          </Typography>
        </Box>
      </Box>
    </Fragment>
  );
};

export default SingleMark;
