import { FC } from "react";

const CDot: FC<{ color: React.CSSProperties["color"] }> = ({ color }) => (
  <span
    style={{
      background: color,
      marginRight: "0.8rem",
      marginBottom: "0.2rem",
      borderRadius: "50%",
      display: "inline-block",
      width: "0.6rem",
      height: "0.6rem",
    }}
  />
);

export default CDot;
