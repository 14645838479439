import { Box, RadioGroup, Typography, useTheme } from "@mui/material";
import Loan from "typedef/Loan";
import { FC, Fragment, useMemo } from "react";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import { PrivateLabelFees } from "typedef/PrivateLabelFees";

export type Marks = Loan["initialOffer"][];

type MultipleMarksProps = {
  interestRate: number | { error?: string } | undefined;
  requestedLoanAmount: number;
  offers: { maxLoanAmount: number; rate: number | undefined }[] | undefined;
  originationPoints: PrivateLabelFees | undefined;
  loan?: Loan;
};

const CMultipleMarksOfferInviteLink: FC<MultipleMarksProps> = ({
  requestedLoanAmount,
  offers,
  interestRate,
  originationPoints,
  loan,
}) => {
  const theme = useTheme();
  const { privateLabel } = usePrivateLabel();

  const checkOffers = useMemo(() => {
    const maxOffer =
      loan?.finalOffer?.maxOffer ?? loan?.initialOffer?.maxOffer ?? 0;

    if (maxOffer === 0) {
      return false;
    } else if (offers?.length && offers?.[0]?.maxLoanAmount > maxOffer) {
      return true;
    } else {
      return false;
    }
  }, [offers, loan]);

  return (
    <Fragment>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        paddingRight={0.5}
        paddingLeft={0.5}
      >
        <Typography
          variant="h3"
          color={theme.palette.text.primary}
          fontFamily={"Pro Display Bold"}
          mb={3}
        >
          <span style={{ color: theme.palette.primary.main }}>
            ${Math.floor(requestedLoanAmount ?? 0).toLocaleString("en-US")}
          </span>
        </Typography>

        {interestRate && (
          <Typography width={280} sx={{ opacity: 0.5, textAlign: "left" }}>
            {!isNaN(Number(interestRate))
              ? (Number(interestRate) * 100)?.toFixed(2)
              : 0}
            {!privateLabel?.hasOriginationFlatFee
              ? !originationPoints
                ? "% Interest Rate, with 5 years interest only, and 25 year amortization"
                : `% Interest Rate, with 5 years interest only, 25 year amortization and ${(
                    originationPoints?.totalFee * 100
                  ).toFixed(2)}% origination points`
              : `% Interest Rate, with 5 years interest only, 25 year amortization and $ ${originationPoints?.PLFlatFee} origination fee.`}
          </Typography>
        )}
      </Box>
      {offers && offers?.length > 0 && (
        <Box mt={1} display={"flex"} justifyContent={"space-between"}>
          <Typography sx={{ opacity: 0.5 }}>Max Loan Amount</Typography>
          <Typography sx={{ opacity: 0.5 }}>Rates as low as</Typography>
        </Box>
      )}
      <RadioGroup>
        {checkOffers ? (
          <Box
            sx={{
              backgroundImage:
                "linear-gradient(to right, lightgray 50%, transparent 50%)",
              backgroundPosition: "0 60%",
              backgroundRepeat: "repeat-x",
              backgroundSize: "8px 1px",
            }}
            display="flex"
            alignItems={"center"}
            justifyContent={"space-between"}
            fontFamily="Pro Display Semibold"
          >
            <Box
              minWidth={100}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{ backgroundColor: theme.palette.background.paper }}
            >
              <p
                style={{ fontSize: 18, fontFamily: "Pro Display Bold" }}
              >{`$${Math.floor(
                loan?.finalOffer?.maxOffer ??
                  loan?.initialOffer?.maxOffer ??
                  offers?.[0]?.maxLoanAmount ??
                  0,
              ).toLocaleString("en-US")}`}</p>
            </Box>
            <Box
              display={"flex"}
              alignSelf={"stretch"}
              sx={{ backgroundColor: theme.palette.background.paper }}
            >
              <Box
                minWidth={80}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"right"}
                gap={1}
              >
                <Typography
                  color={theme.palette.primary.main}
                  fontFamily={"Pro Display Bold"}
                  fontSize={18}
                >
                  {(
                    (loan?.finalOffer?.interestRate ??
                      loan?.initialOffer?.interestRate ??
                      offers?.[0]?.rate ??
                      0) * 100
                  ).toFixed(2)}
                  %
                </Typography>
              </Box>
            </Box>
          </Box>
        ) : (
          offers?.map((offer, index) => (
            <Box
              key={index}
              sx={{
                backgroundImage:
                  "linear-gradient(to right, lightgray 50%, transparent 50%)",
                backgroundPosition: "0 60%",
                backgroundRepeat: "repeat-x",
                backgroundSize: "8px 1px",
              }}
              display="flex"
              alignItems={"center"}
              justifyContent={"space-between"}
              fontFamily="Pro Display Semibold"
            >
              <Box
                minWidth={100}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{ backgroundColor: theme.palette.background.paper }}
              >
                <p
                  style={{ fontSize: 18, fontFamily: "Pro Display Bold" }}
                >{`$${Math.floor(offer?.maxLoanAmount).toLocaleString(
                  "en-US",
                )}`}</p>
              </Box>
              <Box
                display={"flex"}
                alignSelf={"stretch"}
                sx={{ backgroundColor: theme.palette.background.paper }}
              >
                <Box
                  minWidth={80}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"right"}
                  gap={1}
                >
                  <Typography
                    color={theme.palette.primary.main}
                    fontFamily={"Pro Display Bold"}
                    fontSize={18}
                  >
                    {((offer?.rate ?? 0) * 100).toFixed(2)}%
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))
        )}
      </RadioGroup>
    </Fragment>
  );
};

export default CMultipleMarksOfferInviteLink;
