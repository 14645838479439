import { LoanPurposePricingEngine } from "typedef/PricingEngine";

export const getUseOfProceedsAdjuster = (
  loanPurposePricingEngine: LoanPurposePricingEngine,
  useOfProceeds: string,
) => {
  switch (useOfProceeds) {
    case "Home Improvement":
      return loanPurposePricingEngine.homeImprovement;
    case "Debt Consolidation":
      return loanPurposePricingEngine.debtConsolidation;
    case "Other":
      return loanPurposePricingEngine.other;
    default:
      return 0;
  }
};
