import React, { useEffect, useState } from "react";
import { Box, MenuItem, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { incomeOptions } from "./incomeOptions";
import CDialog from "components/CDialog";
import CTextField from "components/CTextField";
import CButton from "components/CButton";
import UploadDocumentInput from "./UploadDocInput/UploadDocInput";
import BorrowerSteps from "typedef/BorrowerSteps";
import CoborrowerSteps from "typedef/CoborrowerSteps";
import dollarFormatter from "utils/FormatterDollar";
import useIncomeDocUpload from "./useIncomeDocUpload";
import CNumberField from "components/CNumberField";

type IncomeDocUploadProps = {
  isAdditional: boolean;
  open: boolean;
  handleClose: () => void;
  isBorrower: boolean;
  step?: BorrowerSteps | CoborrowerSteps;
  incomeSourcesOptions: { label: string; value: string }[];
};

const IncomeDocUpload: React.FC<IncomeDocUploadProps> = ({
  isAdditional,
  open,
  handleClose,
  isBorrower,
  step,
  incomeSourcesOptions,
}) => {
  const {
    control,
    selectedIncomeType,
    handleIncomeSourceType,
    convertToBase64,
    onSubmit,
    availableIncomeOptions,
    moreThanOneIncomeSource,
    optionAmount,
    handleRefresh,
    documentName,
    isLoading,
  } = useIncomeDocUpload({
    step,
    isAdditional,
    open,
    isBorrower,
    handleClose,
    incomeSourcesOptions,
  });

  const modalTitle = isAdditional ? "Add Additional Income" : "Verify Income";
  const [uploadDocumentText, setUploadDocumentText] = useState<string>();

  const handleRefreshAndClose = () => {
    handleRefresh();
    handleClose();
  };

  useEffect(() => {
    if (selectedIncomeType === "selfEmploymentIncome") {
      setUploadDocumentText(
        "Please provide 2 years of tax returns (business & personal).",
      );
    } else if (selectedIncomeType === "salaryIncome") {
      setUploadDocumentText("Please provide your W2 and two recent paystubs.");
    } else if (selectedIncomeType === "socialIncome") {
      setUploadDocumentText(
        "Please provide a copy of your award letter, distribution schedule, or other retirement documentation.",
      );
    } else if (selectedIncomeType === "otherIncome") {
      setUploadDocumentText(
        "Common documents include offer letter, contract, benefits statement, EOB, etc.",
      );
    }
  }, [selectedIncomeType]);

  return (
    <CDialog
      title={modalTitle}
      open={open}
      onClose={handleRefreshAndClose}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Box component="form" sx={{ mt: 2, m: 1 }}>
        <Controller
          name="incomeType"
          control={control}
          rules={{ required: "Income type is required" }}
          render={({ field, fieldState: { error } }) => (
            <Box mb={2}>
              {isAdditional ? (
                <CTextField
                  {...field}
                  select
                  fullWidth
                  required
                  sx={{
                    mb: 2,
                    textAlign: "left",
                  }}
                  label="Type of Income"
                  value={selectedIncomeType}
                  onChange={handleIncomeSourceType}
                >
                  {incomeOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </CTextField>
              ) : (
                <CTextField
                  {...field}
                  select={moreThanOneIncomeSource}
                  fullWidth
                  required
                  sx={{
                    mb: 2,
                    textAlign: "left",
                  }}
                  label="Type of Income"
                  value={selectedIncomeType}
                  onChange={handleIncomeSourceType}
                  InputProps={{
                    readOnly: !moreThanOneIncomeSource,
                  }}
                >
                  {availableIncomeOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </CTextField>
              )}
              {error && (
                <Box color="error.main" fontSize="0.75rem" mt={0.5}>
                  {error.message}
                </Box>
              )}
            </Box>
          )}
        />

        <Controller
          name="amount"
          control={control}
          rules={{
            required: "Amount is required",
            pattern: {
              value: /^\d{1,9}$/,
              message: "Please enter a valid number (max 9 digits)",
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <Box>
              {isAdditional ? (
                <CNumberField
                  {...field}
                  fullWidth
                  required
                  label={`$ Total Annual Compensation - ${
                    isBorrower ? "Primary Borrower" : "Co-Borrower"
                  }`}
                  helperText="This will be verified by uploading documents."
                  placeholder="$200,000"
                  isNumericString
                  thousandSeparator
                  allowNegative={false}
                  allowEmptyFormatting={false}
                  prefix="$ "
                  sx={{ mb: 2, textAlign: "left" }}
                  value={optionAmount}
                />
              ) : (
                <CTextField
                  {...field}
                  fullWidth
                  required
                  sx={{
                    mb: 2,
                    textAlign: "left",
                  }}
                  label={`$ Total Annual Compensation - ${
                    isBorrower ? "Primary Borrower" : "Co-Borrower"
                  }`}
                  value={
                    optionAmount !== undefined
                      ? dollarFormatter.format(optionAmount)
                      : undefined
                  }
                  helperText="This will be verified by uploading documents."
                  InputProps={{
                    readOnly: true,
                  }}
                />
              )}
              {error && (
                <Typography color="error.main" fontSize="1rem" mb={2}>
                  {error?.message}
                </Typography>
              )}
            </Box>
          )}
        />
        <UploadDocumentInput
          onChange={convertToBase64}
          control={control}
          selectedDocumentName={documentName}
          name="document"
        />
        <Typography color="error.main" fontSize="1rem" mb={2}>
          {!documentName && uploadDocumentText}
        </Typography>
      </Box>
      <Box
        flexDirection={"column"}
        display={"flex"}
        gap={2}
        marginTop={4}
        alignItems={"center"}
      >
        <CButton
          fullWidth
          onClick={onSubmit}
          variant="contained"
          loading={isLoading}
          name="missingDocsDialog-upload"
        >
          {modalTitle}
        </CButton>
        <CButton
          type="reset"
          fullWidth
          variant="outlined"
          disabled={isLoading}
          onClick={handleRefreshAndClose}
        >
          Cancel
        </CButton>
      </Box>
    </CDialog>
  );
};

export default IncomeDocUpload;
