import styled from "@emotion/styled";
import { Help } from "@mui/icons-material";
import { Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import color from "context/MaterialUITheme/color";

const HTMLtoolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    placement={"top-start"}
    enterTouchDelay={0}
  >
    <Help fontSize="small" sx={{ ml: 1 }} htmlColor={color.lightGray} />
  </Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    //@ts-ignore
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    maxWidth: 500,
  },
}));
export default HTMLtoolTip;
