import ScrollToTop from "components/ScrollToTop";
import { BrowserRouter } from "react-router-dom";
import { MainThemeUI } from "context/MaterialUITheme/MainThemeUI";
import { UserCustomerContextProvider } from "context/UserCustomer/useUserCustomerContext";
import AppRoutes from "AppRoutes";
import { UsePrivateLabelContextProvider } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import GoogleTagManager from "components/GoogleTagManager";

import "./App.css";

const App = () => {
  return (
    <UsePrivateLabelContextProvider>
      <GoogleTagManager />
      <MainThemeUI>
        <BrowserRouter>
          <UserCustomerContextProvider>
            <ScrollToTop>
              <AppRoutes />
            </ScrollToTop>
          </UserCustomerContextProvider>
        </BrowserRouter>
      </MainThemeUI>
    </UsePrivateLabelContextProvider>
  );
};

export default App;
