import moment from "moment";
import React, { useEffect, useState } from "react";
import Loan from "typedef/Loan";
import API from "utils/API";

type User = {
  socialSecurityNumber: string;
  dateOfBirth: string;
};

export const useConfirmSSNDOB = (loan: Loan, onClose: () => void) => {
  const styles = {
    icon: {
      fontSize: "1.5rem",
    },
    containerButton: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: "1.2rem",
    },
    buttonStyles: {
      mt: 2,
      width: { md: "auto", xs: "100%" },
    },
  };
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [errorsInputs, setErrorsInputs] = useState<{ [key: string]: string }>(
    {},
  );
  const [showSocialSecurityNumber, setShowSocialSecurityNumber] =
    useState(true);

  const [user, setUser] = useState<User>({
    socialSecurityNumber: "",
    dateOfBirth: "",
  });

  const validateUser = (socialSecurityNumber: string, dateOfBirth: string) => {
    const checks = [
      {
        input: "socialSecurityNumber",
        regex: /\d{3}-\d{2}-\d{4}/,
        message: "Invalid Social Security Number.",
      },
      {
        input: "socialSecurityNumber",
        regex: /\S/,
        message: "Social Security Number is required.",
      },
      {
        input: "dateOfBirth",
        regex: /\d{4}-\d{2}-\d{2}/,
        message: "Invalid Date of Birth.",
      },
      {
        input: "dateOfBirth",
        regex: /\S/,
        message: "Date of Birth is required.",
      },
    ];

    const errors = checks.reduce(
      (acc, { input, regex, message }) => {
        if (
          !regex.test(
            input === "socialSecurityNumber"
              ? socialSecurityNumber
              : dateOfBirth,
          )
        ) {
          acc[input] = message;
        } else {
          delete acc[input];
        }
        return acc;
      },
      {} as { [key: string]: string },
    );
    setErrorsInputs(errors);
  };

  const toggleShowSocialSecurityNumber = () => {
    setShowSocialSecurityNumber(!showSocialSecurityNumber);
  };

  const handleUserChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    const updatedUser = {
      ...user,
      [id]: value,
    };

    setUser(updatedUser);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const response = await API.post<{ message: string }>({
      url: "/request-identity-verification",
      data: {
        loanId: loan.id,
        birthdate: moment(user.dateOfBirth).format("yyyy/MM/DD"),
        "identification-number": user.socialSecurityNumber.replace(/-/g, ""),
      },
    });

    if ("error" in response) {
      setLoading(false);
      setError(response.error);
    } else {
      setLoading(false);
      onClose();
    }
  };

  useEffect(() => {
    validateUser(user.socialSecurityNumber, user.dateOfBirth);
  }, [user]);

  return {
    styles,
    loading,
    error,
    errorsInputs,
    user,
    showSocialSecurityNumber,
    toggleShowSocialSecurityNumber,
    handleUserChange,
    handleSubmit,
  };
};
