import { Box, Grid, Radio, Typography, useTheme } from "@mui/material";
import toPercentage from "utils/toPercentage";
import dollarFormatter from "utils/FormatterDollar";
import { useEffect, useState } from "react";
import Loan from "typedef/Loan";
import getEstimatedMonthlyPayment from "utils/getEstimatedMonthlyPayment";

interface Props {
  offertOptions: {
    maxLoanAmount?: number;
    rate: number;
    monthlyPayment?: number;
  }[];
  currentLoan?: Loan;
  loanId?: string;
  handleSubmit: (amount: number, loanId: string) => void;
  isDisabled: boolean;
}

const MINUS_FOUR_OFFERS = -4;
const ACTIONS_TIMEOUT_DELAY = 800;

const OfferOptionsByFico = ({
  offertOptions,
  currentLoan,
  loanId,
  handleSubmit,
  isDisabled,
}: Props) => {
  const theme = useTheme();

  const currentOffer = {
    maxLoanAmount: currentLoan?.initialOffer?.amount,
    rate: currentLoan?.initialOffer?.interestRate,
    monthlyPayment: currentLoan?.initialOffer?.monthlyPayment,
  };
  const lastFourOffers = offertOptions.slice(MINUS_FOUR_OFFERS);

  const filteredLastFourOffers = lastFourOffers.filter(
    (offer) =>
      (Number(offer.maxLoanAmount) || 0).toFixed(0) !==
      (currentOffer.maxLoanAmount ?? 0).toFixed(0),
  );

  const newOfferOptions = [currentOffer, ...filteredLastFourOffers];

  const [offerChecked, setOfferChecked] = useState<number | undefined>(
    currentLoan?.initialOffer?.amount,
  );

  useEffect(() => {
    if (currentLoan) {
      setOfferChecked(currentLoan?.initialOffer?.amount);
    }
  }, [currentLoan]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Grid container mt={{ xs: "10px", md: "20px" }}>
        <Grid item xs={4}>
          <Typography
            sx={{ fontSize: { xs: "1rem", md: "1.2rem" } }}
            textAlign={"start"}
            color="#979797"
          >
            Max Loan
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography
            sx={{ fontSize: { xs: "1rem", md: "1.2rem" } }}
            textAlign={"center"}
            color="#979797"
          >
            Rate
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography
            sx={{ fontSize: { xs: "1rem", md: "1.2rem" } }}
            textAlign="end"
            color="#979797"
          >
            Est. Monthly Payment*
          </Typography>
        </Grid>
      </Grid>
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap="22px"
        mt={{ xs: "5px", md: "20px" }}
      >
        {newOfferOptions.map((option) => {
          const monthlyPayment =
            option?.monthlyPayment ??
            getEstimatedMonthlyPayment(
              option?.maxLoanAmount ?? 0,
              option?.rate as number,
            );
          const monthlyPaymentToMoney = dollarFormatter.format(
            Number(monthlyPayment),
          );
          return (
            <Grid
              container
              key={option?.maxLoanAmount}
              sx={{
                backgroundImage:
                  "linear-gradient(to right, lightgray 50%, transparent 50%)",
                backgroundPosition: "0 60%",
                backgroundRepeat: "repeat-x",
                backgroundSize: "8px 1px",
              }}
              alignItems="center"
            >
              <Grid item xs={4} alignContent={"center"}>
                <Box display="flex" flexDirection={"row"} alignItems={"center"}>
                  <Radio
                    sx={{
                      bgcolor: `${theme.palette.background.paper}`,
                      backgroundImage: "none",
                      "&:hover": {
                        bgcolor: `${theme.palette.background.paper}`,
                      },
                      "&:focus": {
                        bgcolor: `${theme.palette.background.paper}`,
                      },
                    }}
                    checked={offerChecked === option?.maxLoanAmount}
                    onChange={() => setOfferChecked(option?.maxLoanAmount)}
                    onClick={() => {
                      setTimeout(() => {
                        handleSubmit(
                          option?.maxLoanAmount as number,
                          loanId as string,
                        );
                      }, ACTIONS_TIMEOUT_DELAY);
                    }}
                    disabled={
                      isDisabled || offerChecked === option?.maxLoanAmount
                    }
                  />
                  <Typography
                    textAlign="start"
                    bgcolor={theme.palette.background.paper}
                    sx={{
                      width: "fit-content",
                      marginRight: "auto",
                      fontSize: { xs: "0.9rem", md: "1.1rem" },
                    }}
                    display="inline"
                  >
                    {dollarFormatter.format(option?.maxLoanAmount ?? 0)}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  textAlign="center"
                  bgcolor={theme.palette.background.paper}
                  sx={{
                    width: "fit-content",
                    marginLeft: "auto",
                    marginRight: "auto",
                    fontSize: { xs: "0.9rem", md: "1.1rem" },
                  }}
                >
                  {toPercentage(option?.rate as number)}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  fontFamily={"Pro Display Bold"}
                  bgcolor={theme.palette.background.paper}
                  sx={{
                    width: "fit-content",
                    marginLeft: "auto",
                    fontSize: { xs: "0.9rem", md: "1.1rem" },
                  }}
                >
                  {monthlyPaymentToMoney}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </Box>
    </Box>
  );
};

export default OfferOptionsByFico;
