import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import API from "utils/API";
import * as yup from "yup";

const useResendValidateCode = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const emailParam = searchParams.get("email");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const schema = yup.object().shape({
    email: yup.string().email("invalid format").required("email is required"),
  });

  const form = useForm({
    defaultValues: {
      email: emailParam ?? "",
    },
    resolver: yupResolver(schema),
  });

  const handleSubmit = form.handleSubmit((data) => {
    setLoading(true);
    setError("");
    API.post<{ message: string }>({
      url: "/resend-code",
      data: {
        username: data.email,
        type: "email",
        phoneNumber: "",
      },
    })
      .then((result) => {
        if ("error" in result) {
          setError(result.error);
        } else {
          navigate(`/validate-account/${data.email}`, { replace: true });
        }
      })
      .catch((err) => {
        setError(err?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  });

  return { ...form, handleSubmit, loading, error };
};

export default useResendValidateCode;
