import API from "utils/API";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export type ContactUsInputs = {
  email: string;
  tellUs: string;
  lastName: string;
  firstName: string;
  phoneNumber: string;
  message: string;
};

const useContactus = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [showDialogConfirm, setShowDialogConfirm] = useState(false);

  const schema = yup.object().shape({
    tellUs: yup.string().required("This field is required."),
    firstName: yup.string().required("First name is required."),
    lastName: yup.string().required("Last name is required."),
    email: yup
      .string()
      .email("Email has invalid format.")
      .required("Email is required."),
    phoneNumber: yup
      .string()
      .required("Phone number is required.")
      .test(
        "minLenght",
        "The phone number must be 11 characters long.",
        (value) => {
          if (!value) return true;
          if (process.env.REACT_APP_ENV === "prod") {
            return value.replaceAll(/ /g, "").length === 12;
          }
          return true;
        },
      ),
    message: yup.string().required("Message is required."),
  });
  const form = useForm<{
    firstName: string;
    tellUs: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    message: string;
  }>({
    resolver: yupResolver(schema),
  });
  const onSubmit = form.handleSubmit(async (data) => {
    setLoading(true);
    try {
      const response = await API.post({
        url: `/send-contact`,
        data: {
          email: data.email?.trim(),
          firstName: data.firstName,
          lastName: data.lastName,
          phoneNumber: data.phoneNumber.replace(/ /g, ""),
          typeOfUser: data.tellUs,
          message: data.message,
        },
      });
      if (!("error" in response)) {
        form.reset();
        setShowDialogConfirm(true);
      }
    } catch (unknownError) {
      setErrorMessage(String(unknownError));
      console.error(unknownError);
    }

    setLoading(false);
  });

  return {
    onSubmit,
    errorMessage,
    setErrorMessage,
    showDialogConfirm,
    setShowDialogConfirm,
    loading,
    ...form,
  };
};

export default useContactus;
