import CButton from "components/CButton";
import CDialog from "components/CDialog";
import useAnnuncementCoborrower from "./useAnnuncementCoborrower";

const AnnuncementCoborrowerPending = () => {
  const { handleAddAnnuncementCoborrowerPendign, loading, openModal } =
    useAnnuncementCoborrower();

  return (
    <CDialog
      fullWidth
      maxWidth="xs"
      icon="warning"
      scroll={"paper"}
      sx={{ padding: "2rem" }}
      title="Coborrower process is pending"
      description="Please have your co-borrower complete their registration and verifications."
      open={openModal}
      onClose={handleAddAnnuncementCoborrowerPendign}
    >
      <CButton
        fullWidth
        loading={loading}
        disabled={loading}
        onClick={handleAddAnnuncementCoborrowerPendign}
        variant="contained"
        name="annuncementCoborrowerDialog-accept"
        aria-label="annuncementCoborrowerDialog-accept"
      >
        Accept
      </CButton>
    </CDialog>
  );
};

export default AnnuncementCoborrowerPending;
