import { Fragment } from "react";
import CButton from "components/CButton";
import CDialog from "components/CDialog";
import { useForm } from "react-hook-form";
import CTextField from "components/CTextField";
import CoborrowerInvite from "../CoborrowerInvite";
import useNPEAnnouncement from "./useNPEAnnouncement";
import useTracker from "components/CTracker/useTrackerContext";

export type NPEForm = {
  email: string;
};

const NPEAnnouncement = () => {
  const form = useForm<NPEForm>();
  const { property } = useTracker();
  const npeAnnouncement = useNPEAnnouncement();
  const isUniqueExtensions = (value: string) => {
    const extensions = value?.match(/\.[a-zA-Z]+/g) || [];
    const uniqueExtensions = Array.from(new Set(extensions));
    return extensions.length === uniqueExtensions.length;
  };

  return (
    <Fragment>
      <CDialog
        maxWidth="xs"
        icon="warning"
        title={`We've noticed ${property?.NPE} as another owner on your property`}
        description="Will you be adding this person as a co-borrower?"
        open={npeAnnouncement.openAnnouncement}
      >
        <CButton
          fullWidth
          data-cy="opcionYes"
          onClick={npeAnnouncement.handleAccept}
          variant="contained"
          sx={{ marginBottom: 3, marginTop: 1 }}
          name="npeAnnouncementDialog-yes"
        >
          Yes
        </CButton>
        <CButton
          fullWidth
          data-cy="opcionNo"
          onClick={npeAnnouncement.handleSelectNo}
          variant="outlined"
          name="npeAnnouncementDialog-no"
        >
          No
        </CButton>
      </CDialog>

      <CDialog
        maxWidth="xs"
        icon="warning"
        title={`Should you qualify for a loan, we will need to add this person as a non borrowing spouse and would need them to sign`}
        description="Please enter their email below"
        open={npeAnnouncement.openNPEForm}
      >
        <form
          onSubmit={form.handleSubmit((data) => npeAnnouncement.addNPE(data))}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <CTextField
            error={
              form.formState.errors.email?.message || npeAnnouncement.emailError
            }
            helperText={
              form.formState.errors.email?.message || npeAnnouncement.emailError
            }
            {...form.register("email", {
              required: {
                value: true,
                message: "Email is required.",
              },
              minLength: {
                value: 2,
                message: "The Email must be at least 2 characters long.",
              },
              maxLength: {
                value: 100,
                message: "The Email cannot be longer than 100 characters.",
              },
              pattern: {
                value:
                  /^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]{2,40})+(@([a-zA-Z0-9-]{2,20}))+(?:\.[a-zA-Z0]{2,3})*$/i,
                message: "The email format is incorrect.",
              },
              validate: (value) =>
                isUniqueExtensions(value) || "The email format is incorrect.",
            })}
            sx={{ marginTop: 1 }}
            label={"Second owner's email"}
            onChange={() => npeAnnouncement.setEmailError(undefined)}
          />

          <CButton
            sx={{ marginBottom: 3, marginTop: 3 }}
            fullWidth
            type="submit"
            loading={form.formState.isSubmitting}
            variant="contained"
            name="npeAnnouncementDialog-add"
          >
            Add
          </CButton>
          <CButton
            fullWidth
            onClick={npeAnnouncement.handleCloseNPEForm}
            variant="outlined"
            name="npeAnnouncementDialog-cancel"
          >
            Cancel
          </CButton>
        </form>
      </CDialog>

      <CoborrowerInvite
        open={npeAnnouncement.coborrowerInviteIsOpen}
        onClose={() => npeAnnouncement.setCoborrowerInviteIsOpen(false)}
        onDismiss={npeAnnouncement.handleInviteDismiss}
        customFlags={{ offeredAddingNPEAsCoborrower: true }}
      />
    </Fragment>
  );
};

export default NPEAnnouncement;
