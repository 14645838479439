import { Box } from "@mui/system";
import CButton from "components/CButton";
import CDialog from "components/CDialog";
import CTextField from "components/CTextField";
import useDocumentUploadForm from "./useDocumentUploadForm";
import CDialogTransition from "components/CDialogTransition";

type DialogProps = {
  open: boolean;
  refetch: () => void;
  handleClose: () => void;
};

const CUploadDocumentDialog: React.FC<DialogProps> = ({
  open,
  refetch,
  handleClose,
}) => {
  const form = useDocumentUploadForm(handleClose, refetch);

  return (
    <CDialog
      maxWidth="md"
      title={"Upload document"}
      open={open}
      onClose={handleClose}
      TransitionComponent={CDialogTransition}
      aria-labelledby="customized-dialog-title"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <form onSubmit={form.uploadDocument}>
        <CTextField
          fullWidth
          sx={{
            pb: 2,
          }}
          error={form.formState.errors.document?.message}
          type={"file"}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            form.convertToBase64(e);
          }}
          required
          inputProps={{
            accept: [
              "audio/*",
              "video/*",
              ".pdf",
              ".png",
              ".jpg",
              ".doc",
              ".docx",
              ".csv",
              ".xls",
              ".xlsx",
            ],
            ignore: ".svg",
          }}
        />
        <CTextField
          required
          type={"text"}
          fullWidth
          label={"Document name"}
          error={form.formState.errors.documentName?.message}
          {...form.register("documentName", {
            required: {
              value: true,
              message: "Document name is required.",
            },
            minLength: {
              value: 2,
              message: "The Document Name must be at least 2 characters long.",
            },
            maxLength: {
              value: 120,
              message:
                "The Document Name cannot be longer than 120 characters.",
            },
          })}
        />
        <Box flexDirection={"row"} display={"flex"} mt={4}>
          <CButton
            type="reset"
            fullWidth
            variant="outlined"
            sx={{ mr: 2 }}
            onClick={() => form.onReset()}
          >
            Discard
          </CButton>
          <CButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{ ml: 2 }}
            loading={form.loading}
          >
            Save
          </CButton>
        </Box>
      </form>
    </CDialog>
  );
};

export default CUploadDocumentDialog;
