import CButton from "components/CButton";
import { useMediaQuery, useTheme } from "@mui/material";

const LoggedInDocumentForm = ({
  formRef,
  linkToSign,
  token,
  handleClickSignInitials,
  isLoading,
  showButton = false,
  text = "Sign your documents",
}: {
  formRef: React.RefObject<HTMLFormElement>;
  linkToSign?: string;
  token?: string;
  handleClickSignInitials: () => Promise<void>;
  isLoading: boolean;
  showButton: boolean;
  text: string;
}) => {
  const theme = useTheme();
  const buttonsFullWidth = useMediaQuery(theme.breakpoints.only("xs"));

  const onSubmit = () => {
    if (!token) {
      handleClickSignInitials();
    } else {
      formRef?.current?.submit();
    }
  };

  function transformMessageToCamelCase(message: string): string {
    const words = message.split(" ");
    const camelCaseMessage = words
      .map((word, index) => {
        if (index === 0) {
          return word.toLowerCase();
        } else {
          return word.charAt(0).toUpperCase() + word.slice(1);
        }
      })
      .join("");
    return camelCaseMessage;
  }

  return (
    <form
      target="_blank"
      id="token"
      ref={formRef}
      method="POST"
      action={`${linkToSign}`}
    >
      <input type="hidden" name="access_token" value={token} />

      {showButton && (
        <CButton
          disabled={isLoading}
          type="button"
          variant={"contained"}
          fullWidth={buttonsFullWidth}
          loading={isLoading}
          onClick={() => onSubmit()}
          name={`${transformMessageToCamelCase(text)}`}
          aria-label={`${transformMessageToCamelCase(text)}`}
          sx={{
            marginTop: 2,
            whiteSpace: "nowrap",
            ml: { md: 0 },
          }}
        >
          {text}
        </CButton>
      )}
    </form>
  );
};

export default LoggedInDocumentForm;
