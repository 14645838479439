import API from "utils/API";
import Loan from "typedef/Loan";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useCallback, useEffect } from "react";
import useUser from "context/UserCustomer/useUserCustomerContext";

export type Marks = Loan["initialOffer"][];

const useOffer = (isAdmin?: boolean) => {
  const navigate = useNavigate();
  const params = useParams();
  const currentAdminLoanId = params?.loanId;

  const [loan, setLoan] = useState<Loan>();
  const [loading, setLoading] = useState(true);
  const [marks, setMarks] = useState<Marks>([]);
  const [isAccepting, setIsAccepting] = useState(false);
  const [markSelectIndex, setMarkSelectIndex] = useState(0);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const { selectedLoanId } = useUser();

  const saveOffer = useCallback(async () => {
    setIsAccepting(true);
    const response = await API.post({
      url: `/accept-offer/initial?ignoreMaxDti=true`,
      data: { initialOffer: marks[markSelectIndex], loanId: loan?.id },
    });
    if ("error" in response) {
      console.error(response);
      setErrorMessage(response.error);
      setShowErrorMessage(true);
    } else {
      navigate(`/type-of-credit-line?loanId=${selectedLoanId}`, {
        replace: true,
      });
    }

    setIsAccepting(false);
  }, [marks, markSelectIndex, loan?.id, navigate, selectedLoanId]);

  useEffect(() => {
    if (marks.length === 1 && marks[markSelectIndex].amount) {
      setLoading(true);
      saveOffer().finally(() => {
        setLoading(false);
      });
    }
    //eslint-disable-next-line
  }, [marks, saveOffer]);

  useEffect(() => {
    (async () => {
      setShowErrorMessage(false);
      setLoading(true);
      const loanResponse =
        isAdmin && currentAdminLoanId
          ? await API.get<Loan>(
              `/admin-impersonate/get/loan/${currentAdminLoanId}`,
            )
          : await API.get<Loan>(`/get/my-loan?id=${selectedLoanId}`);

      if ("error" in loanResponse) {
        setShowErrorMessage(true);
        setErrorMessage(loanResponse.error);
      } else {
        setLoan(loanResponse.data);
        const { id: loanId } = loanResponse.data;
        const offersResponse =
          isAdmin && currentAdminLoanId
            ? await API.get<Marks>(
                `/admin-impersonate/get/get-offer-initial/initial?loanId=${currentAdminLoanId}&ignoreMaxDti=true`,
              )
            : await API.get<Marks>(
                `/get-offer/initial?loanId=${loanId}&ignoreMaxDti=true`,
              );
        if ("error" in offersResponse) {
          setShowErrorMessage(true);
          setErrorMessage(offersResponse.error);
        } else {
          setMarks(offersResponse.data);
        }
      }
      setLoading(false);
    })();
  }, [isAdmin, currentAdminLoanId, selectedLoanId]);

  return {
    loan,
    loading,
    saveOffer,
    isAccepting,
    errorMessage,
    showErrorMessage,
    marks,
    markSelectIndex,
    setMarkSelectIndex,
  };
};

export default useOffer;
