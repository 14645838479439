import API from "utils/API";
import { useState } from "react";
import useTracker from "components/CTracker/useTrackerContext";
import useUser from "context/UserCustomer/useUserCustomerContext";

interface ResponseBridge {
  bridge_token: string;
  tracking_info: string;
  client_name: string;
  product_type: string;
  allowed_products: [];
  company_mapping_id: string;
  access_token: string;
  user_id: string;
}

const useInsurance = () => {
  const { loan } = useTracker();
  const { user } = useUser();
  const [error, setError] = useState<string | null>();
  const [bridgeToken, setBridgeToken] = useState<string | null>();
  const config = {
    userId: user?.id,
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
  };

  const getBridgeToken = async () => {
    const response = await API.post<ResponseBridge>({
      url: `/verify-insurance`,
      data: { ...config, loanId: loan?.id },
    });

    if ("error" in response) {
      setError(response.error);
    } else {
      setBridgeToken(response.data.bridge_token);
    }
  };

  const sendPublicToken = async (public_token: string) => {
    const response = await API.post({
      url: `/verify-insurance`,
      data: { public_token, loanId: loan?.id },
    });

    if ("error" in response) {
      setError(response.error);
    }
  };
  return {
    error,
    getBridgeToken,
    bridgeToken,
    sendPublicToken,
  };
};

export default useInsurance;
