import { Fragment } from "react";
import CButton from "components/CButton";
import CTextField from "components/CTextField";
import useResendValidateCode from "./useResendValidateCode";
import {
  Box,
  Container,
  FormHelperText,
  Typography,
  useTheme,
} from "@mui/material";

const ResendValidateCode = () => {
  const resendValidateCode = useResendValidateCode();
  const theme = useTheme();
  return (
    <Fragment>
      <Container maxWidth="md" sx={{ mb: 10 }}>
        <Box mb={4}>
          <Typography
            mt={4}
            mb={1}
            variant="h1"
            lineHeight={1}
            color={theme.palette.text.primary}
            letterSpacing={-0.8}
            fontSize={{ xs: "3.5rem", md: "6rem" }}
          >
            <span style={{ color: theme.palette.primary.main }}>Resend</span>
            <br />
            verification code
          </Typography>
          <Typography variant="h6" color={"text.secondary"}>
            A code will be sent to your email.
          </Typography>
        </Box>
        <form onSubmit={resendValidateCode.handleSubmit}>
          <Box
            sx={{
              display: { sx: "block", md: "flex" },
            }}
            gap={4}
          >
            <CTextField
              fullWidth
              label={"Email address"}
              error={resendValidateCode.formState?.errors?.email?.message}
              {...resendValidateCode.register("email")}
            />
            <CButton
              disabled={resendValidateCode.loading}
              loading={resendValidateCode.loading}
              type="submit"
              sx={{
                height: "fit-content",
                mt: 3,
                px: 4,
                py: 1.4,
                whiteSpace: "nowrap",
              }}
              variant="contained"
            >
              Send code
            </CButton>
          </Box>
          {resendValidateCode.error && (
            <FormHelperText error={Boolean(resendValidateCode.error)}>
              <Typography sx={{ ml: 1, mt: 1 }}>
                {resendValidateCode.error}
              </Typography>
            </FormHelperText>
          )}
        </form>
      </Container>
    </Fragment>
  );
};

export default ResendValidateCode;
