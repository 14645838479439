import { Fragment, useEffect } from "react";
import { useLocation } from "react-router";

type Props = {
  children: React.ReactNode;
};

const ScrollToTop = (props: Props) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <Fragment>{props.children}</Fragment>;
};

export default ScrollToTop;
