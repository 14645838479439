import API from "utils/API";
import Loan from "typedef/Loan";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useState, useCallback, useEffect } from "react";
import useUser from "context/UserCustomer/useUserCustomerContext";
import { ValuesHELOC } from "screens/TypeOfCreditLine/useDefineLoanType";
import { LSLoanOfficerToken } from "CONST";

export type ValuesSliderProps = {
  minDraw?: number;
  maxDraw?: number;
  stateCap?: string;
  showSlider?: boolean;
};

export type ErrorOffer = {
  error: string;
  errorId: string;
  status: number;
};

const useHelocOffer = (typeOfOffer = "final") => {
  const location = useLocation();
  const isAdmin = location.pathname.includes("impersonate");
  const params = useParams();
  const currentAdminLoanId = params?.loanId;
  const navigate = useNavigate();
  const [loan, setLoan] = useState<Loan>();
  const [loading, setLoading] = useState(true);
  const [isAccepting, setIsAccepting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [errorOffer, setErrorOffer] = useState<ErrorOffer | undefined>();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [newValuesSlider, setNewValuesSlider] = useState<ValuesHELOC>();
  const [drawLessThan100, setDrawLessThan100] = useState(false);
  const [changeByDraw, setChangeByDraw] = useState(false);
  const [totalFees, setTotalFees] = useState<number>();
  const [initialValuesSliderHELOC, setInitialValuesSliderHELOC] =
    useState<ValuesSliderProps>();
  const [refreshLoan, setRrefreshLoan] = useState(0);
  const [marks, setMarks] = useState<
    {
      value: number;
    }[]
  >([]);
  const { user, logout, selectedLoanId } = useUser();
  const handleRrefresh = () => {
    setRrefreshLoan((prev) => prev + 1);
  };
  useEffect(() => {
    setLoading(true);
    (async () => {
      const response =
        isAdmin && currentAdminLoanId
          ? await API.get<Loan>(
              `/admin-impersonate/get/loan/${currentAdminLoanId}`,
            )
          : await API.get<Loan>(`/get/my-loan?id=${selectedLoanId}`);
      if (!("error" in response)) {
        setLoan(response.data);
      }
    })();
    setLoading(false);
  }, [refreshLoan, isAdmin, currentAdminLoanId, selectedLoanId]);

  useEffect(() => {
    setLoading(true);
    setInitialValuesSliderHELOC(undefined);
    setNewValuesSlider(undefined);
    setMarks([]);
    (async () => {
      const response =
        isAdmin && currentAdminLoanId
          ? await API.get<ValuesSliderProps>(
              `/admin-impersonate/get/get-heloc-slider-values/value?loanId=${currentAdminLoanId}`,
            )
          : await API.get<ValuesSliderProps>(
              `/get/get-heloc-slider-values?loanId=${loan?.id}`,
            );

      if ("error" in response) {
        console.error(response.error);
      } else {
        setInitialValuesSliderHELOC(response.data);
      }
    })();
    setLoading(false);
  }, [loan, isAdmin, currentAdminLoanId]);

  useEffect(() => {
    if (
      initialValuesSliderHELOC?.maxDraw &&
      newValuesSlider?.initialDrawAmount === undefined &&
      loan?.id
    ) {
      setLoading(true);
      (async () => {
        let replaceString = "admin";

        const isLoanOfficer = localStorage.getItem(LSLoanOfficerToken);
        if (isLoanOfficer) {
          replaceString = "lo";
        }

        const recalculatedLoanResponse =
          isAdmin && currentAdminLoanId
            ? await API.post<ValuesHELOC>({
                url: `/admin-impersonate/save-to-loan-${replaceString}/calculate-heloc-offer-${typeOfOffer}`,
                data: {
                  initialDrawAmount: initialValuesSliderHELOC?.maxDraw,
                  loanId: loan?.id,
                },
              })
            : await API.post<ValuesHELOC>({
                url: `/calculate-heloc-offer/${typeOfOffer}`,
                data: {
                  initialDrawAmount: initialValuesSliderHELOC?.maxDraw,
                  loanId: loan?.id,
                },
              });

        if ("error" in recalculatedLoanResponse) {
          if (recalculatedLoanResponse.errorId) {
            setErrorOffer(recalculatedLoanResponse as ErrorOffer);
          } else {
            setShowErrorMessage(true);
            setErrorMessage(recalculatedLoanResponse.error);
          }
        } else {
          setShowErrorMessage(false);
          setErrorMessage("");
          setNewValuesSlider(recalculatedLoanResponse.data);
        }
      })();
      setLoading(false);
      setChangeByDraw(false);
      setDrawLessThan100(false);
    }
    // eslint-disable-next-line
  }, [loan, initialValuesSliderHELOC]);

  useEffect(() => {
    if (loan) {
      const minOffer = initialValuesSliderHELOC?.minDraw ?? 0;
      const maxOffer = initialValuesSliderHELOC?.maxDraw ?? 0;
      const steps = 10.0;
      const jump = (maxOffer - minOffer) / steps;
      const padding = jump * 0.1;
      const paddedMinOffer = minOffer + padding;
      const paddedMaxOffer = maxOffer - padding * 2;
      const paddedJump = (paddedMaxOffer - paddedMinOffer) / steps;
      setMarks(
        Array.from({ length: steps + 1 }).map((_, index) => ({
          value: paddedMinOffer + paddedJump * index,
        })),
      );
    }
    // eslint-disable-next-line
  }, [initialValuesSliderHELOC]);

  const recalculateOffer = async (incomingNewAmount: number | number[]) => {
    if (Array.isArray(incomingNewAmount)) return undefined;
    setLoading(true);
    const recalculatedLoanResponse = await API.post<ValuesHELOC>({
      url: `/calculate-heloc-offer/${typeOfOffer}`,
      data: { initialDrawAmount: incomingNewAmount, loanId: loan?.id },
    });
    if ("error" in recalculatedLoanResponse) {
      setShowErrorMessage(true);
      setErrorMessage(recalculatedLoanResponse.error);
    } else {
      setShowErrorMessage(false);
      setErrorMessage("");
      setNewValuesSlider(recalculatedLoanResponse?.data);
    }
    setLoading(false);
    setChangeByDraw(true);
  };

  const saveOffer = useCallback(
    async (confirmDrawAmount: number) => {
      setIsAccepting(true);
      const response = await API.post({
        url: `/accept-offer/final`,
        data: {
          initialDrawAmount: confirmDrawAmount,
          loanId: loan?.id,
        },
      });
      if ("error" in response) {
        console.error(response);
        setErrorMessage(response.error);
      } else {
        navigate(`/borrower-tracker?loanId=${selectedLoanId}`, {
          replace: true,
        });
      }
      setIsAccepting(false);
    },
    [loan?.id, navigate, selectedLoanId],
  );

  const archiveLoan = async () => {
    if (errorOffer) {
      const response = await API.post({
        url: `/save-to-loan/no-offer-archive?userId=${user?.id}&errorCode=${errorOffer.errorId}&loanId=${loan?.id}`,
      });
      logout();
      if ("error" in response) {
        console.error("Error when archiving!");
      }
    }
  };

  return {
    loan,
    marks,
    loading,
    saveOffer,
    isAccepting,
    errorMessage,
    newValuesSlider,
    showErrorMessage,
    initialValuesSliderHELOC,
    recalculateOffer: recalculateOffer,
    handleRrefresh,
    setDrawLessThan100,
    drawLessThan100,
    archiveLoan,
    changeByDraw,
    errorOffer,
    setTotalFees,
    totalFees,
  };
};

export default useHelocOffer;
