import { Box, Typography, useTheme } from "@mui/material";

export default function LaduInformation() {
  const theme = useTheme();
  return (
    <Box
      display={"flex"}
      borderRadius={10}
      bgcolor={theme.palette.background.paper}
      mb={3}
      py={5}
      px={7}
      position="relative"
      justifyContent={"space-between"}
      alignItems={{ xs: "center", sm: "flex-start" }}
      flexDirection={{ xs: "column", sm: "row" }}
    >
      <Box py={{ xs: 0, sm: 2 }} mt={{ xs: 3, sm: 0 }} flex={1}>
        <Typography
          variant="h6"
          color={theme.palette.text.primary}
          textAlign={"left"}
        >
          <span style={{ fontFamily: "Pro Display Semibold" }}>LADU </span>
          is excited to be partnering with NFTYDoor to offer specialized
          financing tailored to your ADU project that enables you to leverage
          your property's equity to fund your ADU project.
        </Typography>
      </Box>
    </Box>
  );
}
