import { Box, Typography, useTheme } from "@mui/material";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";

const SLSInfoBox: React.FC = () => {
  const theme = useTheme();
  const { privateLabel } = usePrivateLabel();

  function renderTextWithLinks(text: string | undefined) {
    const regex = /(?:^|\s)((?:https?:\/\/)?(?:www\.)[^\s]+)/g;
    const parts = text?.split(regex);
    return parts?.map((part, index) => {
      if (part.match(regex)) {
        const url = part.startsWith("www.") ? "http://" + part : part;
        return (
          <a key={index} href={url} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      } else {
        return <span key={index}>{part}</span>;
      }
    });
  }

  return (
    <Box
      borderRadius={10}
      bgcolor={theme.palette.background.paper}
      px={{ xs: 2, md: 6 }}
      py={{ xs: 4, md: 5 }}
      mt={1}
    >
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={{ xs: "start", md: "center" }}
        flexDirection={{ xs: "column", md: "row" }}
        mt={2}
      >
        <Box>
          <Typography variant="h5" sx={{ fontFamily: "Pro Text Semibold" }}>
            {privateLabel?.homeMonitorScreenTitle}
          </Typography>
          <Typography
            mt={2}
            variant="subtitle1"
            fontWeight={600}
            color={theme.palette.text.secondary}
            sx={{ fontFamily: "Pro Text Light", whiteSpace: "pre-line" }}
          >
            {renderTextWithLinks(privateLabel?.homeMonitorScreenBody)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SLSInfoBox;
