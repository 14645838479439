import CloseIcon from "@mui/icons-material/Close";
import { DialogTitle, IconButton, useTheme } from "@mui/material";

export interface DialogTitleProps {
  onClose: () => void;
  children?: React.ReactNode;
}

const CDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;
  const theme = useTheme();

  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 0,
        fontSize: 24,
        alignItems: "center",
        display: "flex !important",
        fontFamily: "Pro Display Regular",
      }}
      {...other}
    >
      <div style={{ flex: 1 }}>{children}</div>
      {onClose && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            paddingRight: 0,
            color: theme.palette.text.secondary,
          }}
        >
          <CloseIcon fontSize="large" />
        </IconButton>
      )}
    </DialogTitle>
  );
};

export default CDialogTitle;
