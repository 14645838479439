import CButton from "components/CButton";
import CDialog from "components/CDialog";
import useTracker from "components/CTracker/useTrackerContext";
import useAnnuncementCoborrower from "./useAnnuncementCoborrower";
import { ClickAwayListener } from "@mui/material";

const AnnuncementCreateNewOfferPending = () => {
  const { handleAddAnnuncementCreateNewOfferPending, loading, openModal } =
    useAnnuncementCoborrower();
  const { loan } = useTracker();

  return (
    <div>
      {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
      <ClickAwayListener onClickAway={() => {}}>
        <CDialog
          fullWidth
          maxWidth="xs"
          icon="warning"
          scroll={"paper"}
          sx={{ padding: "2rem" }}
          title="Final offer"
          description={loan?.borrowerSteps?.createNewOfferVerification?.message}
          open={openModal}
          onClose={handleAddAnnuncementCreateNewOfferPending}
          disableEscapeKeyDown
        >
          <CButton
            fullWidth
            loading={loading}
            disabled={loading}
            onClick={handleAddAnnuncementCreateNewOfferPending}
            variant="contained"
            name="annuncementCreateNewOfferDialog-accept"
            aria-label="annuncementCreateNewOfferDialog-accept"
          >
            Accept
          </CButton>
        </CDialog>
      </ClickAwayListener>
    </div>
  );
};

export default AnnuncementCreateNewOfferPending;
