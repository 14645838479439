import React from "react";
import { Typography, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Control, Controller } from "react-hook-form";

const UploadDocumentInput = ({
  onChange,
  selectedDocumentName,
  name,
  control,
}: {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedDocumentName?: string;
  name: string;
  control: Control;
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => {
        return (
          <Box>
            <input
              {...field}
              type="file"
              id="upload-document"
              style={{ display: "none" }}
              onChange={onChange}
              value={""}
            />
            <label htmlFor="upload-document">
              <Typography
                component="span"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "primary.main",
                  cursor: "pointer",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                {selectedDocumentName ?? (
                  <>
                    <AddIcon sx={{ fontSize: "1rem", mr: 0.5 }} />
                    Upload documents (required)
                  </>
                )}
              </Typography>
            </label>
            {error ? (
              <Typography color="error.main" fontSize="1rem" mt={2}>
                {error?.message}
              </Typography>
            ) : null}
          </Box>
        );
      }}
    />
  );
};

export default UploadDocumentInput;
