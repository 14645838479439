import color from "context/MaterialUITheme/color";

export const useDebtToIncomeModal = () => {
  const styles = {
    modalStyle: {
      width: "100%",
      maxWidth: 600,
      borderRadius: 8,
      px: 6,
    },
    containerStyle: {
      textAlign: "left",
    },
    contentContainerStyle: {},
    containerPlaid: {
      mt: 4,
    },
    containerTruv: {
      mt: 4,
    },
    text: {
      color: color.black,
      fontSize: 18,
      my: 2,
    },
    buttonStyles: {
      mt: 3,
      width: { md: "auto", xs: "100%" },
    },
  };

  return {
    styles,
  };
};
