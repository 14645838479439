import API from "utils/API";
import { usePlaidLink } from "react-plaid-link";
import { useCallback, useEffect, useState } from "react";

const usePlaidEditBankAccount = (
  openResultModal: () => void,
  handleEditBankError: () => void,
  loanId: string,
) => {
  const [token, setToken] = useState<string | null>(null);
  const isOAuthRedirect = window.location.href.includes("?oauth_state_id=");

  const { open, ready, exit } = usePlaidLink({
    token,
    receivedRedirectUri: isOAuthRedirect ? window.location.href : undefined,
    onSuccess: async (public_token, metadata) => {
      const result = await API.post({
        url: "/save-bank-account/edit",
        data: {
          public_token,
          accounts: metadata.accounts,
          loanId: loanId,
        },
      });

      if (!("error" in result)) {
        openResultModal();
      } else {
        handleEditBankError();
        openResultModal();
        console.error("Error");
      }
    },
  });

  const load = useCallback(async () => {
    const response = await API.get<{ link_token: string }>(
      `/get-plaid/auth/link-token?isEditBankAccount=${true}&loanId=${loanId}`,
    );
    if ("error" in response) {
      handleEditBankError();
      openResultModal();
      console.error(response.error);
    } else {
      const { link_token } = response.data;
      setToken(link_token);
      localStorage.setItem("oauth_plaid_link_token", link_token);
    }
  }, [handleEditBankError, openResultModal, loanId]);

  useEffect(() => {
    if (ready) {
      open();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready, exit]);

  useEffect(() => {
    if (isOAuthRedirect) {
      setToken(localStorage.getItem("oauth_plaid_link_token"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { load };
};

export default usePlaidEditBankAccount;
