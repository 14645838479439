import API from "utils/API";
import { useEffect, useState } from "react";

interface PageItem {
  formatedDate: string;
  formatedAmount: string;
}

interface Payment {
  pageItems: PageItem[];
  pageLimit: number;
  count: number;
  items: number;
}

const usePayments = (loanId?: string) => {
  const [payments, setPayments] = useState<Payment>();

  useEffect(() => {
    if (loanId) {
      API.post({
        url: `/paginate/payments?loanId=${loanId}`,
        data: {
          page: 1,
          items: 400,
        },
      }).then((result) => {
        if ("data" in result) setPayments(result.data as Payment);
      });
    }
  }, [loanId]);

  return {
    payments,
  };
};

export default usePayments;
