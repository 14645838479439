import CancelIcon from "@mui/icons-material/Cancel";
import WarningIcon from "@mui/icons-material/Warning";
import GppGoodIcon from "@mui/icons-material/GppGood";
import SettingsIcon from "@mui/icons-material/Settings";
import CDialogTransition from "components/CDialogTransition";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Dialog, DialogProps, Typography, useTheme } from "@mui/material";

export interface CDialogProps extends DialogProps {
  title?: string;
  description?: string;
  icon?: "cancel" | "warning" | "settings" | "check" | "shield";
  error?: string;
}

const CDialog: React.FC<CDialogProps> = ({
  icon,
  title,
  children,
  description,
  error,
  ...props
}) => {
  const theme = useTheme();
  return (
    <Dialog
      scroll="body"
      {...props}
      TransitionComponent={CDialogTransition}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: 10,
          overflow: "clip",
          textAlign: "center",
          alignItems: "center",
          padding: "4rem",
          justifyContent: "center",
          "@media only screen and (max-width: 600px)": {
            padding: "1rem",
            margin: "0rem",
          },
        },
      }}
    >
      {icon === "cancel" && (
        <CancelIcon
          style={{ fontSize: 60, marginBottom: 16 }}
          htmlColor={theme.palette.error.main}
        />
      )}
      {icon === "warning" && (
        <WarningIcon
          style={{ fontSize: 60, marginBottom: 16 }}
          htmlColor={theme.palette.warning.main}
        />
      )}
      {icon === "check" && (
        <CheckCircleIcon
          style={{ fontSize: 60, marginBottom: 16 }}
          htmlColor={theme.palette.primary.main}
        />
      )}
      {icon === "shield" && (
        <GppGoodIcon
          style={{ fontSize: 60, marginBottom: 16 }}
          htmlColor={theme.palette.primary.main}
        />
      )}
      {icon === "settings" && (
        <SettingsIcon
          style={{ fontSize: 60, marginBottom: 16 }}
          htmlColor={theme.palette.warning.main}
        />
      )}
      {error && error === "BORROWER_IS_ARCHIVED" ? (
        <pre>
          {title && (
            <Typography
              variant="h5"
              fontFamily="Pro Display Semibold"
              marginBottom={3}
              fontSize={30}
            >
              {title}
            </Typography>
          )}
          {description && (
            <Typography
              variant="h6"
              sx={{ color: theme.palette.text.secondary }}
              marginBottom={3}
            >
              {description}
            </Typography>
          )}
          {children}
        </pre>
      ) : (
        <>
          {title && (
            <Typography
              variant="h5"
              fontFamily="Pro Display Semibold"
              marginBottom={3}
              fontSize={30}
            >
              {title}
            </Typography>
          )}
          {description && (
            <Typography
              variant="h6"
              sx={{ color: theme.palette.text.secondary }}
              marginBottom={3}
            >
              {description}
            </Typography>
          )}
          {children}
        </>
      )}
    </Dialog>
  );
};

export default CDialog;
