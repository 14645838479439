import API from "utils/API";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { HomeMonitorContext } from "screens/HomeMonitor/useHomeMonitorContext";
import { isFilePdf } from "utils/validateFile";

export type DocumentUploadFormInputs = {
  document: string;
  documentName: string;
};

const useDocumentUploadForm = (
  handleClose: () => void,
  refetch: () => void,
) => {
  const { loan } = useContext(HomeMonitorContext);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState<string>();

  const form = useForm<DocumentUploadFormInputs>({
    defaultValues: {
      document: "",
      documentName: "",
    },
  });

  async function convertToBase64(event: React.ChangeEvent<HTMLInputElement>) {
    const selectedFile = event.target.files as FileList | null | undefined;
    if (JSON.stringify(selectedFile)?.length > 0) {
      const fileToLoad = selectedFile?.[0];

      const isPdf = await isFilePdf(fileToLoad as File);

      const sizeInMB = fileToLoad && fileToLoad.size / (1024 * 1024);
      const MAX_SIZE_IN_MB = 100;

      if ((sizeInMB && sizeInMB > MAX_SIZE_IN_MB) || !isPdf) {
        event.target.value = "";

        isPdf
          ? alert("You can't upload files larger than 10 MB")
          : alert("You can only upload PDF files");
      } else {
        const fileReader = new FileReader();
        fileReader.onload = function (fileLoadedEvent) {
          form.setValue("document", fileLoadedEvent?.target?.result as string);
        };
        fileReader.readAsDataURL(fileToLoad as Blob);
      }
    }
  }
  const onReset = () => {
    form.setValue("document", "");
    form.setValue("documentName", "");
    handleClose();
  };

  const uploadDocument = form.handleSubmit(async (data) => {
    setLoading(true);
    setErrorSubmit(undefined);
    const result = await API.post({
      url: "/upload-document",
      data: {
        loanId: loan?.id,
        document: data.document,
        documentName: data.documentName,
      },
    });
    if ("error" in result) {
      setErrorSubmit(result.error);
    } else {
      form.setValue("document", "");
      form.setValue("documentName", "");
      handleClose();
      refetch();
    }
    setLoading(false);
  });

  return {
    loading,
    ...form,
    onReset,
    openDialog,
    errorSubmit,
    setOpenDialog,
    uploadDocument,
    convertToBase64,
  };
};

export default useDocumentUploadForm;
