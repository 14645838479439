import { Status } from "typedef/Loan";
import BorrowerSteps from "typedef/BorrowerSteps";
import CoborrowerSteps from "typedef/CoborrowerSteps";

const getDisclosuresData = (
  step: BorrowerSteps | CoborrowerSteps | undefined,
): {
  status: Status;
  message?: string;
} => {
  const finalStatus = step?.finalDisclosuresVerification?.status ?? "pending";
  const message = step?.finalDisclosuresVerification?.message ?? "";
  const finalMessage =
    finalStatus === "failed"
      ? step?.finalDisclosuresVerification.messageToBorrower ?? message
      : message;

  if (finalStatus !== "pending") {
    return {
      status: finalStatus,
      message: finalMessage,
    };
  } else {
    return {
      status: "pending",
      message: "",
    };
  }
};

export default getDisclosuresData;
