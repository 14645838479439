import { DialogProps, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CButton from "components/CButton";
import CDialog from "components/CDialog";
import useLoan from "context/Invite/useLoan";
import { useState } from "react";
import API from "utils/API";

interface ChangeLoanAmountAndAppraisalModalProps {
  onClose: () => void;
  newAmount: number;
  reset: () => void;
  closeEditModal: () => void;
  refresh: Pick<DialogProps, "onClose">["onClose"];
  avmsData?: string[];
}
type EventData = {
  newAmount: number;
  initialDrawAmount: number;
};
const ChangeLoanAmountAndAppraisalModal: React.FC<
  ChangeLoanAmountAndAppraisalModalProps
> = ({
  onClose,
  newAmount,
  reset,
  closeEditModal,
  refresh,
  avmsData,
}: ChangeLoanAmountAndAppraisalModalProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>("");
  const loan = useLoan();
  const eventData: EventData = { newAmount, initialDrawAmount: newAmount };
  const handleSaveAppraisalAndLoanAmount = async (data: EventData) => {
    setLoading(true);
    setError(undefined);
    const response = await API.post({
      url: "/invoke",
      data: {
        InvocationType: "RequestResponse",
        FunctionName: "adminTimelineEvent",
        Service: "nftydoor-admin",
        Payload: {
          ...data,
          adminId: undefined,
          loanId: loan?.id,
          userId: loan?.borrowerId,
          functionName: "eventAddAppraisalAndChangeBalanceOrAmount",
          functionType: "Amount",
          avmsData: avmsData,
        },
      },
    });

    if ("error" in response) {
      setError(response.error ?? "Unknown error");
    } else {
      onClose();
      reset();
      closeEditModal();
      refresh?.({}, "backdropClick");
      window.location.reload();
    }
    setLoading(false);
  };
  return (
    <CDialog
      open={true}
      maxWidth="md"
      icon="warning"
      title="Are you sure you want to change the loan amount?"
    >
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography
          variant="h5"
          fontSize={{ xs: 20, md: 20 }}
          textAlign="center"
          fontFamily={"Pro Display Regular"}
        >
          This will require an appraisal.
        </Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems={{ md: "start", xs: "center" }}
        gap={{ md: "14px", xs: "14px" }}
        marginY={"15px"}
      >
        <CButton
          type="reset"
          variant="outlined"
          fullWidth
          sx={{ marginTop: 2 }}
          onClick={onClose}
        >
          No
        </CButton>
        <CButton
          disabled={Boolean(error)}
          loading={loading}
          variant="contained"
          fullWidth
          sx={{ marginTop: 2 }}
          onClick={() => handleSaveAppraisalAndLoanAmount(eventData)}
        >
          Yes
        </CButton>
      </Box>
    </CDialog>
  );
};
export default ChangeLoanAmountAndAppraisalModal;
