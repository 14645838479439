import { Box, Typography } from "@mui/material";
import CButton from "components/CButton";
import { useDebtToIncomeTruv } from "./useDebtToIncomeTruv";
import { useDebtToIncomeModal } from "../useDebtToIncomeModal";
import color from "context/MaterialUITheme/color";
import Loan from "typedef/Loan";
import { DocsToUpload } from "../../useIncomeVerification";
import UploadEachDocument from "components/CTracker/StepsDialogs/UploadEachDocument";

interface TruvProps {
  docsToUpload: DocsToUpload[];
  handleButton: (manualUpload: boolean) => void;
  needDocsUpload: boolean;
  isSelfEmployed: boolean;
  loading: boolean;
  step?: Loan["borrowerSteps"] | Loan["coborrowerSteps"];
  loan?: Loan;
  taxReturnsRequested?: boolean;
}

export const DebtToIncomeTruv = ({
  docsToUpload,
  handleButton,
  needDocsUpload,
  isSelfEmployed,
  loading,
  step,
  loan,
  taxReturnsRequested,
}: TruvProps) => {
  const { styles } = useDebtToIncomeTruv();
  const { styles: detbToIncomeStyles } = useDebtToIncomeModal();

  const titleText = isSelfEmployed
    ? "Upload Tax Returns (Slow!)"
    : "Connect Via Your Payroll System";
  const descriptionText = isSelfEmployed
    ? "You can upload 2 years of tax returns – personal or business. You must submit a full tax return, including all supporting documents.  Upon receipt of a full tax return it generally takes 1 to 3 business days for a personal tax return and 2 to 5 business days per business tax return."
    : "You will be prompted via Truv to log into your payroll system. If you are unable to make this connection, then you can upload 2 paystubs and your most recent W2.";
  const buttonText = isSelfEmployed
    ? "Upload Tax Returns"
    : "Connect via Payroll System";
  const isErrorMessage = step?.incomeVerification?.errorMessageTruv;

  return (
    <Box sx={styles.containerTruv}>
      <Typography
        component={"h5"}
        variant="h5"
        color={color.black}
        fontWeight={700}
      >
        {titleText}
      </Typography>
      <Typography sx={{ ...detbToIncomeStyles.text, mb: 2 }}>
        {descriptionText}{" "}
        {isSelfEmployed && (
          <Box
            sx={{
              mt: 2,
            }}
          >
            As this process is manual you can expect back and forth
            communication throughout this process for supplemental documentation
            so please remain responsive to ensure that we can work diligently on
            your file.
          </Box>
        )}
        {isSelfEmployed && (
          <Box
            sx={{
              mt: 2,
            }}
          >
            If your HELOC application is time sensitive, please do not select
            this option.
          </Box>
        )}
      </Typography>
      {!taxReturnsRequested && (
        <CButton
          loading={loading}
          data-cy="uploadTaxReturns"
          variant="contained"
          sx={detbToIncomeStyles.buttonStyles}
          onClick={() => handleButton(false)}
          name={
            isSelfEmployed
              ? "debtToIncomeTruv-uploadTaxReturns"
              : "debtToIncomeTruv-connectPayrollSystem"
          }
          aria-label={
            isSelfEmployed
              ? "debtToIncomeTruv-uploadTaxReturns"
              : "debtToIncomeTruv-connectPayrollSystem"
          }
        >
          {buttonText}
        </CButton>
      )}
      {!taxReturnsRequested &&
        isErrorMessage &&
        isErrorMessage !== "Processing… Please give us a moment" && (
          <Typography
            color={color.red}
            sx={{
              p: 1,
            }}
          >
            {isErrorMessage}
          </Typography>
        )}
      {needDocsUpload && (
        <UploadEachDocument docsToUpload={docsToUpload} loan={loan} />
      )}
    </Box>
  );
};
