import { FC } from "react";
import SexOptions from "./SexOptions";
import RaceOptions from "./RaceOptions";
import CButton from "components/CButton";
import CDialog from "components/CDialog";
import { Close } from "@mui/icons-material";
import EthnicityOptions, { InnerFormProps } from "./EthnicityOptions";
import { Typography, Box, IconButton, useTheme } from "@mui/material";
import Citizenship from "./citizenship";

export type InnerPropsDemographicForm = InnerFormProps & {
  errorMessage: string;
};

const DemographicDataDialog: FC<{
  open: boolean;
  onClose: () => void;
  form: InnerPropsDemographicForm;
  onSubmit: () => void;
}> = ({ open, onClose, form, onSubmit }) => {
  const theme = useTheme();

  return (
    <CDialog open={open} maxWidth="md">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography
            variant="h5"
            fontSize={30}
            textAlign="left"
            fontFamily="Pro Display Semibold"
          >
            Demographic Information
          </Typography>
          <IconButton onClick={onClose} sx={{ pr: 0 }}>
            <Close fontSize="large" htmlColor={theme.palette.text.disabled} />
          </IconButton>
        </Box>
        <DemographicOptions form={form} />
        <CButton
          fullWidth
          type="submit"
          sx={{ mt: 4 }}
          variant="contained"
          loading={form.formState.isSubmitting}
          name="demographicVerifictionDialog-Save"
        >
          Save
        </CButton>
      </form>
    </CDialog>
  );
};

const DemographicOptions: React.FC<{
  form: InnerPropsDemographicForm;
}> = ({ form }) => {
  return (
    <Box>
      <Typography variant="subtitle1" sx={{ textAlign: "justify" }}>
        The purpose of collecting this information is to help ensure that all
        applicants are treated fairly and that the housing needs of communities
        and neighborhoods are being fulfilled. For residential mortgage lending,
        federal law requires that we ask applicants for their demographic
        information (ethnicity, sex, and race) in order to monitor our
        compliance with equal credit opportunity, fair housing, and home
        mortgage disclosure laws. You are not required to provide this
        information, but are encouraged to do so. You may select one or more
        designations for “Ethnicity” and one or more designations for “Race”.{" "}
        <br /> <br />
        The law provides that we may not discriminate on the basis of this
        information, or on whether you choose to provide it. *If you do not wish
        to provide some or all of this information, please check below.
        <br /> <br />
        *However if you choose not to provide the information and you have made
        this application in person, Federal regulations require us to note your
        ethnicity, rate and sex on the basis of visual observation or surname.
      </Typography>
      <EthnicityOptions form={form} />
      <RaceOptions form={form} />
      <SexOptions form={form} />
      <Citizenship form={form} />
      <Typography sx={{ color: "red", textAlign: "center", marginTop: "15px" }}>
        {form.formState.errors && form.errorMessage}
      </Typography>
    </Box>
  );
};

export { DemographicOptions, DemographicDataDialog };
