import { memo, useEffect, useMemo, useRef, useState } from "react";
import { Box, Dialog, Grid, Typography } from "@mui/material";
import { styles } from "./styles";
import CButton from "components/CButton";
import CTextField from "components/CTextField";
import CAddressAutocomplete from "components/CAddressAutocomplete";
import Address from "typedef/Address";
import API from "utils/API";
import { LiabilitiesHardPull } from "../useDebts";
import dollarFormatter from "utils/FormatterDollar";
import CDialogTransition from "components/CDialogTransition";
export interface UpdateFields {
  creditorName: string;
  accountNumber: string;
  address: LiabilitiesHardPull["address"];
  borrowerUpdated: boolean;
}

interface ChangeDebtsInformationModalProps {
  open: boolean;
  onClose: () => void;
  loanId: string;
  tradeline: {
    id: string;
    payto: string;
    paytoAddress: LiabilitiesHardPull["address"];
    accountNumber: string;
    monthlyPayment: string;
    balance?: string;
  };
  isBorrower: boolean;
  counter?: {
    current: number;
    total: number;
  };
  handleRefreshData?: (updateFields: UpdateFields, index: number) => void;
  handleNextSlider?: () => void;
  currentIndex: number;
}

const validateSubmitted = (
  data: {
    payto: string;
    accountNumber: string;
  },
  tradeline: ChangeDebtsInformationModalProps["tradeline"],
  address: Address,
) => {
  const isDataModified =
    tradeline?.payto !== data?.payto ||
    tradeline?.accountNumber !== data?.accountNumber;

  const isAddressModified =
    tradeline?.paytoAddress?.subscriberAddress !== address.street_line ||
    tradeline?.paytoAddress?.subscriberCity !== address.city ||
    tradeline?.paytoAddress?.subscriberState !== address.state ||
    tradeline?.paytoAddress?.subscriberZipCode !== address.zipcode;

  const addressIsNotListed =
    address?.street_line === "My address is not listed";

  if (addressIsNotListed) {
    return addressIsNotListed;
  }

  return !isDataModified && !isAddressModified;
};

const ChangeDebtsInformationModal = ({
  open,
  onClose,
  loanId,
  tradeline,
  isBorrower,
  counter,
  handleRefreshData,
  handleNextSlider,
  currentIndex,
}: ChangeDebtsInformationModalProps) => {
  const defaultAddress = useMemo(() => {
    return {
      city: tradeline?.paytoAddress?.subscriberCity ?? "",
      state: tradeline?.paytoAddress?.subscriberState ?? "",
      zipcode: tradeline?.paytoAddress?.subscriberZipCode ?? "",
      secondary: "",
      entries: 0,
      street_line: `${tradeline?.paytoAddress?.subscriberAddress ?? ""}`,
    };
  }, [tradeline]);

  const [loading, setLoading] = useState(false);
  const loadedFields = useRef(false);
  const [data, setData] = useState({
    payto: tradeline?.payto ?? "",
    accountNumber: tradeline?.accountNumber ?? "",
  });

  const handleDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const [addressData, setAddressData] = useState<Address>(defaultAddress);

  const disabled = loading || validateSubmitted(data, tradeline, addressData);

  const handleOnClose = () => {
    onClose();
  };

  const handleSubmitted = async (
    loanId: string,
    isBorrower: boolean,
    tradelineId: string,
    paytoAddressValue: LiabilitiesHardPull["address"],
    creditorName: string,
    accountNumber: string,
    addressData: Address,
    currentIndexValue: number,
  ) => {
    setLoading(true);
    const subscriberCode = paytoAddressValue?.subscriberCode;
    const subscriberName = paytoAddressValue?.subscriberName;
    const subscriberPhone = paytoAddressValue?.subscriberPhone;

    const address = {
      subscriberAddress: addressData.street_line,
      subscriberCity: addressData.city,
      subscriberCode,
      subscriberName,
      subscriberPhone,
      subscriberState: addressData.state,
      subscriberZipCode: addressData.zipcode,
    };

    const updateFields: UpdateFields = {
      creditorName,
      accountNumber,
      address,
      borrowerUpdated: true,
    };

    const result = await API.post<{ accessToken: string }>({
      url: "/invoke",
      data: {
        FunctionName: "manageDebts",
        InvocationType: "RequestResponse",
        Service: "nftydoor-admin",
        Payload: {
          body: {
            loanId,
            isBorrower,
            id: tradelineId,
            creditorName,
            accountNumber,
            address,
            borrowerUpdated: true,
          },
          pathParameters: {
            task: "update-single-tradeline",
          },
        },
      },
    });

    if ("error" in result) {
      setLoading(false);
    } else if ("data" in result) {
      setLoading(false);
      if (handleRefreshData) {
        handleRefreshData(updateFields, currentIndexValue);
      }
      if (handleNextSlider) {
        handleNextSlider();
      } else {
        handleOnClose();
      }
    }
  };

  const handleSaveAndNext = (
    loanId: string,
    isBorrower: boolean,
    tradelineId: string,
    paytoAddressValue: LiabilitiesHardPull["address"],
    creditorName: string,
    accountNumber: string,
    addressData: Address,
    currentIndexValue: number,
  ) => {
    if (disabled) {
      if (handleNextSlider) {
        handleNextSlider();
      }
    } else {
      handleSubmitted(
        loanId,
        isBorrower,
        tradelineId,
        paytoAddressValue,
        creditorName,
        accountNumber,
        addressData,
        currentIndexValue,
      );
    }
  };
  useEffect(() => {
    if (open && loadedFields?.current === false) {
      setData({
        payto: tradeline?.payto ?? "",
        accountNumber: tradeline?.accountNumber ?? "",
      });
      setAddressData(defaultAddress);
      loadedFields.current = true;
    }
  }, [open, tradeline, defaultAddress]);
  const textButton = counter?.current ? "NEXT" : "CLOSE";
  const textButtonConfirm =
    counter && counter?.current === counter?.total ? "SEND" : textButton;

  const [missingData, setMissingData] = useState(false);

  useEffect(() => {
    if (
      textButtonConfirm !== "CLOSE" &&
      (!addressData?.city ||
        !addressData?.street_line ||
        !addressData?.state ||
        !addressData?.zipcode ||
        !data?.payto ||
        !data?.accountNumber)
    ) {
      setMissingData(true);
    } else {
      setMissingData(false);
    }
  }, [textButtonConfirm, addressData, data]);

  return (
    <Dialog
      scroll="body"
      open={open}
      sx={styles.modalStyle}
      TransitionComponent={CDialogTransition}
    >
      {counter ? (
        <Box marginBottom={"20px"}>
          <Box sx={styles.containerCounter}>
            <Typography variant="body1" color={"black"} fontWeight={700}>
              {counter.current} of {counter.total}
            </Typography>
          </Box>
          <Typography
            component={"h5"}
            variant="h5"
            color={"black"}
            fontWeight={700}
          >
            You have selected {counter.total} tradelines for Paydown, let's
            confirm some information
          </Typography>
        </Box>
      ) : (
        <Box marginBottom={"20px"}>
          <Typography
            component={"h5"}
            variant="h5"
            color={"black"}
            fontWeight={700}
          >
            Edit tradeline
          </Typography>
        </Box>
      )}

      <Grid container spacing={2} textAlign={"left"}>
        <Grid item sm={12} md={6}>
          <Typography
            variant="body1"
            color={"black"}
            fontWeight={700}
            sx={{
              fontSize: { xs: "13px", md: "16px" },
            }}
          >
            {tradeline?.payto}
          </Typography>
        </Grid>
        <Grid item sm={12} md={6}>
          {tradeline?.monthlyPayment && (
            <Typography
              textAlign={"end"}
              variant="body1"
              color={"black"}
              fontWeight={700}
              sx={{
                fontSize: { xs: "13px", md: "16px" },
              }}
            >
              Monthly Payment:{" "}
              {dollarFormatter.format(Number(tradeline?.monthlyPayment))}
            </Typography>
          )}
          {tradeline?.balance && (
            <Typography
              textAlign={"end"}
              variant="body1"
              color={"black"}
              fontWeight={700}
              sx={{
                fontSize: { xs: "13px", md: "16px" },
              }}
            >
              Payment Amt: {dollarFormatter.format(Number(tradeline?.balance))}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Box component="form" marginTop={"10px"}>
        <CTextField
          fullWidth
          id="payto"
          name="payto"
          value={data.payto}
          sx={{
            mb: 2,
            textAlign: "left",
          }}
          label="Payto"
          size="small"
          onChange={handleDataChange}
          required={!data?.payto}
        />

        <CAddressAutocomplete
          InputLabelProps={{
            sx: { textAlign: "left" },
          }}
          placeholder="Please enter the address of the Tradeline"
          address={addressData}
          setAddress={(newAddress) => {
            if (newAddress && newAddress !== addressData) {
              setAddressData(newAddress);
            }
          }}
          sx={{ mb: 2 }}
          size="small"
          label="Address"
          inputSize="small"
          required={!addressData?.street_line}
        />
        <CTextField
          fullWidth
          id="accountNumber"
          name="accountNumber"
          defaultValue={tradeline?.accountNumber}
          sx={{
            mb: 2,
            textAlign: "left",
          }}
          label="Account#"
          size="small"
          onChange={handleDataChange}
          required={!tradeline?.accountNumber}
        />
        {missingData && (
          <Box
            sx={{
              mt: 1,
              mb: 2,
              display: "flex",
              justifyContent: "flex-start",
              color: "red",
            }}
          >
            <Typography fontSize={17} sx={{ mb: 2 }}>
              This selected tradeline is missing data. Please complete it in
              order to proceed.
            </Typography>
          </Box>
        )}

        <Box
          justifyContent={"space-around"}
          textAlign={"center"}
          gap={2}
          flexDirection={"row"}
          display={"flex"}
        >
          <CButton
            type="button"
            variant="outlined"
            disabled={loading}
            onClick={() => {
              handleOnClose();
            }}
            sx={{
              maxWidth: "200px",
              margin: "0 auto",
              fontSize: { xs: "13px", md: "16px" },
            }}
          >
            DISCARD
          </CButton>
          <CButton
            type="button"
            variant="contained"
            disabled={counter?.current ? missingData : disabled}
            onClick={() => {
              handleSaveAndNext(
                loanId,
                isBorrower,
                tradeline.id,
                tradeline.paytoAddress,
                data.payto,
                data.accountNumber,
                addressData,
                currentIndex,
              );
            }}
            sx={{
              maxWidth: "200px",
              margin: "0 auto",
              fontSize: { xs: "13px", md: "16px" },
            }}
          >
            SAVE AND {textButtonConfirm}
          </CButton>
        </Box>
      </Box>
    </Dialog>
  );
};

export default memo(ChangeDebtsInformationModal);
