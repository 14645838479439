import useTracker from "components/CTracker/useTrackerContext";
import { TrackerStatusColor } from "typedef/TrackerStatusColor";
import CTrackerTimelineItem from "components/CTimelineItem";
import { Typography } from "@mui/material";
import { useRef, useState } from "react";
import API from "utils/API";
import LoggedInDocumentForm from "components/CLoggedInDocumentForm";

const BrokerDisclosuresVerification: React.FC<{
  trackerStatusColor: TrackerStatusColor;
}> = ({ trackerStatusColor }) => {
  const { loan, type } = useTracker();
  const isBorrower = type === "borrower";
  const step = isBorrower ? loan?.borrowerSteps : loan?.coborrowerSteps;
  const formRef = useRef<HTMLFormElement>(null);
  const [tokenAccess, setTokenAccess] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const noNeedForAppraisal =
    loan?.borrowerSteps?.homeValuationVerification.status === "success" &&
    !loan?.borrowerSteps?.appraisalVerification?.status;

  const prevStep =
    step?.creditVerification.status === "success" &&
    loan?.borrowerSteps?.homeValuationVerification.status === "success";
  const linkToSign = step?.brokerDisclosuresVerification?.link;
  const status = prevStep
    ? step?.brokerDisclosuresVerification?.status ?? "pending"
    : "pending";
  const message = step?.brokerDisclosuresVerification?.message;
  const hasAppraisalType =
    loan?.borrowerSteps?.appraisalVerification?.appraisalType &&
    loan?.borrowerSteps?.appraisalVerification?.appraisalType?.length > 0 &&
    loan?.borrowerSteps?.appraisalVerification?.status !== "pending";

  const handleClickSignInitials = async () => {
    if (!linkToSign) return;
    setIsLoading(true);
    const result = await API.post<{ accessToken: string }>({
      url: "/invoke",
      data: {
        FunctionName: "getDocutechToken",
        InvocationType: "RequestResponse",
        Service: "nftydoor-core",
        Payload: {
          loanId: loan?.id,
          linkToSign,
        },
      },
    });

    if ("error" in result) {
      window.open(linkToSign, "_blank");
      setIsLoading(false);
    } else if ("data" in result) {
      const accessTokenDT = result?.data?.accessToken;
      setTokenAccess(accessTokenDT);
      setTimeout(() => {
        formRef?.current?.submit();
        setIsLoading(false);
      }, 1500);
    }
  };

  return (
    <CTrackerTimelineItem
      status={status}
      title="Broker Disclosures"
      tooltip="Your Broker disclosures will contain your state-specific acknowledgments and a loan cost breakdown."
      loading={isLoading}
    >
      <Typography variant="h6" color={[trackerStatusColor[status]]}>
        {status === "analyzing" && linkToSign && message}
        {status === "analyzing" && !linkToSign && message}
        {status === "failed" && message}
        {status === "success" &&
          (message ?? "We received your signed documents.")}
      </Typography>
      <LoggedInDocumentForm
        formRef={formRef}
        linkToSign={linkToSign}
        token={tokenAccess}
        handleClickSignInitials={handleClickSignInitials}
        isLoading={isLoading}
        showButton={Boolean(
          prevStep &&
            status !== "success" &&
            !message &&
            linkToSign &&
            loan &&
            (noNeedForAppraisal || hasAppraisalType),
        )}
        text={"Sign your Broker Documents"}
      />
    </CTrackerTimelineItem>
  );
};

export default BrokerDisclosuresVerification;
