import { Link } from "react-router-dom";
import CButton from "components/CButton";
import { Box, Typography, useTheme } from "@mui/material";

const NewPasswordSuccess = ({ email }: { email: string }) => {
  const theme = useTheme();
  return (
    <Box py={2} bgcolor={theme.palette.background.paper} borderRadius={8}>
      <Box px={{ xs: 2, md: 6 }} py={{ xs: 3, md: 6 }}>
        <Typography variant="h6" textAlign={"center"} mb={4}>
          The process is complete, log in.
        </Typography>
        <Link
          to={`/login?email=${encodeURIComponent(email)}`}
          style={{ textDecoration: "none" }}
        >
          <CButton fullWidth type="submit" variant="contained">
            Login
          </CButton>
        </Link>
      </Box>
    </Box>
  );
};

export default NewPasswordSuccess;
