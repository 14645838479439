import { AutocompleteValue } from "@mui/material";
import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteCloseReason,
  AutocompleteInputChangeReason,
} from "@mui/material/Autocomplete";
import getEnvVariable from "env";
import { Dispatch, useCallback, useEffect, useRef, useState } from "react";
import Address from "typedef/Address";
import addressToString from "./addressToString";
import sanitizeString from "./sanitizeString";

export interface AddressAutocompleteHookParams {
  address?: Address | null;
  setAddress: Dispatch<Address | null>;
}

const useAddressAutoComplete = ({
  address,
  setAddress,
}: AddressAutocompleteHookParams) => {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const searchTimeout = useRef<NodeJS.Timeout>();
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<Address[]>([]);
  const selectedAddressWithSecondary = useRef<Address>();

  const handleOnChange = useCallback(
    (
      _event: React.SyntheticEvent,
      value: AutocompleteValue<Address, undefined, undefined, undefined>,
      _reason: AutocompleteChangeReason,
      _details?: AutocompleteChangeDetails<Address>,
    ) => {
      if (value) setAddress(value);
    },
    [setAddress],
  );

  const handleOnInputChange = useCallback(
    (
      _event: React.SyntheticEvent,
      value: string,
      _reason: AutocompleteInputChangeReason,
    ) => {
      setText(value);
      if (value === "" && address) {
        setAddress(null);
      }
      if (selectedAddressWithSecondary.current) {
        const selectedAddressWithSecondaryStreetLine =
          selectedAddressWithSecondary.current.street_line;
        if (!selectedAddressWithSecondaryStreetLine?.includes(value)) {
          selectedAddressWithSecondary.current = undefined;
        }
      }
      if (searchTimeout.current) clearTimeout(searchTimeout.current);
      const sanitized = sanitizeString(value);
      if (sanitized) {
        searchTimeout.current = setTimeout(async () => {
          try {
            setLoading(true);
            if (selectedAddressWithSecondary.current) {
              const smartyResponse = await fetch(
                `https://us-autocomplete-pro.api.smartystreets.com/lookup?key=${getEnvVariable(
                  "SMARTY_KEY",
                )}&search=${sanitized.replace(
                  / /g,
                  "+",
                )}&selected=${addressToString(
                  selectedAddressWithSecondary.current,
                ).replace(/ /g, "+")}&source=all`,
              );
              const { suggestions } = (await smartyResponse.json()) as {
                suggestions: Address[];
              };
              setOptions(suggestions);
            } else {
              const smartyResponse = await fetch(
                `https://us-autocomplete-pro.api.smartystreets.com/lookup?key=${getEnvVariable(
                  "SMARTY_KEY",
                )}&search=${sanitized}&source=all`,
              );
              const { suggestions } = (await smartyResponse.json()) as {
                suggestions: Address[];
              };
              setOptions(suggestions);
            }
          } catch (error) {
            console.error(error);
          } finally {
            setLoading(false);
          }
        }, 1000);
      }
    },
    [selectedAddressWithSecondary, address, setAddress],
  );

  useEffect(() => {
    if (address?.secondary && address.entries > 1) {
      const newTextSearch = addressToString(address)?.split(" (")[0];
      selectedAddressWithSecondary.current = address;
      setOptions([]);
      setOpen(true);
      setLoading(true);
      setText(newTextSearch);
      fetch(
        `https://us-autocomplete-pro.api.smartystreets.com/lookup?key=${getEnvVariable(
          "SMARTY_KEY",
        )}&search=${newTextSearch.replace(
          / /g,
          "+",
        )}&selected=${addressToString(address).replace(/ /g, "+")}&source=all`,
      )
        .then((res) => res.json())
        .then((res: { suggestions: Address[] }) => {
          setOptions(res.suggestions);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (address) {
      selectedAddressWithSecondary.current = undefined;
    }
  }, [address]);

  const handleOnOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleOnClose = useCallback(
    (_event: React.SyntheticEvent, _reason: AutocompleteCloseReason) => {
      setOpen(false);
    },
    [],
  );

  return {
    text,
    open,
    options,
    loading,
    handleOnOpen,
    handleOnClose,
    handleOnChange,
    handleOnInputChange,
  };
};

export default useAddressAutoComplete;
