import { useTheme } from "@mui/material";
import CButton from "components/CButton";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import { Link } from "react-router-dom";

const CContactusGladlyButton: React.FC = () => {
  const theme = useTheme();
  const { privateLabel } = usePrivateLabel();
  return (
    <Link
      to={privateLabel?.contactUsLink || "/contactus"}
      style={{ textDecoration: "none" }}
      target="_blank"
    >
      <CButton
        variant="text"
        sx={{
          fontSize: { md: "1.4rem", xs: "2.4rem" },
          fontFamily: "Pro Display Regular",
          color: {
            md: theme.palette.text.primary,
            xs: theme.palette.background.paper,
          },
          overflow: "none",
          ":hover": {
            backgroundColor: {
              md: theme.palette.background.default,
              xs: theme.palette.primary.main,
            },
          },
          padding: 0,
        }}
        disableRipple
      >
        Contact us
      </CButton>
    </Link>
  );
};

export default CContactusGladlyButton;
