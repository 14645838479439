import { Box, Skeleton, Typography, useTheme } from "@mui/material";
import CButton from "components/CButton";
import { FC, useEffect, useState } from "react";
import ShowDetailsModal from "./showDetailsModal";
import { HELOCFormProps } from "typedef/HELOCFormProps";
import API from "utils/API";
import { PrivateLabelFees } from "typedef/PrivateLabelFees";
import dollarFormatter from "utils/FormatterDollar";

const AMOUNT_OF_DECIMALS = 2;

const EstPointsAndFees: FC<HELOCFormProps> = ({
  loan,
  newValuesSlider,
  setTotalFees,
}) => {
  const theme = useTheme();
  const [showDetails, setShowDetails] = useState(false);
  const [fees, setFees] = useState<PrivateLabelFees>();
  const [loading, setLoading] = useState(false);

  const state = loan?.property.address.components.state_abbreviation;
  useEffect(() => {
    API.get<PrivateLabelFees>(
      `/getServiceUnsecured/origination-fees?state=${state}`,
    )
      .then((response) => {
        if (!("error" in response)) {
          setFees(response.data);
        }
      })
      .catch((_error) => {
        console.log(_error);
      });
  }, [state]);
  const loanFees = loan?.loanFees;

  const originationFee =
    fees?.totalFee && newValuesSlider?.initialDrawAmount
      ? fees?.totalFee * newValuesSlider?.initialDrawAmount
      : null;
  const taxes =
    loan?.borrowerSteps?.titleVerification?.countyFees ??
    loan?.borrowerSteps?.titleVerification?.taxFeesAmount;

  const stateIsVA = state === "VA" ? true : false;

  const creditReport = Number(loanFees?.feesByLoanStateType?.creditReport ?? 0);
  const complianceDocPrepeNote = !stateIsVA
    ? Number(loanFees?.feesByLoanStateType?.complianceDocPrepeNote ?? 0)
    : 0;
  const AVMInspection = !stateIsVA
    ? Number(loanFees?.feesByLoanStateType?.AVMInspection ?? 0)
    : 0;
  const idVerificationFee = !stateIsVA
    ? Number(loanFees?.idVerficationFee ?? 0)
    : 0;
  const eRecordingFee = !stateIsVA ? Number(loanFees?.eRecordingFee ?? 0) : 0;
  const legalVestingFee = !stateIsVA
    ? Number(loanFees?.legalVestingFee ?? 0)
    : 0;
  const taxesValue = !stateIsVA ? Number(taxes ?? 0) : 0;

  const total =
    Number(originationFee ?? 0) +
    creditReport +
    complianceDocPrepeNote +
    AVMInspection +
    idVerificationFee +
    eRecordingFee +
    legalVestingFee +
    taxesValue;

  const details = [
    ["Credit Report", creditReport],
    ["Compliance / Doc Prep / Enote", complianceDocPrepeNote],
    ["Property AVM / Inspection", AVMInspection],
    ["ID Verification", idVerificationFee],
    ["eRecording", eRecordingFee],
    ["Legal and Vesting", legalVestingFee],
    ["Taxes", taxesValue],
  ];
  useEffect(() => {
    setLoading(!(loanFees && fees && originationFee));
    setTotalFees(total);
  }, [loanFees, fees, originationFee, total, setTotalFees]);

  const estPointsAndFees = Number(total?.toFixed(AMOUNT_OF_DECIMALS));
  const parsedEstPointsAndFeesAmount = dollarFormatter.format(estPointsAndFees);

  return (
    <>
      <Box
        display="grid"
        alignItems="center"
        sx={{ backgroundColor: theme.palette.background.paper, p: 2 }}
        gridTemplateColumns="1fr 1fr"
      >
        <Typography
          sx={{ fontSize: { xs: "1rem", md: "1.3rem" } }}
          variant="h6"
        >
          Est. Points And Fees
        </Typography>
        <Box
          sx={{
            flexDirection: { xs: "column", md: "row" },
            alignItems: { xs: "flex-start", md: "center" },
          }}
          display="flex"
          alignItems="center"
        >
          {(estPointsAndFees || estPointsAndFees === 0) &&
          newValuesSlider?.amount ? (
            <>
              <Typography
                variant="h5"
                color={theme.palette.text.primary}
                fontFamily={"Pro Display Bold"}
                sx={{ fontSize: { xs: "1rem", md: "1.3rem" } }}
              >
                {parsedEstPointsAndFeesAmount}
              </Typography>
              <CButton
                sx={{
                  minHeight: 0,
                  minWidth: 0,
                  padding: 0,
                  ml: { xs: 0, md: 1 },
                  fontFamily: "Pro Display Regular",
                  fontSize: { xs: "0.9rem", md: "1.2rem" },
                }}
                onClick={() => setShowDetails(true)}
              >
                Show details
              </CButton>
            </>
          ) : (
            <Skeleton width="75%" height={"1.5rem"} />
          )}
        </Box>
      </Box>

      {showDetails && (
        <ShowDetailsModal
          openModal={showDetails}
          onClose={() => {
            setShowDetails(false);
          }}
          totalFee={fees?.totalFee}
          details={details}
          total={total}
          loading={loading}
          originationFee={originationFee}
          state={state}
        />
      )}
    </>
  );
};

export default EstPointsAndFees;
