import fileTypeChecker from "file-type-checker";

export const isFilePdf = (file: File) => {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.onload = () => {
      const result = fileTypeChecker.isPDF(reader.result as ArrayBuffer);
      resolve(result);
    };

    reader.readAsArrayBuffer(file);
  });
};
