import {
  Box,
  Container,
  List,
  ListItem,
  Typography,
  useTheme,
} from "@mui/material";

export default function CHow() {
  const theme = useTheme();
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{ backgroundColor: theme.palette.primary.main }}
    >
      <Container maxWidth="lg">
        <Box
          p={{ md: 8, xs: 0 }}
          py={{ md: 8, xs: 4 }}
          color={theme.palette.background.paper}
        >
          <Typography
            variant="h3"
            mb={{ md: 3, xs: 0 }}
            fontSize={{ xs: "2rem", md: "3rem" }}
            role="heading"
            aria-level={4}
          >
            How does it work?
          </Typography>
          <List sx={{ fontSize: { xs: "1rem", md: "1.4rem" } }}>
            <ListItem disableGutters alignItems="flex-start">
              <Box
                mr={1}
                mt={0.4}
                display="inline"
                fontFamily="Pro Display Semibold"
              >
                •
              </Box>
              <Typography sx={{ fontSize: { xs: "1rem", md: "1.4rem" } }}>
                Contact us today!
              </Typography>
            </ListItem>
            <ListItem disableGutters alignItems="flex-start">
              <Box
                mr={1}
                mt={0.4}
                display="inline"
                fontFamily="Pro Display Semibold"
              >
                •
              </Box>
              <Typography sx={{ fontSize: { xs: "1rem", md: "1.4rem" } }}>
                Learn more about the program, and then sign our partner
                agreement
              </Typography>
            </ListItem>
            <ListItem disableGutters alignItems="flex-start">
              <Box
                mr={1}
                mt={0.4}
                display="inline"
                fontFamily="Pro Display Semibold"
              >
                •
              </Box>
              <Typography sx={{ fontSize: { xs: "1rem", md: "1.4rem" } }}>
                We will customize your branded loan application
              </Typography>
            </ListItem>
            <ListItem disableGutters alignItems="flex-start">
              <Box
                mr={1}
                mt={0.4}
                display="inline"
                fontFamily="Pro Display Semibold"
              >
                •
              </Box>
              <Typography sx={{ fontSize: { xs: "1rem", md: "1.4rem" } }}>
                Receive digital training and support materials
              </Typography>
            </ListItem>
            <ListItem disableGutters alignItems="flex-start">
              <Box
                mr={1}
                mt={0.4}
                display="inline"
                fontFamily="Pro Display Semibold"
              >
                •
              </Box>
              <Typography sx={{ fontSize: { xs: "1rem", md: "1.4rem" } }}>
                Launch marketing campaigns to your customers
              </Typography>
            </ListItem>
            <ListItem disableGutters alignItems="flex-start">
              <Box
                mr={1}
                mt={0.4}
                display="inline"
                fontFamily="Pro Display Semibold"
              >
                •
              </Box>
              <Typography sx={{ fontSize: { xs: "1rem", md: "1.4rem" } }}>
                NFTYDoor handles underwriting, processing, closing, funding, and
                servicing.
              </Typography>
            </ListItem>
            <ListItem disableGutters alignItems="flex-start">
              <Box
                mr={1}
                mt={0.4}
                display="inline"
                fontFamily="Pro Display Semibold"
              >
                •
              </Box>
              <Typography sx={{ fontSize: { xs: "1rem", md: "1.4rem" } }}>
                Earn commission on closed loans
              </Typography>
            </ListItem>
            <ListItem disableGutters alignItems="flex-start">
              <Box
                mr={1}
                mt={0.4}
                display="inline"
                fontFamily="Pro Display Semibold"
              >
                •
              </Box>
              <Typography sx={{ fontSize: { xs: "1rem", md: "1.4rem" } }}>
                Monitor funnel in your admin portal
              </Typography>
            </ListItem>
            <ListItem disableGutters alignItems="flex-start">
              <Box
                mr={1}
                mt={0.4}
                display="inline"
                fontFamily="Pro Display Semibold"
              >
                •
              </Box>
              <Typography sx={{ fontSize: { xs: "1rem", md: "1.4rem" } }}>
                All customer data is yours, and we will purge all records if you
                ever stop offering NFTYDoor home equity products.
              </Typography>
            </ListItem>
          </List>

          <Typography
            my={2}
            variant="h5"
            color={theme.palette.background.paper}
            textAlign="justify"
            letterSpacing={-0.5}
            fontSize={{ xs: "1.2rem", md: "1.2rem" }}
          >
            Note: For banks and other correspondents that want to fund their own
            loans, NFTYDoor can adjust its automated underwriting system to your
            guidelines.
          </Typography>
        </Box>
      </Container>
    </Container>
  );
}
