import { Box, Typography, useTheme } from "@mui/material";
import useUser from "context/UserCustomer/useUserCustomerContext";
import Loan from "typedef/Loan";

const TitleMessages = ({
  thereIsOffer,
  loan,
}: {
  thereIsOffer: boolean;
  loan?: Loan;
}) => {
  const theme = useTheme();
  const { user } = useUser();

  const offerMessage = `Your Debt to Income has been approved! Please confirm your offer and proceed.`;
  const noOfferMessage = `we are sorry but we are no longer able to extend a HELOC offer at this time based on the data in your application. We apologize for any inconvenience.`;

  return (
    <Box mt={3} mb={3}>
      {(user?.firstName || loan?.borrowerFirstName) && (
        <Typography
          variant="h1"
          color={theme.palette.text.primary}
          letterSpacing={-0.8}
          px={{ xs: 0, md: 0 }}
          fontSize={{ xs: "1.7rem", md: "2.4rem" }}
          fontFamily="Pro Display Bold"
          sx={{
            "& .highlight": {
              color: theme.palette.primary.main,
              marginRight: "10px",
              lineHeight: "5dvh",
              display: "block",
            },
            "& .secondary-text": {
              fontFamily: "Pro Display Regular",
              fontSize: { xs: "1.2rem", md: "1.5rem" },
              lineHeight: { xs: 1.2, md: 1.5 },
              display: "block",
            },
          }}
        >
          <span className="highlight">
            {thereIsOffer
              ? `Congrats ${user?.firstName ?? loan?.borrowerFirstName}! `
              : `${user?.firstName ?? loan?.borrowerFirstName}, `}
          </span>
          <span className="secondary-text">
            {thereIsOffer ? offerMessage : noOfferMessage}
          </span>
        </Typography>
      )}
    </Box>
  );
};

export default TitleMessages;
