import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import CButton from "components/CButton";
import CDialog from "components/CDialog";
import { FC } from "react";

interface ConfirmationDialogProps {
  open: boolean;
  close: () => void;
}

const confirmationDialog: FC<ConfirmationDialogProps> = ({ open, close }) => {
  return (
    <CDialog
      open={open}
      maxWidth="md"
      icon="warning"
      title="Someone from CX will research your application and contact you soon. Thank you!"
    >
      <Typography fontWeight={300} fontSize={{ md: "1.3rem" }} marginBottom={3}>
        You can now close this window.
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        gap="24px"
        margin="10px"
        paddingTop="20px"
      >
        <CButton
          fullWidth
          variant="contained"
          onClick={close}
          sx={{
            textTransform: "inherit",
            fontSize: 18,
            fontWeight: 500,
          }}
          name="confirmationDialog-close"
          aria-label="confirmationDialog-close"
        >
          Close
        </CButton>
      </Box>
    </CDialog>
  );
};

export default confirmationDialog;
