import { FC, forwardRef } from "react";
import NumberFormat from "react-number-format";
import CTextField from "components/CTextField";
import { TextFieldProps } from "@mui/material/TextField";

export interface CNumberFieldProps
  extends Omit<TextFieldProps, "error" | "ref"> {
  error?: string;
  value?: number | string;
  defaultValue?: number | string;
  type?: "text" | "tel" | "password";
  format?: string;
  allowEmptyFormatting?: boolean;
  isNumericString?: boolean;
  thousandSeparator?: boolean;
  allowNegative?: boolean;
  newRequired?: string;
  decimalScale?: number;
}

const CNumberField: FC<CNumberFieldProps> = forwardRef(
  ({ error, ...props }, ref) => {
    return (
      <NumberFormat
        getInputRef={ref}
        error={error}
        customInput={CTextField}
        {...props}
      />
    );
  },
);

export default CNumberField;
