import { Box } from "@mui/material";

const DevBanner = () => {
  const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === "[::1]" ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/,
      ),
  );

  const developmentStage = process.env.REACT_APP_ENV !== "prod" && !isLocalhost;

  return (
    <Box display="flex" justifyContent="center">
      {developmentStage && (
        <span
          style={{
            userSelect: "none",
            fontWeight: 550,
            fontFamily: "system-ui",
            fontSize: "16px",
            position: "fixed",
            textAlign: "center",
            top: "0",
            paddingLeft: "10px",
            paddingRight: "10px",
            paddingBottom: "3px",
            backgroundColor: "#e05f1d",
            color: "#FFF",
            zIndex: 999,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
          }}
        >
          DEVELOPMENT
        </span>
      )}
    </Box>
  );
};

export default DevBanner;
