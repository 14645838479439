import { DialogProps, Typography, useTheme } from "@mui/material";
import CButton from "components/CButton";
import CDialog from "components/CDialog";
import AuthCode from "react-auth-code-input";
import useVerificationDialog from "./useVerificationDialog";
import "assets/Styles/authCode.scss";

export interface CVerificationDialogProps extends DialogProps {
  phoneNumber?: string;
  email?: string;
  onConfirmed(): void;
  defaultVerificationType?: "sms";
}

const CVerificationDialog: React.FC<CVerificationDialogProps> = ({
  defaultVerificationType,
  phoneNumber,
  onConfirmed,
  email,
  ...props
}) => {
  const verificationDialog = useVerificationDialog({
    defaultVerificationType,
    onConfirmed,
    email,
    phoneNumber,
  });
  const phoneNumberFormated = `${phoneNumber?.slice(0, 2)} ${phoneNumber?.slice(
    2,
    5,
  )}-${phoneNumber?.slice(5, 8)}-${phoneNumber?.slice(8, 12)}`;
  const theme = useTheme();
  return (
    <CDialog
      maxWidth="xs"
      icon="shield"
      title={
        "In order to verify your identity, we will send you a verification code to your phone number."
      }
      description={`Code sent to ${
        verificationDialog.verificationType === "sms"
          ? phoneNumberFormated
          : email
      }`}
      {...props}
    >
      {props.open && (
        <AuthCode
          autoFocus
          length={6}
          inputClassName={"authCodeInput"}
          containerClassName={"authCodeContainer"}
          allowedCharacters="numeric"
          onChange={verificationDialog.onChangeCode}
          disabled={verificationDialog.loading}
        />
      )}
      {verificationDialog.error && (
        <Typography marginTop={3} color={theme.palette.error.main}>
          {verificationDialog.error}
        </Typography>
      )}
      <CButton
        loading={verificationDialog.resendingCode}
        fullWidth
        sx={{
          background: "none !important",
          width: "fit-content",
          margin: "auto",
        }}
        onClick={verificationDialog.resendCode}
        name="verificationDialog-resendCode"
      >
        Resend code
      </CButton>

      <CButton
        loading={verificationDialog.resendingCode}
        fullWidth
        variant="outlined"
        onClick={verificationDialog.togglerVerificationType}
        name="verificationDialog-verifyInstead"
      >
        Verify with my{" "}
        {verificationDialog.verificationType !== "sms"
          ? "phone number "
          : "email "}
        instead
      </CButton>
    </CDialog>
  );
};

export default CVerificationDialog;
