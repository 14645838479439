import API from "utils/API";
import { usePlaidLink } from "react-plaid-link";
import { useCallback, useEffect, useState } from "react";
import useTracker from "components/CTracker/useTrackerContext";

const usePlaidFunding = () => {
  const [token, setToken] = useState<string | null>(null);
  const isOAuthRedirect = window.location.href.includes("?oauth_state_id=");
  const { loan } = useTracker();
  const isWaitingForMicroTransactions =
    loan?.borrowerSteps?.fundingVerification.pendingVerification;
  const { open, ready, exit } = usePlaidLink({
    token,
    receivedRedirectUri: isOAuthRedirect ? window.location.href : undefined,
    onSuccess: async (public_token, metadata) => {
      await API.post({
        url: "/save-bank-account/add",
        data: {
          public_token,
          accounts: metadata.accounts,
          loanId: loan?.id,
        },
      });
    },
  });

  const load = useCallback(async () => {
    if (token && ready) open();
    if (token && !ready) return;
    const response = await API.get<{ link_token: string }>(
      `/get-plaid/auth/link-token?loanId=${loan?.id}`,
    );
    if ("error" in response) {
      alert(response.error);
    } else {
      const { link_token } = response.data;
      setToken(link_token);
      localStorage.setItem("oauth_plaid_link_token", link_token);
    }
  }, [loan?.id, ready, open, token]);

  useEffect(() => {
    if (token) {
      open();
    }
    return () => {
      exit();
    };
  }, [ready, exit, open, token]);

  useEffect(() => {
    if (isOAuthRedirect) {
      setToken(localStorage.getItem("oauth_plaid_link_token"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { load, isWaitingForMicroTransactions };
};

export default usePlaidFunding;
