import { Box, Typography } from "@mui/material";
import CButton from "components/CButton";
import { useDebtToIncomePlaid } from "./useDebtToIncomePlaid";
import { useDebtToIncomeModal } from "../useDebtToIncomeModal";
import color from "context/MaterialUITheme/color";
import Loan from "typedef/Loan";

interface DebtToIncomeProps {
  handleButton: () => void;
  loading: boolean;
  step?: Loan["borrowerSteps"] | Loan["coborrowerSteps"];
}

export const DebtToIncomePlaid = ({
  handleButton,
  loading,
  step,
}: DebtToIncomeProps) => {
  const { styles } = useDebtToIncomePlaid();
  const { styles: detbToIncomeStyles } = useDebtToIncomeModal();

  const isErrorMessage = step?.incomeVerification?.errorMessagePlaid;

  return (
    <Box sx={styles.containerPlaid}>
      <Typography
        component={"h5"}
        variant="h5"
        color={color.black}
        fontWeight={700}
      >
        Link your Personal Checking Account (Fast!)
      </Typography>
      <Typography sx={{ ...detbToIncomeStyles.text, mb: 2 }}>
        Connect your personal checking account(s) via Plaid. The process is
        simple, secure, and provides instant results! Please note that the
        following banks are currently not available: National Bank, TD Bank, and
        Citizens. Everyone listed on the connected bank account must also be
        added to your HELOC application.
      </Typography>

      <CButton
        variant="contained"
        loading={loading}
        sx={detbToIncomeStyles.buttonStyles}
        onClick={handleButton}
        name="debtToIncomePlaid-linkPersonalAccount"
        aria-label="debtToIncomePlaid-linkPersonalAccount"
      >
        Link Personal Account
      </CButton>

      {isErrorMessage &&
        isErrorMessage !== "Processing… Please give us a moment" && (
          <Typography
            color={color.red}
            sx={{
              p: 1,
            }}
          >
            {isErrorMessage}
          </Typography>
        )}
    </Box>
  );
};
