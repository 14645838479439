import { styled } from "@mui/system";

export const DayButton = styled("button")({
  height: 40,
  width: 40,
  fontSize: 18,
  padding: 0,
  borderRadius: 20,
  textAlign: "center",
  cursor: "pointer",
  border: "none",
  background: "none",
});
