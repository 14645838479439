import { Box, Skeleton, Typography, useTheme } from "@mui/material";
import CButton from "components/CButton";
import { FC, useState } from "react";
import EditLoanAmount from "screens/TypeOfCreditLine/EditLoanAmount";
import { HELOCFormProps } from "typedef/HELOCFormProps";
import dollarFormatter from "utils/FormatterDollar";

const LoanRequest: FC<HELOCFormProps> = ({ handleRrefresh, loan }) => {
  const theme = useTheme();
  const [editLoanAmountOpen, setEditLoanAmountOpen] = useState(false);
  const property = loan?.property;
  let bpoPending = false;

  if (
    loan?.borrowerSteps?.appraisalVerification?.appraisalType === "BPO" &&
    loan?.borrowerSteps?.appraisalVerification?.status === "analyzing"
  ) {
    bpoPending = true;
  }
  const parsedLoanAmount = dollarFormatter.format(
    loan?.finalOffer?.amount ?? 0,
  );

  return (
    <>
      <Box
        display="grid"
        gridTemplateColumns="1fr 1fr"
        alignItems="center"
        sx={{ backgroundColor: theme.palette.background.paper, p: 2 }}
      >
        <Typography
          variant="h6"
          fontFamily="Pro Display Regular"
          sx={{ fontSize: { xs: "1rem", md: "1.3rem", textWrap: "nowrap" } }}
        >
          Loan Request:
        </Typography>
        {loan?.finalOffer?.amount ? (
          <Box
            sx={{
              flexDirection: { xs: "column", md: "row" },
              alignItems: { xs: "flex-start", md: "center" },
            }}
            display="flex"
            alignItems="center"
          >
            <Typography
              color={theme.palette.text.primary}
              variant="h5"
              fontFamily="Pro Display Bold"
              sx={{ fontSize: { xs: "1rem", md: "1.3rem" } }}
            >
              {parsedLoanAmount}
            </Typography>
            {!bpoPending && (
              <CButton
                sx={{
                  minHeight: 0,
                  minWidth: 0,
                  padding: 0,
                  ml: { xs: 0, md: 1 },
                  fontFamily: "Pro Display Regular",
                  fontSize: { xs: "0.9rem", md: "1.2rem" },
                }}
                onClick={() => setEditLoanAmountOpen(true)}
              >
                Edit
              </CButton>
            )}
          </Box>
        ) : (
          <Skeleton width="75%" height={"1.5rem"} />
        )}
      </Box>

      <EditLoanAmount
        endpoint="/change-offer-amount/final"
        open={editLoanAmountOpen}
        tnMaxOffer={
          // temporary TN validation
          loan?.initialOffer.tnMaxOffer
            ? loan?.initialOffer.tnMaxOffer
            : undefined
        }
        loanId={loan?.id}
        onSubmit={() => {
          if (handleRrefresh) {
            handleRrefresh();
          }
        }}
        onClose={() => {
          setEditLoanAmountOpen(false);
        }}
        state={property?.address?.components?.state_abbreviation}
        setEditLoanAmountOpen={setEditLoanAmountOpen}
      />
    </>
  );
};

export default LoanRequest;
