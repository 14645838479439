import API from "utils/API";
import Loan from "typedef/Loan";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useCallback, useEffect } from "react";
import { LSLoanOfficerToken } from "CONST";
import useUser from "context/UserCustomer/useUserCustomerContext";

export type ValuesSliderProps = {
  minDraw?: number;
  maxDraw?: number;
};
export type ValuesHELOC = {
  APR?: number;
  initialDrawAmount?: number;
  interestRate?: number;
  maximumInitialDraw?: number;
  minimumInitialDraw?: number;
  monthlyInterestCharge?: number;
  amount?: number;
};

const useInitialHelocOffer = (isAdmin?: boolean) => {
  const navigate = useNavigate();
  const params = useParams();
  const currentAdminLoanId = params?.loanId;
  const [loan, setLoan] = useState<Loan>();
  const [loading, setLoading] = useState(true);
  const [isAccepting, setIsAccepting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [newValuesSlider, setNewValuesSlider] = useState<ValuesHELOC>();
  const [initialValuesSliderHELOC, setInitialValuesSliderHELOC] =
    useState<ValuesSliderProps>();
  const [refreshLoan, setRrefreshLoan] = useState(0);
  const [marks, setMarks] = useState<
    {
      value: number;
    }[]
  >([]);
  const { selectedLoanId } = useUser();
  const handleRrefresh = () => {
    setRrefreshLoan((prev) => prev + 1);
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      const response =
        isAdmin && currentAdminLoanId
          ? await API.get<Loan>(
              `/admin-impersonate/get/loan/${currentAdminLoanId}`,
            )
          : await API.get<Loan>(`/get/my-loan?id=${selectedLoanId}`);

      if (!("error" in response)) {
        setLoan(response.data);
      }
    })();
    setLoading(false);
  }, [refreshLoan, isAdmin, currentAdminLoanId, selectedLoanId]);

  useEffect(() => {
    setLoading(true);
    setInitialValuesSliderHELOC(undefined);
    setNewValuesSlider(undefined);
    setMarks([]);
    (async () => {
      const response =
        isAdmin && currentAdminLoanId
          ? await API.get<ValuesSliderProps>(
              `/admin-impersonate/get/get-heloc-slider-values/value?loanId=${currentAdminLoanId}`,
            )
          : await API.get<ValuesSliderProps>(
              `/get/get-heloc-slider-values?loanId=${loan?.id}`,
            );
      if (!("error" in response)) {
        setInitialValuesSliderHELOC(response.data);
      }
    })();
    setLoading(false);
  }, [loan, isAdmin, currentAdminLoanId]);

  useEffect(() => {
    if (initialValuesSliderHELOC) {
      setLoading(true);
      (async () => {
        if (initialValuesSliderHELOC?.maxDraw && loan?.id) {
          let replaceString = "admin";

          const isLoanOfficer = localStorage.getItem(LSLoanOfficerToken);
          if (isLoanOfficer) {
            replaceString = "lo";
          }
          const recalculatedLoanResponse =
            isAdmin && currentAdminLoanId
              ? await API.post<ValuesHELOC>({
                  url: `/admin-impersonate/save-to-loan-${replaceString}/calculate-heloc-offer-initial?ignoreMaxDti=true`,
                  data: {
                    initialDrawAmount: initialValuesSliderHELOC?.maxDraw,
                    loanId: currentAdminLoanId,
                  },
                })
              : await API.post<ValuesHELOC>({
                  url: `/calculate-heloc-offer/initial?ignoreMaxDti=true`,
                  data: {
                    initialDrawAmount: initialValuesSliderHELOC?.maxDraw,
                    loanId: loan?.id,
                  },
                });

          if ("error" in recalculatedLoanResponse) {
            setShowErrorMessage(true);
            setErrorMessage(recalculatedLoanResponse.error);
          } else {
            setShowErrorMessage(false);
            setErrorMessage("");
            setNewValuesSlider(recalculatedLoanResponse.data);
          }
        }
      })();
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [loan, initialValuesSliderHELOC, isAdmin, currentAdminLoanId]);

  useEffect(() => {
    if (loan) {
      const minOffer = initialValuesSliderHELOC?.minDraw ?? 0;
      const maxOffer = initialValuesSliderHELOC?.maxDraw ?? 0;
      const steps = 10.0;
      const jump = (maxOffer - minOffer) / steps;
      const padding = jump * 0.1;
      const paddedMinOffer = minOffer + padding;
      const paddedMaxOffer = maxOffer - padding * 2;
      const paddedJump = (paddedMaxOffer - paddedMinOffer) / steps;
      setMarks(
        Array.from({ length: steps + 1 }).map((_, index) => ({
          value: paddedMinOffer + paddedJump * index,
        })),
      );
    }
    // eslint-disable-next-line
  }, [initialValuesSliderHELOC]);

  const recalculateOffer = async (
    _: unknown,
    incomingNewAmount: number | number[],
  ) => {
    if (Array.isArray(incomingNewAmount)) return undefined;
    setLoading(true);
    let newAmount = incomingNewAmount;
    if (incomingNewAmount > (initialValuesSliderHELOC?.maxDraw ?? 0))
      newAmount = initialValuesSliderHELOC?.maxDraw ?? 0;
    if (incomingNewAmount < (initialValuesSliderHELOC?.minDraw ?? 0))
      newAmount = initialValuesSliderHELOC?.minDraw ?? 0;
    const recalculatedLoanResponse = await API.post<ValuesHELOC>({
      url: `/calculate-heloc-offer/initial?ignoreMaxDti=true`,
      data: { initialDrawAmount: newAmount, loanId: loan?.id },
    });
    if ("error" in recalculatedLoanResponse) {
      setShowErrorMessage(true);
      setErrorMessage(recalculatedLoanResponse.error);
    } else {
      setShowErrorMessage(false);
      setErrorMessage("");
      setNewValuesSlider(recalculatedLoanResponse?.data);
    }
    setLoading(false);
  };

  const saveOffer = useCallback(async () => {
    setIsAccepting(true);
    const response = await API.post({
      url: `/accept-offer/initial`,
      data: {
        initialDrawAmount: newValuesSlider?.initialDrawAmount,
        loanId: loan?.id,
      },
    });
    if ("error" in response) {
      setErrorMessage(response.error);
    } else {
      navigate(`/borrower-tracker?loanId=${selectedLoanId}`, { replace: true });
    }
    setIsAccepting(false);
    // eslint-disable-next-line
  }, [navigate, loan, initialValuesSliderHELOC, newValuesSlider]);

  return {
    loan,
    marks,
    loading,
    saveOffer,
    isAccepting,
    errorMessage,
    newValuesSlider,
    showErrorMessage,
    initialValuesSliderHELOC,
    recalculateOffer: recalculateOffer,
    handleRrefresh,
  };
};

export default useInitialHelocOffer;

export type InitialHELOCOffer = ReturnType<typeof useInitialHelocOffer>;
