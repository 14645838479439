const AMOUNT_OF_MONTHS_IN_A_YEAR = 12;
const AMOUNT_OF_DECIMALS = 2;

const getEstimatedMonthlyPayment = (
  loanAmount: number,
  interestRate: number,
) => {
  const annualPayment = loanAmount * interestRate;
  return (annualPayment / AMOUNT_OF_MONTHS_IN_A_YEAR).toFixed(
    AMOUNT_OF_DECIMALS,
  );
};

export default getEstimatedMonthlyPayment;
