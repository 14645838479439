import API from "utils/API";
import Loan from "typedef/Loan";
import { useCallback, useEffect, useState } from "react";
import useUser from "context/UserCustomer/useUserCustomerContext";
import { useParams } from "react-router-dom";

const useLoan = ({ isAdmin }: { isAdmin: boolean }) => {
  const [loan, setLoan] = useState<Loan>();
  const [loading, setLoading] = useState(false);
  const { selectedLoanId } = useUser();
  const params = useParams();
  const currentAdminLoanId = params?.loanId;
  const getAndSetCoborrowerLoan = useCallback(async () => {
    setLoading(true);
    try {
      const response =
        isAdmin && currentAdminLoanId
          ? await API.get<Loan>(
              `/admin-impersonate/get/loan/${currentAdminLoanId}`,
            )
          : await API.get<Loan>(`/get/my-loan?id=${selectedLoanId}`);
      if ("error" in response) {
        console.error(response.error);
        alert(response.error);
      } else {
        setLoan(response.data);
      }
    } catch (error) {
      console.error(error);
      alert(error);
    }
    setLoading(false);
  }, [currentAdminLoanId, isAdmin, selectedLoanId]);

  const refetchLoan = useCallback(async () => {
    await getAndSetCoborrowerLoan();
  }, [getAndSetCoborrowerLoan]);

  useEffect(() => {
    getAndSetCoborrowerLoan();
  }, [getAndSetCoborrowerLoan]);

  return { loan, refetchLoan, loading };
};

export default useLoan;
