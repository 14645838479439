import { LoansListProps } from "./UserTable";

const getRedirectMessage = ({
  loan,
  isBorrower,
}: {
  loan?: LoansListProps;
  isBorrower: boolean;
}) => {
  const goToInitialOffer =
    !loan?.borrowerFlags?.initialOfferAccepted && isBorrower;

  const goToSignUpFormB2 =
    !isBorrower && loan?.coborrowerInviteCode && !loan?.coborrowerSteps;

  const shouldProcessLoan = loan?.submittedByLO === false;

  if (shouldProcessLoan) {
    return "Go to submission page";
  } else if (loan?.isFunded || loan?.isArchived) {
    return "Go Home Monitor";
  } else if (goToSignUpFormB2) {
    return "Go to Sign Up";
  } else if (goToInitialOffer) {
    return "Go to Offer";
  } else {
    return "Go To Tracker";
  }
};

export default getRedirectMessage;
