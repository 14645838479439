import useUser from "context/UserCustomer/useUserCustomerContext";
import { useEffect, useState } from "react";
import API from "utils/API";

type Paginate = {
  page: number;
  pageItems: paginateItem[];
  pageLimit: number;
  loading: boolean;
};

type Address = {
  line1: string;
  locality: string;
  region: string;
  postal_code: string;
};

type paginateItem = {
  formatedDate: string;
  formatedAmount: string;
  state: string;
  metadata: object;
  paymentType: string;
  status: string;
  payTo: string;
  check: string;
  memo: string;
  address: Address | string;
};

export type PaymentsHook = {
  refetch: boolean;
  payments: Paginate;
  setRefetch: React.Dispatch<React.SetStateAction<boolean>>;
  currentPage: number;
  handleChange: (event: React.ChangeEvent<unknown>, value: number) => void;
};

const usePayments = (loanId?: string) => {
  const { userAdmin } = useUser();
  const [refetch, setRefetch] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  const [payments, setPayments] = useState<Paginate>({
    page: 1,
    pageItems: [],
    pageLimit: 1,
    loading: false,
  });

  useEffect(() => {
    const invokeUrl = userAdmin?.id ? "/admin-impersonate/invoke" : "/invoke";
    if (!loanId) {
      return;
    }
    setPayments((prev) => ({
      ...prev,
      loading: true,
    }));
    API.post<Paginate>({
      url: invokeUrl,
      data: {
        FunctionName: "paginateEntity",
        InvocationType: "RequestResponse",
        Service: "nftydoor-admin",
        Payload: {
          body: {
            page: currentPage,
            items: 10,
          },
          pathParameters: {
            entity: "payments",
          },
          queryStringParameters: {
            loanId: loanId,
          },
        },
      },
    })
      .then((result) => {
        if (!("error" in result) && "data" in result) {
          setPayments({
            ...result?.data,
            loading: false,
          });
        }
      })
      .catch(() => {
        setPayments((prev) => ({
          ...prev,
          loading: false,
        }));
      });
  }, [loanId, currentPage, refetch, userAdmin?.id]);

  return {
    refetch,
    payments,
    setRefetch,
    currentPage,
    handleChange,
  };
};

export default usePayments;
