import {
  Box,
  Checkbox,
  TableCell,
  TableRow,
  Theme,
  Button,
} from "@mui/material";
import React, { memo, useState } from "react";
import { LiabilitiesHardPull } from "../useDebts";
import ChangeDebtsInformationModal, {
  UpdateFields,
} from "../ChangeDebtsInformationModal";

const MAX_NUM_CHAR = 25;

interface DebtItemProps {
  row: LiabilitiesHardPull;
  index: number;
  disabled: boolean;
  handleSelectTradeLine: (
    e: React.ChangeEvent<HTMLInputElement>,
    row: LiabilitiesHardPull,
    index: number,
  ) => void;
  monthlyPayment: string;
  balanceAmount: string;
  isBorrower: boolean;
  loanId: string;
  theme?: Theme;
  handleRefreshData?: (updateFields: UpdateFields, index: number) => void;
  currentIndex: number;
}

const DebtItem = ({
  row,
  index,
  disabled,
  handleSelectTradeLine,
  monthlyPayment,
  balanceAmount,
  isBorrower,
  loanId,
  theme,
  handleRefreshData,
  currentIndex,
}: DebtItemProps) => {
  const [openEdit, setOpenEdit] = useState(false);
  return (
    <>
      <TableRow
        sx={{
          height: "6px",
        }}
      />
      <TableRow
        sx={{
          backgroundColor: disabled
            ? theme?.palette?.background?.default
            : row?.paydownStatus || row?.payoffStatus
              ? "#c7f9cc"
              : theme?.palette?.background?.paper,
          "& td, & th": {
            border: 0,
            margin: 0,
            padding: "3px",
          },
          "& td": {
            paddingLeft: "6px",
          },
          marginTop: "6px",
        }}
      >
        <TableCell
          component="th"
          scope="row"
          sx={{
            fontSize: { sm: "12px", md: "14px" },
            borderTopLeftRadius: "16px",
            borderBottomLeftRadius: "16px",
            color: disabled ? "grey" : "black",
          }}
        >
          <Box
            component="span"
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Checkbox
              name="tradelineCheckBox"
              disabled={disabled}
              checked={
                Boolean(row?.paydownStatus) || Boolean(row?.payoffStatus)
              }
              onChange={(e) => {
                handleSelectTradeLine(e, row, index);
              }}
              sx={{
                color: "black",
                paddingRight: "5px",
                paddingLeft: "0px",
                "&.Mui-checked": {
                  color: "green",
                },
              }}
            />
            {row?.creditorName}
          </Box>
        </TableCell>
        <TableCell
          align="inherit"
          sx={{
            fontSize: { sm: "12px", md: "14px" },
            color: disabled ? "grey" : "black",
          }}
        >
          {row?.accountType?.length > MAX_NUM_CHAR
            ? `${row?.accountType?.slice(0, MAX_NUM_CHAR)}...`
            : row?.accountType}
        </TableCell>
        <TableCell
          align="inherit"
          sx={{
            fontSize: { sm: "12px", xs: "14px" },
            color: disabled ? "grey" : "black",
          }}
        >
          {monthlyPayment}
        </TableCell>
        <TableCell
          align="inherit"
          sx={{
            borderTopRightRadius: "16px",
            borderBottomRightRadius: "16px",
            fontSize: "16px",
            color: disabled ? "grey" : "black",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>{balanceAmount}</Box>
            {!disabled && (
              <Button
                sx={{
                  padding: 0,
                  width: "40px",
                }}
                onClick={() => setOpenEdit(true)}
              >
                Edit
              </Button>
            )}
          </Box>
        </TableCell>
        <ChangeDebtsInformationModal
          open={openEdit}
          onClose={() => setOpenEdit(false)}
          tradeline={{
            accountNumber: row?.accountNumber,
            payto: row?.creditorName,
            paytoAddress: row?.address,
            id: row?.id,
            balance: row?.balance,
            monthlyPayment: row?.monthlyPayment,
          }}
          isBorrower={isBorrower}
          loanId={loanId}
          handleRefreshData={handleRefreshData}
          currentIndex={currentIndex}
        />
      </TableRow>
    </>
  );
};

export default memo(DebtItem);
