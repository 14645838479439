import { useState } from "react";
import Typography from "@mui/material/Typography";
import { DemographicDataDialog } from "./DemographicDataDialog";
import CTrackerTimelineItem from "components/CTimelineItem";
import useTracker from "components/CTracker/useTrackerContext";
import { TrackerStatusColor } from "typedef/TrackerStatusColor";
import DemographicsDataChecks from "typedef/DemographicsDataChecks";
import useDemographicsData, { getDemographicsData } from "./useDemographicData";

import API from "utils/API";
import { DemographicDataResponseType } from "typedef/DemographicDataForm";
import { InnerFormProps } from "./DemographicDataDialog/EthnicityOptions";

const DemographicDataVerification: React.FC<{
  trackerStatusColor: TrackerStatusColor;
}> = ({ trackerStatusColor }) => {
  const { loan, type } = useTracker();
  const isBorrower = type === "borrower";
  const step = isBorrower ? loan?.borrowerSteps : loan?.coborrowerSteps;
  const status = step?.demographicsVerification?.status ?? "pending";

  const message = step?.demographicsVerification?.message ?? "";
  const [demographicsDialogOpen, setDemographicsDialogOpen] = useState(false);
  const onClose = () => setDemographicsDialogOpen(false);
  const form = useDemographicsData();

  const onSubmitDemographics = form.handleSubmit(async (data) => {
    const validatedDemographicsData =
      getDemographicsData<DemographicsDataChecks>({
        demographicsData: data,
        form: form,
      });
    if ("error" in validatedDemographicsData) {
      form.setErrorMessage(validatedDemographicsData.message);
    } else {
      form.setErrorMessage(undefined);

      try {
        const response = await API.post<DemographicDataResponseType>({
          url: `/save-to-loan/demographics`,
          data: { loanId: loan?.id, ...validatedDemographicsData },
        });
        if ("error" in response) {
          form.setErrorMessage(response.error);
        } else {
          onClose();
        }
      } catch (unknownError) {
        form.setErrorMessage(String(unknownError));
        console.error(unknownError);
      }
    }
  });

  return (
    <CTrackerTimelineItem
      status={status}
      title="Demographics"
      tooltip="We collect demographic data as part of federal requirements. The Home Mortgage Disclosure Act (HMDA) is a federal law that requires lenders to share their mortgage data so it's possible to better determine and dismantle credit discrimination practices."
      {...(status === "analyzing" && {
        action: {
          message: "Add Demographics Data",
          onClick: () => setDemographicsDialogOpen(true),
        },
      })}
    >
      <Typography variant="h6" color={[trackerStatusColor[status]]}>
        {status === "analyzing" && message}
        {status === "failed" && message}
        {status === "success" && message}
      </Typography>
      <DemographicDataDialog
        open={demographicsDialogOpen}
        onClose={onClose}
        form={form as InnerFormProps & { errorMessage: string }}
        onSubmit={onSubmitDemographics}
      />
    </CTrackerTimelineItem>
  );
};

export default DemographicDataVerification;
