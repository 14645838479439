import { Container, Box, Typography, Paper, useTheme } from "@mui/material";
import { TitleComponent } from "./TitleComponent";

interface ErrorComponentProps {
  messageError?: string | null;
}
export const ErrorComponent = ({ messageError }: ErrorComponentProps) => {
  const theme = useTheme();
  return (
    <Container maxWidth="md">
      <TitleComponent title="Impersonate Borrower" />
      <Box
        component={Paper}
        sx={{
          width: "100%",
          height: "60vh",
          objectFfit: "cover",
          objectPosition: " 67% center",
          marginTop: "20px",
          padding: "20px",
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            paddingBottom: "20px",
          }}
        >
          <Typography
            mt={4}
            mb={5}
            variant="body1"
            lineHeight={1}
            letterSpacing={-0.8}
            margin={0}
            fontSize={"1.5rem"}
          >
            {messageError}
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};
