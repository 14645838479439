import { FC, useMemo } from "react";
import CDialog from "components/CDialog";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditBankAccount from "typedef/EditBankAccountProcess";
import Loan from "typedef/Loan";

type EditBankResultModalDialogProps = {
  open: boolean;
  onDismiss: () => void;
  editBankProcess: EditBankAccount | undefined;
  loan: Loan;
  error: boolean;
};

const EditBankResultModalDialog: FC<EditBankResultModalDialogProps> = ({
  open,
  onDismiss,
  editBankProcess,
  loan,
  error,
}) => {
  const theme = useTheme();
  const editBankContent = useMemo(() => {
    if (error) {
      return "Something went wrong. Please try again";
    }
    return !editBankProcess || !editBankProcess.pendingVerification
      ? `Your account ending in ***
          ${loan.borrowerSteps?.fundingVerification.mask} was added successfully`
      : `Your new account is pending verification Please check back in a day`;
  }, [editBankProcess, loan, error]);

  return (
    <CDialog
      fullWidth
      maxWidth="xs"
      scroll={"paper"}
      open={open}
      onClose={onDismiss}
      disableEscapeKeyDown
      hideBackdrop
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Box
        gap={2}
        width={"100%"}
        display={"flex"}
        alignItems={"start"}
        justifyContent={"start"}
        flexDirection={"column"}
      >
        <Box width={"100%"} display={"flex"} justifyContent={"space-between"}>
          <Typography fontFamily={"Pro Display Bold"} variant="h5">
            Edit bank
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onDismiss}
            sx={{
              padding: 0,
              color: theme.palette.text.disabled,
            }}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </Box>
        <Typography
          fontFamily={"Pro Display Bold"}
          variant="h6"
          textAlign={"left"}
        >
          {editBankContent}
        </Typography>
      </Box>
    </CDialog>
  );
};

export default EditBankResultModalDialog;
