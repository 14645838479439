import CButton from "components/CButton";

import heroImage from "assets/Images/hero-lenders.png";
import { Box, Container, Typography, useTheme } from "@mui/material";

export default function CBanner() {
  const theme = useTheme();
  return (
    <Box
      display={"flex"}
      bgcolor={"white"}
      justifyContent={"center"}
      alignItems={{ xs: "end", md: "center" }}
    >
      <img
        alt="Group of business people."
        src={heroImage}
        style={{
          width: "100%",
          height: "80vh",
          objectFit: "cover",
          objectPosition: "95%",
        }}
      />
      <Box
        sx={{
          width: "100%",
          height: "80vh",
          position: "absolute !important",
          display: { xs: "block", md: "none" },
          background:
            "repeating-linear-gradient(to bottom, rgba(245, 246, 252, 0.1), rgba(103, 103, 170, 1))",
        }}
      />

      <Container
        maxWidth="lg"
        sx={{
          position: "absolute",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box width={{ md: "50%", xs: "100%" }}>
          <Typography
            mb={2}
            variant="h2"
            color={theme.palette.background.paper}
            mt={{ md: 0, xs: "1.5rem" }}
            fontFamily="Pro Display Semibold"
            fontSize={{ md: "3.8rem", xs: "2rem" }}
          >
            Power Your Home <br />
            Equity Loan Business
          </Typography>
          <Typography
            my={3}
            variant="h6"
            color={theme.palette.background.paper}
            textAlign={"justify"}
          >
            Most lenders offer purchase and REFI loan products. With increasing
            interest rates, REFIs are less desirable today, and yet customers
            need a modern way to tap into their home equity to help manage their
            finances.
          </Typography>
          <Box mt={2} mb={{ xs: 4, md: 0 }}>
            <CButton
              variant="outlined"
              href="mailto:info@nftydoor.com?Subject=info@nftydoor.com"
              sx={{
                mt: 3,
                "-webkit-box-shadow": "0px 5px 28px -3px rgba(0,0,0,0.58)",
                boxShadow: "0px 5px 28px -3px rgba(0,0,0,0.58)",
                width: { xs: "100%", md: "50%" },
              }}
            >
              Become a Partner
            </CButton>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
