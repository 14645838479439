import CTracker from "components/CTracker";
import { TrackerContextProvider } from "components/CTracker/useTrackerContext";
import useUserCustomerContext from "context/UserCustomer/useUserCustomerContext";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";

const BorrowerTracker: React.FC = () => {
  const { loan, user, handleSelectLoan, selectedLoanId } =
    useUserCustomerContext();

  const [searchParams] = useSearchParams();
  const loanId = searchParams.get("loanId");
  const navigate = useNavigate();
  const isCoborrower = user && user.id === loan?.coborrowerId;

  useEffect(() => {
    if (loanId && !selectedLoanId) {
      handleSelectLoan(loanId);
    }

    if (loan && !loan?.borrowerFlags?.initialOfferAccepted && !isCoborrower) {
      navigate(`/type-of-credit-line?loanId=${loanId}`, {
        replace: true,
      });
    }
  }, [loanId, selectedLoanId, handleSelectLoan, loan, navigate, isCoborrower]);

  if (isCoborrower) {
    return (
      <Navigate to={`/coborrower-tracker?loanId=${loan?.id}`} replace={true} />
    );
  }

  return (
    <TrackerContextProvider>
      <CTracker isBorrower={true} />
    </TrackerContextProvider>
  );
};

export default BorrowerTracker;
