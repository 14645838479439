import { PrivateLabel } from "typedef/PrivateLabel";
import { useEffect, useState } from "react";
import API from "utils/API";

type Data = {
  firstName: string;
  lastName: string;
  licenseNumber: string;
  NMLS: number;
  sk: string;
  id: string;
};
type BluesageValidateResponse = {
  status: number;
  data: { message?: string; data?: [Data] };
};
const blueSageValidateLicense = async (
  state: string,
  loanOfficerId: string,
  privateLabel?: PrivateLabel,
) => {
  const response = await API.post({
    url: `/bluesage-validate-license`,
    data: {
      state: state,
      loanOfficerId: loanOfficerId,
      key: "7f12f5e9-1986-2022-af75-22e7936986bc",
      privateLabelId: privateLabel?.id,
    },
  });

  return response as unknown as BluesageValidateResponse;
};
export default blueSageValidateLicense;

export function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);
    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);
  return debouncedValue;
}
