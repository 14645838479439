import color from "context/MaterialUITheme/color";
import { styled } from "@mui/system";
import { Container, Typography } from "@mui/material";

const StyledContainer = styled(Container)({
  height: "100%",
  maxWidth: "md",
  maxHeight: "100%",
  minHeight: "60vh",
  alignSelf: "center",
  alignItems: "center",
  alignContent: "center",
  justifyContent: "center",
  display: "flex !important",
  padding: "0 0 0 0 !important",
});

const StyledTypography = styled(Typography)({
  color: color.black,
  marginTop: "15%",
  marginBottom: "15%",
  fontFamily: "Pro Display Semibold",
});

export { StyledContainer, StyledTypography };
