import Address from "typedef/Address";

function addressToString(incomingAddress?: Address) {
  const address = { ...incomingAddress };
  if (!address) return "";
  let whiteSpace = "";
  if (address.secondary) {
    if ((address.entries ?? 0) > 1) {
      address.secondary += " (" + address.entries + ")";
    }
    whiteSpace = " ";
  }
  if (address.street_line === "My address is not listed") {
    return address.street_line;
  } else {
    return `${address.street_line}${whiteSpace}${address.secondary} ${address.city}, ${address.state} ${address.zipcode}`;
  }
}

export default addressToString;
