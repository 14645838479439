const STUDENT_MULTIPLIER_TRADELINE = 0.01;

export const calculateMonthlyAmount = ({
  revolvingOrInstallment,
  accountType,
  monthlyPaymentAmount,
  amount1,
}: {
  revolvingOrInstallment: string;
  accountType: string;
  monthlyPaymentAmount: string;
  amount1: number;
}) => {
  let monthlyAmount = parseInt(monthlyPaymentAmount ?? "0", 10);

  const isStudentLoans =
    revolvingOrInstallment === "I" &&
    accountType === "12" &&
    monthlyPaymentAmount === "00000000";

  if (isStudentLoans) {
    monthlyAmount = amount1 * STUDENT_MULTIPLIER_TRADELINE;
  }

  return monthlyAmount;
};

export default calculateMonthlyAmount;
