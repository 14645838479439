import {
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  useTheme,
} from "@mui/material";
import { FC, Fragment, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import DemographicsDataChecks from "typedef/DemographicsDataChecks";

type InnerFormProps = UseFormReturn<DemographicsDataChecks, unknown>;

const Citizenship: FC<{ form: InnerFormProps }> = ({ form }) => {
  const theme = useTheme();
  const [currentCitizenship, setCurrentCitizenship] =
    useState<DemographicsDataChecks["citizenship"]>("");

  const handleCitizenshipChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentCitizenship(
      e.target.value as DemographicsDataChecks["citizenship"],
    );
    form.setValue(
      "citizenship",
      e.target.value as
        | "usCitizen"
        | "permanentResidentAlien"
        | "nonPermanentResidentAlien",
    );
  };

  return (
    <Fragment>
      <Box
        mt={2}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography variant="h5" color={theme.palette.text.primary}>
          Citizenship
        </Typography>
      </Box>
      <Box>
        <RadioGroup
          aria-label="citizenship"
          name="citizenship"
          value={currentCitizenship}
          onChange={handleCitizenshipChange}
        >
          <FormControlLabel
            value="US citizen"
            control={<Radio />}
            label="U.S. Citizen"
          />
          <FormControlLabel
            value="Permanent resident alien"
            control={<Radio />}
            label="Permanent Resident Alien"
          />
          <FormControlLabel
            value="Non permanent resident alien"
            control={<Radio />}
            label="Non-Permanent Resident Alien"
          />
        </RadioGroup>
      </Box>
    </Fragment>
  );
};

export default Citizenship;
