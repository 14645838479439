import {
  Box,
  Radio,
  RadioGroup,
  Typography,
  FormControlLabel,
  useTheme,
} from "@mui/material";
import Loan from "typedef/Loan";
import { FC, Fragment } from "react";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

export type Marks = Loan["initialOffer"][];

type MultipleMarksProps = {
  loan?: Loan;
  setMarkSelectIndex: (value: number) => void;
  markSelectIndex: number;
  marks: Marks;
};

const CMultipleMarksOffer: FC<MultipleMarksProps> = ({
  loan,
  marks,
  markSelectIndex,
  setMarkSelectIndex,
}) => {
  const theme = useTheme();
  return (
    <Fragment>
      <Typography
        variant="h5"
        color={theme.palette.text.primary}
        fontFamily={"Pro Display Bold"}
      >
        You requested{" "}
        <span style={{ color: theme.palette.primary.main }}>
          ${Math.floor(loan?.initialOffer?.amount ?? 0).toLocaleString("en-US")}
        </span>
        , please see some additional options below
      </Typography>
      <Box
        mt={1}
        display={"flex"}
        justifyContent={"space-between"}
        paddingRight={0.5}
        paddingLeft={0.5}
      >
        <Typography sx={{ opacity: 0.5 }}>Max Loan Amount</Typography>
        <Typography sx={{ opacity: 0.5 }}>Rates as low as</Typography>
      </Box>
      <RadioGroup
        onChange={(e) => {
          setMarkSelectIndex(Number(e.target.value));
        }}
      >
        {marks?.map((mark, index) => (
          <Box
            key={index}
            sx={{
              backgroundImage:
                "linear-gradient(to right, lightgray 50%, transparent 50%)",
              backgroundPosition: "0 60%",
              backgroundRepeat: "repeat-x",
              backgroundSize: "8px 1px",
              backgroundColor:
                index === markSelectIndex
                  ? theme.palette.background.default
                  : theme.palette.background.paper,
              borderRadius: index === markSelectIndex ? 4 : 0,
            }}
            display="flex"
            alignItems={"center"}
            justifyContent={"space-between"}
            fontFamily="Pro Display Semibold"
          >
            <Box
              sx={{
                backgroundColor:
                  index === markSelectIndex
                    ? theme.palette.background.default
                    : theme.palette.background.paper,
                borderRadius: index === markSelectIndex ? 4 : 0,

                paddingLeft: 1,
              }}
              minWidth={100}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <FormControlLabel
                value={index}
                control={
                  <Radio
                    checked={index === markSelectIndex}
                    checkedIcon={<CheckCircleRoundedIcon />}
                  />
                }
                label={`$${Math.floor(mark.amount).toLocaleString("en-US")}`}
              />
            </Box>
            <Box
              sx={{
                backgroundColor:
                  index === markSelectIndex
                    ? theme.palette.background.default
                    : theme.palette.background.paper,
                borderRadius: index === markSelectIndex ? 4 : 0,
                paddingRight: 1,
              }}
              display={"flex"}
              alignSelf={"stretch"}
            >
              <Box
                minWidth={120}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                gap={1}
              >
                <Typography fontFamily={"Pro Display Bold"}>
                  {(mark.interestRate * 100).toFixed(2)}%
                </Typography>
                <Typography>
                  <small>{(mark.APR * 100).toFixed(2)}% APR</small>
                </Typography>
              </Box>
            </Box>
          </Box>
        ))}
      </RadioGroup>
    </Fragment>
  );
};

export default CMultipleMarksOffer;
