import moment from "moment";
import { useState } from "react";
import Loan from "typedef/Loan";
import API from "utils/API";
const useClosingRon = ({ loan }: { loan: Loan }) => {
  const [isCloseNowModalOpen, setIsCloseNowModalOpen] = useState(false);
  const [isLoadingFinalDisc, setIsLoadingFinalDisc] = useState(false);
  const handleConfirmClosingNowModal = () => {
    setIsCloseNowModalOpen((prev) => !prev);
  };
  const handlSendFinalDisclosures = async () => {
    setIsLoadingFinalDisc(true);
    setIsCloseNowModalOpen(true);
    try {
      await API.post({
        url: "/invoke",
        data: {
          InvocationType: "RequestResponse",
          FunctionName: "scheduleClosingDate",
          Service: "nftydoor-app",
          Payload: {
            loanId: loan.id,
            userId: loan.borrowerId,
            date: moment().toISOString(),
            saveTime: false,
            generatedByBorrower: true,
          },
        },
      });
    } catch (error) {
      setIsLoadingFinalDisc(false);
    }
  };
  return {
    handlSendFinalDisclosures,
    handleConfirmClosingNowModal,
    isCloseNowModalOpen,
    isLoadingFinalDisc,
  };
};
export default useClosingRon;
