import { CircularProgress, Divider, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { Fragment } from "react/jsx-runtime";
import IncomeDocUpload from "./IncomeDocUpload/IncomeDocUpload";
import React, { useMemo, useState } from "react";
import CoborrowerInvite from "components/CTracker/StepsDialogs/CoborrowerInvite";
import DebtsTable from "../DebtsTable";
import Loan from "typedef/Loan";
import BorrowerSteps from "typedef/BorrowerSteps";
import CoborrowerSteps from "typedef/CoborrowerSteps";
import CButton from "components/CButton";
import { incomeOptions } from "./IncomeDocUpload/incomeOptions";
import { IncomeSources } from "context/Invite/useInviteContext";
import useDebts from "../DebtsTable/useDebts";
import toPercentage from "utils/toPercentage";
import {
  getAnualIncomeByTypeSelection,
  StepName,
} from "../DebtsTable/getIncomeValues";
import { useGetDtiByCreditScore } from "utils/useGetDtiByCreditScore";
import ProgressBarSection from "./incomeProgressBar";

const MAX_ADDITIONAL_INCOME_ALLOWED = 5;
const HUNDRED = 100;

type SelfGuidedDTIProps = {
  isBorrower: boolean;
  loan?: Loan;
  step?: BorrowerSteps | CoborrowerSteps;
};

const SelfGuidedDTI: React.FC<SelfGuidedDTIProps> = ({
  loan,
  isBorrower,
  step,
}) => {
  const [isAdditional, setIsAdditional] = useState(false);
  const [open, setOpen] = useState(false);
  const [coborrowerInviteOpen, setCoborrowerInviteOpen] = useState(false);
  const [showDebtsTable, setShowDebtsTable] = useState(
    (loan?.initialOffer?.useProceeds === "Debt Consolidation" ||
      loan?.borrowerFlags?.tradelinesProposedAndDTIConditionallyApproved) ??
      false,
  );

  const handleUseDebts = useDebts({ loan, step, isBorrower });
  const theme = useTheme();
  const incomeSources = step?.incomeVerification?.incomeSources || {};
  const coborrowerAlreadyExists = loan?.coborrowerId !== undefined;
  const employmentType = isBorrower
    ? loan?.borrowerEmploymentType
    : loan?.coborrowerEmploymentType;

  const selfEmployedIncomes =
    step?.incomeVerification?.selfEmployed?.selfEmployedData;

  const filteredIncomeSourceOptions = incomeOptions.filter((option) => {
    const isAlreadyUploaded = selfEmployedIncomes?.some(
      (income) => income.incomeType === option.label && income?.incomeSource,
    );

    if (
      (employmentType === "Self Employed" &&
        option.value === "selfEmploymentIncome") ||
      (employmentType === "Full Time Employed/ W2" &&
        option.value === "salaryIncome")
    ) {
      return false;
    }

    const hasIncomeValue = incomeSources[option.value as keyof IncomeSources];

    return hasIncomeValue && !isAlreadyUploaded;
  });

  const borrowerUploadMaxAdditionalIncome =
    (
      selfEmployedIncomes?.filter(
        (income) => income?.uploadedByBorrower && !income.incomeSource,
      ) ?? []
    ).length >= MAX_ADDITIONAL_INCOME_ALLOWED;

  const handleOpenVerify = () => {
    setIsAdditional(false);
    setOpen(true);
  };
  const handleOpenAdditional = () => {
    setIsAdditional(true);
    setOpen(true);
  };
  const handleClose = () => {
    setIsAdditional(false);
    setOpen(false);
  };

  const proposedPaydownMessage = (
    <span>
      Your DTI has been reviewed and is approved
      <br />
      subject to the following payments
    </span>
  );

  const dtiGoodMessage = handleUseDebts.isProposedPaydown
    ? proposedPaydownMessage
    : "Great Job! Your DTI looks good";

  const b1AnnualIncome = useMemo(() => {
    let amount = loan?.borrowerSteps?.incomeVerification?.annualIncome;
    if (loan?.borrowerSteps?.incomeVerification?.incomeSelection) {
      amount = getAnualIncomeByTypeSelection({
        loan,
        selection: loan?.borrowerSteps?.incomeVerification?.incomeSelection,
        stepName: StepName.borrowerSteps,
      });
    }

    return amount ?? 0;
  }, [loan]);

  const b2AnnualIncome = useMemo(() => {
    let amount = loan?.coborrowerSteps?.incomeVerification?.annualIncome ?? 0;
    if (loan?.coborrowerSteps?.incomeVerification?.incomeSelection) {
      amount = getAnualIncomeByTypeSelection({
        loan,
        selection: loan?.coborrowerSteps?.incomeVerification?.incomeSelection,
        stepName: StepName.coborrowerSteps,
      });
    }

    return amount ?? 0;
  }, [loan]);

  const mdlB1 = loan?.borrowerSteps?.creditVerification?.monthlyDebtLoad ?? 0;
  const mdlB2 = loan?.coborrowerSteps?.creditVerification?.monthlyDebtLoad ?? 0;
  const totalMdl = mdlB1 + mdlB2;
  const loanMonthlyPayment =
    loan?.finalOffer?.monthlyPayment ?? loan?.initialOffer?.monthlyPayment ?? 0;
  const b1MonthlyIncome = b1AnnualIncome / handleUseDebts.TOTAL_MONTHS_IN_YEAR;
  const b2MonthlyIncome = b2AnnualIncome / handleUseDebts.TOTAL_MONTHS_IN_YEAR;
  const totalMonthlyIncome = b1MonthlyIncome + b2MonthlyIncome;

  const statedMonthlyIncome =
    (loan?.initialOffer?.annualIncome ?? 0) /
    handleUseDebts.TOTAL_MONTHS_IN_YEAR;

  const dti =
    (totalMdl + loanMonthlyPayment) /
    (totalMonthlyIncome ?? statedMonthlyIncome);

  const dtiItems = useGetDtiByCreditScore();
  const maxdti = dtiItems?.dti;
  if (maxdti === undefined) {
    return (
      <CircularProgress
        size={32}
        sx={{ display: "block", marginTop: "20px" }}
      />
    );
  }
  const isDtiTooHigh = dti > maxdti;
  const dtiColor = isDtiTooHigh
    ? theme.palette.error.main
    : theme.palette.success.main;
  const dtiColorToShow = showDebtsTable ? handleUseDebts.dtiColor : dtiColor;
  const isDtiTooHighToShow = showDebtsTable
    ? handleUseDebts.isDtiTooHigh
    : isDtiTooHigh;
  const dtiToShow = showDebtsTable ? handleUseDebts.dti : dti;

  const debtsCofirmed =
    loan?.[isBorrower ? "borrowerFlags" : "coborrowerFlags"]?.debtsCofirmed;

  const tradelinesProposedAndDTIConditionallyApproved =
    loan?.borrowerFlags?.tradelinesProposedAndDTIConditionallyApproved;

  const showAdditionalIncome =
    loan?.initialOffer?.useProceeds === "Debt Consolidation" &&
    !tradelinesProposedAndDTIConditionallyApproved
      ? !debtsCofirmed
      : true;

  return (
    <Fragment>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "10px 0",
          flexWrap: "wrap",
        }}
      >
        <Typography data-cy="dtiNumber" fontSize={48} color={dtiColorToShow}>
          DTI:
          {isFinite(dtiToShow) ? toPercentage(dtiToShow) : "-"}
        </Typography>
        <Typography data-cy="dtiMessage" fontSize={20} color={dtiColorToShow}>
          {isDtiTooHighToShow ? (
            <span>
              Your DTI Score is still too high,
              <br /> it needs to be at {maxdti * HUNDRED}% or below
            </span>
          ) : null}
          {!isDtiTooHighToShow ? dtiGoodMessage : null}
        </Typography>
      </Box>
      {handleUseDebts.showDTI && showAdditionalIncome && !debtsCofirmed ? (
        <>
          <ProgressBarSection step={step} isBorrower={isBorrower} />
          <Fragment>
            {!showDebtsTable && filteredIncomeSourceOptions?.length ? (
              <>
                <CButton
                  variant="contained"
                  disabled={!filteredIncomeSourceOptions?.length}
                  sx={{
                    marginTop: 2,
                    whiteSpace: "nowrap",
                    marginBottom: 1,
                    textTransform: "inherit",
                  }}
                  onClick={handleOpenVerify}
                >
                  Verify Income
                </CButton>
                <Divider
                  orientation="horizontal"
                  flexItem
                  sx={{ marginTop: "10px", marginBottom: "12px" }}
                />
              </>
            ) : null}

            {!showDebtsTable ? (
              <>
                <Typography
                  marginTop={3}
                  fontSize={20}
                  color={theme.palette.text.primary}
                >
                  You may be able to lower your DTI by paying off debt(s) from
                  the list below
                </Typography>
                <CButton
                  variant="outlined"
                  name="payoffDebt"
                  disabled={showDebtsTable}
                  sx={{
                    marginTop: 1,
                    whiteSpace: "nowrap",
                    marginBottom: 1,
                    textTransform: "inherit",
                  }}
                  onClick={() => setShowDebtsTable(true)}
                >
                  Payoff a Debt
                </CButton>
              </>
            ) : null}
            {showDebtsTable ? (
              <DebtsTable
                useDebts={handleUseDebts}
                loan={loan}
                isBorrower={isBorrower}
                step={step}
                dtiItems={dtiItems}
                showDebts={() => setShowDebtsTable(false)}
              />
            ) : null}
          </Fragment>

          {showDebtsTable ? (
            <Divider
              orientation="horizontal"
              flexItem
              sx={{ marginTop: "10px", marginBottom: "12px" }}
            />
          ) : null}
          <Typography variant="h6" sx={{ marginTop: "20px" }}>
            Or you can add more income
          </Typography>

          <Box
            display="flex"
            gap="10px"
            mt={1}
            flexWrap="wrap"
            width={{ sm: 500, md: "100%" }}
          >
            {showDebtsTable && filteredIncomeSourceOptions?.length ? (
              <CButton
                variant="contained"
                disabled={!filteredIncomeSourceOptions?.length}
                sx={{
                  whiteSpace: "nowrap",
                  marginBottom: 1,
                  textTransform: "inherit",
                }}
                onClick={handleOpenVerify}
              >
                Verify Income
              </CButton>
            ) : null}
            {isBorrower ? (
              <CButton
                variant="outlined"
                disabled={coborrowerAlreadyExists}
                sx={{
                  whiteSpace: "nowrap",
                  marginBottom: 1,
                  textTransform: "inherit",
                }}
                onClick={() => setCoborrowerInviteOpen(true)}
              >
                Add a Co-Borrower
              </CButton>
            ) : null}
            <CButton
              variant="outlined"
              sx={{
                whiteSpace: "nowrap",
                marginBottom: 1,
                textTransform: "inherit",
              }}
              disabled={borrowerUploadMaxAdditionalIncome}
              onClick={handleOpenAdditional}
            >
              Add Additional Income
            </CButton>
          </Box>
          <IncomeDocUpload
            isAdditional={isAdditional}
            open={open}
            handleClose={handleClose}
            isBorrower={isBorrower}
            step={step}
            incomeSourcesOptions={filteredIncomeSourceOptions}
          />
          <CoborrowerInvite
            open={coborrowerInviteOpen}
            onClose={() => setCoborrowerInviteOpen(false)}
            fromDTI={true}
          />
        </>
      ) : null}
    </Fragment>
  );
};

export default SelfGuidedDTI;
