import { Typography, useTheme } from "@mui/material";
import dollarFormatter from "utils/FormatterDollar";
import CTrackerTimelineItem from "components/CTimelineItem";
import useTracker from "components/CTracker/useTrackerContext";

const OfferInformation: React.FC = () => {
  const theme = useTheme();
  const { loan } = useTracker();
  const initialOffer = loan?.initialOffer;
  const finalOffer = loan?.finalOffer;
  const offerHasError = finalOffer?.error || initialOffer?.error;
  const errorMessage = offerHasError
    ? initialOffer?.message || finalOffer?.message
    : "";

  return (
    <CTrackerTimelineItem
      status={offerHasError ? "failed" : "success"}
      title="Offer"
      tooltip="Your offer has been calculated using your credit, loan balance, value, and requested loan amount. See FAQ’s for additional detail."
    >
      {initialOffer && !finalOffer && !offerHasError && (
        <>
          <Typography
            variant="h6"
            data-cy="loanAmountOffer"
            color={theme.palette.primary.main}
          >
            {loan.loanType === "HELOAN"
              ? `$${(initialOffer.amount ?? 0).toLocaleString(
                  "en-US",
                )} Loan with an interest rate of ${(
                  (initialOffer.interestRate ?? 0) * 100
                ).toFixed(2)}% and an APR of ${(
                  Math.round((initialOffer.APR ?? 0) * 10000) / 100
                ).toFixed(2)}%`
              : `$${(initialOffer.amount ?? 0).toLocaleString(
                  "en-US",
                )} Line of credit with an initial interest rate of ${(
                  (initialOffer.interestRate ?? 0) * 100
                ).toFixed(2)}%`}
          </Typography>
          {loan.property.address.components.state_abbreviation === "TN" ? (
            <Typography variant="h6" color={theme.palette.text.primary}>
              {loan.loanType === "HELOAN"
                ? `15 year term, (payments of ${dollarFormatter.format(
                    initialOffer.monthlyPayment ?? 0,
                  )})`
                : ` Initial draw amount of $${initialOffer?.initialDrawAmount?.toLocaleString(
                    "en-US",
                  )}
                  5 year interest only draw period followed by 10 year fully amortized repayment period`}
            </Typography>
          ) : (
            <Typography variant="h6" color={theme.palette.text.primary}>
              {loan.loanType === "HELOAN"
                ? `25 year term, (payments of ${dollarFormatter.format(
                    initialOffer.monthlyPayment ?? 0,
                  )})`
                : ` Initial draw amount of $${initialOffer?.initialDrawAmount?.toLocaleString(
                    "en-US",
                  )}
                  5 year interest only draw period followed by 25 year fully amortized repayment period`}
            </Typography>
          )}
        </>
      )}
      {initialOffer && finalOffer && (
        <>
          <Typography
            variant="h6"
            data-cy="loanAmountOffer"
            color={theme.palette.primary.main}
          >
            {loan.loanType === "HELOAN"
              ? `$${(finalOffer.amount ?? 0).toLocaleString(
                  "en-US",
                )} Loan with an interest rate of ${(
                  (finalOffer.interestRate ?? 0) * 100
                ).toFixed(2)}% and an APR of ${(
                  Math.round((finalOffer.APR ?? 0) * 10000) / 100
                ).toFixed(2)}%`
              : `$${(finalOffer.amount ?? 0).toLocaleString(
                  "en-US",
                )} Line of credit with an initial interest rate of ${(
                  (finalOffer.interestRate ?? 0) * 100
                ).toFixed(2)}%`}
          </Typography>
          {loan.property.address.components.state_abbreviation === "TN" ? (
            <Typography variant="h6" color={theme.palette.text.primary}>
              {loan.loanType === "HELOAN"
                ? `15 year term, (payments of ${dollarFormatter.format(
                    finalOffer?.monthlyPayment ?? 0,
                  )})`
                : ` Initial draw amount of $${finalOffer?.initialDrawAmount?.toLocaleString(
                    "en-US",
                  )}
                  5 year interest only draw period followed by 10 year fully amortized repayment period`}
            </Typography>
          ) : (
            <Typography variant="h6" color={theme.palette.text.primary}>
              {loan.loanType === "HELOAN"
                ? `25 year term, (payments of ${dollarFormatter.format(
                    finalOffer?.monthlyPayment ?? 0,
                  )})`
                : ` Initial draw amount of $${finalOffer?.initialDrawAmount?.toLocaleString(
                    "en-US",
                  )}
                  5 year interest only draw period followed by 25 year fully amortized repayment period`}
            </Typography>
          )}
        </>
      )}
      {offerHasError ? (
        <Typography variant="h6" color={theme.palette.text.primary}>
          {errorMessage}
        </Typography>
      ) : null}
    </CTrackerTimelineItem>
  );
};

export default OfferInformation;
