import { useTheme } from "@mui/material";
import CLink from "components/CLink";

const CContactusButton: React.FC<{
  pathname?: string;
  handleClick?: () => void;
  contactUsLink?: string;
}> = ({ pathname, handleClick, contactUsLink }) => {
  const isSelected = pathname === "/contactus";
  const theme = useTheme();
  return contactUsLink && contactUsLink?.length > 0 ? (
    <CLink
      target="_blank"
      handleClick={handleClick}
      to={contactUsLink}
      sx={{
        fontSize: { md: "1.4rem", xs: "2.4rem" },
        color: {
          md: isSelected
            ? theme.palette.primary.main
            : theme.palette.text.primary,
          xs: theme.palette.background.paper,
        },
      }}
    >
      Contact us
    </CLink>
  ) : (
    <CLink
      handleClick={handleClick}
      to="/contactus"
      sx={{
        fontSize: { md: "1.4rem", xs: "2.4rem" },
        color: {
          md: isSelected
            ? theme.palette.primary.main
            : theme.palette.text.primary,
          xs: theme.palette.background.paper,
        },
      }}
    >
      Contact us
    </CLink>
  );
};

export default CContactusButton;
