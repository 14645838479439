import { env } from "env";
import { useState } from "react";
import HomeIcon from "assets/Icons/HomeIcon";
import CTermsDialog from "../CTermsDialog";
import {
  Box,
  Link,
  useTheme,
  Container,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import useInvite from "context/Invite/useInviteContext";

const CFooter: React.FC = () => {
  const theme = useTheme();
  const pathname = window.location.pathname;

  const isLongFooter =
    pathname === "/" ||
    pathname === "/faqs" ||
    pathname === "/lenders" ||
    pathname === "*";

  const [termsIsOpen, setTermsIsOpen] = useState(false);

  const [enviromentSelected, setEnviroment] = useState(
    localStorage.getItem("currentEviroment") ?? "DEV",
  );
  const { privateLabel } = usePrivateLabel();
  if (pathname === "/faqs") {
    window.location.replace(
      "https://help.nftydoor.com/hc/en-us/categories/20824511618199-FAQs",
    );
  }
  const invite = useInvite();

  const showTextFooter =
    (!invite &&
      ["/", "/login", "/contactus", "/signup", "/faqs"].includes(pathname)) ||
    (invite &&
      (pathname.startsWith("/invite/") ||
        ["/", "/login", "/contactus", "/signup", "/faqs"].includes(pathname)));

  const footerBody = privateLabel?.borrowerPortalConfiguration
    ?.customHomePageContent?.footerBody ? (
    privateLabel.borrowerPortalConfiguration.customHomePageContent.footerBody
  ) : (
    <>
      Eligibility is subject to completion of an application and verification of
      home ownership, occupancy, title, income, employment, credit, home value,
      collateral, and underwriting requirements. Loan program terms and
      conditions will depend on underwriting and consumer credit
      characteristics. This is not a commitment to lend or offer of credit.
    </>
  );

  const footerLicensesLink = privateLabel?.borrowerPortalConfiguration
    ?.customHomePageContent?.footerLicensesLink
    ? privateLabel.borrowerPortalConfiguration.customHomePageContent
        .footerLicensesLink
    : "licenses";

  const footerExtra = privateLabel?.borrowerPortalConfiguration
    ?.customHomePageContent?.footerExtra
    ? privateLabel.borrowerPortalConfiguration.customHomePageContent.footerExtra
    : "Equal Housing Lender";

  const footerNYAuthorizedPL =
    privateLabel?.borrowerPortalConfiguration?.customHomePageContent
      ?.NYAuthorizedFooter;

  return (
    <Container
      maxWidth={isLongFooter ? "lg" : "md"}
      sx={{
        my: "2rem",
        width: { xs: "90vw" },
        margin: { xs: "2rem auto 0 auto" },
        maxWidth: "840px",
      }}
      disableGutters={useMediaQuery(theme.breakpoints.only("xs"))}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems={{ md: "start", xs: "center" }}
        flexDirection={{ md: "row", xs: "column" }}
      >
        <Box sx={{ alignItems: { xs: "center" } }}>
          <Typography>{privateLabel?.footerPolicy?.footerRow}</Typography>
        </Box>

        <Box display="flex">
          {privateLabel?.footerPolicy.privacyTerms === undefined ||
          privateLabel?.footerPolicy?.privacyTerms === "" ? (
            <Link
              onClick={() => setTermsIsOpen(true)}
              sx={{ mr: 1, cursor: "pointer", textDecoration: "none" }}
              tabIndex={0}
              role="button"
              data-acsb-clickable={true}
              data-acsb-navigable={true}
              data-acsb-now-navigable={true}
            >
              <Typography
                data-acsb-clickable={true}
                data-acsb-navigable={true}
                data-acsb-now-navigable={true}
              >
                Terms of Use
              </Typography>
            </Link>
          ) : (
            <Link
              href={privateLabel?.footerPolicy.privacyTerms}
              sx={{ mr: 1, cursor: "pointer", textDecoration: "none" }}
              tabIndex={0}
              role="button"
              data-acsb-clickable={true}
              data-acsb-navigable={true}
              data-acsb-now-navigable={true}
            >
              <Typography
                data-acsb-clickable={true}
                data-acsb-navigable={true}
                data-acsb-now-navigable={true}
              >
                Terms of Use
              </Typography>
            </Link>
          )}
          <Typography>|</Typography>
          <Link
            href={privateLabel?.footerPolicy.privacyPolicyLink}
            target="_blank"
            sx={{ mx: 1, cursor: "pointer", textDecoration: "none" }}
            tabIndex={0}
            role="button"
            data-acsb-clickable={true}
            data-acsb-navigable={true}
            data-acsb-now-navigable={true}
          >
            <Typography
              data-acsb-clickable={true}
              data-acsb-navigable={true}
              data-acsb-now-navigable={true}
            >
              Privacy Policy
            </Typography>
          </Link>
          <Typography>|</Typography>
          <Link
            href={footerLicensesLink}
            sx={{ ml: 1, cursor: "pointer", textDecoration: "none" }}
            tabIndex={0}
            role="button"
            data-acsb-clickable={true}
            data-acsb-navigable={true}
            data-acsb-now-navigable={true}
          >
            <Typography
              data-acsb-clickable={true}
              data-acsb-navigable={true}
              data-acsb-now-navigable={true}
            >
              Licenses
            </Typography>
          </Link>
        </Box>
      </Box>
      <Box
        my={1}
        width={"100%"}
        display="flex"
        justifyContent="space-between"
        alignItems={{ md: "start", xs: "center" }}
        flexDirection={{ md: "row", xs: "column" }}
      >
        <Typography
          color={theme.palette.text.secondary}
          variant="subtitle2"
          p={{ xs: 2, md: 0 }}
          textAlign={"justify"}
        >
          {footerBody}
        </Typography>
      </Box>
      <Box
        display="flex"
        width={"100%"}
        justifyContent="space-between"
        alignItems={{ md: "start", xs: "center" }}
        flexDirection={{ md: "row", xs: "column" }}
      >
        <Box
          display="flex"
          alignItems={{ md: "start", xs: "center" }}
          flexDirection={{ md: "row", xs: "column" }}
        >
          <Typography
            sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}
            noWrap={true}
          >
            {privateLabel?.footerPolicy?.footerRow}
          </Typography>
          <Typography mx={0.8} display={{ xs: "none", md: "block" }}>
            |
          </Typography>
          <Link
            sx={{
              cursor: "pointer",
              color: theme.palette.text.primary,
              textDecoration: "none",
            }}
            href={`https://www.nmlsconsumeraccess.org/TuringTestPage.aspx?ReturnUrl=/EntityDetails.aspx/COMPANY/${privateLabel?.NMLScompany}`}
            target="_blank"
            data-acsb-tooltip="NewWindow"
            data-acsb-clickable={true}
            data-acsb-navigable={true}
            data-acsb-now-navigable={true}
          >
            <span
              className="acsb-sr-only"
              data-acsb-sr-only={true}
              data-acsb-force-visible={true}
              aria-hidden={false}
              data-acsb-hidden={false}
            >
              New Window
            </span>
            <Typography noWrap={true}>
              NMLS ID #{privateLabel?.NMLScompany}
            </Typography>
          </Link>
          <Link
            sx={{
              ml: 1,
              cursor: "pointer",
              color: theme.palette.primary.main,
              textDecoration: "none",
            }}
            href="https://www.nmlsconsumeraccess.org"
            target="_blank"
          >
            <Typography noWrap={true}> www.nmlsconsumeraccess.org</Typography>
          </Link>
        </Box>
      </Box>

      <Box
        display={"flex"}
        mt={{ xs: 0, md: 1 }}
        sx={{
          alignItems: "center",
          justifyContent: { xs: "center", md: "left" },
        }}
      >
        <HomeIcon style={{ transform: "translateY(0.3rem)" }} />
        <Typography noWrap={true}>{footerExtra}</Typography>
      </Box>

      <Box
        my={1}
        width={"100%"}
        display="flex"
        justifyContent="space-between"
        alignItems={{ md: "start", xs: "center" }}
        flexDirection={{ md: "row", xs: "column" }}
      >
        <Typography
          color={theme.palette.text.primary}
          p={{ xs: 2, md: 0 }}
          textAlign={"justify"}
          variant="subtitle1"
          data-cy="footer"
        >
          {privateLabel?.footerPolicy?.NYstateAuthorizedSite === false &&
          showTextFooter
            ? footerNYAuthorizedPL
            : null}
        </Typography>
      </Box>
      {process.env.REACT_APP_ENV === "prod" ? null : (
        <Box>
          <Box display="flex" justifyContent="end">
            <Typography>
              Current version: {process.env.REACT_APP_CURRENT_VERSION}{" "}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="end">
            <select
              value={enviromentSelected}
              onChange={(e) => {
                localStorage.setItem("currentEviroment", e.target.value);
                setEnviroment(e.target.value);
                window.location.reload();
              }}
              onSelect={(e) => {
                console.log(e);
              }}
            >
              {Object.keys(env).map((items, index) => (
                <option key={index} value={items}>
                  {items}
                </option>
              ))}
            </select>
          </Box>
        </Box>
      )}

      <CTermsDialog
        open={termsIsOpen}
        handleClose={() => setTermsIsOpen(false)}
      />
    </Container>
  );
};

export default CFooter;
