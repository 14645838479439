import { Fragment } from "react";
import CButton from "components/CButton";
import {
  Box,
  useTheme,
  Skeleton,
  Container,
  Typography,
  FormControl,
  useMediaQuery,
} from "@mui/material";
import SingleMark from "./SingleMark";
import CLoader from "components/CLoader";
import useHeloanOffer from "./useHeloanOffer";
import CLoaderOffer from "components/CLoaderOffer";
import CMultipleMarksOffer from "components/CMultipleMarksOffer";
import useUser from "context/UserCustomer/useUserCustomerContext";

const HELOANOffer: React.FC = () => {
  const offer = useHeloanOffer();
  const theme = useTheme();
  const { user } = useUser();
  const loan = offer.loan;
  const property = loan?.property;
  const marks = offer?.marks?.slice(-1)?.[0]?.amount;

  return (
    <Fragment>
      <CLoader loading={offer && user && property && marks ? false : true} />
      <Container
        maxWidth="md"
        disableGutters={useMediaQuery(theme.breakpoints.only("xs"))}
      >
        <Box>
          <Typography
            mt={4}
            mb={5}
            variant="h1"
            lineHeight={1}
            color={theme.palette.text.primary}
            letterSpacing={-0.8}
            px={{ xs: 2, md: 0 }}
            fontSize={{ xs: "2rem", md: "3rem" }}
          >
            <span
              style={{ color: theme.palette.primary.main, marginRight: "10px" }}
            >
              Here's your new offer.{" "}
            </span>{" "}
            You’ve been pre-qualified for up to a $
            {offer?.marks[offer?.marks?.length - 1]?.amount?.toLocaleString(
              "en-US",
            )}{" "}
            loan with an interest rate of{" "}
            {(
              (offer?.marks[offer?.marks?.length - 1]?.interestRate ?? 0) * 100
            )?.toFixed(2)}
            % and an APR of{" "}
            {((offer?.marks[offer?.marks?.length - 1]?.APR ?? 0) * 100).toFixed(
              2,
            )}
            %
          </Typography>
        </Box>
        <Box
          mb={0.5}
          py={{ md: 5, xs: 2 }}
          px={{ md: 9, xs: 4 }}
          bgcolor={theme.palette.background.paper}
          borderRadius={10}
        >
          <Box py={{ xs: 2, md: 0 }}>
            <Typography fontWeight={200} sx={{ opacity: 0.9 }}>
              Subject Property
            </Typography>
            {property?.address?.delivery_line_1 ? (
              <Typography
                variant="h5"
                color={theme.palette.text.primary}
                fontFamily={"Pro Display Bold"}
              >
                {property?.address?.delivery_line_1}.{" "}
                {property?.address?.components?.city_name},{" "}
                {property?.address.components.state_abbreviation}.{" "}
                {property?.address.components.zipcode}
              </Typography>
            ) : (
              <Skeleton width="75%" height={"1.5rem"} />
            )}
          </Box>
        </Box>

        <Box
          mt={2}
          borderRadius={8}
          display={"flex"}
          py={{ md: 7, xs: 4 }}
          px={{ md: 9, xs: 4 }}
          bgcolor={theme.palette.background.paper}
          flexDirection={"column"}
        >
          <FormControl>
            {offer.loading && <CLoaderOffer />}
            {offer.marks.length > 1 && <CMultipleMarksOffer {...offer} />}
            {offer.marks.length === 1 && <SingleMark {...offer} />}
            <Box>
              <Box mt={4}>
                {offer.showErrorMessage && (
                  <Typography
                    variant="subtitle1"
                    display={"flex"}
                    my={1}
                    color={theme.palette.error.main}
                    alignSelf={"center"}
                    justifyContent={"center"}
                  >
                    {offer.errorMessage}
                  </Typography>
                )}
                <CButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  disabled={offer.loading}
                  onClick={offer.saveOffer}
                  loading={offer.isAccepting}
                >
                  Continue
                </CButton>
                <Box mt={3}>
                  <Typography mt={4} fontFamily={"Pro Display Light"}>
                    <span
                      style={{
                        fontFamily: "Pro Display Semibold",
                      }}
                    >
                      Note:
                    </span>{" "}
                    for loans $68,908 and above, we will require an appraisal
                    fee of
                    <span
                      style={{
                        fontFamily: "Pro Display Semibold",
                        color: theme.palette.primary.main,
                      }}
                    >
                      {" "}
                      $
                      {Number(loan?.initialOffer?.appraisalFees).toLocaleString(
                        "en-US",
                      )}
                    </span>
                    .
                  </Typography>
                </Box>
              </Box>
            </Box>
          </FormControl>
        </Box>
      </Container>
    </Fragment>
  );
};

export default HELOANOffer;
