const styles = {
  modal: {
    maxWidth: 600,
  },
  description: {
    fontSize: "1.2rem",
    margin: "1rem 0",
  },
  containerButton: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "1.2rem",
    gap: "1.5rem",
  },
  buttonStyles: {
    mt: 2,
    width: { md: "auto", xs: "100%" },
  },
};

export default styles;
