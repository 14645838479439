import {
  Box,
  useTheme,
  Container,
  Typography,
  useMediaQuery,
} from "@mui/material";
import SubmitCode from "./SubmitCode";
import { Fragment } from "react";

const ConfirmNewPassword = () => {
  const theme = useTheme();
  return (
    <Fragment>
      <Container
        maxWidth="md"
        disableGutters={useMediaQuery(theme.breakpoints.only("xs"))}
      >
        <Box>
          <Typography
            mt={4}
            mb={5}
            variant="h1"
            lineHeight={1}
            color={theme.palette.text.primary}
            letterSpacing={-0.8}
            pl={{ xs: 2, md: 0 }}
            fontSize={{ xs: "3.5rem", md: "6rem" }}
          >
            <span style={{ color: theme.palette.primary.main }}>Password</span>{" "}
            reset.
          </Typography>
        </Box>
        <SubmitCode />
      </Container>
    </Fragment>
  );
};

export default ConfirmNewPassword;
