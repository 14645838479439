import { Fragment } from "react";
import { useTheme } from "@mui/material";
import { StyledContainer, StyledTypography } from "./useStyles";

export default function LoanCompleted() {
  const theme = useTheme();

  return (
    <Fragment>
      <StyledContainer maxWidth="lg">
        <StyledTypography variant="h1">
          <span style={{ color: theme.palette.primary.main }}>404</span>
          <br />
          Page not found!
        </StyledTypography>
      </StyledContainer>
    </Fragment>
  );
}
