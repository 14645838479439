import { useTheme } from "@mui/material";
import CButton from "components/CButton";
import { useNavigate } from "react-router-dom";

const CForlendersButton: React.FC<{
  isLongHeader?: boolean;
  handleClick: () => void;
}> = ({ isLongHeader, handleClick }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <CButton
      onClick={() => {
        handleClick();
        navigate("/lenders", { replace: true });
      }}
      sx={{
        maxWidth: "240px",
        marginTop: { md: 0, xs: 2 },
        marginLeft: { md: 2, xs: 0 },
        backgroundColor: isLongHeader
          ? theme.palette.background.paper
          : theme.palette.background.default,
      }}
      variant="outlined"
    >
      For lenders
    </CButton>
  );
};

export default CForlendersButton;
