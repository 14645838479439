import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import RequestedDocItem from "../RequestedDocItem";
import { NeededDoc } from "components/CTracker/Steps/AddMissingDocsDialog";

type DocumentRequestProps = {
  docsToUpload: NeededDoc[];
  isCoborrower?: boolean;
};

const DocumentsRequest: React.FC<DocumentRequestProps> = ({
  docsToUpload,
  isCoborrower = false,
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (docsToUpload.length > 0) {
      setLoading(false);
    }
  }, [docsToUpload]);

  return (
    <Box>
      <Typography
        variant="h5"
        textAlign="justify"
        color={theme.palette.text.primary}
      >
        Document Request(s):
      </Typography>
      <Typography variant="body1" textAlign="justify">
        The following documents will need to be reviewed to process this loan.
        If you have them, please upload now.
        {!isCoborrower &&
          ` You may also upload them after
        accepting your offer.`}
      </Typography>
      {loading ? (
        <CircularProgress
          size={48}
          sx={{ color: "#6767aa", marginTop: "30px" }}
        />
      ) : (
        <Box>
          {docsToUpload.map((missingDoc, index) => (
            <RequestedDocItem
              key={missingDoc?.docName + index}
              position={index + 1}
              document={missingDoc}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default DocumentsRequest;
