import { IconButton, InputAdornment, Typography, Box } from "@mui/material";
import CDialog from "components/CDialog";
import { useConfirmSSNDOB } from "./useConfirmSSNDOB";

import { RemoveRedEye, VisibilityOff } from "@mui/icons-material";
import CTextField from "components/CTextField";
import moment from "moment";
import CNumberField from "components/CNumberField";

import CButton from "components/CButton";
import Loan from "typedef/Loan";

interface ConfirmSSNDOBModalProps {
  loan: Loan;
  open: boolean;
  handleOnClose: () => void;
}

export const ConfirmSSNDOB = ({
  loan,
  open,
  handleOnClose,
}: ConfirmSSNDOBModalProps) => {
  const {
    styles,
    loading,
    error,
    errorsInputs,
    user,
    showSocialSecurityNumber,
    toggleShowSocialSecurityNumber,
    handleUserChange,
    handleSubmit,
  } = useConfirmSSNDOB(loan, handleOnClose);

  return (
    <CDialog open={open} className="confirm-ssn-dob-modal">
      <Typography variant="h3">Confirm SSN/DOB</Typography>

      <Box component="form" onSubmit={handleSubmit}>
        <CNumberField
          fullWidth
          id={"socialSecurityNumber"}
          name={"socialSecurityNumber"}
          label={"Social Security Number"}
          type={showSocialSecurityNumber ? "text" : "password"}
          sx={{ mt: 2, width: "100%" }}
          format="###-##-####"
          placeholder="xxx-xx-xxxx"
          onChange={handleUserChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={toggleShowSocialSecurityNumber}>
                  {showSocialSecurityNumber ? (
                    <RemoveRedEye sx={styles.icon} />
                  ) : (
                    <VisibilityOff sx={styles.icon} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={user.socialSecurityNumber}
        />
        {errorsInputs.socialSecurityNumber && (
          <Typography color="error">
            {errorsInputs.socialSecurityNumber}
          </Typography>
        )}
        <CTextField
          fullWidth
          id="dateOfBirth"
          name="dateOfBirth"
          type="date"
          sx={{ mt: 2, width: "100%" }}
          onChange={handleUserChange}
          InputProps={{
            inputProps: {
              min: "1908-02-05",
              max: moment().subtract(18, "year").format("YYYY-MM-DD"),
            },
          }}
          format="YYYY-MM-DD"
          label="Date of Birth"
          value={user.dateOfBirth}
        />
        {errorsInputs.dateOfBirth && (
          <Typography color="error">{errorsInputs.dateOfBirth}</Typography>
        )}
        <Box sx={styles.containerButton}>
          <CButton
            type="button"
            loading={loading}
            variant="contained"
            sx={styles.buttonStyles}
            onClick={handleOnClose}
            name={"discard-confirm-ssn-dob"}
            aria-label={"discard-confirm-ssn-dob"}
          >
            Discard
          </CButton>
          <CButton
            type="submit"
            disabled={loading || Boolean(Object.keys(errorsInputs).length > 0)}
            loading={loading}
            variant="contained"
            sx={styles.buttonStyles}
            name={"send-confirm-ssn-dob"}
            aria-label={"send-confirm-ssn-dob"}
          >
            Confirm SSN/DOB
          </CButton>

          {error && <Typography color="error">{error}</Typography>}
        </Box>
      </Box>
    </CDialog>
  );
};
