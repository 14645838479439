import React, { useState, FC } from "react";
import {
  DialogActions,
  DialogContent,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  DialogProps,
  IconButton,
  useTheme,
  SelectChangeEvent,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useForm, Controller } from "react-hook-form";
import CButton from "components/CButton";
import CDialog from "components/CDialog";
import API from "utils/API";
import { Box } from "@mui/system";
interface OccupancyModalProps extends DialogProps {
  title: string;
  subtitle: string;
  selectOptions: string[];
  endpoint: string;
  loanAmount: number;
  loanId?: string;
  onClose?: () => void;
  loanValueSelectedByDefault?: string;
  handleRefreshLoan: () => void;
  handleRefreshOffer: () => void;
  loading: boolean;
  setIsLoadingOffer: React.Dispatch<React.SetStateAction<boolean>>;
  setHasError: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEditModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const EditModalWithSelect: FC<OccupancyModalProps> = ({
  title,
  subtitle,
  selectOptions,
  endpoint,
  loanId,
  loanAmount,
  onClose,
  loanValueSelectedByDefault,
  setIsLoadingOffer,
  loading,
  setHasError,
  setIsEditModal,
  handleRefreshLoan,
  handleRefreshOffer,
  ...props
}) => {
  const { control, handleSubmit } = useForm<{ newValue: string }>();
  const [selectedValue, setSelectedValue] = useState<string>(
    loanValueSelectedByDefault || "",
  );
  const theme = useTheme();
  const handleChange = (e: SelectChangeEvent<string>) => {
    setSelectedValue(e.target.value);
  };
  const onSubmit = handleSubmit(async (_data) => {
    setIsLoadingOffer(true);
    setIsEditModal(false);
    try {
      // update loan occupancy on the BD
      const responseLoanOccupancy = await API.post({
        url: endpoint,
        method: "POST",
        data: { newValue: selectedValue, loanId },
      });
      if ("error" in responseLoanOccupancy) {
        setHasError(true);
      } else {
        try {
          // recalculate inital offer
          const responseRecalculateInitialOffer = await API.post({
            url: "/change-offer-amount/initial",
            method: "PUT",
            data: {
              amount: loanAmount,
              loanId,
            },
          });
          if ("error" in responseRecalculateInitialOffer) {
            setHasError(true);
          } else {
            handleRefreshLoan();
            handleRefreshOffer();
          }
        } catch (error) {
          setHasError(true);
        }
      }
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoadingOffer(false);
    }
  });

  return (
    <CDialog {...props} maxWidth="xs" onClose={onClose}>
      <DialogContent>
        <FormControl fullWidth>
          <Box display="flex" alignItems="center">
            <Typography
              variant="h5"
              fontSize={{ xs: 20, md: 30 }}
              textAlign="left"
              fontFamily={"Pro Display Semibold"}
            >
              {title}{" "}
            </Typography>
            <IconButton onClick={onClose}>
              <Close fontSize="large" htmlColor={theme.palette.text.disabled} />
            </IconButton>
          </Box>
          <InputLabel sx={{ textAlign: "left", mt: "16px" }}>
            {subtitle}
          </InputLabel>
          <Controller
            name="newValue"
            control={control}
            defaultValue={loanValueSelectedByDefault}
            render={({ field }) => (
              <Select
                {...field}
                value={selectedValue}
                labelId="select-label"
                label="Select"
                onChange={handleChange}
              >
                {selectOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          width="100%"
        >
          <CButton
            onClick={onSubmit}
            type="submit"
            variant="contained"
            fullWidth
            sx={{ paddingY: "19px", marginTop: 2 }}
            loading={loading}
          >
            Save changes
          </CButton>
          <CButton
            onClick={onClose}
            type="reset"
            variant="outlined"
            fullWidth
            sx={{ paddingY: "19px", marginTop: 2 }}
          >
            Discard
          </CButton>
        </Box>
      </DialogActions>
    </CDialog>
  );
};
export default EditModalWithSelect;
