import API from "utils/API";
import Loan from "typedef/Loan";
import HELOCOffer from "./HELOCOffer";
import HELOANOffer from "./HELOANOffer";
import CLoader from "components/CLoader";
import { Fragment, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import useUser from "context/UserCustomer/useUserCustomerContext";

interface FinalOfferProps {
  isAdmin?: boolean;
}

const FinalOffer = ({ isAdmin }: FinalOfferProps) => {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { selectedLoanId, setSelectedLoanId, handleSelectLoan } = useUser();
  const currentAdminLoanId = params?.loanId;
  const loanIdParam = searchParams.get("loanId");

  const [loan, setLoan] = useState<Loan>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loanIdParam && !selectedLoanId) {
      handleSelectLoan(loanIdParam, isAdmin);
    }
  }, [
    loanIdParam,
    selectedLoanId,
    setSelectedLoanId,
    loan,
    handleSelectLoan,
    isAdmin,
  ]);

  useEffect(() => {
    setLoading(true);

    (async () => {
      setLoading(true);
      const loanResponse =
        isAdmin && currentAdminLoanId
          ? await API.get<Loan>(
              `/admin-impersonate/get/loan/${currentAdminLoanId}`,
            )
          : await API.get<Loan>(`/get/my-loan?id=${selectedLoanId}`);

      if ("error" in loanResponse) {
        return setLoading(false);
      } else {
        setLoan(loanResponse.data);
        setLoading(false);
      }
    })();
  }, [isAdmin, currentAdminLoanId, selectedLoanId]);

  return (
    <Fragment>
      <CLoader loading={loading} />
      {loan && loan.loanType === "HELOAN" && <HELOANOffer />}
      {loan && loan.loanType === "HELOC" && <HELOCOffer />}
    </Fragment>
  );
};

export default FinalOffer;
