import API from "utils/API";
import { NPEForm } from ".";
import { SubmitHandler } from "react-hook-form";
import useTracker from "components/CTracker/useTrackerContext";
import { useCallback, useEffect, useMemo, useState } from "react";

const useNPEAnnouncement = () => {
  const {
    loan,
    property,
    setIsModalOpen,
    coborrowerInviteIsOpen,
    setCoborrowerInviteIsOpen,
  } = useTracker();
  const [emailError, setEmailError] = useState<string>();
  const propertyNPE = useMemo(() => property?.NPE, [property?.NPE]);
  const b2Exists = loan?.coborrowerId;
  const isMultipleProperty = useMemo(
    () => property?.isMultipleProperty,
    [property?.isMultipleProperty],
  );
  const [openAnnouncement, setOpenAnnouncement] = useState(false);
  const [openNPEForm, setOpenNPEForm] = useState(false);

  useEffect(() => {
    if (!propertyNPE || b2Exists) {
      setIsModalOpen(false);
    } else if (
      propertyNPE &&
      !isMultipleProperty &&
      !loan?.borrowerFlags?.offeredAddingNPEAsCoborrower &&
      !coborrowerInviteIsOpen
    ) {
      setTimeout(() => setOpenAnnouncement(true), 500);
      setIsModalOpen(true);
    }
  }, [
    isMultipleProperty,
    propertyNPE,
    b2Exists,
    setIsModalOpen,
    loan?.borrowerFlags?.offeredAddingNPEAsCoborrower,
    coborrowerInviteIsOpen,
  ]);

  const handleSelectNo = async () => {
    setOpenAnnouncement(false);
    setTimeout(() => setOpenNPEForm(true), 500);
  };

  const handleAccept = async () => {
    setOpenAnnouncement(false);
    setTimeout(() => setCoborrowerInviteIsOpen(true), 500);
  };

  const handleCloseNPEForm = () => {
    setOpenNPEForm(false);
    setTimeout(() => setOpenAnnouncement(true), 500);
  };

  const handleInviteDismiss = () => {
    setCoborrowerInviteIsOpen(false);
    setTimeout(() => setOpenAnnouncement(true), 500);
  };

  const addNPE = useCallback(
    async ({ email }) => {
      const response = await API.post({
        url: `/save-to-loan/add-npe?loanId=${loan?.id}`,
        data: {
          email: email?.trim(),
        },
      });

      await API.post({
        url: `/save-to-loan/flags?entity=borrower&loanId=${loan?.id}`,
        data: {
          offeredAddingNPEAsCoborrower: true,
        },
      });

      if ("error" in response) {
        if (response.error.includes("Email")) {
          setEmailError(response.error);
        } else {
          setEmailError(undefined);
          alert(response.error);
        }
      } else {
        setEmailError(undefined);
        setOpenNPEForm(false);
        setIsModalOpen(false);
        await API.post({
          url: `/save-to-loan/flags?entity=borrower&loanId=${loan?.id}`,
          data: {
            offeredAddingNPEAsCoborrower: true,
          },
        });
      }
    },
    [loan?.id, setIsModalOpen],
  ) as SubmitHandler<NPEForm>;

  return {
    openAnnouncement,
    handleCloseNPEForm,
    handleInviteDismiss,
    setOpenNPEForm,
    addNPE,
    handleSelectNo,
    handleAccept,
    openNPEForm,
    coborrowerInviteIsOpen,
    setCoborrowerInviteIsOpen,
    emailError,
    setEmailError,
  };
};

export default useNPEAnnouncement;
