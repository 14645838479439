export const deniedTypes = [
  {
    value: "WeDoNotGrantCreditToAnyApplicantOnTheTermsAndConditionsRequested",
    label:
      "We don't grant credit to any applicant on the terms and conditions requested",
  },
  {
    value: "ValueorTypeofCollateralNotSufficient",
    label: "Value or Type of Collateral not sufficient",
  },
  {
    value: "unacceptablePaymentRecordOnPreviousMortgage",
    label: "Unacceptable Payment Record On Previous Mortgage",
  },
  {
    value: "unacceptableTypeOfCreditReferencesProvided",
    label: "Unacceptable type of credit references provided",
  },
  { value: "unacceptableProperty", label: "Unacceptable Property" },
  {
    value: "unacceptableLeaseholdEstate",
    label: "Unacceptable leasehold estate",
  },
  { value: "unableToVerifyIncome", label: "Unable to Verify Income" },
  {
    value: "unableToVerifyEmployment",
    label: "Unable to verify employment",
  },
  { value: "temporaryResidence", label: "Temporary Residence" },
  {
    value: "temporaryOrIrregularEmployment",
    label: "Temporary or irregular employment",
  },
  {
    value: "poorCreditPerformanceWithUs",
    label: "Poor credit performance with us",
  },
  { value: "noCreditFile", label: "No credit file" },
  { value: "limitCreditExperience", label: "Limited credit experience" },
  {
    value: "insufficientPropertyData",
    label: "Insufficient Property Data",
  },
  { value: "insufficientCreditFile", label: "Insufficient Credit File" },
  {
    value: "incomeInsufficientForAmountOfCreditRequested",
    label: "Income Insufficient for amount of credit requested",
  },
  {
    value: "delinquentPastOrPresentCreditObligationsWithOther",
    label: "Delinquent past or present credit obligations with other",
  },
  {
    value: "bankruptcyPastOrPresent",
    label: "Bankruptcy Past or Present",
  },
  {
    value: "foreclosureRepoIndicator",
    label: "Foreclosure or repossession",
  },
  {
    value: "unableToVerifyCreditReferences",
    label: "Unable to verify credit references",
  },
  {
    value: "creditApplicationWithdrawn",
    label: "Credit Application Withdrawn",
  },
  {
    value: "excessiveObligationsInRelationToIncome",
    label: "Excessive obligations in relation to income (Income Section)",
  },
];
