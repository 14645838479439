import DrawHELOC from "./DrawHELOC";
import Documents from "./Documents";
import AddressBox from "./AddressBox";
import LoanBalance from "./LoanBalance";
import ArchivedReason from "./ArchivedReason";
import Typography from "@mui/material/Typography";
import HomeMonitorLoader from "./HomeMonitorLoader";
import QuestionsBox from "components/CQuestionChat";
import ScheduledPayments from "./ScheduledPayments";
import { HomeMonitorContextProvider } from "./useHomeMonitorContext";
import { Box, Container, useMediaQuery, useTheme } from "@mui/material";
import useLoan from "./useLoan";
import SLSInfoBox from "./SLSInfoBox";
import ArchivedBox from "./ArchivedBox";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";
import PaymentHistoryBox from "./PaymentsHistory";
import { useNavigate, useSearchParams } from "react-router-dom";
import useUser from "context/UserCustomer/useUserCustomerContext";
import { useEffect } from "react";

const HomeMonitor: React.FC<{ isAdmin: boolean }> = ({ isAdmin }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { loan, loading } = useLoan({ isAdmin });
  const [searchParams] = useSearchParams();
  const loanId = searchParams.get("loanId");
  const isServicerNFTYDoor = loan?.servicer === "NFTYDoor";
  const { privateLabel } = usePrivateLabel();
  const isNfty = privateLabel?.isNFTYDoor;
  const isPrivateLabel = privateLabel !== undefined;
  const { selectedLoanId, setSelectedLoanId } = useUser();

  const isArchived = loan?.borrowerFlags?.archiveType;

  useEffect(() => {
    if (!isAdmin) {
      if (loanId && !selectedLoanId) {
        setSelectedLoanId(loanId);
      } else if (!loanId) {
        navigate("/loans", { replace: true });
      }
    }
  }, [loanId, selectedLoanId, setSelectedLoanId, loan, navigate, isAdmin]);

  return (
    <HomeMonitorContextProvider isAdmin>
      <HomeMonitorLoader />
      <Container
        maxWidth="md"
        disableGutters={useMediaQuery(theme.breakpoints.only("xs"))}
      >
        <Box>
          <Typography
            mt={4}
            mb={5}
            variant="h1"
            lineHeight={1}
            color={theme.palette.text.primary}
            letterSpacing={-0.8}
            px={{ xs: 2, md: 0 }}
            fontSize={{ xs: "2.5rem", md: "4rem" }}
          >
            Home Monitor
          </Typography>
        </Box>
        {!loading && (
          <Box>
            <AddressBox />
            {isArchived && (
              <>
                <ArchivedReason />
                <Documents isArchived={isArchived} />
              </>
            )}
            {!isArchived && <PaymentHistoryBox />}
            {!isServicerNFTYDoor && !isArchived && <SLSInfoBox />}
            {!isArchived && isServicerNFTYDoor && isPrivateLabel && isNfty && (
              <DrawHELOC />
            )}
            {!isArchived && isServicerNFTYDoor && isPrivateLabel && (
              <ScheduledPayments isAdmin />
            )}
            {!isArchived && isServicerNFTYDoor && isPrivateLabel && (
              <LoanBalance />
            )}
            {!isArchived && <Documents />}
            {!isArchived && isServicerNFTYDoor && <QuestionsBox />}
            {isArchived && <ArchivedBox />}
          </Box>
        )}
      </Container>
    </HomeMonitorContextProvider>
  );
};

export default HomeMonitor;
