import useUser from "context/UserCustomer/useUserCustomerContext";
import { useEffect, useState } from "react";
import Loan from "typedef/Loan";
import API from "utils/API";

const useLoan = () => {
  const [loan, setLoan] = useState<Loan>();
  const { user, logout, selectedLoanId } = useUser();

  useEffect(() => {
    if (user && selectedLoanId) {
      API.get<Loan>(`/get/my-loan?id=${selectedLoanId}`).then((response) => {
        if ("error" in response) {
          console.error(response);
        } else {
          setLoan(response.data);
        }
      });
    }
  }, [user, logout, selectedLoanId]);

  return loan;
};

export default useLoan;
