import CTable from "./CTable";
import { Fragment } from "react";
import { DocumentsHook } from "./useDocuments";
import PublishIcon from "@mui/icons-material/Publish";
import CUploadDocumentDialog from "./UploadDocumentDialog";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import useHomeMonitor from "../useHomeMonitorContext";
import ArchivedDocuments from "../ArchivedDocuments";

const Documents: React.FC<{ isArchived?: string }> = ({ isArchived }) => {
  const theme = useTheme();
  const homeMonitorContext = useHomeMonitor();
  const documentsHook = homeMonitorContext.documentsHook as DocumentsHook;

  return (
    <Fragment>
      <Box
        mt={1}
        display={"flex"}
        alignItems={"center"}
        bgcolor={theme.palette.background.paper}
        px={{ xs: 2, md: 6 }}
        pt={{ xs: 4, md: 5 }}
        justifyContent={"space-between"}
        sx={
          documentsHook.documents.pageItems.length !== 0
            ? { borderTopLeftRadius: 33, borderTopRightRadius: 33 }
            : { borderRadius: 10 }
        }
      >
        <Typography variant="h5" fontFamily={"Pro Text Semibold"}>
          {isArchived ? "Loan Documents" : "Documents"}
        </Typography>
        {!homeMonitorContext.loan?.borrowerFlags?.archiveType && (
          <>
            <IconButton
              size="large"
              onClick={() => documentsHook.setOpenDialog(true)}
            >
              <Box
                bgcolor={theme.palette.primary.main}
                width={"50px"}
                height={"50px"}
                borderRadius={"50%"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <PublishIcon htmlColor={theme.palette.background.paper} />
              </Box>
            </IconButton>
          </>
        )}
      </Box>
      {documentsHook.documents.pageItems.length !== 0 && (
        <Box
          mt={0}
          bgcolor={theme.palette.background.paper}
          px={{ xs: 2, md: 6 }}
          py={{ xs: 4, md: 5 }}
          sx={{ borderBottomLeftRadius: 33, borderBottomRightRadius: 33 }}
        >
          {!isArchived ? (
            <CTable
              documents={documentsHook.documents}
              currentPage={documentsHook.currentPage}
              handleChange={documentsHook.handleChange}
            />
          ) : (
            <ArchivedDocuments
              documents={documentsHook.documents}
              currentPage={documentsHook.currentPage}
              handleChange={documentsHook.handleChange}
            />
          )}
        </Box>
      )}
      <CUploadDocumentDialog
        open={documentsHook.openDialog}
        handleClose={() => documentsHook.setOpenDialog(false)}
        refetch={() => documentsHook.setRefetch(!documentsHook.refetch)}
      />
    </Fragment>
  );
};

export default Documents;
