import { Box, Typography, useTheme } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import Loan from "typedef/Loan";
import { useGetDtiByCreditScore } from "utils/useGetDtiByCreditScore";

const WarningMessage = ({ loan }: { loan?: Loan }) => {
  const theme = useTheme();

  const dtiItems = useGetDtiByCreditScore();

  const maxdti = dtiItems.dti;

  if (
    (loan &&
      maxdti !== undefined &&
      loan?.initialOffer?.debtToIncomeRatio > maxdti) ||
    loan?.initialOffer?.useProceeds === "Debt Consolidation"
  ) {
    return (
      <Box
        color={theme.palette.text.primary}
        boxShadow="rgba(0,0,0,0.16) 0px 1px 4px"
        sx={{
          borderRadius: "24px",
          mt: "40px",
          mb: "20px",
        }}
        paddingLeft={{ xs: 0, md: 0 }}
        paddingRight={{ xs: 0, md: 0 }}
        marginLeft={{ xs: 2, md: 0 }}
        marginRight={{ xs: 2, md: 0 }}
        bgcolor={theme.palette.background.paper}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems={"center"}
          gap="18px"
          sx={{ px: { xs: "20px", md: "70px" }, py: "32px" }}
        >
          <WarningIcon style={{ color: "#EAB308" }} />
          <Typography fontSize="20px">
            Please be advised that this offer is based on doing a debt
            consolidation.
          </Typography>
        </Box>
      </Box>
    );
  }

  if (
    loan?.borrowerSteps?.appraisalVerification &&
    loan?.borrowerFlags?.enableAppraisal
  ) {
    return (
      <Box
        sx={{
          borderRadius: "33px",
          mt: "40px",
          mb: "20px",
        }}
        bgcolor={theme.palette.background.paper}
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems={"center"}
          gap="18px"
          sx={{ px: "70px", py: "32px" }}
        >
          <WarningIcon style={{ color: "#EAB308" }} />
          <Typography fontSize="20px">
            Special note regarding your property value. We were not able to
            confirm your property value with an AVM (Automated Valuation Model).
            We have ordered a BPO (Broker Price Opinion) and expect the results
            generally within 2-3 days. If the report comes back with a valuation
            that supports your application, then there will be no delays.
            However, if the BPO value is lower than expected, it might cause
            your offer to change and/or be withdrawn.
          </Typography>
        </Box>
      </Box>
    );
  }

  return null;
};

export default WarningMessage;
