import { Box, Typography } from "@mui/material";
import styles from "./styles";
import CDialog from "components/CDialog";
import CButton from "components/CButton";
import useUser from "context/UserCustomer/useUserCustomerContext";

interface DeniedLoanModalProps {
  open: boolean;
  message: string;
}

export const DeniedLoanModal = ({ open, message }: DeniedLoanModalProps) => {
  const { logout } = useUser();

  const handleClose = () => {
    logout();
  };

  return (
    <CDialog open={open} className="denied-loan-modal">
      <Box>
        <Typography variant="h3">Denied Loan</Typography>
        <Typography variant="body1" sx={styles.shortDescription}>
          This loan has been denied and an adverse action letter will be sent.
        </Typography>
        <Typography variant="body2" sx={styles.description}>
          {message}
        </Typography>
        <Box sx={styles.containerButton}>
          <CButton
            variant="contained"
            sx={styles.buttonStyles}
            onClick={handleClose}
            name={"close-and-log-out-denied-loan-modal"}
            aria-label={"close-and-log-out-denied-loan-modal"}
          >
            Close and Log Out
          </CButton>
        </Box>
      </Box>
    </CDialog>
  );
};
