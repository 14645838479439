import { Fragment, useContext } from "react";
import CDetailLine from "../CDetailLine";
import { Box, Typography, useTheme } from "@mui/material";
import dollarFormatter from "utils/FormatterDollar";
import { HomeMonitorContext } from "../useHomeMonitorContext";
import useMonthlyPaymentsDone from "../useMonthlyPaymentsDone";

const LoanBalance: React.FC = () => {
  const theme = useTheme();
  const { loan } = useContext(HomeMonitorContext);
  const currentDuePaymentNumber = useMonthlyPaymentsDone(loan);

  if (loan?.loanType === "HELOC") return null;
  else
    return (
      <Fragment>
        <Box
          mt={1}
          bgcolor={theme.palette.background.paper}
          px={{ xs: 2, md: 6 }}
          py={{ xs: 4, md: 5 }}
          sx={{ borderTopLeftRadius: 33, borderTopRightRadius: 33 }}
        >
          <Typography variant="h5" fontFamily={"Pro Text Semibold"}>
            Your Loan balance is{" "}
            <span style={{ color: theme.palette.primary.main }}>
              {dollarFormatter.format(
                (loan?.borrowerSteps?.fundingVerification
                  ?.amortizationSchedule?.[currentDuePaymentNumber]
                  ?.principalBalance ?? 0) +
                  (loan?.borrowerSteps?.fundingVerification
                    .amortizationSchedule?.[currentDuePaymentNumber]
                    .principalPayment ?? 0),
              )}
            </span>
          </Typography>
        </Box>
        <Box
          mt={0.4}
          bgcolor={theme.palette.background.paper}
          px={{ xs: 2, md: 6 }}
          py={{ xs: 4, md: 5 }}
          sx={{ borderBottomLeftRadius: 33, borderBottomRightRadius: 33 }}
        >
          <Typography
            mb={2}
            variant="subtitle1"
            color={theme.palette.text.secondary}
            whiteSpace={"nowrap"}
          >
            Loan Details
          </Typography>
          <CDetailLine
            firstColumn={"Payments remaining"}
            secondColumn={String(
              (loan?.borrowerSteps?.fundingVerification.amortizationSchedule
                ?.length ?? 0) - currentDuePaymentNumber,
            )}
          />
        </Box>
      </Fragment>
    );
};

export default LoanBalance;
