import CHow from "./CHow";
import { Fragment } from "react";
import CHomeEquity from "./CHomeEquity";
import CBanner from "screens/Home/CBanner";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import {
  Box,
  Grid,
  useTheme,
  Container,
  Typography,
  useMediaQuery,
} from "@mui/material";

import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import CottageOutlinedIcon from "@mui/icons-material/CottageOutlined";
import HandymanOutlinedIcon from "@mui/icons-material/HandymanOutlined";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import AirportShuttleOutlinedIcon from "@mui/icons-material/AirportShuttleOutlined";
import { usePrivateLabel } from "context/PrivateLabelContext/UsePrivateLabelContextProvider";

export default function Home() {
  const theme = useTheme();
  const { privateLabel } = usePrivateLabel();

  return (
    <Fragment>
      <CBanner />
      <CHomeEquity />
      <CHow />
      <Container
        maxWidth="lg"
        disableGutters={useMediaQuery(theme.breakpoints.only("xs"))}
      >
        <Box
          bgcolor={theme.palette.background.paper}
          p={{ md: 8, xs: 2 }}
          sx={
            privateLabel
              ? { borderBottomLeftRadius: 33, borderBottomRightRadius: 33 }
              : null
          }
        >
          <Typography
            variant="h3"
            mb={"1.5rem"}
            color={theme.palette.text.primary}
            letterSpacing={-1.4}
            fontSize={{ xs: "2rem", md: "3rem" }}
            role="heading"
            aria-level={4}
          >
            What can you use a{" "}
            <>
              <span style={{ color: theme.palette.primary.main }}>
                {privateLabel?.lenderName}
              </span>{" "}
              loan for?
            </>
          </Typography>
          <Box flexGrow={1}>
            <Grid
              container
              spacing={{ xs: 1, md: 3 }}
              columns={{ xs: 4, sm: 6, md: 12 }}
            >
              <Grid item xs={2} md={4} key={1}>
                <CottageOutlinedIcon
                  data-acsb-hidden="true"
                  data-acsb-force-hidden="true"
                  htmlColor={theme.palette.primary.main}
                  sx={{ fontSize: { xs: "3rem", md: "4.5rem" } }}
                />
                <Typography
                  variant="h5"
                  color={theme.palette.text.primary}
                  letterSpacing={-0.8}
                  fontSize={{ xs: "1.2rem", md: "1.5rem" }}
                  role="heading"
                  aria-level={4}
                >
                  For another property
                </Typography>
              </Grid>
              <Grid item xs={2} md={4} key={2}>
                <HandymanOutlinedIcon
                  htmlColor={theme.palette.primary.main}
                  sx={{ fontSize: { xs: "3rem", md: "4.5rem" } }}
                />
                <Typography
                  variant="h5"
                  color={theme.palette.text.primary}
                  letterSpacing={-0.8}
                  fontSize={{ xs: "1.2rem", md: "1.5rem" }}
                >
                  Home improvement
                </Typography>
              </Grid>
              <Grid item xs={2} md={4} key={3}>
                <AirportShuttleOutlinedIcon
                  htmlColor={theme.palette.primary.main}
                  sx={{ fontSize: { xs: "3rem", md: "4.5rem" } }}
                />
                <Typography
                  variant="h5"
                  color={theme.palette.text.primary}
                  letterSpacing={-0.8}
                  fontSize={{ xs: "1.2rem", md: "1.5rem" }}
                >
                  Finance a car
                </Typography>
              </Grid>
              <Grid item xs={2} md={4} key={4}>
                <SchoolOutlinedIcon
                  htmlColor={theme.palette.primary.main}
                  sx={{ fontSize: { xs: "3rem", md: "4.5rem" } }}
                />
                <Typography
                  variant="h5"
                  color={theme.palette.text.primary}
                  letterSpacing={-0.8}
                  fontSize={{ xs: "1.2rem", md: "1.5rem" }}
                >
                  Tuition
                </Typography>
              </Grid>
              <Grid item xs={2} md={4} key={5}>
                <ShoppingBagOutlinedIcon
                  htmlColor={theme.palette.primary.main}
                  sx={{ fontSize: { xs: "3rem", md: "4.5rem" } }}
                />
                <Typography
                  variant="h5"
                  color={theme.palette.text.primary}
                  letterSpacing={-0.8}
                  fontSize={{ xs: "1.2rem", md: "1.5rem" }}
                >
                  Other major purchases
                </Typography>
              </Grid>
              <Grid item xs={2} md={4} key={6}>
                <PaidOutlinedIcon
                  htmlColor={theme.palette.primary.main}
                  sx={{ fontSize: { xs: "3rem", md: "4.5rem" } }}
                />
                <Typography
                  variant="h5"
                  color={theme.palette.text.primary}
                  letterSpacing={-0.8}
                  fontSize={{ xs: "1.2rem", md: "1.5rem" }}
                >
                  To better manage your finances
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {privateLabel ? null : (
          <Box
            mt={0.5}
            p={{ md: 8, xs: 2 }}
            bgcolor={theme.palette.background.paper}
            sx={{ borderBottomLeftRadius: 33, borderBottomRightRadius: 33 }}
          >
            <Typography
              variant="h4"
              color={theme.palette.text.primary}
              fontSize={{ md: "2.5rem", xs: "1.6rem" }}
              role="heading"
              aria-level={5}
            >
              Still have questions? Check out our{" "}
              <span style={{ color: theme.palette.primary.main }}>chat </span>
              with one of our Customer Success team members.
            </Typography>
          </Box>
        )}
      </Container>
    </Fragment>
  );
}
