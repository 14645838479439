import API from "utils/API";
import Loan from "typedef/Loan";
import { useCallback, useEffect, useState } from "react";
import useUser from "context/UserCustomer/useUserCustomerContext";

const useLoan = () => {
  const [loan, setLoan] = useState<Loan>();
  const { user, loan: loanAdmin, selectedLoanId } = useUser();

  const getAndSetCoborrowerLoan = useCallback(async () => {
    if (user) {
      const response = await API.get<Loan>(`/get/my-loan?id=${selectedLoanId}`);

      if ("error" in response) {
        console.error(response.error);
      } else {
        setLoan(response.data);
      }
    }
  }, [user, selectedLoanId]);

  useEffect(() => {
    getAndSetCoborrowerLoan();
    const timeoutId = setInterval(getAndSetCoborrowerLoan, 2000);
    return () => {
      clearInterval(timeoutId);
    };
  }, [getAndSetCoborrowerLoan]);

  useEffect(() => {
    if (loanAdmin) {
      setLoan(loanAdmin);
    }
  }, [loanAdmin]);

  return loan;
};

export default useLoan;
