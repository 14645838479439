import { Box } from "@mui/system";
import { Skeleton, useTheme } from "@mui/material";

const CLoaderOffer = () => {
  const theme = useTheme();
  return (
    <Box>
      <Box p={1}>
        <Skeleton width={"100%"} height={"1.5rem"} />
      </Box>
      <Box
        sx={{
          backgroundImage:
            "linear-gradient(to right, lightgray 50%, transparent 50%)",
          backgroundPosition: "0 60%",
          backgroundRepeat: "repeat-x",
          backgroundSize: "8px 1px",
        }}
        display="flex"
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Box
          width={100}
          p={1}
          sx={{ backgroundColor: theme.palette.background.paper }}
        >
          <Skeleton width={"100%"} height={"1.5rem"} />
        </Box>
        <Box
          width={100}
          p={1}
          sx={{ backgroundColor: theme.palette.background.paper }}
        >
          <Skeleton width={"100%"} height={"1.5rem"} />
        </Box>
      </Box>
      <Box
        sx={{
          backgroundImage:
            "linear-gradient(to right, lightgray 50%, transparent 50%)",
          backgroundPosition: "0 60%",
          backgroundRepeat: "repeat-x",
          backgroundSize: "8px 1px",
        }}
        display="flex"
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Box
          width={100}
          p={1}
          sx={{ backgroundColor: theme.palette.background.paper }}
        >
          <Skeleton width={"100%"} height={"1.5rem"} />
        </Box>
        <Box
          width={100}
          p={1}
          sx={{ backgroundColor: theme.palette.background.paper }}
        >
          <Skeleton width={"100%"} height={"1.5rem"} />
        </Box>
      </Box>
    </Box>
  );
};

export default CLoaderOffer;
