import { Fragment } from "react";
import { Box, useTheme } from "@mui/material";

type Prop = {
  loading: boolean;
};

const CLoader: React.FC<Prop> = ({ loading }) => {
  const theme = useTheme();
  if (loading) {
    return (
      <Fragment>
        <Box
          top={0}
          bottom={0}
          zIndex={9999}
          width={"100%"}
          height={"100%"}
          position={"fixed"}
          bgcolor={theme.palette.background.default}
          left={0}
          right={0}
        >
          <div className="loadingio-spinner-ball-7cbcr8kgvgk">
            <div
              className="ldio-atnwm437r8"
              style={{ backgroundColor: theme.palette.background.default }}
            >
              <div
                style={{ backgroundColor: theme.palette.primary.main }}
              ></div>
            </div>
          </div>
        </Box>
      </Fragment>
    );
  } else {
    return null;
  }
};

export default CLoader;
